import React from 'react'
import Image from '../Image/Index'
import { Link } from '@inertiajs/inertia-react'


const ProcessSection = ({ modalIsOpen, setModalIsOpen }) => {

    return (
        <div className="bg-white py-12">
            <div className="max-w-7xl mx-auto">
                <div className='block sm:flex justify-center items-center px-4'>
                    <h2 className="text-4xl sm:text-4xl py-5 relative">
                        <span className="before:block before:absolute"> How can Gurulabs help </span>
                        <span className="text-green-500 relative">
                            grow your business?
                        </span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="291" height="3" fill="none" className="hidden lg:block left-112 absolute"><path stroke="#48BB78" strokeWidth="2" d="M0 1.385h291" /></svg>
                    </h2>
                </div>
            </div>
            <div>
                <div className="max-w-7xl mx-auto py-16">
                    <div className="block lg:flex justify-center items-center py-8 md:py-16 lg:py-24">
                        <div className="w-full lg:w-1/2 px-4">
                            <h3 className="text-4xl flex flex-wrap items-end">
                                <svg xmlns="http://www.w3.org/2000/svg" className='mr-4' width="23" height="60" fill="none"><path fill="#48BB78" d="M.187 13.218V.338h22.437v59.13H8.206v-46.25H.187Z" /></svg>
                                You before found Gurulabs
                            </h3>
                            <p className="py-4 text-gray-700 text-base">
                                Before working with Gurulabs, many businesses struggle to gain traction online and attract the attention of their target audience. With our expert team, however, businesses gain access to a range of services and solutions designed to help them overcome these challenges and start strong.
                            </p>
                            <h4 className="text-3xl flex items-end py-4">Guru's Approach</h4>
                            <p className="py-4 text-gray-700 text-base">
                                Our team approaches every client's business as if it were our own. We align their objectives and collaborate the potential of their business.
                            </p>
                            <button onClick={() => {
                                setModalIsOpen(!modalIsOpen)
                                mixpanel.track("Schedule a meeting button clicked")
                            }} className="text-green-500 flex items-center" >
                                Schedule a meeting
                                <svg xmlns="http://www.w3.org/2000/svg" className="ml-2" width="19" height="11" fill="none"><g fill="#48BB78" clipPath="url(#a)"><path d="M13.27 10.465a.686.686 0 0 1-.633-.947.686.686 0 0 1 .149-.223l3.629-3.63-4.027-4.027a.686.686 0 1 1 .97-.97l4.512 4.518a.686.686 0 0 1 0 .967l-4.117 4.117a.686.686 0 0 1-.484.195Z" /><path d="M17.386 6.352H.686a.686.686 0 1 1 0-1.372h16.7a.686.686 0 0 1 0 1.372Z" /></g><defs><clipPath id="a"><path fill="#fff" d="M0 .469h18.069v10H0z" /></clipPath></defs></svg>
                            </button>
                        </div>
                        <div className="w-full lg:w-1/2 px-4">
                            <Image
                                src="frontend/images/png/process-01.webp"
                                fallback="frontend/images/png/process-01.png"
                                className="h-[328px] w-[238px] mx-auto"
                                alt="Lead Generation Process"
                            />
                        </div>
                    </div>
                    <div className="block lg:flex flex-row-reverse justify-center items-center py-8 md:py-16 lg:py-24">
                        <div className="w-full lg:w-1/2 px-4">
                            <h3 className="text-4xl flex flex-wrap items-end">
                                <svg xmlns="http://www.w3.org/2000/svg" className='mr-4' width="42" height="61" fill="none"><path fill="#48BB78" d="M1.159 49.896c1.836-1.458 2.673-2.133 2.511-2.025 5.292-4.374 9.45-7.965 12.474-10.773 3.078-2.808 5.67-5.751 7.776-8.83 2.106-3.077 3.159-6.074 3.159-8.99 0-2.214-.513-3.942-1.539-5.184-1.026-1.242-2.565-1.863-4.617-1.863s-3.672.783-4.86 2.349c-1.134 1.512-1.701 3.672-1.701 6.48H.997c.108-4.59 1.08-8.424 2.916-11.502 1.89-3.078 4.347-5.346 7.371-6.804C14.362 1.296 17.764.567 21.49.567c6.426 0 11.259 1.647 14.499 4.94 3.294 3.295 4.941 7.588 4.941 12.88 0 5.778-1.971 11.15-5.913 16.119-3.942 4.914-8.964 9.72-15.066 14.418h21.87v11.259H1.159V49.896Z" /></svg>
                                Partnering for Success
                            </h3>
                            <p className="py-4 text-gray-700 text-base">
                                During the process of working with Gurulabs, businesses receive personalized attention and expert guidance every step of the way. Our team of professionals works collaboratively with each client to develop tailored solutions that meet their unique needs and goals, with ongoing communication and support to ensure success.
                            </p>
                            <h4 className="text-3xl flex items-end py-4">Guru's Strategy</h4>
                            <p className="py-4 text-gray-700 text-base">
                                We design and develop strategies, leverage industry leading tools to increase profit and maximize growth.
                            </p>
                            <button onClick={() => {
                                setModalIsOpen(!modalIsOpen)
                                mixpanel.track("Schedule a meeting button clicked")
                            }} className="text-green-500 flex items-center" >
                                Schedule a meeting
                                <svg xmlns="http://www.w3.org/2000/svg" className="ml-2" width="19" height="11" fill="none"><g fill="#48BB78" clipPath="url(#a)"><path d="M13.27 10.465a.686.686 0 0 1-.633-.947.686.686 0 0 1 .149-.223l3.629-3.63-4.027-4.027a.686.686 0 1 1 .97-.97l4.512 4.518a.686.686 0 0 1 0 .967l-4.117 4.117a.686.686 0 0 1-.484.195Z" /><path d="M17.386 6.352H.686a.686.686 0 1 1 0-1.372h16.7a.686.686 0 0 1 0 1.372Z" /></g><defs><clipPath id="a"><path fill="#fff" d="M0 .469h18.069v10H0z" /></clipPath></defs></svg>
                            </button>
                        </div>
                        <div className="w-full lg:w-1/2 px-4">
                            <Image
                                src="frontend/images/png/process-02.webp"
                                fallback="frontend/images/png/process-02.png"
                                className="h-[491px] w-[467px] mx-auto"
                                alt="Lead Gen Process"
                            />
                        </div>
                    </div>
                    <div className="block lg:flex justify-center items-center py-8 md:py-16 lg:py-24">
                        <div className="w-full lg:w-1/2 px-4">
                            <h3 className="text-4xl flex flex-wrap items-end">
                                <svg xmlns="http://www.w3.org/2000/svg" className='mr-4' width="42" height="62" fill="none"><path fill="#48BB78" d="M1.131 18.14c.216-5.779 2.106-10.234 5.67-13.366C10.365 1.642 15.198.076 21.3.076c4.05 0 7.506.702 10.368 2.106 2.916 1.404 5.103 3.321 6.561 5.751 1.512 2.43 2.268 5.158 2.268 8.181 0 3.564-.891 6.48-2.673 8.748-1.782 2.215-3.861 3.727-6.237 4.537v.323c3.078 1.026 5.508 2.727 7.29 5.103 1.782 2.377 2.673 5.427 2.673 9.153 0 3.349-.783 6.319-2.349 8.91-1.512 2.538-3.753 4.537-6.723 5.995-2.916 1.457-6.399 2.186-10.449 2.186-6.48 0-11.664-1.593-15.552-4.779C2.643 53.105.618 48.299.402 41.873h13.446c.054 2.376.729 4.266 2.025 5.67 1.296 1.35 3.186 2.025 5.67 2.025 2.106 0 3.726-.594 4.86-1.782 1.188-1.242 1.782-2.863 1.782-4.86 0-2.593-.837-4.456-2.511-5.59-1.62-1.188-4.239-1.782-7.857-1.782h-2.592V24.295h2.592c2.754 0 4.968-.459 6.642-1.377 1.728-.971 2.592-2.672 2.592-5.102 0-1.944-.54-3.456-1.62-4.536-1.08-1.08-2.565-1.62-4.455-1.62-2.052 0-3.591.62-4.617 1.863-.972 1.242-1.539 2.78-1.701 4.617H1.131Z" /></svg>
                                From Leads to Loyal Clients
                            </h3>
                            <p className="py-4 text-gray-700 text-base">
                                After working with Gurulabs, businesses experience a remarkable transformation in their online presence. Our expert lead generation solutions help businesses achieve increased online visibility, higher engagement rates, and a significant boost in conversion rates and revenue. Our tailored approach has been proven to unlock the full potential of online businesses and help them achieve their goals.
                            </p>
                            <h4 className="text-3xl flex items-end py-4">Guru's Implementation</h4>
                            <p className="py-4 text-gray-700 text-base">
                                Our team believes in addressing the who, where, when, and how of reaching the desired goals and objectives.
                            </p>
                            <button onClick={() => {
                                setModalIsOpen(!modalIsOpen)
                                mixpanel.track("Schedule a meeting button clicked")
                            }} className="text-green-500 flex items-center" >
                                Schedule a meeting
                                <svg xmlns="http://www.w3.org/2000/svg" className="ml-2" width="19" height="11" fill="none"><g fill="#48BB78" clipPath="url(#a)"><path d="M13.27 10.465a.686.686 0 0 1-.633-.947.686.686 0 0 1 .149-.223l3.629-3.63-4.027-4.027a.686.686 0 1 1 .97-.97l4.512 4.518a.686.686 0 0 1 0 .967l-4.117 4.117a.686.686 0 0 1-.484.195Z" /><path d="M17.386 6.352H.686a.686.686 0 1 1 0-1.372h16.7a.686.686 0 0 1 0 1.372Z" /></g><defs><clipPath id="a"><path fill="#fff" d="M0 .469h18.069v10H0z" /></clipPath></defs></svg>
                            </button>
                        </div>
                        <div className="w-full lg:w-1/2 px-4">
                            <Image
                                src="frontend/images/png/process-03.webp"
                                fallback="frontend/images/png/process-03.png"
                                className="h-[452px] w-[256px] mx-auto"
                                alt="Gurulabs Lead Generation Process"
                            />
                        </div>
                    </div>
                    <div>
                        <Image
                            src="/frontend/images/png/process-04.webp"
                            fallback="/frontend/images/png/process-04.png"
                            className="w-full mx-auto"
                            height="720px" width="1080px"
                            alt="Gurulabs Dashboard"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ProcessSection
