import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Link } from '@inertiajs/inertia-react';

function CookiePolicy() {
    const [cookie, setCookie] = useCookies(['cookieConsent']);

    // Load GTM and update consent based on the current cookie state
    useEffect(() => {
        if (cookie.cookieConsent) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'consent_update',
                'analytics_storage': 'granted',
                'ad_storage': 'granted',
                'event_category': 'Consent',
                'event_action': 'Update',
                'event_label': 'Consent granted'
            });
        } else {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'consent_update',
                'analytics_storage': 'denied',
                'ad_storage': 'denied'
            });
        }
    }, [cookie.cookieConsent]);

    const handleCookieConsent = () => {
        setCookie('cookieConsent', true, {
            path: '/',
            maxAge: 31536000, // 1 year
            sameSite: 'Strict',
        });

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'consent_update',
            'analytics_storage': 'granted',
            'ad_storage': 'granted'
        });
    };

    return (
        <div className="pointer-events-none fixed inset-x-0 bottom-0 px-6 pb-6">
            <div className="pointer-events-auto ml-auto max-w-xl rounded-xl bg-white p-6 shadow-lg ring-1 ring-gray-900/10">
                <p className="text-sm leading-6 text-gray-900">
                    This site uses cookies. We and selected third parties use cookies (or similar technologies) for technical purposes, to enhance and analyze site usage, to support our marketing efforts, and for other purposes described in our Cookie Policy. See our{' '}
                    <Link href="/privacy-policy" className="font-semibold text-emerald-600">
                        cookie policy
                    </Link>.
                </p>
                <div className="mt-4 flex items-center gap-x-5">
                    <button
                        onClick={handleCookieConsent}
                        type="button"
                        className="rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                    >
                        Accept all
                    </button>
                    <Link
                        href="/privacy-policy"
                        className="text-sm font-semibold leading-6 text-gray-900"
                    >
                        Learn More
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default CookiePolicy;
