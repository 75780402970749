import React, { useState } from 'react';

const LinkedInCostCalculator = () => {
    const [numberOfInMails, setNumberOfInMails] = useState(500);
    const [costPerSend, setCostPerSend] = useState(2.00);
    const [totalCost, setTotalCost] = useState(1000.00);
    const [currency, setCurrency] = useState("USD");

    const calculateTotalCost = () => {
        const total = numberOfInMails * costPerSend;
        setTotalCost(total);
    };

    const resetDefaults = () => {
        setNumberOfInMails(500);
        setCostPerSend(2.00);
        setTotalCost(1000.00);
        setCurrency("USD");
    };

    const validateNumberInput = (e, setter) => {
        const value = e.target.value;
        if (!isNaN(value) && value >= 0) {
            setter(Number(value));
        }
    };

    return (
        <section>
            <div className="">
                <div className="max-w-lg mx-auto mt-8 p-6 bg-gray-100 rounded-md shadow-md">
                    <h2 className="text-2xl font-semibold mb-4">LinkedIn Ads Cost Calculator</h2>
                    <div className="mb-4">
                        <label htmlFor="numberOfInMails" className="block mb-1">Number of InMails Sent:</label>
                        <input
                            type="text"
                            id="numberOfInMails"
                            value={numberOfInMails}
                            onChange={(e) => validateNumberInput(e, setNumberOfInMails)}
                            className="border-gray-300 rounded-md p-2 w-full"
                            min="0"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="costPerSend" className="block mb-1">Estimated Cost Per Send (CPS):</label>
                        <input
                            type="text"
                            id="costPerSend"
                            value={costPerSend}
                            step="0.01"
                            onChange={(e) => validateNumberInput(e, setCostPerSend)}
                            className="border-gray-300 rounded-md p-2 w-full"
                            min="0"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="currency" className="block mb-1">Currency:</label>
                        <select
                            id="currency"
                            value={currency}
                            onChange={(e) => setCurrency(e.target.value)}
                            className="border-gray-300 rounded-md p-2 w-full"
                        >
                            <option value="USD">USD</option>
                            <option value="EUR">EUR</option>
                            <option value="GBP">GBP</option>
                        </select>
                    </div>
                    <div className="mb-4 flex justify-between">
                        <button
                            onClick={calculateTotalCost}
                            className="px-4 py-2 button text-gray-700 rounded hover:bg-blue-600"
                        >
                            Calculate Cost
                        </button>
                        <button
                            onClick={resetDefaults}
                            className="px-4 py-2 bg-gray-400 text-white rounded hover:bg-gray-500"
                        >
                            Reset
                        </button>
                    </div>
                    <div className="mb-4">
                        <h3 className="text-xl">
                            LinkedIn Ads Cost: {currency} {totalCost.toFixed(2)}
                        </h3>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LinkedInCostCalculator;
