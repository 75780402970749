import React from 'react';
import Select from 'react-select';
import { Controller } from 'react-hook-form';


const businessTypeOptions = [
    { value: 'Product', label: 'Product' },
    { value: 'Service', label: 'Service' },
]

const businessProblems = [
    { value: 'Getting Leads', label: 'Getting Leads' },
    { value: 'Making Sales', label: 'Making Sales' },
    { value: 'Recruiting and hiring', label: 'Recruiting and hiring' },
    { value: 'What to Sell', label: 'What to Sell' },
    { value: 'Content Videos', label: 'Content Videos' },
    { value: 'Search Engine Optimization', label: 'Search Engine Optimization' },
    { value: 'Website Traffic', label: 'Website Traffic' },
    { value: 'Other', label: 'Other' }
]

const socialMediaoptions = [
    { value: 'Facebook', label: 'Facebook' },
    { value: 'Instagram', label: 'Instagram' },
    { value: 'LinkedIn', label: 'LinkedIn' },
    { value: 'Twitter', label: 'Twitter' },
    { value: 'TikTok', label: 'TikTok' },
    { value: 'YouTube', label: 'YouTube' },
    { value: 'Pinterest', label: 'Pinterest' },
    { value: 'Other', label: 'Other' },
    { value: 'None', label: 'None' }
]

const monthlyBudgetOptions = [
    { value: '$0 - $1,000', label: 'Under $1,000 [not qualified]' },
    { value: '$1k - $5k', label: '$1k - $5k' },
    { value: '$5k - $10k', label: '$5k - $10k' },
    { value: '$10k - $25k', label: '$10k - $25k' },
    { value: '$25k - $50k', label: '$25k - $50k' },
    { value: '$50k - $100k', label: '$50k - $100k' },
    { value: '$100k+', label: '$100k+' }
];

const BusinessInformation = ({ register, errors, trigger, control }) => {

    // Function to trigger validation for the entire form
    const triggerValidation = async () => {
        await trigger(); // Trigger validation for all fields
    };


    return (
        <div className="bg-white p-6">
            <div className="py-6">
                <h3 className="text-base font-semibold leading-6 text-gray-900">Get a quote in your email</h3>
                <p className="mt-1 text-sm text-gray-500">Please add your information to receive a custom quote</p>
            </div>
            <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6">
                    <label htmlFor="business_type" className="block text-sm font-medium leading-6 text-gray-900">Which best describes your Business?</label>
                    <Controller
                        name="business_type"
                        control={control}
                        rules={{ required: 'Business type field is required' }}
                        render={({ field }) => (
                            <Select
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                    }),
                                }}
                                id="business_type"
                                options={businessTypeOptions}
                                onChange={(selectedOption) => {
                                    field.onChange(selectedOption);
                                    triggerValidation();
                                }}
                                {...field}
                            />
                        )}
                    />
                    {errors.business_type && <span className="text-red-500">{errors.business_type.message}</span>}
                </div>
                <div className="col-span-6">
                    <label htmlFor="business_problem" className="block text-sm font-medium leading-6 text-gray-900">Describe your business requirement</label>
                    <Controller
                        name="business_problem"
                        control={control}
                        rules={{ required: 'Business requirement field is required' }}
                        render={({ field }) => (
                            <Select
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                    }),
                                }}
                                id="business_problem"
                                options={businessProblems}
                                onChange={(selectedOption) => {
                                    field.onChange(selectedOption);
                                    triggerValidation();
                                }}
                                {...field}
                            />
                        )}
                    />
                    {errors.business_problem && <span className="text-red-500">{errors.business_problem.message}</span>}
                </div>
                <div className="col-span-6">
                    <label htmlFor="socialmedia_options" className="block text-sm font-medium leading-6 text-gray-900">On which social media platforms are you currently running paid advertisements?</label>
                    <Controller
                        name="socialmedia_options"
                        control={control}
                        rules={{ required: 'Social Media Options are required' }}
                        render={({ field }) => (
                            <Select
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                    }),
                                }}
                                id="socialmedia_options"
                                isMulti
                                options={socialMediaoptions}
                                onChange={(selectedOption) => {
                                    field.onChange(selectedOption);
                                    triggerValidation();
                                }}
                                {...field}
                            />
                        )}
                    />
                    {errors.socialmedia_options && <span className="text-red-500">{errors.socialmedia_options.message}</span>}
                </div>
                <div className="col-span-6">
                    <label htmlFor="monthly_budget" className="block text-sm font-medium leading-6 text-gray-900">How much is your monthly marketing budget?</label>
                    <Controller
                        name="monthly_budget"
                        control={control}
                        rules={{ required: 'Monthly Budget field is required' }}
                        render={({ field }) => (
                            <Select
                                id="monthly_budget"
                                options={monthlyBudgetOptions}
                                onChange={(selectedOption) => {
                                    field.onChange(selectedOption);
                                    triggerValidation();
                                }}
                                {...field}
                            />
                        )}
                    />
                    {errors && errors.monthly_budget && <span className="text-red-500">{errors.monthly_budget.message}</span>}
                </div>
                <div className="col-span-6">
                    <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">Please describe your business in 1-3 sentences</label>
                    <textarea
                        type="text"
                        name="description"
                        id="description"
                        {...register('description', { required: 'Please describe your business field is required' })}
                        onBlur={triggerValidation}
                        className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                    ></textarea>
                    {errors.description && <span className="text-red-500">{errors.description.message}</span>}
                </div>
            </div>
        </div>
    );
};

export default BusinessInformation;
