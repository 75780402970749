import React, { useMemo } from 'react';
import { useTable } from 'react-table';
import MOCK_DATA from './MOCK_DATA.json';
import { COLUMNS } from './columns';

const LocalSEOPricingTable = () => {
    const columns = useMemo(() => COLUMNS, []);
    const data = useMemo(() => MOCK_DATA, []);

    const tableInstance = useTable({
        columns,
        data,
    });

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

    return (
        <section>
            <div className="max-w-7xl mx-auto py-24 overflow-hidden">
                <table id="pricing" className="min-w-full divide-y divide-gray-300" {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr className="bg-green-25 text-xl" {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th
                                        className="[&:not(:first-child)]:text-center px-3 py-3.5 text-left text-xl font-semibold text-gray-700"
                                        {...column.getHeaderProps()}
                                    >
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, index) => {
                            prepareRow(row);
                            return (
                                <tr className={index % 2 === 0 ? undefined : 'bg-green-25'} {...row.getRowProps()}>
                                    {row.cells.map(cell => (
                                        <td
                                            className="[&:not(:first-child)]:text-center whitespace-nowrap py-4 pl-4 pr-3 text-md border-b border-gray-50 font-medium text-gray-700 sm:pl-3"
                                            {...cell.getCellProps()}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    ))}
                                </tr>
                            );
                        })}
                        <tr>
                            <td colSpan={columns.length} className="text-center py-4">
                                <a
                                    href='https://calendly.com/gurulabs/15min'
                                    className="button text-gray-900 py-2 px-4 rounded"
                                >
                                    Schedule a Meeting
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>
    );
};

export default LocalSEOPricingTable;
