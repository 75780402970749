import React, { useState } from 'react';

const MRRGrowthRateCalculator = () => {
    const [lastMonthMRR, setLastMonthMRR] = useState(1000);
    const [thisMonthMRR, setThisMonthMRR] = useState(1100);

    const calculateGrowthRate = () => {
        if (lastMonthMRR === 0) {
            return "N/A"; // Avoid division by zero
        }
        const growthRate = ((thisMonthMRR - lastMonthMRR) / lastMonthMRR) * 100;
        return growthRate.toFixed(2);
    };

    return (
        <section>
            <div className="py-12">
                <div className="max-w-lg mx-auto mt-8 p-6 bg-gray-100 rounded-md shadow-md">
                    <h2 className="text-2xl font-semibold mb-4">MRR Growth Rate Calculator</h2>
                    <div className="mb-4">
                        <label htmlFor="lastMonthMRR" className="block mb-1">Last Month MRR:</label>
                        <input
                            type="number"
                            id="lastMonthMRR"
                            value={lastMonthMRR}
                            onChange={(e) => setLastMonthMRR(Number(e.target.value))}
                            className="border-gray-300 rounded-md p-2 w-full"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="thisMonthMRR" className="block mb-1">This Month MRR:</label>
                        <input
                            type="number"
                            id="thisMonthMRR"
                            value={thisMonthMRR}
                            onChange={(e) => setThisMomthMRR(Number(e.target.value))}
                            className="border-gray-300 rounded-md p-2 w-full"
                        />
                    </div>
                    <div className="mb-4">
                        <h3 className="text-xl">
                            MRR Growth Rate: {calculateGrowthRate()}%
                        </h3>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MRRGrowthRateCalculator;
