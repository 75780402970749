import React, { useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';

function ColorSwatchGenerator() {
    const [colors, setColors] = useState({
        primary: '#0056b3',
        secondary: '#e83e8c',
        tertiary: '#e9ecef',
        background: '#343a40'
    });

    const generateRandomColor = () => {
        const newColors = {
            primary: '#' + Math.floor(Math.random() * 16777215).toString(16),
            secondary: '#' + Math.floor(Math.random() * 16777215).toString(16),
            tertiary: '#' + Math.floor(Math.random() * 16777215).toString(16),
            background: '#' + Math.floor(Math.random() * 16777215).toString(16)
        };
        setColors(newColors);
    };

    const copyToClipboard = (color) => {
        navigator.clipboard.writeText(color).then(() => {
            toast.success(`Copied ${color} to clipboard!`);
        }, (err) => {
            toast.error('Failed to copy!', err);
            console.error(); // Handle possible errors
        });
    };

    return (
        <div className="text-center m-5">
            <div className="block md:flex justify-around mb-5 py-8">
                <div className="w-full md:w-1/5 border border-gray-900 h-96 bg-[color:var(--primary-color)] text-white p-2 cursor-pointer rounded-lg"
                    style={{ '--primary-color': colors.primary }}
                    onClick={() => copyToClipboard(colors.primary)}>
                    Primary: {colors.primary}
                </div>
                <div className="w-full md:w-1/5 border border-gray-900 h-96 bg-[color:var(--secondary-color)] text-white p-2 cursor-pointer rounded-lg"
                    style={{ '--secondary-color': colors.secondary }}
                    onClick={() => copyToClipboard(colors.secondary)}>
                    Secondary: {colors.secondary}
                </div>
                <div className="w-full md:w-1/5 border border-gray-900 h-96 bg-[color:var(--background-color)] text-black p-2 cursor-pointer rounded-lg"
                    style={{ '--background-color': colors.tertiary }}
                    onClick={() => copyToClipboard(colors.tertiary)}>
                    Tertiary: {colors.tertiary}
                </div>
                <div className="w-full md:w-1/5 border border-gray-900 h-96 bg-[color:var(--text-color)] text-white p-2 cursor-pointer rounded-lg"
                    style={{ '--text-color': colors.background }}
                    onClick={() => copyToClipboard(colors.background)}>
                    Background: {colors.background}
                </div>
            </div>
            <button onClick={generateRandomColor} className="text-lg py-2 px-5 text-gray-700 font-bold rounded button">
                Generate New Colors
            </button>
        </div>
    );
}

export default ColorSwatchGenerator;
