import React, { useState } from 'react';

const questions = [
    {
        question: "Optimization Score is made up of over 50 recommendations to optimize Search campaigns.",
        answers: [
            { text: "True", isCorrect: true },
            { text: "False", isCorrect: false }
        ]
    },
    {
        question: "Which of the following factors wouldn’t change an account’s optimization score?",
        answers: [
            { text: "Changes in auction dynamics", isCorrect: false },
            { text: "Changes in tracked conversions", isCorrect: false },
            { text: "Renaming campaigns", isCorrect: true },
            { text: "Shifts in spend mix", isCorrect: false }
        ]
    },
    {
        question: "How does Google Ads generate responsive search ads?",
        answers: [
            { text: "Google Ads creates them based on existing ad copy, landing pages, and extensions", isCorrect: false },
            { text: "Google Ads creates them based on existing high performing ad copy", isCorrect: false },
            { text: "Google Ads mixes and matches headlines and description lines that have been provided", isCorrect: true },
            { text: "Google Ads mixes and matches headlines and URLs that have been provided", isCorrect: false }
        ]
    },
    {
        question: "How many ads should be implemented per ad group?",
        answers: [
            { text: "One or two", isCorrect: false },
            { text: "Only one", isCorrect: false },
            { text: "Three to five", isCorrect: true },
            { text: "Two to three", isCorrect: false }
        ]
    },
    {
        question: "Which are the three required parts of a text ad?",
        answers: [
            { text: "Description, path fields, and headline", isCorrect: false },
            { text: "Description, path fields, and URL", isCorrect: false },
            { text: "Headline, description, and path fields", isCorrect: true },
            { text: "Headline, description, and URL", isCorrect: false }
        ]
    },
    {
        question: "What is the key value proposition of Google Search campaigns?",
        answers: [
            { text: "Appear as a text ad, a video ad, or a banner ad on one of Google’s partner sites", isCorrect: false },
            { text: "Influence organic results", isCorrect: false },
            { text: "Reach people on Google.com that might be interested in your brand, regardless of what they’re searching for", isCorrect: false },
            { text: "Show your ads when a customer is searching for your product or service", isCorrect: true }
        ]
    },
    {
        question: "Which part of a Search ad isn’t automatically generated by Dynamic Search Ads?",
        answers: [
            { text: "All of these are automatically generated", isCorrect: false },
            { text: "Description line", isCorrect: true },
            { text: "Destination URL", isCorrect: false },
            { text: "Headline", isCorrect: false }
        ]
    },
    {
        question: "Which two people might see an ad with the keyword +black +shirt (set as broad match modifier)?",
        answers: [
            { text: "Someone searching for the term 'I want to buy a black shirt'", isCorrect: true },
            { text: "Someone searching for the term 'black button shirt'", isCorrect: true },
            { text: "Someone searching for the term 'shirt'", isCorrect: false },
            { text: "Someone searching for the term 'shirt brown'", isCorrect: false }
        ]
    },
    {
        question: "How can Google Ads help you advance your business goals?",
        answers: [
            { text: "By building awareness of your brand", isCorrect: true },
            { text: "By driving online, in-app, in-person, and over-the-phone sales", isCorrect: true },
            { text: "By influencing consideration of your products and services", isCorrect: true },
            { text: "All of the above", isCorrect: true }
        ]
    },
    {
        question: "Match each autobidding strategy to the right campaign goal.",
        answers: [
            { text: "Target CPA: Conversions", isCorrect: true },
            { text: "Target ROAS: Revenue", isCorrect: true },
            { text: "Maximize Clicks: Traffic", isCorrect: true },
            { text: "Target Impression Share: Visibility", isCorrect: true }
        ]
    },
    {
        question: "Which of the following is a core benefit of Google Ads automated bidding?",
        answers: [
            { text: "User ID-based bidding", isCorrect: false },
            { text: "Impression share-based bidding", isCorrect: false },
            { text: "Daily budget pacing", isCorrect: false },
            { text: "Auction-time bidding", isCorrect: true }
        ]
    },
    {
        question: "Why do search ad extensions matter?",
        answers: [
            { text: "The cost-per-click on ad extensions is lower than on a search ad headline", isCorrect: false },
            { text: "They guarantee higher engagement for advertisers", isCorrect: false },
            { text: "They increase ad quality and drive lower conversion costs", isCorrect: false },
            { text: "They increase engagement and influence ad quality", isCorrect: true }
        ]
    },
    {
        question: "If an advertiser doesn’t want to add remarketing tags to a website, why would Customer Match be a good fit for them?",
        answers: [
            { text: "Customer Match allows you to reach people who have been to your website", isCorrect: false },
            { text: "Customer Match allows you to reach people who haven’t been to your website yet", isCorrect: false },
            { text: "Customer Match relies on your own data instead of a remarketing tag", isCorrect: true },
            { text: "It wouldn’t be a good fit. You have to tag your website to use Customer Match", isCorrect: false }
        ]
    },
    {
        question: "True or false? Affinity Audiences allows advertisers to reach people who’re actively researching and intending to buy the products or services they offer.",
        answers: [
            { text: "True", isCorrect: false },
            { text: "False", isCorrect: true }
        ]
    },
    {
        question: "Which of the following can be customized with audience signals to make Search campaigns more efficient?",
        answers: [
            { text: "Headline length", isCorrect: false },
            { text: "Keywords", isCorrect: true },
            { text: "Networks", isCorrect: false },
            { text: "All of the above", isCorrect: false }
        ]
    },
    {
        question: "What are the three main factors that determine ad quality?",
        answers: [
            { text: "Expected clickthrough rate, ad formats, and ad relevance", isCorrect: false },
            { text: "Expected clickthrough rate, landing page experience, and ad relevance", isCorrect: true },
            { text: "Expected clickthrough rate, max CPC bid, and landing page experience", isCorrect: false },
            { text: "Max CPC bid, landing page experience, and ad relevance", isCorrect: false }
        ]
    },
    {
        question: "Which attributes describe a good landing page experience?",
        answers: [
            { text: "Easy to navigate", isCorrect: true },
            { text: "High amount of user traffic", isCorrect: false },
            { text: "Relevant and original content", isCorrect: true },
            { text: "Transparency about your business", isCorrect: true }
        ]
    },
    {
        question: "You want to increase the relevance of a Google Search ad so it’s more meaningful to potential customers and provides value-added information to their searches. What two actions might improve the relevance of your ad?",
        answers: [
            { text: "Posting transparency statements on the website.", isCorrect: false },
            { text: "Selecting only relevant languages in campaign setup.", isCorrect: false },
            { text: "Selecting distinct geographic areas.", isCorrect: false },
            { text: "Rewriting the landing page for clarity.", isCorrect: true },
            { text: "Changing the ad’s call-to-action statement.", isCorrect: true }
        ]
    },
    {
        question: "Why is automating your bid better than manual bidding when it comes to creating a successful Google Ads campaign?",
        answers: [
            { text: "Because user intent and the likelihood to complete valuable actions for your business don’t change according to location, time, or device", isCorrect: false },
            { text: "Because the customer journey has become more complex and bids should therefore be based on general user behavior.", isCorrect: false },
            { text: "Because the right bid can often be a hard-to-reach static target", isCorrect: true },
            { text: "Because not bidding efficiently can make you miss valuable conversions", isCorrect: true }
        ]
    },
    {
        question: "What two things are types of value-based Smart Bidding strategies?",
        answers: [
            { text: "Maximize conversion value with target ROAS", isCorrect: true },
            { text: "Manual CPC", isCorrect: false },
            { text: "Maximize conversion value", isCorrect: true },
            { text: "Target impression share", isCorrect: false }
        ]
    },
    {
        question: "How does Google AI help marketers maximize search ad performance amidst billions of searches on Google every day?",
        answers: [
            { text: "Google AI lets Ad Rank prioritize either anticipated clickthrough rate or ad relevance.", isCorrect: false },
            { text: "Google AI is so effective that marketers don’t even need to invest in high-quality image assets to maximize their impact.", isCorrect: false },
            { text: "Google AI lets broad match and Smart Bidding connect ads to queries and make adjustments to bids in real time.", isCorrect: true },
            { text: "Google AI lets Smart Bidding anticipate which queries will have the highest volume and it sets bids automatically in response.", isCorrect: false }
        ]
    },
    {
        question: "Which of these are three efficient ways that marketers can apply recommendations that affect optimization score?",
        answers: [
            { text: "Reviewing recommendations and making adjustments under campaign settings", isCorrect: true },
            { text: "Reviewing and applying individual recommendations within each of the categories", isCorrect: true },
            { text: "Using 'Apply all' to implement every recommendation with one click", isCorrect: true },
            { text: "Reviewing and applying individual recommendations in your Keyword report", isCorrect: false },
            { text: "Opting in to apply particular recommendations automatically", isCorrect: true }
        ]
    },
    {
        question: "Siona needs to make sure her ads are getting a minimum number of impressions on the top of the page. What type of automated bidding strategy is Siona using?",
        answers: [
            { text: "Target return on ad spend (tROAS)", isCorrect: false },
            { text: "Maximize clicks", isCorrect: false },
            { text: "Target impression share", isCorrect: true },
            { text: "Target cost-per-acquisition (tCPA)", isCorrect: false }
        ]
    },
    {
        question: "You’re a marketing executive at an airline company and have been asked to plan your company’s online advertising budget on a monthly basis. You decided to use Google Ads’ Performance Planner to help accomplish this task. What are two advantages Performance Planner offers you?",
        answers: [
            { text: "Performance Planner integrates with other budgeting software, such as QuickBooks.", isCorrect: false },
            { text: "Performance Planner leverages machine learning for forecasting.", isCorrect: true },
            { text: "Performance Planner will help you identify funds from other operational budgets to allocate to marketing.", isCorrect: false },
            { text: "Performance Planner forecasting is powered by billions of Google searches conducted each week.", isCorrect: true }
        ]
    },
    {
        question: "Google Ads was designed to deliver three things to every advertiser: relevance, control, and results. It provides relevance by connecting advertisers with the right people at the right time. It provides results by charging only when you get a click. How does Google Ads provide control?",
        answers: [
            { text: "By giving advertisers control over the number of specific actions their spend will return.", isCorrect: false },
            { text: "By giving advertisers control over which competitors they place ads against in auctions.", isCorrect: false },
            { text: "By giving advertisers control over the maximum they spend per month.", isCorrect: true },
            { text: "By giving advertisers control over the next highest bid allowed in auctions they enter.", isCorrect: false }
        ]
    },
    {
        question: "How many responsive search ads can you have in Google Ads?",
        answers: [
            { text: "There’s a limit of three enabled responsive search ads per ad group.", isCorrect: true },
            { text: "There’s no limit of enabled responsive search ads per ad group.", isCorrect: false },
            { text: "There’s a limit of six enabled responsive search ads per ad group.", isCorrect: false },
            { text: "There’s a limit of five enabled responsive search ads per ad group.", isCorrect: false }
        ]
    },
    {
        question: "You’ve been tasked with marketing a new line of plumbing services, but you have a set budget you can’t exceed. Why is Google Ads a viable option?",
        answers: [
            { text: "Google Ads guarantees phone calls to your business.", isCorrect: false },
            { text: "Google Ads allows you to cap the number of ads you pay for, based on your business.", isCorrect: false },
            { text: "Google Ads offers separate auctions for low budgets.", isCorrect: false },
            { text: "Google Ads gives you control over your budget.", isCorrect: true }
        ]
    },
    {
        question: "You’re working on a Google Search ad that’s not performing as expected. You specifically want more users to click on the ad. What action might improve the click-through rate on your ad?",
        answers: [
            { text: "Increasing the trustworthiness of the website.", isCorrect: false },
            { text: "Reducing the bid rate on the ad.", isCorrect: false },
            { text: "Changing the call-to-action message of the ad.", isCorrect: true },
            { text: "Modifying the ad’s landing page to load faster.", isCorrect: false }
        ]
    },
    {
        question: "Which searches can an ad show for broad match keyword 'home furnace repair?'",
        answers: [
            { text: "replace home furnace", isCorrect: true },
            { text: "homes for sale", isCorrect: false },
            { text: "new homes for sale", isCorrect: false },
            { text: "house furnace replacement", isCorrect: true }
        ]
    },
    {
        question: "What’s one way Google AI helps marketers connect with potential customers?",
        answers: [
            { text: "Google AI is used by Responsive Search Ads to provide recommendations for optimizing marketers’ websites.", isCorrect: false },
            { text: "Google AI understands nuance in human language and connects marketers with people searching for what they offer.", isCorrect: true },
            { text: "Google AI is used by Smart Bidding to predict the value of each query for the coming quarter, enabling marketers to plan their spend.", isCorrect: false },
            { text: "Google AI defines business goals for marketers based on their offline conversion data.", isCorrect: false }
        ]
    },
    {
        question: "What are the two current keywordless campaign types that marketers can use to drive incremental reach?",
        answers: [
            { text: "Dynamic Search Ads", isCorrect: true },
            { text: "Performance Max", isCorrect: true },
            { text: "Expanded text ads", isCorrect: false },
            { text: "Responsive search ads", isCorrect: false }
        ]
    },
    {
        question: "Pete is the marketing director for an electric car company. He recently chose 'leads' as his Google Search campaign marketing goal. What did he hope to achieve by selecting 'leads' as his goal?",
        answers: [
            { text: "Have more people view his website.", isCorrect: false },
            { text: "Encourage customers to purchase electric cars.", isCorrect: false },
            { text: "Promote videos that speak to the business mission.", isCorrect: false },
            { text: "Increase email list sign-ups from potential customers.", isCorrect: true }
        ]
    },
    {
        question: "What are two benefits of using broad match, Smart Bidding, and responsive search ads together?",
        answers: [
            { text: "They automatically generate headlines based on advertisers’ websites.", isCorrect: false },
            { text: "They help save time so marketers can reinvest in their most important priorities.", isCorrect: true },
            { text: "They help advertisers reach the right user, at the right price, with relevant ads.", isCorrect: true },
            { text: "They remove the need for marketers to optimize Search campaigns.", isCorrect: false }
        ]
    },
    {
        question: "Lola is in the process of selecting a campaign type to suit her business objectives. Why is it important that she consider business objectives before choosing her campaign type?",
        answers: [
            { text: "Different campaign types have different minimum and maximum budget requirements.", isCorrect: false },
            { text: "Certain campaign types will only serve ads during particular times of the day and week.", isCorrect: false },
            { text: "Google Ads will automatically create ad messaging based on the campaign type she chooses.", isCorrect: false },
            { text: "The campaign type chosen will determine where her ads appear and the format of those ads.", isCorrect: true }
        ]
    },
    {
        question: "What is one way that Performance Planner helps businesses increase sales?",
        answers: [
            { text: "By relying on customer feedback for optimal ad placement", isCorrect: false },
            { text: "By maximizing the number of conversions for a spend scenario", isCorrect: true },
            { text: "By choosing ad types that your target demographic finds most appealing", isCorrect: false },
            { text: "By providing a discount on all ads after purchasing a license", isCorrect: false }
        ]
    },
    {
        question: "Theo is looking to improve his Google Search Ads campaign. On his Google Ads Recommendations page, he notices that his Google Search campaign has an optimization score of 75%. What does this mean?",
        answers: [
            { text: "The campaign is performing 25% under budget.", isCorrect: false },
            { text: "The campaign is performing better than 75% of all search campaigns.", isCorrect: false },
            { text: "The campaign needs a 75% improvement to be fully optimized.", isCorrect: false },
            { text: "The campaign could improve up to 25% by following the listed recommendations.", isCorrect: true }
        ]
    },
    {
        question: "When implementing value-based bidding, which of these three solutions will permit you to create strong conversion measurement foundations?",
        answers: [
            { text: "Consent mode", isCorrect: true },
            { text: "Enhanced conversions", isCorrect: true },
            { text: "Global site-wide tagging", isCorrect: true },
            { text: "Smart Bidding", isCorrect: false },
            { text: "Broad match", isCorrect: false }
        ]
    },
    {
        question: "Which of the following are three benefits of opting-in for the automatic applying of recommendations?",
        answers: [
            { text: "You’ll be automatically opting into Smart Bidding.", isCorrect: true },
            { text: "You’ll see better campaign performance.", isCorrect: true },
            { text: "You’ll be implementing best practices routinely to your Google Ads accounts.", isCorrect: true },
            { text: "You’ll be implementing responsive search ads automatically", isCorrect: false },
            { text: "You’ll be able to opt in without a budget increase.", isCorrect: false }
        ]
    },
    {
        question: "An advertiser is focused primarily on direct response, as opposed to branding. The advertiser should delete keywords from a search campaign if the keywords:",
        answers: [
            { text: "Generate many clicks and conversions", isCorrect: false },
            { text: "Generate many impressions and very few conversions", isCorrect: true },
            { text: "Contain more than two words in the phrase", isCorrect: false },
            { text: "Contain words that are duplicated in a display campaign", isCorrect: false }
        ]
    },
    {
        question: "What is a benefit of online advertising with Google AdWords?",
        answers: [
            { text: "Advertisers can identify the Internet Protocol (IP) address of users who are searching for products", isCorrect: false },
            { text: "Ads can include up to 50 characters for the first three lines of ad text", isCorrect: false },
            { text: "Ads are displayed to users who are searching for a particular product or service", isCorrect: true },
            { text: "Advertisers can pay to place their websites in the natural search results", isCorrect: false }
        ]
    },
    {
        question: "It is beneficial to create multiple ad groups in order to:",
        answers: [
            { text: "Opt specific ad groups into various Google networks", isCorrect: false },
            { text: "Break up keywords and ads into related themes", isCorrect: true },
            { text: "Set different budgets for each ad group", isCorrect: false },
            { text: "Pause specific keywords if they are not performing well", isCorrect: false }
        ]
    },
    {
        question: "Which AdWords settings are specified at the account level?",
        answers: [
            { text: "A daily budget and a set of keywords and placements", isCorrect: false },
            { text: "Network distribution preferences and a set of keywords", isCorrect: false },
            { text: "A unique email address, a password, and billing information", isCorrect: true },
            { text: "Location targeting, cost-per-click (CPC) bids, and match types", isCorrect: false }
        ]
    },
    {
        question: "An online retail company is based in the United States but ships to customers all over the world. If this company wants to serve Spanish language ads to Spanish speaking users, which targeting option should be refined?",
        answers: [
            { text: "Language targeting", isCorrect: true },
            { text: "Regional targeting", isCorrect: false },
            { text: "Ad scheduling", isCorrect: false },
            { text: "Demographic targeting", isCorrect: false }
        ]
    },
    {
        question: "Which method would be recommended for an advertiser who is trying to increase the Quality Score of a low-performing keyword?",
        answers: [
            { text: "Repeat the keyword as many times as possible in the ad text.", isCorrect: false },
            { text: "Delete the keyword and add the keyword to the campaign again", isCorrect: false },
            { text: "Modify the ad associated with that keyword to direct to a highly-relevant landing page", isCorrect: true },
            { text: "Increase the daily budget for the campaign in which the keyword is located", isCorrect: false }
        ]
    },
    {
        question: "An advertiser who decides to edit the location targeting of an ad can do this at the:",
        answers: [
            { text: "Ad group level", isCorrect: false },
            { text: "Keyword level", isCorrect: false },
            { text: "Campaign level", isCorrect: true },
            { text: "Account level", isCorrect: false }
        ]
    },
    {
        question: "If your campaign is opted into show ads on the Google Display Network, and your Display Network ads have a lower CTR than your existing search ads, how will this impact the quality score of your search campaign?",
        answers: [
            { text: "None of these options is correct", isCorrect: true },
            { text: "Your ad performance on the Display Network does not affect your rank for search ads", isCorrect: false },
            { text: "Your daily budget will be adjusted to account for a drop in CTR", isCorrect: false },
            { text: "Your quality score will be adjusted to reflect the average CTR of both your search and display network campaign performance", isCorrect: false }
        ]
    },
    {
        question: "An advertiser wants to achieve the top position in paid search results. Which recommendations would improve the likelihood of top ad position?",
        answers: [
            { text: "Improve Quality Score and increase cost-per-click (CPC)", isCorrect: true },
            { text: "Decrease cost-per-click (CPC) and increase daily budget", isCorrect: false },
            { text: "Decrease cost-per-click (CPC) and decrease daily budget", isCorrect: false },
            { text: "Improve Quality Score and decrease cost-per-click (CPC)", isCorrect: false }
        ]
    },
    {
        question: "To determine which ad language to target to a user, the AdWords system refers to that user's:",
        answers: [
            { text: "Chrome Browser setting", isCorrect: false },
            { text: "Operating system language", isCorrect: false },
            { text: "Home country's language", isCorrect: false },
            { text: "Google interface language setting", isCorrect: true }
        ]
    },
    {
        question: "Which budget delivery option is most appropriate for an advertiser who wants AdWords to distribute ads evenly throughout the day?",
        answers: [
            { text: "Accelerated", isCorrect: false },
            { text: "Optimized", isCorrect: false },
            { text: "Scheduled", isCorrect: false },
            { text: "Standard", isCorrect: true }
        ]
    },
    {
        question: "An advertiser wants to allocate additional budget to advertising a new product line. In order to accomplish this goal, the advertiser should create:",
        answers: [
            { text: "An ad group specific to the product line with a higher daily budget", isCorrect: false },
            { text: "Additional text ads that specifically feature the product line", isCorrect: false },
            { text: "A campaign with a separate daily budget specific to the product line", isCorrect: true },
            { text: "An ad group specific to the product line with targeted ad text", isCorrect: false }
        ]
    },
    {
        question: "Which line of ad text would be disapproved based on Google’s advertising policies?",
        answers: [
            { text: "Want fast results?", isCorrect: false },
            { text: "Free shipping", isCorrect: false },
            { text: "Best deals - click here", isCorrect: true },
            { text: "Fast, easy, effective", isCorrect: false }
        ]
    },
    {
        question: "How do managed placements on the Display Network work?",
        answers: [
            { text: "Advertisers can guarantee placement on prominent and popular sites", isCorrect: false },
            { text: "Advertisers manually select the desired sites on which their ads may appear", isCorrect: true },
            { text: "Keywords are used to place ads next to content that matches the ad", isCorrect: false },
            { text: "Appropriate sites are automatically chosen for the advertiser by the Google AdWords system", isCorrect: false }
        ]
    },
    {
        question: "Which formula does Google use to rank keyword-targeted ads on Google Search?",
        answers: [
            { text: "Maximum cost-per-click (CPC) bid x Quality Score", isCorrect: true },
            { text: "(Maximum cost-per-click (CPC) bid + Daily Budget) / Quality Score", isCorrect: false },
            { text: "(Maximum cost-per-click (CPC) bid x Quality Score) / Daily Budget", isCorrect: false },
            { text: "Maximum cost-per-click (CPC) bid only", isCorrect: false }
        ]
    },
    {
        question: "What happens when an advertiser sets a daily budget lower than the recommended amount, using the 'Standard' delivery method?",
        answers: [
            { text: "Ads will show when a user searches on the advertiser's keywords, but the ads rank will be reduced", isCorrect: false },
            { text: "Ads will show each time a user searches on the advertiser's keywords, but only during specified time periods", isCorrect: false },
            { text: "Ads will not show every time a user searches on the advertiser's keywords", isCorrect: true },
            { text: "Ads will never show when a user searches on the advertiser's keywords", isCorrect: false }
        ]
    },
    {
        question: "If an advertiser improves the Quality Score of a keyword, this keyword may:",
        answers: [
            { text: "Receive fewer impressions on the Search Network", isCorrect: false },
            { text: "Automatically reset its match type to Broad", isCorrect: false },
            { text: "Earn the ad a higher average position", isCorrect: true },
            { text: "Be more likely to appear in bold when displayed in an ad", isCorrect: false }
        ]
    },
    {
        question: "By adding managed placements to a Display Network campaign, you can show your ad:",
        answers: [
            { text: "On specific webpages, online video games, RSS feeds, and mobile sites and apps that you select", isCorrect: true },
            { text: "On webpages where the Smart Pricing feature determines ROI", isCorrect: false },
            { text: "On Google-owned and operated properties such as Gmail and Google News", isCorrect: false },
            { text: "On webpages where a contextual targeting algorithm identified a match between your keywords and a publisher's content", isCorrect: false }
        ]
    },
    {
        question: "What happens as a result of a search campaign consistently meeting its daily budget?",
        answers: [
            { text: "Accelerated ad delivery", isCorrect: false },
            { text: "Higher average cost-per-clicks (CPCs)", isCorrect: false },
            { text: "Missed potential ad impressions", isCorrect: true },
            { text: "Fewer sites targeted at once", isCorrect: false }
        ]
    },
    {
        question: "Which of these metrics is especially important to clients who are running a branding campaign?",
        answers: [
            { text: "Clickthrough rate (CTR)", isCorrect: false },
            { text: "Average cost-per-click (avg. CPC)", isCorrect: false },
            { text: "Impressions", isCorrect: true },
            { text: "Phone call conversions", isCorrect: false }
        ]
    },
    {
        question: "What does 'converted clicks' measure?",
        answers: [
            { text: "The total number of clicks within your chosen conversion window", isCorrect: false },
            { text: "The total number of clicks that led to a conversion", isCorrect: true },
            { text: "The percentage of clicks that led to a conversion", isCorrect: false },
            { text: "The total number of conversions divided by the total number of clicks", isCorrect: false }
        ]
    },
    {
        question: "Your client wants to show ads to people who've visited her website before. Which Adwords feature would you recommend she use?",
        answers: [
            { text: "Dynamic Search Ads", isCorrect: false },
            { text: "Ecommerce tracking", isCorrect: false },
            { text: "Remarketing", isCorrect: true },
            { text: "Conversion tracking", isCorrect: false }
        ]
    },
    {
        question: "A My Client Center (MCC) account manager wants to grant Standard Access to a linked client. Before making this change, the account manager should consider that Standard Access users can:",
        answers: [
            { text: "Change the access levels of other users", isCorrect: false },
            { text: "Delete the account", isCorrect: false },
            { text: "Invite others to access the account", isCorrect: true },
            { text: "See average cost-per-click (CPC) costs", isCorrect: false }
        ]
    },
    {
        question: "Which strategy should Giorgio use to increase the number of relevant clicks from his Search Network campaign?",
        answers: [
            { text: "Broaden his keyword list for ads with the lowest clickthrough rate (CTR)", isCorrect: false },
            { text: "Add new relevant keywords and remove keywords with low clickthrough rate (CTR)", isCorrect: true },
            { text: "Increase bids for ads with the lowest average position and clickthrough rate (CTR)", isCorrect: false },
            { text: "Increase bids on relevant keywords with low clicks and clickthrough rate (CTR)", isCorrect: false }
        ]
    },
    {
        question: "A client that owns a fine-dining restaurant in London wants to get more phone calls from people researching places to eat on their mobile phones. How should you optimize this client’s campaign to help achieve their goal?",
        answers: [
            { text: "Include a phone number in the client's ad's headlines and monitor performance with the mobile clicks-to-call segment", isCorrect: false },
            { text: "Add call extensions to the client's ads and monitor performance with the mobile clicks-to-call segment", isCorrect: true },
            { text: "Include a phone number in the client's ad's headline and monitor performance with the top vs. other segment", isCorrect: false },
            { text: "Add call extensions to the client's ads and monitor performance with the top vs. other segment", isCorrect: false }
        ]
    },
    {
        question: "When someone clicks your ad, the actual amount you're charged will be:",
        answers: [
            { text: "The minimum needed to hold your ad position", isCorrect: true },
            { text: "The minimum needed to hold your ad position but never less than 50% of your maximum cost-per-click (max. CPC) bid", isCorrect: false },
            { text: "The minimum needed to hold your ad position but never more than 120% of your maximum cost-per-click (max. CPC) bid", isCorrect: false },
            { text: "The minimum needed to hold your ad position or 50% of your maximum cost-per-click (max. CPC) bid", isCorrect: false }
        ]
    },
    {
        question: "Which bidding strategy should Sara use if her goal is to get more people to call her local catering business?",
        answers: [
            { text: "Cost-per-view (CPV)", isCorrect: false },
            { text: "Cost-per-thousand-impressions (CPM)", isCorrect: false },
            { text: "Cost-per-click (CPC)", isCorrect: true },
            { text: "Cost-per-acquisition (CPA)", isCorrect: false }
        ]
    },
    {
        question: "A benefit of My Client Center (MCC) is the:",
        answers: [
            { text: "Increased Quality Score enjoyed on shared keywords", isCorrect: false },
            { text: "Dashboard that provides summaries of statistics for all client accounts", isCorrect: true },
            { text: "Ability to link multiple accounts with Google Analytics", isCorrect: false },
            { text: "Ability to edit campaign settings across multiple accounts simultaneously", isCorrect: false }
        ]
    },
    {
        question: "What’s a reason to use the 'Search Network with Display Select' campaign type?",
        answers: [
            { text: "Your ads only show on the first page search results", isCorrect: false },
            { text: "You can pick the exact websites where you want your ad to show", isCorrect: false },
            { text: "You can use one budget to advertise on the Search Network and Display Network", isCorrect: true },
            { text: "Your video ads can run on the Search Network", isCorrect: false }
        ]
    },
    {
        question: "What happens when a campaign consistently meets its average daily budget?",
        answers: [
            { text: "Ads in that campaign will stop showing for the rest of the billing cycle", isCorrect: false },
            { text: "Average cost-per-click (CPC) bids will be lowered", isCorrect: false },
            { text: "Your budget is automatically adjusted", isCorrect: false },
            { text: "Ads in that campaign will show less often than they could", isCorrect: true }
        ]
    },
    {
        question: "You can use Keyword Planner to identify:",
        answers: [
            { text: "The number of negative keywords you should add", isCorrect: false },
            { text: "The amount of traffic potential keywords might get", isCorrect: true },
            { text: "Which text ads are performing best based on your keywords", isCorrect: false },
            { text: "Webpages where your ad can appear based on your keywords", isCorrect: false }
        ]
    },
    {
        question: "Which of the following items is not a component of Quality Score?",
        answers: [
            { text: "Landing page experience", isCorrect: false },
            { text: "Maximum cost-per-click (max. CPC) bid", isCorrect: true },
            { text: "Ad relevance", isCorrect: false },
            { text: "Expected clickthrough rate (CTR)", isCorrect: false }
        ]
    },
    {
        question: "Your ad can show on the Google Search Network when someone searches for terms that are similar to your:",
        answers: [
            { text: "Keywords", isCorrect: true },
            { text: "Placements", isCorrect: false },
            { text: "Website", isCorrect: false },
            { text: "Ad text", isCorrect: false }
        ]
    },
    {
        question: "What changes can you make to the Display Network campaign of a client who wants to drive awareness of her natural beauty brand?",
        answers: [
            { text: "Increase the daily budget and add text ads with clear call-to-actions like 'Buy now'", isCorrect: false },
            { text: "Use a balanced combination of broad-, exact-, and phrase-matched keywords", isCorrect: false },
            { text: "Add display ads and affinity audiences targeting people interested in green living and beauty", isCorrect: true },
            { text: "Target large metropolitan areas where people are more likely to encounter her product", isCorrect: false }
        ]
    },
    {
        question: "Which client would you advise to use radius targeting?",
        answers: [
            { text: "Luis, whose e-commerce business delivers nationwide", isCorrect: false },
            { text: "Denise, whose service can reach customers within 30 miles", isCorrect: true },
            { text: "Christopher, who wants to promote his new product in select cities", isCorrect: false },
            { text: "Mabel, who wants to exclude her ads from certain cities", isCorrect: false }
        ]
    },
    {
        question: "Which bidding strategy should you use to maximize the number of clicks her ads can get?",
        answers: [
            { text: "Cost-per-thousand impressions (CPM)", isCorrect: false },
            { text: "Cost-per-acquisition (CPA)", isCorrect: false },
            { text: "Automatic cost-per-click (CPC)", isCorrect: true },
            { text: "Manual cost-per-click (CPC)", isCorrect: false }
        ]
    },
    {
        question: "Which strategy would you recommend to improve the number of relevant clicks?",
        answers: [
            { text: "Broaden the keyword list for ads with the lowest clickthrough rate (CTR)", isCorrect: false },
            { text: "Add new relevant keywords and remove low CTR keywords", isCorrect: true },
            { text: "Increase bids for ads with the lowest average position and CTR", isCorrect: false },
            { text: "Increase bids on relevant keywords with low clicks and CTR", isCorrect: false }
        ]
    },
    {
        question: "Which formula represents how Ad Rank is determined on Google search?",
        answers: [
            { text: "Popularity of the website being advertised", isCorrect: false },
            { text: "Historic average position of each ad", isCorrect: false },
            { text: "Maximum cost-per-click (CPC) multiplied by Quality Score", isCorrect: true },
            { text: "How much an advertiser is willing to spend each day", isCorrect: false }
        ]
    },
    {
        question: "What is the impact of poor landing page quality on an ad group?",
        answers: [
            { text: "The keywords in the ad group will be paused", isCorrect: false },
            { text: "The ads in the ad group will be disapproved due to low Quality Score", isCorrect: false },
            { text: "The keywords in the ad group will have a lower Quality Score", isCorrect: true },
            { text: "The entire campaign will be paused", isCorrect: false }
        ]
    },
    {
        question: "A keyword with very low clickthrough rate (CTR) will usually receive:",
        answers: [
            { text: "More impressions on the Google Display Network", isCorrect: false },
            { text: "Impressions only on the Google Search Network", isCorrect: false },
            { text: "A low average cost-per-click (CPC) on Google search", isCorrect: false },
            { text: "A low Quality Score on the Google Search Network", isCorrect: true }
        ]
    },
    {
        question: "If the cost-per-thousand impressions (CPM) option is not available for a campaign, the most likely reason is that the campaign:",
        answers: [
            { text: "Has used CPM pricing before", isCorrect: false },
            { text: "Is only opted into the Google Display Network", isCorrect: false },
            { text: "Has never used CPM pricing before", isCorrect: false },
            { text: "Is only opted into the Google Search and the Search Network", isCorrect: true }
        ]
    },
    {
        question: "You are focused on reaching viewers with video content, and are looking to pay only when a user views your ad (CPV model). Which form of AdWords video advertising would be most appropriate?",
        answers: [
            { text: "TrueView video formats", isCorrect: true },
            { text: "YouTube homepage ads", isCorrect: false },
            { text: "Click-to-Play video ad formats", isCorrect: false },
            { text: "CPM Video ad formats", isCorrect: false }
        ]
    },
    {
        question: "When setting up a campaign, you can use Display Planner to get an idea of the budget and bids you should set based on your:",
        answers: [
            { text: "Traffic estimates for placement ideas", isCorrect: true },
            { text: "Historical conversion rate estimates for your keyword and placement ideas", isCorrect: false },
            { text: "Historical cost-per-click (CPC) estimates for your keyword and placement ideas", isCorrect: false },
            { text: "Traffic estimates for your keyword ideas", isCorrect: false }
        ]
    },
];

function Quiz() {
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [score, setScore] = useState(0);
    const [showScore, setShowScore] = useState(false);
    const [showAnswerFeedback, setShowAnswerFeedback] = useState(null); // To show if the selected answer was correct

    const handleAnswerClick = (isCorrect) => {
        if (isCorrect) {
            setScore(score + 1);
        }
        setShowAnswerFeedback(isCorrect ? 'Correct!' : 'Incorrect!');

        // Delay before moving to the next question to allow the user to see feedback
        setTimeout(() => {
            setShowAnswerFeedback(null); // Reset feedback
            const nextQuestion = currentQuestion + 1;
            if (nextQuestion < questions.length) {
                setCurrentQuestion(nextQuestion);
            } else {
                setShowScore(true);
            }
        }, 1500); // 1.5-second delay before going to the next question
    };

    return (
        <div className="flex flex-col items-center justify-center h-180 bg-gray-100">
            {showScore ? (
                <div className="bg-white p-8 rounded-lg shadow-md text-center">
                    <h1 className="text-2xl font-bold mb-4">Quiz Completed!</h1>
                    <p className="text-lg">You scored {score} out of {questions.length}</p>
                </div>
            ) : (
                <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-xl">
                    <div className="mb-4">
                        <div className="text-lg font-medium text-gray-700">
                            Question {currentQuestion + 1} / {questions.length}
                        </div>
                        <div className="mt-2 text-xl font-semibold">
                            {questions[currentQuestion].question}
                        </div>
                    </div>
                    <div className="flex flex-col space-y-3">
                        {questions[currentQuestion].answers.map((answer, index) => (
                            <button
                                key={index}
                                onClick={() => handleAnswerClick(answer.isCorrect)}
                                className="button text-white py-2 px-4 rounded-lg hover:bg-blue-600 focus:outline-none"
                            >
                                {answer.text}
                            </button>
                        ))}
                    </div>
                    {showAnswerFeedback && (
                        <div className={`mt-4 text-xl font-bold ${showAnswerFeedback === 'Correct!' ? 'text-green-500' : 'text-red-500'}`}>
                            {showAnswerFeedback}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default Quiz;
