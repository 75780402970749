import React from 'react'
import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const faqs = [
    {
        question: "What is Enhanced Conversion Tracking for Google Ads?",
        answer: "Enhanced Conversion Tracking is a feature that allows you to capture more detailed conversion data, improving the accuracy of your conversion reporting and helping you better understand customer behavior.",
    },
    {
        question: "How does Enhanced Conversion Tracking improve Google Ads performance?",
        answer: "By providing more accurate data, Enhanced Conversion Tracking helps you understand which campaigns are truly driving conversions. This enables better optimization of ad spend and strategies, leading to improved ROI.",
    },
    {
        question: "What types of conversions can be tracked using Enhanced Conversion Tracking?",
        answer: "Enhanced Conversion Tracking can track various conversions, including online purchases, form submissions, phone calls, and other key actions taken by users after interacting with your ads.",
    },
    {
        question: "Is Enhanced Conversion Tracking difficult to set up?",
        answer: "Gurulabs makes the setup process seamless. Our team will handle the technical implementation, ensuring that your Google Ads account is configured correctly to capture enhanced conversion data.",
    },
    {
        question: "Do I need special software for Enhanced Conversion Tracking?",
        answer: "No additional software is required. Enhanced Conversion Tracking works with Google Ads and Google Analytics, integrating with your existing setup to provide detailed conversion insights.",
    },
    {
        question: "Can Enhanced Conversion Tracking help with cross-device tracking?",
        answer: "Yes, Enhanced Conversion Tracking is capable of tracking conversions across multiple devices, providing a more holistic view of customer interactions and helping you understand the full customer journey.",
    },
    {
        question: "How does Enhanced Conversion Tracking work with Google Analytics?",
        answer: "Enhanced Conversion Tracking can be integrated with Google Analytics to provide deeper insights into user behavior. This integration allows you to see the complete path to conversion, from initial engagement to final action.",
    },
    {
        question: "What is the difference between standard and Enhanced Conversion Tracking?",
        answer: "Standard tracking may miss or inaccurately attribute conversions, while Enhanced Conversion Tracking captures more data points, leading to a higher accuracy rate in reporting and better insights into the performance of your ads.",
    },
    {
        question: "Can Enhanced Conversion Tracking be used for remarketing?",
        answer: "Yes, the detailed data provided by Enhanced Conversion Tracking can be used to create highly targeted remarketing lists, allowing you to reach potential customers who have already shown interest in your products or services.",
    },
    {
        question: "How often is conversion data updated with Enhanced Conversion Tracking?",
        answer: "Conversion data with Enhanced Conversion Tracking is updated in real-time, allowing you to make timely adjustments to your ad campaigns and strategies based on the latest information.",
    }
];

function EnhancedConversionTrackingFAQs() {
    return (
        <div className="bg-white">
            <div className="mx-auto max-w-7xl px-6 py-12 sm:py-24 lg:px-8 lg:py-24">
                <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
                    <h2 className="text-3xl font-bold leading-10 tracking-tight text-gray-900">Frequently Asked Questions</h2>
                    <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                        {faqs.map((faq) => (
                            <Disclosure as="div" key={faq.question} className="pt-6">
                                {({ open }) => (
                                    <>
                                        <dt>
                                            <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                                                <span className="text-base font-semibold leading-7">{faq.question}</span>
                                                <span className="ml-6 flex h-7 items-center">
                                                    {open ? (
                                                        <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                    ) : (
                                                        <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                    )}
                                                </span>
                                            </Disclosure.Button>
                                        </dt>
                                        <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                            <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
}

export default EnhancedConversionTrackingFAQs
