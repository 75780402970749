import mixpanel from 'mixpanel-browser';
import React, { useEffect, useState } from 'react';
import { Link, usePage } from '@inertiajs/inertia-react';
import Slider from '../../../../Components/Slider/Index';
import PageLayout from '../../../../Layout/PageLayout/Index';
import Newsletter from '../../../../Components/Newsletter/Index';
import PPCMetaHead from '../../../../Components/PPCMetaHead/Index';
import PaidAdsLeadsForm from '../../../../Layout/PaidAdsLeadsForm/Index';
import PaidAdsPricing from '../../../../Components/PaidAdsPricing/Index';
import PaidAdsPricingTable from '../../../../Components/PaidAdsPricingTable/Index';
import Process from '../../../../Components/Process/Index';
import SocialProof from '../../../../Components/SocialProof/Index';
import PPCFAQs from '../../../../Components/FAQs/PPCFAQs/Index';
import { SiFacebook, SiInstagram, SiLinkedin, SiTiktok, SiYoutube, SiTwitter } from '@icons-pack/react-simple-icons';


// More imports as required...

const SurpriseAZPPCPage = ({ listings }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const { city, state, cities } = usePage().props;
    const [weather, setWeather] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        mixpanel.track(`${city.name} Page Visited`)

    }, []);

    return (
        <PageLayout>
            <PPCMetaHead
                    title={`${city.name} PPC Agency | Pay-Per-Click Services in ${state.code} | Gurulabs`}
                    description={`Get top-notch PPC services in ${city.name}, ${state.code} with Gurulabs. Contact us for expert PPC management to grow your business.`}
             />
           <section className="max-w-7xl mx-auto overflow-hidden">
                <div className="">
                    <div className='py-4'>
                        <h1 className="py-4 text-3xl lg:text-6xl text-center my-1">Affordable
                            <span className="text-green-500 relative mx-2">
                                Pay Per Click
                                <svg width="175" height="3" viewBox="0 0 175 3" fill="none" className="absolute hidden lg:block left-6">
                                    <path d="M1.43945 1.54395H173.903" stroke="#48BB78" strokeWidth="2" strokeLinecap="round" />
                                </svg>
                            </span>
                            Management Agency in {city.name}, {state.code}</h1>
                    </div>
                    <div className='py-4' >
                        {
                            isMobile ? (
                                <div className='grid grid-cols-1 lg:grid-cols-2'>
                                    <div className="px-4 w-[400px] h-[720px] mx-auto aspect-w-16 aspect-h-9">
                                        <video autoPlay loop muted controls playsInline className="mx-auto">
                                            <source src="/frontend/videos/intro.mp4" type="video/mp4" />
                                        </video>
                                    </div>
                                    <PaidAdsLeadsForm />
                                </div>
                            ) : (
                                <div className='grid grid-cols-1 lg:grid-cols-2'>
                                    <div className="px-4 w-[400px] h-[720px] mx-auto aspect-w-16 aspect-h-9">
                                        <video autoPlay loop muted controls playsInline className="mx-auto">
                                            <source src="/frontend/videos/intro.mp4" type="video/mp4" />
                                        </video>
                                    </div>
                                    <PaidAdsLeadsForm />
                                </div>
                            )
                        }

                    </div>
                </div>
            </section>
            <Slider />
            <section>
                <div className="bg-gray-50 py-12">
                    <div className="max-w-7xl mx-auto">
                        <div className="block sm:flex justify-center items-center px-4">
                            <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                <span className="relative">Explore Our Service Plans</span>
                                <span className="text-green-500 ml-2 relative">in {city.name}, {state.code}!</span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="absolute left-3/4 ml-16 hidden xl:block"
                                    width={151}
                                    height={3}
                                    fill="none"
                                >
                                    <path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" />
                                </svg>
                            </h2>
                        </div>
                        <PaidAdsPricing modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} city={city.name} state={state.code} />
                    </div>
                </div>
            </section>
            <section>
                <div className="max-w-5xl mx-auto py-24">
                    <div className="block sm:flex sm:flex-col justify-center items-center px-4">
                        <h2 className="block text-3xl sm:text-4xl py-5 relative">
                            <span className="relative">Tech-Enabled</span>
                            <span className="text-green-500 ml-2 relative">Social Media Ads Management</span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="absolute left-1/2 -mt-2 ml-16 hidden xl:block"
                                width={238}
                                height={17}
                                fill="none"
                            >
                                <path stroke="#48BB78" strokeLinecap="round" strokeWidth="2" d="M1.833 12.55c101.19 8.798 198.897-3.667 235.102-11" />
                            </svg>
                        </h2>
                        <p className="text-base text-center">
                            Generate quality leads in {city.name} with our expert social media ads management services.
                        </p>
                    </div>
                </div>
                <div className="max-w-7xl mx-auto pb-24 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    <Process
                        title="Facebook Ads Management"
                        description={`In the vibrant city of ${city.name}, ${state.name}, our Facebook Ads management services are designed to engage the dynamic local community. Utilizing advanced targeting, we tap into the city's diverse interests—from music and arts to tech innovations—ensuring your ads reach and resonate with the ideal audience.`}
                        button="button-3"
                        Icon={SiFacebook}
                    />
                    <Process
                        title="LinkedIn Ads Management"
                        description={`Leverage LinkedIn Ads with Gurulabs in ${city.name}, ${state.name}, to connect with the city's bustling professional scene. Our campaigns target key decision-makers in major local industries, from tech startups to established corporations, driving high-quality leads and fostering valuable B2B relationships.`}
                        button="button-2"
                        Icon={SiLinkedin}
                    />
                    <Process
                        title="YouTube Ads Management"
                        description={`Gurulabs offers expert YouTube Ads management in ${city.name}, ${state.name}, tailored to captivate the city's tech-savvy and creative populace. We create engaging video content and strategic placements that convert viewers into customers, enhancing your brand's visibility in this innovative city.`}
                        button="button-3"
                        Icon={SiYoutube}
                    />
                    <Process
                        title="Twitter (X) Ads Management"
                        description={`Our Twitter Ads management services in ${city.name}, ${state.name}, harness the platform’s real-time capabilities to create impactful campaigns. By tapping into local trends and conversations, we increase your brand’s visibility and drive traffic, engaging the city's active social media users with data-driven strategies.`}
                        button="button-2"
                        Icon={SiTwitter}
                    />
                    <Process
                        title="Instagram Ads Management"
                        description={`Choose Gurulabs for Instagram Ads management in ${city.name}, ${state.name}, and benefit from visually stunning ads that capture the city's vibrant culture. From scenic landscapes to bustling urban scenes, our precise targeting ensures significant engagement and business growth within this lively community.`}
                        button="button-3"
                        Icon={SiInstagram}
                    />
                    <Process
                        title="TikTok Ads Management"
                        description={`Tap into TikTok’s dynamic audience with our ads management in ${city.name}, ${state.name}. Our team creates shareable and entertaining content that captures the essence of the city’s youthful energy and creativity, maximizing visibility and engagement for your brand.`}
                        button="button-2"
                        Icon={SiTiktok}
                    />
                </div>
            </section>
            <section className="bg-gray-50 py-12 px-4">
                <div className="max-w-7xl mx-auto overflow-hidden">
                    <div className="text-xl py-4 text-gray-700 prose prose-lg">
                        <h1>The City of Surprise, Arizona: A Hub of Opportunity for Digital Marketing</h1>

<h2>Introduction</h2>

<p>Welcome to Surprise, a vibrant city nestled in the heart of Arizona that offers a unique blend of history, culture, and modernity. As an emerging hotspot for businesses and residents alike, Surprise presents a prime location for digital marketing endeavors, particularly Pay-Per-Click (PPC) advertising. In this comprehensive guide, we will delve into the major features, historical background, cultural significance, and the immense potential for PPC advertising in Surprise. Discover how this dynamic city can be the catalyst for your business's online success.</p>

<h2>Major Features of Surprise</h2>

<p>Surprise, Arizona, boasts a myriad of features that make it a compelling destination for both residents and businesses. Here are some of the key highlights:</p>

<ul>
  <li>Expansive Parks and Recreation: The city is renowned for its numerous parks, recreational facilities, and outdoor spaces, providing ample opportunities for leisure, sports, and community gatherings.</li>
  <li>Growing Economy: Surprise has seen significant economic growth in recent years, attracting businesses from various industries and fostering a thriving entrepreneurial ecosystem.</li>
  <li>Quality Education: The city is home to top-rated schools, colleges, and educational institutions, ensuring access to quality education for residents of all ages.</li>
  <li>Diverse Dining and Shopping Options: Surprise offers a diverse culinary scene and a range of shopping destinations, catering to varied tastes and preferences.</li>
  <li>Community Events and Festivals: The city hosts a multitude of events, festivals, and cultural celebrations throughout the year, fostering a strong sense of community spirit.</li>
  <li>Strategic Location: Situated in the rapidly growing Maricopa County, Surprise enjoys a strategic location that provides easy access to major cities, transportation networks, and commercial hubs.</li>
</ul>

<h2>Historical Background</h2>

<p>Surprise traces its origins back to the early 1930s when real estate developer and state legislator Flora Mae Statler founded the town. Initially envisioned as a housing development, Surprise gradually evolved into a thriving city known for its strong community spirit and rapid growth.</p>

<p>Over the decades, Surprise has witnessed significant economic development, infrastructure enhancements, and population growth, transforming it into a dynamic urban center that embraces both its historical roots and forward-thinking mindset. Today, the city stands as a testament to resilience, innovation, and the enduring spirit of its residents.</p>

<h2>Cultural Significance</h2>

<p>Surprise's cultural landscape is as diverse as its population, reflecting a rich tapestry of traditions, art forms, and community engagement. From public art installations and heritage sites to music festivals and culinary delights, the city celebrates its cultural heritage with enthusiasm and pride.</p>

<p>The vibrant arts scene in Surprise offers numerous opportunities for local artists, performers, and creatives to showcase their talents and connect with a supportive audience. Additionally, the city's commitment to cultural preservation and promotion ensures that residents and visitors alike can experience the richness of its heritage through various initiatives and programs.</p>

<h2>Suitability for Digital Marketing: Focus on PPC Advertising</h2>

<p>Surprise's dynamic business environment and tech-savvy population make it an ideal setting for digital marketing strategies, with PPC advertising emerging as a powerful tool for businesses looking to enhance their online presence and drive targeted traffic to their websites.</p>

<p>PPC advertising in Surprise offers a host of benefits for businesses, including:</p>

<ul>
  <li>Targeted Reach: With PPC campaigns, businesses can reach specific audiences based on demographics, interests, and online behavior, ensuring that their ads are seen by the right people at the right time.</li>
  <li>Cost-Effective Marketing: PPC enables businesses to set their budgets, monitor their spending, and adjust their campaigns in real-time, allowing for a cost-effective and measurable marketing approach.</li>
  <li>Enhanced Visibility: By appearing at the top of search engine results pages and across relevant websites, businesses can increase their visibility and brand awareness, driving more traffic to their websites.</li>
  <li>Conversion Tracking: PPC platforms offer robust analytics tools that enable businesses to track their campaign performance, measure conversions, and optimize their strategies for maximum ROI.</li>
</ul>

<h2>Local Business Environment in Surprise</h2>

<p>Surprise is home to a diverse range of businesses spanning various industries, including healthcare, retail, technology, hospitality, and more. The city's business-friendly policies, strategic location, and supportive community make it an attractive destination for entrepreneurs and established companies alike.</p>

<p>Local businesses in Surprise can leverage digital marketing tools such as PPC advertising to amplify their online presence, attract new customers, and drive revenue growth. By tailoring their PPC campaigns to target specific demographics, keywords, and geographic locations, businesses can engage with their target audience effectively and enhance their competitive edge in the market.</p>

<h2>Target Audience in Surprise</h2>

<p>The diverse population of Surprise presents a multitude of opportunities for businesses to connect with their target audience and expand their market reach. Whether catering to families, young professionals, retirees, or tourists, businesses in Surprise can tailor their PPC campaigns to resonate with the unique preferences and needs of local consumers.</p>

<p>With a growing tech-savvy demographic and an increasing reliance on digital platforms for information and commerce, Surprise offers a ripe environment for businesses to engage with potential customers through targeted PPC advertising strategies. By understanding the demographics, behavior patterns, and preferences of the local audience, businesses can craft compelling ad campaigns that drive engagement, conversions, and long-term customer loyalty.</p>

<h2>Conclusion</h2>

<p>In conclusion, Surprise, Arizona, stands as a city brimming with opportunities for businesses seeking to harness the power of digital marketing, particularly through PPC advertising. With its vibrant community, growing economy, diverse business landscape, and supportive infrastructure, Surprise offers an ideal setting for businesses to thrive and succeed in the digital age.</p>

<p>Whether you are a local business looking to expand your online reach or a marketing agency seeking to maximize your clients' ROI, Surprise presents a wealth of possibilities for leveraging PPC advertising to drive targeted traffic, boost conversions, and achieve tangible results. Explore the endless possibilities that await you in Surprise, where innovation meets tradition and success awaits those ready to seize it.</p>
                    </div>
                </div>
            </section>
            {listings && listings.length > 0 ? (
                <section>
                    <div className="max-w-7xl mx-auto">
                        <div className="max-w-5xl mx-auto py-24">
                            <div className="block sm:flex sm:flex-col justify-center items-center px-4">
                                <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                    <span className="relative">We support small business</span>
                                    <span className="text-green-500 ml-2 relative">near {city.name}, {state.code}</span>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="absolute left-1/2 -mt-2 ml-16 hidden xl:block"
                                        width={238}
                                        height={17}
                                        fill="none"
                                    >
                                        <path stroke="#48BB78" strokeLinecap="round" strokeWidth="2" d="M1.833 12.55c101.19 8.798 198.897-3.667 235.102-11" />
                                    </svg>
                                </h2>
                                <p className="text-base text-center">
                                    Generate quality leads in {city.name} with our expert social media ads management services.
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-wrap justify-center items-center">
                            {listings
                                .filter(listing => listing.website && listing.phone) // Only show listings with a valid image_url
                                .map((listing, index) => (
                                        <div key={listing.id || index} className="w-full sm:w-1/2 lg:w-1/3 px-3 mb-6">
                                            <div className="p-4 max-w-md rounded-lg overflow-hidden shadow-lg bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out">
                                                <div className="p-4">
                                                    <h2 className="font-bold text-2xl mb-4 text-gray-800 whitespace-nowrap overflow-hidden text-ellipsis">{listing.name}</h2>
                                                    <div className="mb-4 text-gray-600">
                                                        <p>{listing.address}</p>
                                                        <p>{listing.city}, {listing.state} {listing.zip}</p>
                                                        <p>{listing.country || ''}</p>
                                                    </div>
                                                    <p className="text-gray-600 text-md">{listing.category}</p>
                                                    <p className="text-gray-600 text-md">Rating: {listing.rating || 'No rating available'} ⭐</p>
                                                    <p className="text-gray-600 text-md pb-4">Phone:  <a href={`tel:${listing.phone}`} className="text-green-600 hover:underline mb-4">
                                                        {listing.phone}
                                                    </a>
                                                    </p>
                                                    {listing.website && (
                                                        <a
                                                            href={`${listing.website.includes('http') ? listing.website : `https://${listing.website}`}?utm_source=gurulabs&utm_medium=referral&utm_campaign=${city.name}-${state.code}-ppc`}
                                                            className="relative w-full py-2 px-3 top-1 right-2 button rounded-sm text-gray-700 hover:bg-green-500"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            Visit Website
                                                        </a>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                ))
                            }
                        </div>
                    </div>
                </section>
            ) :

                (
                    <>
                    </>
                )}
            <section>
                <div className="bg-amber-50 py-12">
                    <div className="max-w-7xl mx-auto">
                        <div>
                            <div className='block sm:flex justify-center items-center px-4'>
                                <h1 className="block text-3xl sm:text-4xl py-5 relative">
                                    <span className="relative">Cities We Serve in {state.name}</span>
                                    <span className="text-green-500 ml-2 relative">
                                        for PPC Services
                                    </span>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4 ml-16 hidden xl:block" width={151} height={3} fill="none"><path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                                </h1>
                            </div>
                            <div className="px-4">
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                    {cities
                                        .sort(() => Math.random() - 0.5) // Shuffle the array
                                        .slice(0, 21) // Select the first 21 cities after shuffling
                                        .map((city) => (
                                            <Link key={city.id} href={`/ppc/${state.slug}/${city.slug}`} className="text-green-500">
                                                Pay Per Click Management near {city.name}
                                            </Link>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <PaidAdsPricingTable city={city.name} state={state.code} />
            <Newsletter />
            <section>
                <div className="bg-gray-50 py-12">
                    <div className="max-w-7xl mx-auto">
                        <div className="block sm:flex justify-center items-center px-4">
                            <h1 className="block text-3xl sm:text-4xl py-5 relative">
                                <span className="relative">Your Reliable Partner for </span>
                                <span className="text-green-500 ml-2 relative">
                                    PPC Management in {city.name}, {state.code}
                                </span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="absolute left-3/4 ml-16 hidden xl:block"
                                    width={151}
                                    height={3}
                                    fill="none"
                                >
                                    <path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" />
                                </svg>
                            </h1>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 px-4">
                            <div>
                                <p className="text-xl py-4 text-gray-700">
                                    <strong>Real Success Stories from {city.name}</strong>
                                </p>
                                <p className="text-xl py-4 text-gray-700">
                                    Our PPC strategies have driven significant growth for businesses in {city.name}. We've helped a local retailer increase sales by 50% in three months and a service provider double their client base with targeted ad campaigns.
                                </p>
                                <p className="text-xl py-4 text-gray-700">
                                    A tech startup saw a 70% reduction in their cost per acquisition while scaling their campaigns. Our data-driven approach and continuous optimization ensure sustainable growth and profitability.
                                </p>
                                <p className="text-xl py-4 text-gray-700">
                                    These success stories highlight our commitment to delivering measurable results. Whether you're a small business or a growing enterprise, our PPC strategies are designed to help you thrive in {city.name}.
                                </p>
                            </div>
                            <div>
                                <p className="text-xl py-4 text-gray-700">
                                    <strong>Commitment to Excellence</strong>
                                </p>
                                <p className="text-xl py-4 text-gray-700">
                                    Transparency, integrity, and exceptional results are the pillars of our PPC management. We provide detailed reports and regular updates, ensuring you understand the impact of every dollar spent.
                                </p>
                                <p className="text-xl py-4 text-gray-700">
                                    Our team is dedicated to understanding your business and creating PPC strategies that contribute to your overall growth. We focus on long-term success through continuous optimization and strategic adjustments.
                                </p>
                                <p className="text-xl py-4 text-gray-700">
                                    Experience the difference with a partner dedicated to driving your success in {city.name}. Our local expertise and industry knowledge ensure highly targeted campaigns that resonate with your audience.
                                </p>
                                <p className="text-xl py-4 text-gray-700">
                                    By choosing us, you are choosing a partner invested in your success. We are passionate about helping businesses in {city.name} thrive and are here to support you every step of the way.
                                </p>
                                <p className="text-xl py-4 text-gray-700">
                                    Discover more about our <a href="https://www.gurulabs.dev/paid-ads" className="font-bold underline">Paid Ads services</a> and how they can benefit your business in {city.name}, {state.code}.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <SocialProof city={city.name} state={state.code} />
            <PPCFAQs city={city.name} state={state.code} />
            {/* Rest of the JSX */}
        </PageLayout>
    );
};

export default SurpriseAZPPCPage;