import React, { useState } from 'react';

const CPMCalculator = () => {
    const [totalImpressions, setTotalImpressions] = useState(10000);
    const [cpm, setCPM] = useState(10.00);
    const [currency, setCurrency] = useState('USD');
    const [totalCost, setTotalCost] = useState(100.00);

    const calculateTotalCost = () => {
        const total = (totalImpressions / 1000) * cpm;
        setTotalCost(total);
    };

    return (
        <div className="max-w-lg mx-auto mt-8 p-6 bg-gray-100 rounded-md shadow-md">
            <h2 className="text-2xl font-semibold mb-4">Cost per 1,000 Impressions (CPM) Calculator</h2>
            <div className="mb-4">
                <label htmlFor="totalImpressions" className="block mb-1">Total Impressions:</label>
                <input
                    type="text"
                    id="totalImpressions"
                    value={totalImpressions}
                    onChange={(e) => setTotalImpressions(Number(e.target.value))}
                    className="border-gray-300 rounded-md p-2 w-full"
                    min="0"
                />
            </div>
            <div className="mb-4">
                <label htmlFor="cpm" className="block mb-1">CPM (Cost per 1,000 Impressions):</label>
                <input
                    type="text"
                    id="cpm"
                    value={cpm}
                    onChange={(e) => setCPM(Number(e.target.value))}
                    className="border-gray-300 rounded-md p-2 w-full"
                    min="0"
                />
            </div>
            <div className="mb-4">
                <label htmlFor="currency" className="block mb-1">Currency:</label>
                <select
                    id="currency"
                    value={currency}
                    onChange={(e) => setCurrency(e.target.value)}
                    className="border-gray-300 rounded-md p-2 w-full"
                >
                    <option value="USD">USD</option>
                    <option value="EUR">EUR</option>
                    <option value="GBP">GBP</option>
                </select>
            </div>
            <div className="mb-4">
                <button
                    onClick={calculateTotalCost}
                    className="px-4 py-2 button text-white rounded hover:bg-blue-700"
                >
                    Calculate Total Cost
                </button>
            </div>
            <div className="mb-4">
                <h3 className="text-xl">
                    Total Ad Spend: {currency} {totalCost.toFixed(2)}
                </h3>
            </div>
        </div>
    );
};

export default CPMCalculator;
