import React from 'react'
import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
// import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const faqs = [
    {
        question: "What is Search Engine Optimization (SEO)?",
        answer: "Search Engine Optimization (SEO) is the practice of enhancing a website's visibility on search engine results pages (SERPs) through organic (non-paid) methods. It involves optimizing various elements of a website, including content, keywords, meta tags, and backlinks, to improve its ranking on search engines like Google, Bing, and Yahoo.",
    },
    {
        question: "Why is Search Engine Optimization (SEO) important?",
        answer: "SEO is crucial because it helps increase a website's visibility, driving more organic traffic from search engines. Higher visibility leads to more potential customers discovering your site, which can result in increased brand awareness, credibility, and revenue. Effective SEO strategies ensure that your website meets the search engine's criteria for relevance and quality.",
    },
    {
        question: "What does Search Engine Optimization (SEO) refer to?",
        answer: "SEO refers to the process of optimizing a website to improve its rankings on search engine results pages. This involves a variety of techniques, including keyword research, content creation, link building, and technical SEO adjustments, all aimed at making the site more attractive to search engines and users alike.",
    },
    {
        question: "How does paid search compare to Search Engine Optimization (SEO)?",
        answer: "Paid search, or Pay-Per-Click (PPC) advertising, involves paying for ad placements on search engine results pages, whereas SEO focuses on improving organic search rankings without direct payment. PPC can yield immediate results and targeted traffic, but SEO is a long-term strategy that builds sustainable organic traffic over time.",
    },
    {
        question: "What are the key factors related to Search Engine Optimization (SEO)?",
        answer: "Key factors in SEO include on-page elements (such as content quality, keyword usage, and meta tags), off-page factors (like backlinks and social signals), and technical aspects (such as site speed, mobile-friendliness, and proper indexing). Together, these elements help search engines understand and rank a website.",
    },
    {
        question: "How does utilizing SEO when writing a headline benefit a webpage?",
        answer: "Using SEO techniques when writing a headline ensures that the headline is both compelling and optimized for search engines. This can increase the likelihood of the page appearing in relevant search results, attracting more clicks, and improving the overall user experience by clearly indicating the page's content.",
    },
    {
        question: "What is the primary goal of Search Engine Optimization (SEO)?",
        answer: "The primary goal of SEO is to improve a website's organic search rankings, thereby increasing its visibility and attracting more relevant traffic from search engines. This can lead to higher engagement, more leads or sales, and better overall online presence for the website.",
    },
    {
        question: "What are the benefits of Search Engine Optimization (SEO)?",
        answer: "Benefits of SEO include increased website traffic, higher search engine rankings, improved user experience, enhanced credibility and trust, better return on investment (ROI) compared to paid advertising, and long-term sustainable growth. SEO also helps businesses stay competitive in their industry by maintaining a strong online presence.",
    },
    {
        question: "What is the difference between Search Engine Optimization (SEO) and Search Engine Marketing (SEM)?",
        answer: "SEO focuses on optimizing a website to achieve higher rankings in organic search results, while SEM encompasses both SEO and paid search strategies (PPC). SEM aims to increase visibility through both unpaid and paid methods, providing a broader approach to driving traffic and achieving marketing goals.",
    },
    {
        question: "How can Gurulabs help with Search Engine Optimization (SEO)?",
        answer: "Gurulabs offers comprehensive SEO services tailored to your business needs. Our experts conduct thorough keyword research, optimize your website's on-page and technical elements, create high-quality content, and build authoritative backlinks. We also provide detailed analytics and reporting to track your SEO progress and make data-driven adjustments, ensuring sustained growth and improved search engine rankings."
    },
    {
        question: "What is search engine optimization?",
        answer: "Search engine optimization (SEO) is the process of improving the visibility and ranking of a website on search engines like Google. It involves optimizing website content, structure, and external factors to enhance its relevance and authority for specific keywords.",
    },
    {
        question: "How does Gurulabs approach search engine optimization?",
        answer: "Gurulabs employs a comprehensive SEO strategy that includes keyword research, on-page optimization, link building, content creation, and technical SEO improvements. Our goal is to enhance both the visibility and usability of your site to drive organic traffic and conversions.",
    },
    {
        question: "What are search engine optimization services?",
        answer: "SEO services involve various techniques and strategies to improve a website’s ranking in search engine results pages (SERPs). These services include keyword research, content optimization, technical SEO, link building, and performance tracking, all aimed at boosting organic traffic.",
    },
    {
        question: "Why should I choose Gurulabs for search engine optimization?",
        answer: "Gurulabs offers specialized SEO services with a track record of improving search engine rankings and increasing organic traffic. Our expert team uses the latest tools and strategies tailored to meet the specific needs and goals of your business.",
    },
    {
        question: "What is local search engine optimization?",
        answer: "Local SEO is a strategy to optimize your website to rank higher for a local audience. It involves optimizing your site for location-specific queries and listings, ensuring that local consumers find you when they are searching for services or products in your area.",
    },
    {
        question: "How does Google search engine optimization differ?",
        answer: "Google SEO focuses on optimizing for Google’s algorithms, which account for over 90% of global web search traffic. It involves understanding and adapting to Google’s ranking factors, including keywords, site speed, mobile-friendliness, and content quality.",
    },
    {
        question: "Can Gurulabs help with eCommerce search engine lay and optimization?",
        answer: "Yes, Gurulabs specializes in eCommerce SEO, which involves optimizing online stores to rank higher in search results and attract more targeted traffic that can lead to increased sales and revenue.",
    },
    {
        question: "What are search engine optimization techniques?",
        answer: "Effective SEO techniques include on-page optimization (like keyword integration and content creation), off-page optimization (such as quality backlink building), and technical SEO (improving site structure, enhancing mobile responsiveness, and speeding up page load times).",
    },
    {
        question: "What kind of search engine optimization strategies does Gurulabs use?",
        answer: "Gurulabs uses tailored SEO strategies that focus on thorough market analysis, competitor research, and white-hat optimization techniques to enhance your website's visibility and authority effectively and sustainably.",
    },
    {
        question: "What are search engine optimization tools?",
        answer: "SEO tools are software programs designed to assist in optimizing a website for search engines. They provide insights into keyword rankings, backlink quality, content optimization, and more, helping to guide effective SEO strategies.",
    },
    {
        question: "What does a search engine optimization consultant do?",
        answer: "An SEO consultant at Gurulabs assesses your current website and digital marketing strategies, identifies areas for improvement, and devises a plan to enhance your search engine visibility and overall digital presence.",
    },
    {
        question: "What is the benefit of outsourcing search engine optimization to Gurulabs?",
        answer: "Outsourcing SEO to Gurulabs allows you to leverage our expertise and resources to boost your search engine rankings efficiently. We stay up-to-date with the latest trends and algorithm changes to ensure your SEO strategy remains effective.",
    },
    {
        question: "How do I get started with search engine optimization at Gurulabs?",
        answer: "To get started with SEO at Gurulabs, contact our team to schedule a consultation. We will review your website and business objectives, then craft a customized SEO plan designed to achieve your specific goals and maximize your online presence.",
    },
];






function SEOFAQs() {
    return (
        <div className="bg-white">
            <div className="mx-auto max-w-7xl px-6 py-12 sm:py-24 lg:px-8 lg:py-24">
                <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
                    <h2 className="text-3xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
                    <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                        {faqs.map((faq) => (
                            <Disclosure as="div" key={faq.question} className="pt-6">
                                {({ open }) => (
                                    <>
                                        <dt>
                                            <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                                                <span className="text-base font-semibold leading-7">{faq.question}</span>
                                                <span className="ml-6 flex h-7 items-center">
                                                    {open ? (
                                                        <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                    ) : (
                                                        <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                    )}
                                                </span>
                                            </Disclosure.Button>
                                        </dt>
                                        <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                            <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
}


export default SEOFAQs
