import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import PersonalInformation from '../PaidAdsLeadsForm/PersonalInformation/Index';
import BusinessInformation from '../PaidAdsLeadsForm/BusinessInformation/Index';
import AdditionalDetails from '../PaidAdsLeadsForm/AdditionalDetails/Index';
import Swal from 'sweetalert2';
import { Inertia } from '@inertiajs/inertia';

const LeadsModal = ({ isOpen, setIsOpen }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [captcha, setCaptchaValue] = useState('');
    const [formValues, setFormValues] = useState({});

    const { register, handleSubmit, formState: { errors }, trigger, control, reset } = useForm();

    const handleNext = async () => {
        const isValid = await trigger(); // Trigger form validation
        console.log(isValid);
        if (isValid) {
            setCurrentStep(currentStep + 1);
        }
    };

    const handlePrevious = () => {
        setCurrentStep(currentStep - 1);
    };

    const onSubmit = async (data) => {
        // setFormValues({ ...formValues, ...data });

        if (currentStep === 2) {
            // Process form submission
            try {
                await Inertia.post('/get-started', { ...data, captcha: captcha });
                Swal.fire(
                    'Awesome!',
                    'Your information has been received!',
                    'success'
                );
                setIsOpen(false); // Close the modal after successful submission
            } catch (error) {
                setIsOpen(true);
                toast.error('An error occurred, please try again');
            }

        } else {
            handleNext();
        }
    };

    useEffect(() => {
        const handleBodyScroll = () => {
            if (isOpen) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = 'auto';
            }
        };

        handleBodyScroll();

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpen]);

    const resetForm = () => {
        setCurrentStep(0);
        setFormValues({});
        reset(); // Reset the form
    };

    const steps = [
        {
            title: 'Personal Information',
            component: <PersonalInformation
                register={register}
                errors={errors}
                trigger={trigger}
                control={control}
                captcha={captcha}
                setCaptchaValue={setCaptchaValue}
            />
        },
        {
            title: 'Business Information',
            component: <BusinessInformation
                register={register}
                errors={errors}
                trigger={trigger}
                control={control}
                captcha={captcha}
                setCaptchaValue={setCaptchaValue}
            />
        },
        {
            title: 'Additional Details',
            component: <AdditionalDetails
                register={register}
                errors={errors}
                trigger={trigger}
                control={control}
                captcha={captcha}
                setCaptchaValue={setCaptchaValue}
            />
        }
    ];

    return (
        <Modal isOpen={isOpen} ariaHideApp={false} shouldCloseOnOverlayClick={false} onRequestClose={() => { setIsOpen(false); resetForm(); }} style={
            {
                overlay: {
                    backgroundColor: 'rgba(0,0,0,0.9)',
                    overflowY: 'hidden !important'
                },
                content: {
                    position: 'fixed',
                    maxWidth: '680px',
                    maxHeight: "700px",
                    margin: '200px auto',

                }
            }
        }>
            <button className="absolute top-0 right-0 m-2 p-2" onClick={() => { setIsOpen(false); resetForm(); }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-500 hover:text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>
            <form onSubmit={handleSubmit(onSubmit)}>
                {steps[currentStep].component}
                <div className="flex flex-col sm:flex-row justify-between mt-4">
                    {currentStep > 0 && (
                        <button
                            type="button"
                            onClick={handlePrevious}
                            className="button bg-gray-300 hover:bg-gray-400 text-white py-2 px-4 rounded sm:w-auto w-full sm:mr-2 mb-2 sm:mb-0"
                        >
                            Previous
                        </button>
                    )}
                    {currentStep === steps.length - 1 && (
                        <button
                            type="submit"
                            className={`button bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded ${currentStep === 0 ? 'w-full' : 'w-auto'} sm:mr-2 mb-2 sm:mb-0`}
                        >
                            Submit
                        </button>
                    )}
                    {currentStep < steps.length - 1 && (
                        <button
                            type="button"
                            onClick={handleNext}
                            className={`button bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${currentStep === 0 ? 'w-full' : 'w-auto'} sm:mr-2`}
                        >
                            Next
                        </button>
                    )}

                </div>

            </form>
        </Modal>
    )
}
export default LeadsModal;
