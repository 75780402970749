import React from "react";
import Flickity from "react-flickity-component";

const flickityOptions = {
    cellAlign: "center",
    autoPlay: true,
    contain: true,
    initialIndex: 0,
    prevNextButtons: true,
    pageDots: true,
    wrapAround: true,
    lazyLoad: true
}


const onIsSelectedEvent = (flickity) => {
    // console.log(flickity);
};

const SearchEngineSlider = () => {
    return (
        <>
            <Flickity
                flickityRef={onIsSelectedEvent} // takes a reference to the Flickity instance as the first argument
                className={'bg-white h-104 py-8 md:py-12 lg:py-16 shadow-md rounded-3xl outline-none max-w-7xl mx-auto'}  // default ''
                elementType={'div'} // default 'div'
                options={flickityOptions} // takes flickity options {}
                disableImagesLoaded={false} // default false
                reloadOnUpdate // default false
                static // default false
            >
                <div className="carousel-cell px-2 flex flex-col items-center">
                    <h3 className="prose-blockquote py-4 text-xl md:text-2xl lg:text-3xl text-center">
                        “Great communication, great skills, project finished way ahead of schedule. It was a really wonderful experience working with them.”
                    </h3>
                    <img src="https://www.renaeusa.com/_nuxt/img/renae.138febb.png" className="h-20 w-20 rounded-full" alt="Renae' Mussachio" />
                    <p className="text-xl py-2 text-center">Renae' Mussachio</p>
                    <p className="text-base text-center">Conscious Creator</p>
                </div>
                <div className="carousel-cell px-2 flex flex-col items-center">
                    <h3 className="prose-blockquote py-4 text-xl md:text-2xl lg:text-3xl text-center">
                        “They were able to explain complex concepts in a simple and straightforward manner, making it easy for me to understand and implement the necessary changes. ”
                    </h3>
                    <img src="/frontend/images/png/22.webp" className="h-20 w-20 rounded-full" alt="Jason" />
                    <p className="text-xl py-2 text-center">Jason</p>
                    <p className="text-base text-center">PreStar Services Group</p>
                </div>
            </Flickity>
        </>
    )
}
export default SearchEngineSlider
