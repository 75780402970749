import React from 'react'

const NewsletterForm = () => {
    return (
        <div className="mt-8 xl:mt-0">
            <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase">
                Subscribe to our newsletter
            </h3>
            <p className="mt-4 text-base text-gray-700">
                The latest news, articles, and resources, sent to your inbox weekly.
            </p>
            <form className="mt-4 sm:flex sm:max-w-md">
                <label htmlFor="email-address" className="sr-only">
                    Email address
                </label>
                <input
                    type="email"
                    name="email-address"
                    id="email-address"
                    autoComplete="email"
                    required
                    className="appearance-none min-w-0 w-full bg-white border border-transparent py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white focus:border-white focus:placeholder-gray-400"
                    placeholder="Enter your email"
                />
                <div className="mt-3 rounded-md sm:mt-0 sm:ml-1 sm:flex-shrink-0">
                    <button
                        type="submit"
                        className="w-full bg-gray-900 border border-transparent py-2 px-4 flex items-center justify-center text-base font-medium text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-gray-500"
                    >
                        Subscribe
                    </button>
                </div>
            </form>
        </div>
    )
}
export default NewsletterForm
