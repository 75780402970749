import React, { useState } from 'react';

const Calculator = () => {
    const [averageOrderValue, setAverageOrderValue] = useState(99);
    const [quantity, setQuantity] = useState(1);
    const [blendedCOGS, setBlendedCOGS] = useState(0);
    const [creditCardFees, setCreditCardFees] = useState(2.48);
    const [merchantAccountFees, setMerchantAccountFees] = useState(0.3);
    const [shippingCosts, setShippingCosts] = useState(0);
    const [fulfillmentCostPerUnit, setFulfillmentCostPerUnit] = useState(25);
    const [fbManagementCost, setFbManagementCost] = useState(0);

    const calculateTotalCost = () => {
        const totalCOGS = blendedCOGS * quantity;
        const totalFees = parseFloat(creditCardFees) + parseFloat(merchantAccountFees) + parseFloat(shippingCosts) + (fulfillmentCostPerUnit * quantity) + parseFloat(fbManagementCost);
        return totalCOGS + totalFees;
    };

    const calculatePercentageCost = () => {
        const totalCost = calculateTotalCost();
        return ((totalCost / averageOrderValue) * 100).toFixed(2);
    };

    const calculateGrossProfitMargin = () => {
        return (100 - calculatePercentageCost()).toFixed(2);
    };

    const calculateCPAToBreakEven = () => {
        return (averageOrderValue - calculateTotalCost()).toFixed(2);
    };

    const calculateROASBreakEven = () => {
        return (calculateCPAToBreakEven() / averageOrderValue).toFixed(2);
    };

    return (
        <section>
            <div className="py-12">
                <div className="max-w-lg mx-auto mt-8 p-6 bg-gray-100 rounded-md shadow-md">
                    <h2 className="text-2xl font-semibold mb-4">Facebook Ads <br /> Cost of Goods Profit Calculator</h2>
                    <div className="mb-4">
                        <label htmlFor="averageOrderValue" className="block mb-1">Average Order Value:</label>
                        <input type="number" id="averageOrderValue" value={averageOrderValue} onChange={(e) => setAverageOrderValue(e.target.value)} className="border-gray-300 rounded-md p-2 w-full" />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="quantity" className="block mb-1">Quantity:</label>
                        <input type="number" id="quantity" value={quantity} onChange={(e) => setQuantity(e.target.value)} className="border-gray-300 rounded-md p-2 w-full" />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="blendedCOGS" className="block">Blended COGS:</label>
                        <input type="number" id="blendedCOGS" value={blendedCOGS} onChange={(e) => setBlendedCOGS(e.target.value)} className="border-gray-300 rounded-md p-2 w-full" />
                    </div>
                    <div>
                        <label htmlFor="creditCardFees">Credit Card Fees:</label>
                        <input type="number" id="creditCardFees" value={creditCardFees} onChange={(e) => setCreditCardFees(e.target.value)} className="border-gray-300 rounded-md p-2 w-full" />
                    </div>
                    <div>
                        <label htmlFor="merchantAccountFees">Merchant Account Fees:</label>
                        <input type="number" id="merchantAccountFees" value={merchantAccountFees} onChange={(e) => setMerchantAccountFees(e.target.value)} className="border-gray-300 rounded-md p-2 w-full" />
                    </div>
                    <div>
                        <label htmlFor="shippingCosts">Shipping Costs:</label>
                        <input type="number" id="shippingCosts" value={shippingCosts} onChange={(e) => setShippingCosts(e.target.value)} className="border-gray-300 rounded-md p-2 w-full" />
                    </div>
                    <div>
                        <label htmlFor="fulfillmentCostPerUnit">Fulfillment Cost Per Unit:</label>
                        <input type="number" id="fulfillmentCostPerUnit" value={fulfillmentCostPerUnit} onChange={(e) => setFulfillmentCostPerUnit(e.target.value)} className="border-gray-300 rounded-md p-2 w-full" />
                    </div>
                    <div>
                        <label htmlFor="fbManagementCost">FB Management Cost:</label>
                        <input type="number" id="fbManagementCost" value={fbManagementCost} onChange={(e) => setFbManagementCost(e.target.value)} className="border-gray-300 rounded-md p-2 w-full" />
                    </div>
                    {/* Other input fields... */}
                    <div className="mb-4">
                        <h3 className="text-xl py-4">Total Cost of Product & to Fulfill: ${calculateTotalCost().toFixed(2)}</h3>
                        <p className="text-xl">Percentage Cost: {calculatePercentageCost()}%</p>
                    </div>
                    <div className="mb-4">
                        <h3 className="text-xl">Gross Profit Margins: {calculateGrossProfitMargin()}%</h3>
                    </div>
                    <div className="mb-4">
                        <h3 className="text-xl">
                            CPA To Break Even on Platform: ${calculateCPAToBreakEven()}
                            <p>(without marketing costs)</p>
                        </h3>
                        <p className="text-xl py-4">ROAS Break Even (Profit - AOV): {calculateROASBreakEven()}</p>

                    </div>
                </div>
            </div>
        </section>
    );
};

export default Calculator;
