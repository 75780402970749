import React from 'react'
import LeadsForm from '../../../../Components/LeadsForm/Index'


const AutomationHero = ({ name }) => {
    return (
        <section className="ai-hero">
            <div className="max-w-3xl mx-auto px-4">
                <div className="block text-center pt-12">
                    <h1 className="text-5xl xl:text-6xl">
                        Unlock Efficiency with
                        <br />
                        <svg width="270" height="17" fill="none" className="absolute mt-12 ml-24 hidden md:block">
                            <path stroke="#48BB78" strokeWidth="2" d="M.5 3C183-3.5 145.5 10 123 14s118-4 146.5-7.5" />
                        </svg>
                        <span className="mr-2 text-green-500 font-semibold">
                            {name} Consulting & Integration
                        </span>
                        to Transform Your Business
                    </h1>
                    <p className="py-5 text-md md:text-lg max-w-xl mx-auto">
                        Achieve over 30% reduction in operational costs. Our expert Zapier consulting and integration services help you scale efficiently while optimizing your processes.
                    </p>
                </div>
                <div className="max-w-3xl mx-auto">
                    <LeadsForm />
                </div>
            </div>
        </section>
    )
}
export default AutomationHero
