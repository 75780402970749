import React, { useState } from 'react';

const ConversionValueCalculator = () => {
    const [revenueGenerated, setRevenueGenerated] = useState(5000);
    const [numberOfConversions, setNumberOfConversions] = useState(100);
    const [conversionValue, setConversionValue] = useState(0.0);

    const calculateConversionValue = () => {
        if (numberOfConversions > 0) {
            const value = revenueGenerated / numberOfConversions;
            setConversionValue(value);
        }
    };

    return (
        <div className="max-w-lg mx-auto mt-8 p-6 bg-gray-100 rounded-md shadow-md">
            <h2 className="text-2xl font-semibold mb-4">Conversion Value Calculator</h2>
            <div className="mb-4">
                <label htmlFor="revenueGenerated" className="block mb-1">Revenue Generated:</label>
                <input
                    type="number"
                    id="revenueGenerated"
                    value={revenueGenerated}
                    onChange={(e) => setRevenueGenerated(Number(e.target.value))}
                    className="border-gray-300 rounded-md p-2 w-full"
                    min="0"
                />
            </div>
            <div className="mb-4">
                <label htmlFor="numberOfConversions" className="block mb-1">Number of Conversions:</label>
                <input
                    type="number"
                    id="numberOfConversions"
                    value={numberOfConversions}
                    onChange={(e) => setNumberOfConversions(Number(e.target.value))}
                    className="border-gray-300 rounded-md p-2 w-full"
                    min="0"
                />
            </div>
            <div className="mb-4">
                <button
                    onClick={calculateConversionValue}
                    className="px-4 py-2 button text-white rounded hover:bg-blue-700"
                >
                    Calculate Conversion Value
                </button>
            </div>
            <div className="mb-4">
                <h3 className="text-xl">
                    Conversion Value: ${conversionValue.toFixed(2)}
                </h3>
            </div>
        </div>
    );
};

export default ConversionValueCalculator;
