import React from 'react'
import Swal from 'sweetalert2'
import { Link } from '@inertiajs/inertia-react'
import { useForm } from '@inertiajs/inertia-react'
import toast from 'react-hot-toast'


const Newsletter = () => {
    const { data, setData, post, processing, errors, reset, wasSuccessful, isDirty } = useForm({
        email: "",
    });

    const handleFormSubmission = e => {
        e.preventDefault()
        try {
            post('/api/newsletter/signup', {
                preserveScroll: true,
                onSuccess: () => {
                    reset()
                    Swal.fire(
                        'Awesome!',
                        'Your information has been received!',
                        'success'
                    )
                },
                onError: (errors) => {
                    toast.error(errors.email)
                },
            }, data)
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <section>
            <div className="bg-green-10 px-3 h-full py-12">
                <div className="max-w-7xl mx-auto">
                    <div className="flex justify-between items-center">
                        <div className="w-full lg:w-10/12">
                            <h3 className="text-5xl py-2 text-white">Give me the latest news!</h3>
                            <h4 className="text-xl py-5 text-white">Want to know which websites saw the most traffic growth in your industry? Not sure why your SEO strategy doesn’t work? Or simply looking for SEO tips? Subscribe to our newsletter to receive updates on the content you care about.</h4>
                            <form onSubmit={(e) => handleFormSubmission(e)} className="py-3">
                                <input
                                    type="email"
                                    className="w-full mt-1 md:-mt-1 lg:w-8/12 py-3 shadow-md rounded-md"
                                    placeholder='Your email'
                                    value={data.email}
                                    onChange={(e) => setData('email', e.target.value)}
                                />
                                <button
                                    disabled={!isDirty || processing}
                                    className="w-full mt-1 md:-mt-1 lg:w-4/12 text-white bg-gray-700 py-3 px-4 shadow-md rounded-md"
                                    type="submit">
                                    Subscribe
                                </button>
                            </form>
                            {errors.email && <p className="text-red-500">{errors.email}</p>}
                            <div className="max-w-2xl py-2">
                                <h5 className="text-sm text-white">By clicking “Subscribe” you agree to <Link href="/privacy-policy">Gurulabs Privacy Policy</Link> and consent to Semrush using your contact data for newsletter purposes</h5>
                            </div>
                        </div>
                        <div className="hidden lg:block lg:w-3/12">
                            <img src="/frontend/images/png/image-06.png" width="288px" height="324px" alt="Subscribe" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Newsletter
