import React from 'react'


const Process = ({ button, title, description, Icon }) => {
    return (
        <div className={`m-3 p-3 rounded-2xl ${button}`}>
            <button aria-label="Button" className="bg-white py-4 px-4 rounded-xl cursor-default">
                {Icon && <Icon className="h-6 w-6 text-amber-500" />}
            </button>
            <h3 className="text-gray-900 pt-4 text-lg">{title}</h3>
            <p className="text-gray-700 py-3">{description}</p>
        </div>
    )
}
export default Process
