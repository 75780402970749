import React, { useEffect, useState } from 'react';
import MainLayout from '../../Layout/MainLayout/Index';
import MetaHead from '../../Components/MetaHead';
import mixpanel from 'mixpanel-browser';

const LeadsThankYou = () => {
    const [leadInfo, setLeadInfo] = useState({});

    useEffect(() => {
        // Track page visit with Mixpanel
        mixpanel.track("Lead Confirmation Page Visited");

        // Parse query string
        const queryParams = new URLSearchParams(window.location.search);

        // Extract and decode JSON fields
        const decodeJSON = (param) => {
            try {
                return JSON.parse(param);
            } catch (e) {
                return null;
            }
        };

        // Extract all the required parameters from the query string
        const leadData = {
            first_name: queryParams.get('first_name'),
            last_name: queryParams.get('last_name'),
            email: queryParams.get('email'),
            website: queryParams.get('website') || 'Not provided',
            phone_number: queryParams.get('phone_number'),
            company_name: queryParams.get('company_name'),
            description: queryParams.get('description'),
            business_problem: decodeJSON(queryParams.get('business_problem'))?.label || 'Not provided',
            socialmedia_options: decodeJSON(queryParams.get('socialmedia_options'))?.[0]?.label || 'Not provided',
            monthly_budget: decodeJSON(queryParams.get('monthly_budget'))?.label || 'Not provided',
            marketing_type: queryParams.get('marketing_type') || 'Not provided',
            active_platforms: queryParams.get('active_platforms') || 'Not provided',
            hear_about: queryParams.get('hear_about') || 'Not provided',
            annual_revenue: decodeJSON(queryParams.get('annual_revenue'))?.label || 'Not provided',
            automation_tools: decodeJSON(queryParams.get('automation_tools'))?.label || 'Not provided',
            business_type: decodeJSON(queryParams.get('business_type'))?.label || 'Not provided',
        };

        setLeadInfo(leadData);
    }, []);

    return (
        <MainLayout>
            <MetaHead title="Thank You for Contacting Us" description="We appreciate your interest in our services" />
            <section className="max-w-7xl mx-auto p-6">
                <div className='flex flex-col items-center'>
                    <img src="/frontend/images/gif/thankyou.webp" className="h-[700px] w-[700px] py-8 px-4" alt="Thank-you By Gurulabs" />
                    <div className="px-4 sm:px-0">
                        <h3 className="text-3xl font-semibold leading-7 text-gray-900">Thank You, {leadInfo.first_name}!</h3>
                        <p className="mt-1 max-w-2xl text-lg text-gray-500">We have received your information. Below are the details you provided:</p>
                    </div>
                    <div className="mt-6 border-t border-gray-100">
                        <dl className="divide-y divide-gray-100">
                            {/* Full Name */}
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Full Name</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{leadInfo.first_name} {leadInfo.last_name}</dd>
                            </div>

                            {/* Email */}
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Email Address</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{leadInfo.email}</dd>
                            </div>

                            {/* Website */}
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Website</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{leadInfo.website}</dd>
                            </div>

                            {/* Phone Number */}
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Phone Number</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{leadInfo.phone_number}</dd>
                            </div>

                            {/* Company */}
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Company</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{leadInfo.company_name}</dd>
                            </div>

                            {/* Description */}
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Description</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{leadInfo.description}</dd>
                            </div>

                            {/* Business Problem */}
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Business Problem</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{leadInfo.business_problem}</dd>
                            </div>

                            {/* Social Media */}
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Social Media</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{leadInfo.socialmedia_options}</dd>
                            </div>

                            {/* Monthly Budget */}
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Monthly Budget</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{leadInfo.monthly_budget}</dd>
                            </div>

                            {/* Marketing Type */}
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Marketing Type</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{leadInfo.marketing_type}</dd>
                            </div>

                            {/* Active Platforms */}
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Active Platforms</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{leadInfo.active_platforms}</dd>
                            </div>

                            {/* Hear About Us */}
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">How did you hear about us?</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{leadInfo.hear_about}</dd>
                            </div>

                            {/* Annual Revenue */}
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Annual Revenue</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{leadInfo.annual_revenue}</dd>
                            </div>

                            {/* Automation Tools */}
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Automation Tools</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{leadInfo.automation_tools}</dd>
                            </div>

                            {/* Business Type */}
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Business Type</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{leadInfo.business_type}</dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </section>
        </MainLayout>
    );
};

export default LeadsThankYou;
