import React from 'react';
import { Tab } from '@headlessui/react'
import SearchEngineSlider from '../SearchEngineSlider/Index';
import SocialMediaSlider from '../SocialMediaSlider/Index';

const TestimonialSection = () => {
    return (
        <div className="bg-amber-50 py-12 overflow-hidden">
            <div className="max-w-7xl mx-auto">
                <div className='block sm:flex justify-center items-center px-4'>
                    <h2 className="block text-3xl sm:text-4xl py-5 relative">
                        <span className="relative">This is why they use</span>
                        <span className="text-amber-950 ml-2 relative">
                            Gurulabs
                        </span>
                        <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4 ml-0 hidden xl:block" width={151} height={3} fill="none"><path stroke="#FFAC36" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                    </h2>
                </div>
                <div className='px-4'>
                    <div className="rounded-lg">
                        <Tab.Group as='div' className="" vertical>
                            <Tab.List as='div' className="block xs:flex flex-wrap justify-center items-center gap-6">
                                <Tab as='div' className="flex flex-col items-center outline-none cursor-pointer py-4">
                                    {({ selected }) => (
                                        <div className={`${selected ? "border-b-4 border-amber-950 flex flex-col items-center" : "border-b-2 border-gray-500 flex flex-col items-center"}`}>
                                            <h3 className="text-xl py-4">Search Engine</h3>
                                        </div>
                                    )}
                                </Tab>
                                <Tab as='div' className="flex flex-col items-center outline-none cursor-pointer py-4">
                                    {({ selected }) => (
                                        <div className={`${selected ? "border-b-4 border-amber-950 flex flex-col items-center" : "border-b-2 border-gray-500 flex flex-col items-center"}`}>
                                            <h3 className="text-xl py-4">Social Media</h3>
                                        </div>
                                    )}
                                </Tab>
                                <Tab as='div' className="flex flex-col items-center outline-none cursor-pointer py-4">
                                    {({ selected }) => (
                                        <div className={`${selected ? "border-b-4 border-amber-950 flex flex-col items-center" : "border-b-2 border-gray-500 flex flex-col items-center"}`}>
                                            <h3 className="text-xl py-4">eCommerce</h3>
                                        </div>
                                    )}
                                </Tab>
                                <Tab as='div' className="flex flex-col items-center outline-none cursor-pointer py-4">
                                    {({ selected }) => (
                                        <div className={`${selected ? "border-b-4 border-amber-950 flex flex-col items-center" : "border-b-2 border-gray-500 flex flex-col items-center"}`}>
                                            <h3 className="text-xl py-4">Local Lead Ads</h3>
                                        </div>
                                    )}
                                </Tab>
                                <Tab as='div' className="flex flex-col items-center outline-none cursor-pointer py-4">
                                    {({ selected }) => (
                                        <div className={`${selected ? "border-b-4 border-amber-950 flex flex-col items-center" : "border-b-2 border-gray-500 flex flex-col items-center"}`}>
                                            <h3 className="text-xl py-4">Data Analytics</h3>
                                        </div>
                                    )}
                                </Tab>
                                <Tab as='div' className="flex flex-col items-center outline-none cursor-pointer py-4">
                                    {({ selected }) => (
                                        <div className={`${selected ? "border-b-4 border-amber-950 flex flex-col items-center" : "border-b-2 border-gray-500 flex flex-col items-center"}`}>
                                            <h3 className="text-xl py-4">Development</h3>
                                        </div>
                                    )}
                                </Tab>
                            </Tab.List>
                            <Tab.Panels className="py-12 max-w-7xl mx-auto">
                                <Tab.Panel>
                                    <SearchEngineSlider />
                                </Tab.Panel>
                                <Tab.Panel>
                                    <SocialMediaSlider />
                                </Tab.Panel>
                                <Tab.Panel>
                                    {/* <TestimonialSlider /> */}
                                </Tab.Panel>
                                <Tab.Panel>
                                    {/* <TestimonialSlider /> */}
                                </Tab.Panel>
                                <Tab.Panel>
                                    {/* <TestimonialSlider /> */}
                                </Tab.Panel>
                                <Tab.Panel>
                                    {/* <TestimonialSlider /> */}
                                </Tab.Panel>
                            </Tab.Panels>
                        </Tab.Group>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TestimonialSection
