import React, { useEffect } from 'react'
import Modal from 'react-modal'

function CalendlyModal({ isOpen, setIsOpen }) {

    useEffect(() => {

        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }

        const close = (e) => {
            if (e.keyCode === 27) {
                props.onCloseModal()
                document.body.style.overflow = 'unset';
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [isOpen, setIsOpen]);

    return (
        <Modal isOpen={isOpen} ariaHideApp={false} shouldCloseOnOverlayClick={false} onRequestClose={() => setIsOpen(false)} style={
            {
                overlay: {
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    overflowY: 'hidden !important',
                },
                content: {
                    position: 'fixed',
                    maxWidth: '700px',
                    maxHeight: "750px",
                    margin: '0 auto',

                }
            }
        }>
            <iframe src="https://calendly.com/gurulabs/15min?embed_domain=www.gurulabs.dev&embed_type=PopupText&hide_event_type_details=1&hide_gdpr_banner=1&primary_color=22c55e" height="100%" width="100%"></iframe>
        </Modal >
    )
}
export default CalendlyModal
