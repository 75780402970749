import React from 'react'
import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
// import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const faqs = [
    {
        question: "What services does Gurulabs offer for Instagram ads management?",
        answer: "Gurulabs offers comprehensive Instagram ads management services including campaign setup, optimization, monitoring, and analytics to maximize your ad performance and ROI.",
    },
    {
        question: "How can Gurulabs help with creating Instagram ads?",
        answer: "Our team at Gurulabs can assist in crafting targeted ad content, selecting appropriate audiences, and designing engaging visuals to ensure your Instagram ads stand out and perform well.",
    },
    {
        question: "Does Gurulabs provide training on managing Instagram ads?",
        answer: "Yes, Gurulabs offers training sessions for individuals and teams that cover all aspects of Instagram ads, from basic setup to advanced strategies to optimize your advertising efforts.",
    },
    {
        question: "Can Gurulabs help reduce the cost of my Instagram ads?",
        answer: "Absolutely! Gurulabs specializes in ad spend optimization to ensure that your budget is used efficiently, reducing overall costs while improving ad performance.",
    },
    {
        question: "What makes Gurulabs different when it comes to Instagram ads management?",
        answer: "Gurulabs sets itself apart by combining state-of-the-art technology with personalized service to craft ads that not only meet but exceed our clients' marketing objectives.",
    },
    {
        question: "How does Gurulabs approach Instagram video ads?",
        answer: "Gurulabs uses engaging content, strategic targeting, and continuous optimization to ensure your Instagram video ads capture attention and drive results.",
    },
    {
        question: "Can Gurulabs assist with international Instagram ad campaigns?",
        answer: "Yes, we have expertise in managing global Instagram ad campaigns, helping you to reach and engage with audiences in different markets effectively.",
    },
    {
        question: "What types of businesses can benefit from Gurulabs' Instagram ads services?",
        answer: "Gurulabs works with a variety of business sectors including retail, technology, real estate, and more, tailoring strategies to meet specific industry needs.",
    },
    {
        question: "How does Gurulabs measure the success of Instagram ads?",
        answer: "We utilize advanced tracking and analytics to measure key performance indicators like engagement rates, conversion rates, and ROI, ensuring your ads are achieving desired goals.",
    },
    {
        question: "Does Gurulabs offer support for Instagram Ads Library?",
        answer: "Yes, Gurulabs can help you navigate the Instagram Ads Library to research ad designs and strategies that are transparent and effective.",
    },
    {
        question: "How often does Gurulabs update client Instagram ad strategies?",
        answer: "We believe in continuous improvement and regularly update ad strategies based on performance data and evolving market trends to keep your campaigns effective.",
    },
    {
        question: "Can Gurulabs manage my Instagram Ads Manager account?",
        answer: "Yes, our team can fully manage your Instagram Ads Manager account, overseeing all aspects from ad creation to budget management and reporting.",
    },
    {
        question: "What are the latest trends in Instagram ads that Gurulabs is utilizing?",
        answer: "Gurulabs stays ahead by leveraging AI-driven analytics, influencer collaborations, and personalized ad experiences to enhance engagement and effectiveness of Instagram ad campaigns.",
    },
    {
        question: "How can I get started with Gurulabs for my Instagram advertising needs?",
        answer: "Simply contact us through our website or give us a call to schedule a consultation. Our team will discuss your needs and how we can help boost your Instagram advertising efforts.",
    },
    {
        question: "What budget do I need to start Instagram advertising with Gurulabs?",
        answer: "We offer flexible pricing models that can be tailored to your budget and goals. Contact us for a detailed and customized quote.",
    },
    {
        question: "Does Gurulabs offer any guarantees for Instagram ad performance?",
        answer: "While advertising always involves variables, Gurulabs guarantees commitment to optimizing your campaigns for maximum performance and satisfaction.",
    },
    {
        question: "Can Gurulabs help with Instagram ad compliance issues?",
        answer: "Yes, we ensure that all ads managed by Gurulabs comply with Instagram's advertising policies and regulations to prevent any disruptions in your campaigns.",
    },
    {
        question: "What reporting does Gurulabs provide for Instagram ad campaigns?",
        answer: "Gurulabs provides detailed reports on campaign performance, including audience insights, cost analysis, and recommendations for further optimization.",
    },
    {
        question: "Can Gurulabs optimize my existing Instagram ad campaigns?",
        answer: "Yes, our experts can analyze and revamp existing campaigns to enhance their performance through strategic adjustments and advanced targeting techniques.",
    },
    {
        question: "How does Gurulabs handle influencer collaborations on Instagram?",
        answer: "Gurulabs collaborates with influencers to create authentic and engaging content, leveraging their audience to boost your brand's visibility and engagement on Instagram.",
    }
];




function InstagramFAQs() {
    return (
        <div className="bg-white">
            <div className="mx-auto max-w-7xl px-6 py-12 sm:py-24 lg:px-8 lg:py-24">
                <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
                    <h2 className="text-3xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
                    <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                        {faqs.map((faq) => (
                            <Disclosure as="div" key={faq.question} className="pt-6">
                                {({ open }) => (
                                    <>
                                        <dt>
                                            <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                                                <span className="text-base font-semibold leading-7">{faq.question}</span>
                                                <span className="ml-6 flex h-7 items-center">
                                                    {open ? (
                                                        <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                    ) : (
                                                        <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                    )}
                                                </span>
                                            </Disclosure.Button>
                                        </dt>
                                        <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                            <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
}


export default InstagramFAQs
