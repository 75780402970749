import React from 'react';
import LeadsForm from '../LeadsForm/Index';
import { motion } from 'framer-motion';
import BackgroundSVG from '../../Components/BackgroundSVG/Index'; // Import your SVG component here

const HeroSection = () => {
    return (
        <section className="relative w-full h-[85vh] overflow-hidden">
            {/* Render your SVG as the background */}
            <BackgroundSVG />

            {/* Text and content with higher z-index */}
            <div className="relative z-0 max-w-3xl mx-auto px-4">
                <div className="block text-center pt-12">
                    {/* Motion h1 with responsive font size */}
                    <motion.h1
                        animate={{ y: 10 }}
                        className="text-5xl xl:text-6xl font-bold"
                    >
                        An agency with a twist
                        <br />
                        <motion.svg
                            width="270"
                            height="17"
                            fill="none"
                            className="absolute mt-12 ml-24 hidden md:block"
                        >
                            <motion.path
                                d="M.5 3C183-3.5 145.5 10 123 14s118-4 146.5-7.5"
                                stroke="#48BB78"
                                strokeWidth="2"
                                initial={{ pathLength: 0 }}
                                animate={{ pathLength: 1 }}  // Animate the drawing of the underline
                                transition={{
                                    duration: 2,
                                    ease: 'easeInOut',  // Smooth animation
                                    repeat: Infinity,   // Optional, if you want the animation to repeat
                                    repeatType: 'reverse',  // Reverse to give it a draw and retract effect
                                }}
                            />
                        </motion.svg>
                        <span className="mr-2 py-2 text-green-500 font-semibold">
                            Innovative solutions
                        </span>{' '} {/* Add space after span */}
                        to transform your business
                    </motion.h1>
                    <p className="py-5 text-md md:text-lg max-w-xl mx-auto">
                        Over 30% reduction in operational costs. Our innovative solutions not only help scale but also optimize your businesses.
                    </p>
                </div>

                {/* Leads form with proper positioning */}
                <div className="max-w-3xl mx-auto">
                    <LeadsForm />
                </div>
            </div>
        </section>
    );
};

export default HeroSection;
