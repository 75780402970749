import React from 'react'
import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
// import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'


const faqs = [
    {
        question: "What services does Gurulabs offer for Shopify ads management?",
        answer: "Gurulabs offers comprehensive Shopify ads management services including campaign setup, optimization, monitoring, and analytics to maximize your ad performance and ROI.",
    },
    {
        question: "How can Gurulabs help with creating Shopify ads?",
        answer: "Our team at Gurulabs can assist in crafting targeted ad content, selecting appropriate keywords, and designing engaging visuals to ensure your Shopify ads stand out and perform well.",
    },
    {
        question: "Does Gurulabs provide training on managing Shopify ads?",
        answer: "Yes, Gurulabs can offer training sessions for individuals and teams that cover all aspects of Shopify ads, from basic setup to advanced strategies to optimize your advertising efforts.",
    },
    {
        question: "Can Gurulabs help reduce the cost of my Shopify ads?",
        answer: "Absolutely! Gurulabs specializes in ad spend optimization to ensure that your budget is used efficiently, reducing overall costs while improving ad performance.",
    },
    {
        question: "What makes Gurulabs different when it comes to Shopify ads management?",
        answer: "Gurulabs sets itself apart by combining state-of-the-art technology with personalized service to craft ads that not only meet but exceed our clients' marketing objectives.",
    },
    {
        question: "How does Gurulabs approach Shopify ads bidding strategies?",
        answer: "Gurulabs uses data-driven insights to choose the optimal bidding strategies, whether it’s manual CPC, target CPA, or maximize clicks, ensuring your ads achieve the best possible performance.",
    },
    {
        question: "Can Gurulabs assist with international Shopify ads campaigns?",
        answer: "Yes, we have expertise in managing global Shopify ads campaigns, helping you to reach and engage with audiences in different markets effectively.",
    },
    {
        question: "What types of businesses can benefit from Gurulabs' Shopify ads services?",
        answer: "Gurulabs works with a variety of business sectors including retail, technology, real estate, and more, tailoring strategies to meet specific industry needs.",
    },
    {
        question: "How does Gurulabs measure the success of Shopify ads?",
        answer: "We utilize advanced tracking and analytics to measure key performance indicators like click-through rates, conversion rates, and ROI, ensuring your ads are achieving desired goals.",
    },
    {
        question: "How often does Gurulabs update client Shopify ad strategies?",
        answer: "We believe in continuous improvement and regularly update ad strategies based on performance data and evolving market trends to keep your campaigns effective.",
    },
    {
        question: "Can Gurulabs manage my Shopify Ads Manager account?",
        answer: "Yes, our team can fully manage your Shopify Ads Manager account, overseeing all aspects from ad creation to budget management and reporting.",
    },
    {
        question: "What are the latest trends in Shopify ads that Gurulabs is utilizing?",
        answer: "Gurulabs stays ahead by leveraging AI-driven analytics, dynamic product ads, and personalized ad experiences to enhance engagement and effectiveness of Shopify ad campaigns.",
    },
    {
        question: "How can I get started with Gurulabs for my Shopify advertising needs?",
        answer: "Simply contact us through our website or give us a call to schedule a consultation. Our team will discuss your needs and how we can help boost your Shopify advertising efforts.",
    },
    {
        question: "What budget do I need to start Shopify advertising with Gurulabs?",
        answer: "We offer flexible pricing models that can be tailored to your budget and goals. Contact us for a detailed and customized quote.",
    },
    {
        question: "Does Gurulabs offer any guarantees for Shopify ads performance?",
        answer: "While advertising always involves variables, Gurulabs guarantees commitment to optimizing your campaigns for maximum performance and satisfaction.",
    },
    {
        question: "Can Gurulabs help with Shopify ads compliance issues?",
        answer: "Yes, we ensure that all ads managed by Gurulabs comply with Shopify's advertising policies and regulations to prevent any disruptions in your campaigns.",
    },
    {
        question: "What reporting does Gurulabs provide for Shopify ads campaigns?",
        answer: "Gurulabs provides detailed reports on campaign performance, including audience insights, cost analysis, and recommendations for further optimization.",
    },
    {
        question: "Can Gurulabs optimize my existing Shopify ads campaigns?",
        answer: "Yes, our experts can analyze and revamp existing campaigns to enhance their performance through strategic adjustments and advanced targeting techniques.",
    },
    {
        question: "How does Gurulabs handle dynamic product ads on Shopify?",
        answer: "Gurulabs leverages dynamic product ads by using your product catalog to automatically generate targeted ads, saving time and improving ad relevance.",
    },
];





function ShopifyAdsFAQs() {
    return (
        <div className="bg-white">
            <div className="mx-auto max-w-7xl px-6 py-12 sm:py-24 lg:px-8 lg:py-24">
                <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
                    <h2 className="text-3xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
                    <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                        {faqs.map((faq) => (
                            <Disclosure as="div" key={faq.question} className="pt-6">
                                {({ open }) => (
                                    <>
                                        <dt>
                                            <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                                                <span className="text-base font-semibold leading-7">{faq.question}</span>
                                                <span className="ml-6 flex h-7 items-center">
                                                    {open ? (
                                                        <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                    ) : (
                                                        <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                    )}
                                                </span>
                                            </Disclosure.Button>
                                        </dt>
                                        <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                            <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
}


export default ShopifyAdsFAQs
