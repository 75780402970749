import { Link } from '@inertiajs/inertia-react'
import mixpanel from 'mixpanel-browser'
import React from 'react'

const croPricing = [
    {
        "id": 1,
        "title": "Aggressive CRO Plan",
        "subtitle": "Comprehensive Optimization",
        "name": "Aggressive CRO $1,500 / month",
        "price": "$1,200",
        "duration": "monthly",
        "image": "/frontend/images/svg/icon-15.svg",
        "description": "Dive deep into conversion optimization with our Aggressive CRO Plan. Includes extensive user testing, heatmaps, and more to maximize your site’s conversion potential.",
        "paymentUrl": "#",
        "features": [
            "4 Initial Website User Testing Videos",
            "Heatmap Testing on 8 Pages",
            "Click Stream Testing on 8 Pages",
            "Shopping Cart Abandonment Testing",
            "Monthly Ongoing CRO & UX Assets"
        ]
    },
    {
        "id": 2,
        "title": "Market Leader CRO Plan",
        "subtitle": "Advanced Engagement",
        "name": "Market Leader CRO $3,000 / month",
        "price": "$2,499",
        "duration": "monthly",
        "image": "/frontend/images/svg/icon-16.svg",
        "description": "Elevate your business with our Market Leader CRO Plan designed for market leaders aiming to refine their user experience and enhance conversion rates.",
        "paymentUrl": "#",
        "features": [
            "6 Initial Website User Testing Videos",
            "Heatmap Testing on 16 Pages",
            "Click Stream Testing on 16 Pages",
            "Shopping Cart Abandonment Testing",
            "Monthly Ongoing CRO & UX Assets",
            "Landing Page Creation",
            "Monthly Ongoing CRO & UX Assets"
        ]
    },
    {
        "id": 3,
        "title": "Enterprise CRO Plan",
        "subtitle": "Elite Performance",
        "item": "Enterprise CRO $8,000 / month",
        "price": "$6,999",
        "duration": "monthly",
        "image": "/frontend/images/svg/icon-18.svg",
        "description": "Our most exclusive service, the Enterprise CRO Plan, offers bespoke solutions, including custom UX design and development projects to optimize at scale.",
        "paymentUrl": "#",
        "features": [
            "6 Initial Website User Testing Videos",
            "Heatmap Testing on 16 Pages",
            "Click Stream Testing on 16 Pages",
            "Shopping Cart Abandonment Testing",
            "Monthly Ongoing CRO & UX Assets",
            "Landing Page Creation",
            "Custom UX Design and Development",
            "Monthly Ongoing CRO & UX Assets"
        ]
    }
];


const CROPricing = ({ modalIsOpen, setModalIsOpen }) => {
    return (
        <div className="max-w-7xl mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 py-20 px-2">
                {
                    croPricing.map((item, index) => (
                        <div className="border-2 border-gray-100 py-6" key={index}>
                            {item.id === 2 && (
                                <div className="bg-green-300 flex justify-center items-center -mt-10">
                                    <h2 className="py-2 text-gray-700">Most Popular</h2>
                                </div>
                            )}
                            <div className="bg-white py-4 px-2">
                                <img src={item.image} className="py-2 px-2" alt={`${item.title}`} />
                                <h2 className="text-xl py-2">{item.title}</h2>
                                <h3 className="text-lg text-gray-700 py-2">{item.subtitle}</h3>
                                <p className="text-gray-700 py-2">{item.description}</p>
                                <h4 className="text-xl text-gray-900 py-2">{item.price} / {item.duration}</h4>
                                <ul className="py-4 px-2">
                                    {item.features.map((feature, index) => (

                                        <li className="flex items-center text-gray-700 py-4 px-2 border-b-2" key={index}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none"><g fill="#48BB78" clipPath="url(#a)"><path d="M9.13 12.024a.765.765 0 0 1-.506-.19L4.852 8.505a.766.766 0 0 1 1.013-1.15l3.236 2.845 6.754-6.706a.768.768 0 1 1 1.079 1.094L9.67 11.81a.768.768 0 0 1-.54.215Z" /><path d="M8.661 17.541a8.502 8.502 0 0 1-8.38-9.88A8.5 8.5 0 0 1 11.388.987a.766.766 0 1 1-.492 1.45 6.969 6.969 0 1 0 4.45 8.581.765.765 0 1 1 1.468.438 8.548 8.548 0 0 1-8.152 6.084Z" /></g><defs><clipPath id="a"><path fill="#fff" d="M.161.542h17v17h-17z" /></clipPath></defs></svg>
                                            {feature}
                                        </li>

                                    ))}
                                </ul>
                                <div className="flex items-center text-gray-700 py-2 ml-4 px-2">
                                    {/* <a href={item.paymentUrl} className="button w-full py-4 px-4 text-white text-center">Get Started {item.title}</a> */}
                                    <button
                                        onClick={() => {
                                            setModalIsOpen(!modalIsOpen)
                                            mixpanel.track("Schedule a meeting CRO Pricing button clicked");
                                        }}

                                        className="relative w-full py-3 px-3 top-1 right-2 button rounted-sm text-gray-700 hover:bg-green-500"
                                    >
                                        <span className="text-xl">Schedule a meeting</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
            <div className="flex flex-col items-center">
                <p className="py-2 ">*Prices are based on monthly plans, and can be subscribed and unsubscribed at any time.</p>
                <a href="#pricing" className="button text-gray-700 py-4 px-4">See all plans details</a>
            </div>
        </div>
    )
}
export default CROPricing
