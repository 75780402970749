import React, { useEffect, useState } from 'react'
import PageLayout from '../../../../Layout/PageLayout/Index'
import MetaHead from '../../../../Components/MetaHead'
import Quiz from '../../../../Components/ExamQuiz/Index'
import LeadsModal from '../../../../Layout/LeadsModal/Index'
import CalendarModal from '../../../../Layout/CalendarModal/Index'
import mixpanel from 'mixpanel-browser'


const GMBCategoryFinder = ({ }) => {
    const [isMobile, setIsMobile] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    useEffect(() => {
        mixpanel.track("Google Ads Search Exam Page visited");
    }, []);

    return (
        <PageLayout>
            <MetaHead title="Google Ads Search Certification Exam Answers | Gurulabs"
                description="Prepare for the Google Ads Search Certification Exam with Gurulabs. Access a comprehensive guide with detailed answers to key questions, practice quizzes, and insights to help you pass the exam with confidence." />
            <section>
                <div className='max-w-7xl mx-auto'>
                    <div className="py-8">
                        <Quiz />
                    </div>
                </div>
            </section>
            <section className="max-w-7xl mx-auto overflow-hidden">
                <div className="">
                    <div className="py-8 text-center">
                        {
                            isMobile ? (
                                <Link
                                    onClick={() => {
                                        mixpanel.track("Get Started Mobile button clicked");
                                    }}
                                    href='/get-started'
                                    className="relative py-4 px-4 top-1 right-2 button rounded-sm text-gray-700 hover:bg-green-700 transition duration-300 ease-in-out"
                                >
                                    <span className="text-xl">Struggling with Google Ads?</span>
                                </Link>
                            ) : (
                                <button
                                    onClick={() => {
                                        setIsOpen(!isOpen);
                                        mixpanel.track("Get Started button clicked");
                                    }}
                                    className="relative py-4 px-4 top-1 right-2 button rounded-sm text-gray-700 hover:bg-green-700 transition duration-300 ease-in-out"
                                >
                                    <span className="text-xl">Struggling with Google Ads?</span>
                                </button>
                            )
                        }
                    </div>
                </div>
            </section>
            <section>
                <div className="bg-gray-50 py-12">
                    <div className="max-w-7xl mx-auto">
                        <div>
                            <div className="block sm:flex justify-center items-center px-4">
                                <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                    <span className="relative">Google Ads Search Exam</span>
                                    <span className="text-green-500 ml-2 relative">
                                        Practice Quiz
                                    </span>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="absolute left-3/4 ml-16 hidden xl:block"
                                        width={151}
                                        height={3}
                                        fill="none"
                                    >
                                        <path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" />
                                    </svg>
                                </h2>
                            </div>
                        </div>
                        <div className="px-4">
                            <div>
                                <p className="text-xl py-4 text-gray-700">
                                    Introducing the Google Ads Search Exam Practice Quiz
                                </p>

                                <p className="text-xl py-4 text-gray-700">
                                    Preparing for the Google Ads Search certification exam can be a challenging but rewarding experience. To help you succeed, we are excited to introduce our comprehensive Google Ads Search Exam Practice Quiz. Designed specifically for aspiring professionals and marketing enthusiasts, this quiz covers essential topics and key concepts to ensure you are well-prepared for the official exam.
                                </p>

                                <p className="text-xl py-4 text-gray-700">
                                    <strong>Enhance Your Exam Preparation</strong>
                                </p>

                                <p className="text-xl py-4 text-gray-700">
                                    Our Google Ads Search Practice Quiz simplifies your exam preparation by offering a collection of carefully curated questions that reflect the type of content you'll encounter in the actual certification exam. These questions cover everything from bidding strategies and ad optimization to automated bidding features and performance tracking, giving you a comprehensive overview of the platform.
                                </p>

                                <p className="text-xl py-4 text-gray-700">
                                    By using this quiz, you will familiarize yourself with the structure of the exam, learn how to apply practical knowledge, and gain confidence in your ability to handle real-world scenarios. This is your opportunity to test your understanding of Google Ads concepts and improve your chances of passing the exam on your first attempt.
                                </p>

                                <p className="text-xl py-4 text-gray-700">
                                    <strong>Key Features of Our Practice Quiz</strong>
                                </p>

                                <p className="text-xl py-4 text-gray-700">
                                    Our practice quiz includes multiple-choice questions that test your knowledge of Google Ads Search campaigns. Each question is designed to help you understand the principles of search advertising, keyword optimization, audience targeting, and performance metrics. We also provide detailed feedback on your answers to help you learn from any mistakes and refine your strategy.
                                </p>

                                <p className="text-xl py-4 text-gray-700">
                                    You'll also have the opportunity to track your progress and measure your readiness for the official certification exam. The quiz helps you identify areas where you may need further review and gives you the chance to retake questions to reinforce your knowledge.
                                </p>

                                <p className="text-xl py-4 text-gray-700">
                                    <strong>Why Choose Our Practice Quiz?</strong>
                                </p>

                                <p className="text-xl py-4 text-gray-700">
                                    We believe that preparation is key to success, and our Google Ads Search Exam Practice Quiz is designed to help you approach the exam with confidence. Whether you're just getting started or looking to fine-tune your skills, this quiz will equip you with the knowledge and insights you need to pass the exam and become certified in Google Ads Search.
                                </p>

                                <p className="text-xl py-4 text-gray-700">
                                    In conclusion, our Google Ads Search Exam Practice Quiz is an essential tool for anyone looking to master Google Ads and earn their certification. Start practicing today and take the first step toward a successful career in digital marketing.
                                </p>

                                <p className="text-xl py-4 text-gray-700">
                                    Ready to test your skills? Jump into the <a href="/quiz" className="font-bold underline">practice quiz</a> now and get started on your journey to becoming a Google Ads expert!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <LeadsModal isOpen={isOpen} setIsOpen={setIsOpen} />
            <CalendarModal isOpen={modalIsOpen} setIsOpen={setModalIsOpen} />
        </PageLayout>
    )
}
export default GMBCategoryFinder
