import React, { useState } from 'react';

const BudgetForClicksCalculator = () => {
    const [targetClicks, setTargetClicks] = useState(500);
    const [cpc, setCPC] = useState(2.00);
    const [estimatedBudget, setEstimatedBudget] = useState(0.0);

    const calculateBudget = () => {
        const budget = targetClicks * cpc;
        setEstimatedBudget(budget);
    };

    return (
        <div className="max-w-lg mx-auto mt-8 p-6 bg-gray-100 rounded-md shadow-md">
            <h2 className="text-2xl font-semibold mb-4">Budget Estimation for Target Clicks</h2>
            <div className="mb-4">
                <label htmlFor="targetClicks" className="block mb-1">Target Clicks:</label>
                <input
                    type="text"
                    id="targetClicks"
                    value={targetClicks}
                    onChange={(e) => setTargetClicks(Number(e.target.value))}
                    className="border-gray-300 rounded-md p-2 w-full"
                    min="0"
                />
            </div>
            <div className="mb-4">
                <label htmlFor="cpc" className="block mb-1">Cost Per Click (CPC):</label>
                <input
                    type="text"
                    id="cpc"
                    value={cpc}
                    onChange={(e) => setCPC(Number(e.target.value))}
                    className="border-gray-300 rounded-md p-2 w-full"
                    min="0"
                />
            </div>
            <div className="mb-4">
                <button
                    onClick={calculateBudget}
                    className="px-4 py-2 button text-white rounded hover:bg-blue-700"
                >
                    Calculate Budget
                </button>
            </div>
            <div className="mb-4">
                <h3 className="text-xl">
                    Estimated Budget: ${estimatedBudget.toFixed(2)}
                </h3>
            </div>
        </div>
    );
};

export default BudgetForClicksCalculator;
