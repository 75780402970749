import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select'
import ReCAPTCHA from 'react-google-recaptcha';

const annualRevenueOptions = [
    { value: '$0 - $100k', label: '$0 - $100k' },
    { value: '$100k - $500k', label: '$100k - $500k' },
    { value: '$500k - $1M', label: '$500k - $1M' },
    { value: '$1M - $2M', label: '$1M - $2M' },
    { value: '$2M - $5M', label: '$2M - $5M' },
    { value: '$5M - $10M', label: '$5M - $10M' },
    { value: '$10M - $20M', label: '$10M - $20M' },
    { value: '$20M+', label: '$20M+' }
];

const automationToolsOptions = [
    { value: 'zapier', label: 'Zapier' },
    { value: 'integromat', label: 'Integromat' },
    { value: 'pabbly', label: 'Pabbly' },
    { value: 'pipedream', label: 'Pipedream' },
    { value: 'automate.io', label: 'Automate.io' },
    { value: 'other', label: 'Other' }
]

const AdditionalDetails = ({ register, errors, control, setCaptchaValue }) => {
    // Function to trigger validation for the entire form
    const triggerValidation = async () => {
        await trigger(); // Trigger validation for all fields
    };


    return (
        <div className="bg-white p-6">
            <div>
                <h3 className="text-base font-semibold leading-6 text-gray-900">Get a quote in your email</h3>
                <p className="mt-1 text-sm text-gray-500">Please add your information to receive a custom quote</p>
            </div>
            <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-6">
                    <label htmlFor="marketing_type" className="block text-sm font-medium leading-6 text-gray-900">
                        Great! What types of marketing are you currently doing?
                    </label>
                    <input
                        required
                        type="text"
                        id="marketing_type"
                        {...register('marketing_type')}
                        onBlur={triggerValidation}
                        className={`mt-2 block w-full rounded-md border-0 px-3 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6 ${errors.marketing_type ? 'border-red-500' : ''}`}
                    />
                    {errors.marketing_type && <span className="text-red-500 text-sm">{errors.marketing_type.message}</span>}
                </div>
                <div className="col-span-6 sm:col-span-6">
                    <label htmlFor="active_platforms" className="block text-sm font-medium leading-6 text-gray-900">
                        Which social media platforms do you actively utilize for marketing purposes?
                    </label>
                    <input
                        required
                        type="text"
                        id="active_platforms"
                        {...register('active_platforms')}
                        className="mt-2 block w-full rounded-md border-0 px-3 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                    />
                    {errors.active_platforms && <span className="text-red-500 text-sm">{errors.active_platforms.message}</span>}
                </div>
                <div className="col-span-6 sm:col-span-6">
                    <label htmlFor="hear_about" className="block text-sm font-medium leading-6 text-gray-900">Where did you initially come across Gurulabs?</label>
                    <input
                        type="text"
                        id="hear_about"
                        {...register('hear_about')}
                        className="mt-2 block w-full rounded-md border-0 px-3 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                    />
                    {errors.hear_about && <span className="text-red-500 text-sm">{errors.hear_about.message}</span>}
                </div>
                <div className="col-span-6">
                    <label htmlFor="annual-revenue" className="block text-sm font-medium leading-6 text-gray-900">How much is your annual marketing budget?</label>
                    <Controller
                        name="annual_revenue"
                        control={control}
                        rules={{ required: 'Monthly marketing budget is required' }}
                        render={({ field }) => (
                            <Select
                                {...field}
                                id="annual-revenue"
                                options={annualRevenueOptions}
                            />
                        )}
                    />
                    {errors.annual_revenue && <span className="text-red-500 text-sm">{errors.annual_revenue.message}</span>}
                </div>
                <div className="col-span-6">
                    <label htmlFor="automation_tools" className="block text-sm font-medium leading-6 text-gray-900">Which marketing automation tools do you currently use?</label>
                    <Controller
                        name="automation_tools"
                        control={control}
                        rules={{ required: 'Marketing automation tools are required' }}
                        render={({ field }) => (
                            <Select
                                {...field}
                                id="automation_tools"
                                options={automationToolsOptions}
                            />
                        )}
                    />
                    {errors.automation_tools && <span className="text-red-500 text-sm">{errors.automation_tools.message}</span>}
                </div>
                <div className="col-span-6">
                    <label htmlFor="captcha" className="block text-sm font-medium leading-6 text-gray-900">Please complete the reCAPTCHA</label>
                    <ReCAPTCHA
                        sitekey="6LfWNpQpAAAAAOatrVoUPaOR35DrLZSweaTzIKGj"
                        onChange={(value) => setCaptchaValue(value)}
                    />
                    {errors.captcha === '' && <span className="text-red-500 text-sm">Please complete the reCAPTCHA</span>}
                </div>
            </div>
        </div>
    );
};

export default AdditionalDetails;
