import React, { useState } from 'react'
import PageLayout from '../../../Layout/PageLayout/Index'
import MetaHead from '../../../Components/MetaHead'
import LeadsModal from '../../../Layout/LeadsModal/Index';
import CalendarModal from '../../../Layout/CalendarModal/Index';
import mixpanel from 'mixpanel-browser';
import FacebookAdsAOVCalculator from '../../../Components/Calculators/COGSCalculator/FacebookAdsAOVCalculator/Index';

const FacebookAdsCostCalculatorPage = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    return (
        <PageLayout>
            <MetaHead title="Facebook Ads Cost Calculator | PPC Campaign Cost Estimator"
                description="Estimate Facebook ad costs quickly with our PPC campaign cost calculator." />
            <section>
                <div className='max-w-7xl mx-auto'>
                    <FacebookAdsAOVCalculator />
                </div>
            </section>
            <section className="max-w-7xl mx-auto overflow-hidden">
                <div className="">
                    <div className="py-8 text-center">
                        {
                            isMobile ? (
                                <Link
                                    onClick={() => {
                                        mixpanel.track("Get Started Mobile button clicked");
                                    }}
                                    href='/get-started'
                                    className="relative py-4 px-4 top-1 right-2 button rounded-sm text-gray-700 hover:bg-green-700 transition duration-300 ease-in-out"
                                >
                                    <span className="text-xl">Struggling with Facebook Ads?</span>
                                </Link>
                            ) : (
                                <button
                                    onClick={() => {
                                        setIsOpen(!isOpen);
                                        mixpanel.track("Get Started button clicked");
                                    }}
                                    className="relative py-4 px-4 top-1 right-2 button rounded-sm text-gray-700 hover:bg-green-700 transition duration-300 ease-in-out"
                                >
                                    <span className="text-xl">Struggling with Facebook Ads?</span>
                                </button>
                            )
                        }
                    </div>
                </div>
            </section>
            <section>
                <div className="bg-gray-50 py-12">
                    <div className="max-w-7xl mx-auto">
                        <div>
                            <div className='block sm:flex justify-center items-center px-4'>
                                <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                    <span className="relative">Entrepreneur's Best Friend </span>
                                    <span className="text-green-500 ml-2 relative">
                                        Product/Service Cost Calculator
                                    </span>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4 ml-16 hidden xl:block" width={151} height={3} fill="none"><path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                                </h2>
                            </div>
                        </div>
                        <div className="px-4">
                            <div>
                                <p className="text-xl py-4 text-gray-700">Introducing the Product/Service Cost Calculator</p>

                                <p className="text-xl py-4 text-gray-700">As businesses strive to streamline their operations and optimize profitability, having precise control over product and service costs becomes essential. To aid businesses in this crucial aspect, we are excited to present the Product/Service Cost Calculator—a comprehensive tool designed to accurately estimate the costs associated with delivering your products or services.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Accurately Forecasting Your Costs</strong></p>

                                <p className="text-xl py-4 text-gray-700">The Product/Service Cost Calculator simplifies the financial planning of product and service delivery. By inputting values such as the Average Order Value (AOV), product costs, credit card fee percentage, merchant account fees, shipping costs, and fulfillment costs, businesses can instantly determine the total cost. This level of precision allows for better budget management and provides clarity on the financial impact of delivering products or services.</p>

                                <p className="text-xl py-4 text-gray-700">This tool is designed not only to provide immediate cost estimates but also to facilitate strategic adjustments. Business owners and managers can modify the inputs to explore different cost scenarios, enabling them to discover the most cost-effective strategies for their specific operations.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Enhancing Operational Efficiency and Profit Margins</strong></p>

                                <p className="text-xl py-4 text-gray-700">One of the greatest benefits of using the Product/Service Cost Calculator is its ability to enhance the efficiency of operational management. With clear insights into the costs associated with various components of product or service delivery, businesses can optimize their spending and focus resources on the most impactful areas. This targeted approach helps maximize profit margins and reduces the likelihood of budget overruns.</p>

                                <p className="text-xl py-4 text-gray-700">Moreover, the calculator promotes proactive budgeting and cost management, empowering businesses to maintain control over their expenditures and avoid unexpected costs.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Empowering Your Business with Strategic Insights</strong></p>

                                <p className="text-xl py-4 text-gray-700">At its core, the Product/Service Cost Calculator provides businesses with the strategic insights needed to craft more effective operational plans. By delivering accurate and timely cost predictions, our tool enables business owners and managers to plan with confidence and invest wisely in their operations.</p>

                                <p className="text-xl py-4 text-gray-700">In conclusion, if you're seeking a tool that offers reliability, efficiency, and strategic depth for managing your product or service delivery costs, the Product/Service Cost Calculator is your go-to resource. Take control of your operational budget and optimize your strategies for maximum profitability. For more information or to start using this tool, please visit <a href="/" className="font-bold underline">our website</a>.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <LeadsModal isOpen={isOpen} setIsOpen={setIsOpen} />
            <CalendarModal isOpen={modalIsOpen} setIsOpen={setModalIsOpen} />
        </PageLayout>
    )
}
export default FacebookAdsCostCalculatorPage
