import React, { useEffect } from 'react'

const SocialProof = () => {
    useEffect(() => {
        ! function (w, d) {
            if (!w || !d) return false
            var s = d.createElement('script');
            s.setAttribute('src', 'https://fbl.embedwidgets.com/2OFZqy8dkO1AKHlzDhuW.js');
            d.body.appendChild(s);
        }(window, document)
        document.getElementById('reviews').classList.add('fbl_w_2OFZqy8dkO1AKHlzDhuW')
    }, [])
    return (
        <div className="bg-amber-50 py-12">
            <div className="max-w-7xl mx-auto">
                <div className='block sm:flex justify-center items-center px-4'>
                    <h2 className="block text-3xl sm:text-4xl py-5 relative">
                        <span className="relative">Have a look at our wall of </span>
                        <span className="text-amber-950 ml-2 relative">
                            Social Proof!
                        </span>
                        <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4 ml-0 hidden xl:block" width={500} height={3} fill="none"><path stroke="#FFAC36" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                    </h2>
                </div>
            </div>
            <div>
                <div className="max-w-7xl mx-auto py-24">
                    <div className="">
                        <div id="reviews"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SocialProof
