import React, { useEffect, useState } from 'react'
import mixpanel from 'mixpanel-browser'
import MainLayout from '../../Layout/MainLayout/Index'
import MetaHead from '../../Components/MetaHead'
import AboutSection from '../../Components/AboutSection/Index'
import HeroSection from '../../Components/HeroSection/Index'
import ServiceSection from '../../Components/ServiceSection/Index'
import Slider from '../../Components/Slider/Index'
import ProcessSection from '../../Components/ProcessSection/Index'
import ReviewSection from '../../Components/ReviewSection/Index'
import TestimonialSection from '../../Components/TestimonialSection/Index'
import SocialProof from '../../Components/SocialProof/Index'
import Pricing from '../../Components/Pricing/Index'
import Newsletter from '../../Components/Newsletter/Index'
import CalendarModal from '../../Layout/CalendarModal/Index'
import HomeFAQs from '../../Components/FAQs/HomeFAQs/Index'
import Process from '../../Components/Process/Index'
import { ChartBarIcon, Cog6ToothIcon, CogIcon, DocumentTextIcon, EyeIcon, GiftIcon, SunIcon, UserGroupIcon, UserIcon } from '@heroicons/react/24/outline'
import ServiceTabs from '../../Components/ServiceTabs/Index'


function Home() {
    const [modalIsOpen, setModalIsOpen] = useState(false)
    useEffect(() => {
        mixpanel.track("Home Page Visited")
    }, [])

    return (
        <MainLayout>
            <MetaHead title="Gurulabs - Top Lead Generation & Digital Marketing Agency" description="Gurulabs: Expert Digital Marketing and Lead Generation Services. Serving Washington DC, Virginia, and Maryland with top-notch internet marketing solutions." />
            <HeroSection />
            <Slider />
            <AboutSection />
            <section>
                <div className="bg-amber-50">
                    <div className="max-w-7xl mx-auto">
                        <ServiceTabs />
                    </div>
                </div>
            </section>
            <section>
                <div className="max-w-5xl mx-auto py-24">
                    <div className='block sm:flex sm:flex-col justify-center items-center px-4'>
                        <h2 className="block text-3xl sm:text-4xl py-5 relative">
                            <span className="relative">Our Tech-Enabled</span>
                            <span className="text-green-500 ml-2 relative">
                                Lead Gen Process
                            </span>
                            <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-1/2 -mt-2 ml-16 hidden xl:block" width={238} height={17} fill="none"><path stroke="#48BB78" strokeLinecap="round" strokeWidth="2" d="M1.833 12.55c101.19 8.798 198.897-3.667 235.102-11" /></svg>
                        </h2>
                        <p className="text-base text-center">Our Lead generation process helps you identify potential customers or clients and gather their contact information to nurture them toward a sale.</p>
                    </div>
                </div>
                <div className="max-w-7xl mx-auto pb-24">
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
                        <Process
                            title="Identify Target Audience"
                            description="We excel in pinpointing the exact target audience for your business. Our team utilizes advanced market research techniques and cutting-edge data analytics tools to create comprehensive buyer personas. We delve into demographics, behavior patterns, and interests to ensure your marketing efforts are directed towards individuals most likely to engage with and benefit from your products or services. By understanding your target audience, Gurulabs helps you focus your resources on high-potential leads, optimizing your marketing efficiency and effectiveness."
                            button="button-3"
                            Icon={UserGroupIcon}
                        />
                        <Process
                            title="Create Compelling Content"
                            description="We understands that engaging content is the cornerstone of effective lead generation. Our skilled content creators craft high-quality blog posts, infographics, videos, and ebooks tailored to resonate with your audience's interests and pain points. We employ storytelling techniques and SEO best practices to ensure your content not only attracts but also retains your audience’s attention. By partnering with Gurulabs, you gain access to a content strategy that enhances brand authority, drives organic traffic, and fosters meaningful connections with potential leads."
                            button="button-2"
                            Icon={DocumentTextIcon}
                        />
                        <Process
                            title="Optimize for SEO and SEM"
                            description="Visibility is key to attracting potential leads, and Gurulabs is adept at optimizing your online presence. Our SEO experts conduct thorough keyword research to identify the terms your target audience is searching for. We optimize your website’s on-page elements and create effective PPC campaigns to ensure your content ranks high on search engine results pages. By continuously monitoring and adjusting our strategies based on performance data, Gurulabs ensures sustained growth in organic and paid traffic, putting your business in front of the right eyes."
                            button="button-3"
                            Icon={Cog6ToothIcon}
                        />
                        <Process
                            title="Utilize Social Media Marketing"
                            description="Social media and email marketing are powerful tools for engaging with your audience, and Gurulabs knows how to leverage these channels to their full potential. We develop and implement targeted social media campaigns that foster community engagement and expand your reach. Our email marketing strategies are designed to nurture leads through personalized, value-driven content, from drip sequences to newsletters. By utilizing these platforms effectively, Gurulabs helps you build lasting relationships with your audience, turning potential leads into loyal customers."
                            button="button-2"
                            Icon={UserIcon}
                        />
                        <Process
                            title="Implement Lead Magnets and CTAs"
                            description="Gurulabs specializes in creating compelling lead magnets and strategically placing calls-to-action (CTAs) that drive conversions. Whether it’s offering free trials, webinars, or exclusive discounts, our team designs and implements enticing offers that capture your audience’s interest. We ensure that CTAs are prominently placed and clearly communicate the value proposition, guiding prospects smoothly through the conversion funnel. By integrating these tactics, Gurulabs maximizes your lead capture potential and ensures a steady stream of high-quality leads."
                            button="button-3"
                            Icon={GiftIcon}
                        />
                        <Process
                            title="Track, Analyze, and Optimize"
                            description="Continuous improvement is crucial in lead generation, and Gurulabs provides the analytical expertise needed to track, analyze, and optimize your efforts. We utilize advanced analytics tools to monitor key performance metrics and gain insights into your lead generation campaigns. Our team conducts A/B testing and performance analysis to identify areas for enhancement, ensuring your strategies remain effective and up-to-date. By partnering with Gurulabs, you gain a data-driven approach to lead generation that adapts to changing market dynamics, ensuring sustained growth and success."
                            button="button-2"
                            Icon={ChartBarIcon}
                        />
                    </div>
                </div>
            </section>
            <ProcessSection modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
            <section>
                <div className="bg-gray-50 py-12 h-full">
                    <div className="max-w-7xl mx-auto">
                        <div className='block sm:flex justify-center items-center px-4'>
                            <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                <span className="relative">Simple plan for small businesses and </span>
                                <span className="text-green-500 ml-2 relative">
                                    solo agencies
                                </span>
                                <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4 ml-16 hidden xl:block" width={151} height={3} fill="none"><path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                            </h2>
                        </div>
                        <Pricing modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
                    </div>
                </div>
            </section>
            {/* <ReviewSection /> */}
            <SocialProof />
            <HomeFAQs />
            <TestimonialSection />
            <CalendarModal isOpen={modalIsOpen} setIsOpen={setModalIsOpen} />
        </MainLayout>
    )
}

export default Home


// < ServiceSection />
//         <AboutSection />
//         <Newsletter />
//         <ReviewSection />
//         <TestimonialSection />
