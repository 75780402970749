import mixpanel from 'mixpanel-browser';
import React, { useEffect, useState } from 'react';
import { Link, usePage } from '@inertiajs/inertia-react';
import Slider from '../../../../Components/Slider/Index';
import PageLayout from '../../../../Layout/PageLayout/Index';
import Newsletter from '../../../../Components/Newsletter/Index';
import PPCMetaHead from '../../../../Components/PPCMetaHead/Index';
import PaidAdsLeadsForm from '../../../../Layout/PaidAdsLeadsForm/Index';
import PaidAdsPricing from '../../../../Components/PaidAdsPricing/Index';
import PaidAdsPricingTable from '../../../../Components/PaidAdsPricingTable/Index';
import Process from '../../../../Components/Process/Index';
import SocialProof from '../../../../Components/SocialProof/Index';
import PPCFAQs from '../../../../Components/FAQs/PPCFAQs/Index';
import { SiFacebook, SiInstagram, SiLinkedin, SiTiktok, SiYoutube, SiTwitter } from '@icons-pack/react-simple-icons';


// More imports as required...

const SanBernardinoCAPPCPage = ({ listings }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const { city, state, cities } = usePage().props;
    const [weather, setWeather] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        mixpanel.track(`${city.name} Page Visited`)

    }, []);

    return (
        <PageLayout>
            <PPCMetaHead
                    title={`${city.name} PPC Agency | Pay-Per-Click Services in ${state.code} | Gurulabs`}
                    description={`Get top-notch PPC services in ${city.name}, ${state.code} with Gurulabs. Contact us for expert PPC management to grow your business.`}
             />
           <section className="max-w-7xl mx-auto overflow-hidden">
                <div className="">
                    <div className='py-4'>
                        <h1 className="py-4 text-3xl lg:text-6xl text-center my-1">Affordable
                            <span className="text-green-500 relative mx-2">
                                Pay Per Click
                                <svg width="175" height="3" viewBox="0 0 175 3" fill="none" className="absolute hidden lg:block left-6">
                                    <path d="M1.43945 1.54395H173.903" stroke="#48BB78" strokeWidth="2" strokeLinecap="round" />
                                </svg>
                            </span>
                            Management Agency in {city.name}, {state.code}</h1>
                    </div>
                    <div className='py-4' >
                        {
                            isMobile ? (
                                <div className='grid grid-cols-1 lg:grid-cols-2'>
                                    <div className="px-4 w-[400px] h-[720px] mx-auto aspect-w-16 aspect-h-9">
                                        <video autoPlay loop muted controls playsInline className="mx-auto">
                                            <source src="/frontend/videos/intro.mp4" type="video/mp4" />
                                        </video>
                                    </div>
                                    <PaidAdsLeadsForm />
                                </div>
                            ) : (
                                <div className='grid grid-cols-1 lg:grid-cols-2'>
                                    <div className="px-4 w-[400px] h-[720px] mx-auto aspect-w-16 aspect-h-9">
                                        <video autoPlay loop muted controls playsInline className="mx-auto">
                                            <source src="/frontend/videos/intro.mp4" type="video/mp4" />
                                        </video>
                                    </div>
                                    <PaidAdsLeadsForm />
                                </div>
                            )
                        }

                    </div>
                </div>
            </section>
            <Slider />
            <section>
                <div className="bg-gray-50 py-12">
                    <div className="max-w-7xl mx-auto">
                        <div className="block sm:flex justify-center items-center px-4">
                            <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                <span className="relative">Explore Our Service Plans</span>
                                <span className="text-green-500 ml-2 relative">in {city.name}, {state.code}!</span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="absolute left-3/4 ml-16 hidden xl:block"
                                    width={151}
                                    height={3}
                                    fill="none"
                                >
                                    <path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" />
                                </svg>
                            </h2>
                        </div>
                        <PaidAdsPricing modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} city={city.name} state={state.code} />
                    </div>
                </div>
            </section>
            <section>
                <div className="max-w-5xl mx-auto py-24">
                    <div className="block sm:flex sm:flex-col justify-center items-center px-4">
                        <h2 className="block text-3xl sm:text-4xl py-5 relative">
                            <span className="relative">Tech-Enabled</span>
                            <span className="text-green-500 ml-2 relative">Social Media Ads Management</span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="absolute left-1/2 -mt-2 ml-16 hidden xl:block"
                                width={238}
                                height={17}
                                fill="none"
                            >
                                <path stroke="#48BB78" strokeLinecap="round" strokeWidth="2" d="M1.833 12.55c101.19 8.798 198.897-3.667 235.102-11" />
                            </svg>
                        </h2>
                        <p className="text-base text-center">
                            Generate quality leads in {city.name} with our expert social media ads management services.
                        </p>
                    </div>
                </div>
                <div className="max-w-7xl mx-auto pb-24 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    <Process
                        title="Facebook Ads Management"
                        description={`In the vibrant city of ${city.name}, ${state.name}, our Facebook Ads management services are designed to engage the dynamic local community. Utilizing advanced targeting, we tap into the city's diverse interests—from music and arts to tech innovations—ensuring your ads reach and resonate with the ideal audience.`}
                        button="button-3"
                        Icon={SiFacebook}
                    />
                    <Process
                        title="LinkedIn Ads Management"
                        description={`Leverage LinkedIn Ads with Gurulabs in ${city.name}, ${state.name}, to connect with the city's bustling professional scene. Our campaigns target key decision-makers in major local industries, from tech startups to established corporations, driving high-quality leads and fostering valuable B2B relationships.`}
                        button="button-2"
                        Icon={SiLinkedin}
                    />
                    <Process
                        title="YouTube Ads Management"
                        description={`Gurulabs offers expert YouTube Ads management in ${city.name}, ${state.name}, tailored to captivate the city's tech-savvy and creative populace. We create engaging video content and strategic placements that convert viewers into customers, enhancing your brand's visibility in this innovative city.`}
                        button="button-3"
                        Icon={SiYoutube}
                    />
                    <Process
                        title="Twitter (X) Ads Management"
                        description={`Our Twitter Ads management services in ${city.name}, ${state.name}, harness the platform’s real-time capabilities to create impactful campaigns. By tapping into local trends and conversations, we increase your brand’s visibility and drive traffic, engaging the city's active social media users with data-driven strategies.`}
                        button="button-2"
                        Icon={SiTwitter}
                    />
                    <Process
                        title="Instagram Ads Management"
                        description={`Choose Gurulabs for Instagram Ads management in ${city.name}, ${state.name}, and benefit from visually stunning ads that capture the city's vibrant culture. From scenic landscapes to bustling urban scenes, our precise targeting ensures significant engagement and business growth within this lively community.`}
                        button="button-3"
                        Icon={SiInstagram}
                    />
                    <Process
                        title="TikTok Ads Management"
                        description={`Tap into TikTok’s dynamic audience with our ads management in ${city.name}, ${state.name}. Our team creates shareable and entertaining content that captures the essence of the city’s youthful energy and creativity, maximizing visibility and engagement for your brand.`}
                        button="button-2"
                        Icon={SiTiktok}
                    />
                </div>
            </section>
            <section className="bg-gray-50 py-12 px-4">
                <div className="max-w-7xl mx-auto overflow-hidden">
                    <div className="text-xl py-4 text-gray-700 prose prose-lg">
                        <h1>Welcome to San Bernardino, California: A Haven for Digital Marketing</h1>

<p>Welcome to San Bernardino, a vibrant city nestled in the heart of California! Known for its rich history, diverse culture, and growing business landscape, San Bernardino offers a plethora of opportunities for businesses looking to thrive in the digital realm. In this comprehensive guide, we'll explore the major features of San Bernardino, delve into its historical background and cultural significance, and showcase why this city is a prime location for digital marketing, particularly Pay-Per-Click (PPC) advertising.</p>

<h2>Exploring the City of San Bernardino</h2>

<p>Situated in the picturesque Inland Empire region, San Bernardino is a city brimming with energy and innovation. Home to over 200,000 residents, this vibrant community boasts a diverse population, vibrant arts scene, and a thriving economy. From stunning mountain views to bustling downtown streets, San Bernardino offers a unique blend of natural beauty and urban convenience.</p>

<h3>Historical Background</h3>

<p>Founded in 1810, San Bernardino has a storied past that dates back to the Spanish colonial era. Originally established as a Spanish mission, the city grew rapidly during the California Gold Rush, becoming a bustling hub for trade and commerce. Today, San Bernardino stands as a testament to its rich history, with historic landmarks and cultural institutions that pay homage to its heritage.</p>

<h3>Cultural Significance</h3>

<p>San Bernardino is a melting pot of cultures, reflecting the diverse tapestry of California's population. From vibrant festivals celebrating Latinx heritage to art galleries showcasing local talent, the city embraces its multicultural identity with pride. Music, art, and cuisine thrive in San Bernardino, offering residents and visitors alike a taste of the world's cultural richness.</p>

<h2>Suitability for Digital Marketing: Why Choose San Bernardino?</h2>

<p>When it comes to digital marketing, San Bernardino is a goldmine of opportunities waiting to be uncovered. As a business owner looking to expand your reach and attract customers online, leveraging the power of PPC advertising in San Bernardino can give you a competitive edge. Here's why:</p>

<ul>
  <li><strong>Thriving Business Environment:</strong> San Bernardino is home to a diverse array of businesses, from tech startups to local boutiques. With a strong entrepreneurial spirit and a supportive business community, the city provides fertile ground for digital marketing initiatives.</li>
  
  <li><strong>Target Audience:</strong> Understanding your target audience is key to successful PPC advertising. In San Bernardino, you'll find a diverse demographic mix, including young professionals, families, and students. Tailoring your PPC campaigns to resonate with the local population can drive meaningful engagement and conversions.</li>
  
  <li><strong>Economic Growth:</strong> San Bernardino's economy is on the rise, with new opportunities emerging in sectors such as healthcare, logistics, and e-commerce. By harnessing the power of PPC advertising, businesses can capitalize on this growth trajectory and expand their customer base.</li>
  
  <li><strong>Digital Connectivity:</strong> In an increasingly digital world, having a strong online presence is crucial for business success. San Bernardino's tech-savvy population and access to high-speed internet make it an ideal location for implementing cutting-edge digital marketing strategies like PPC advertising.</li>
</ul>

<h2>The Benefits of PPC Advertising in San Bernardino</h2>

<p>PPC advertising offers a host of benefits for businesses looking to enhance their online visibility and drive targeted traffic to their websites. In the context of San Bernardino, utilizing PPC can open up new avenues for growth and customer acquisition. Here are some key benefits of PPC advertising in San Bernardino:</p>

<ul>
  <li><strong>Targeted Reach:</strong> With PPC advertising, you can target specific keywords, demographics, and locations to reach your ideal customers in San Bernardino. By tailoring your ad campaigns to local preferences and interests, you can attract high-quality leads and increase conversions.</li>
  
  <li><strong>Immediate Results:</strong> Unlike traditional marketing channels, PPC advertising delivers quick results, allowing you to see the impact of your campaigns in real-time. In San Bernardino's fast-paced business environment, this instant feedback can help you optimize your marketing strategies for maximum effectiveness.</li>
  
  <li><strong>Cost-Effective:</strong> PPC advertising offers a cost-effective way to promote your business in San Bernardino. With pay-per-click pricing models, you only pay when users click on your ads, ensuring that your marketing budget is used efficiently to drive tangible results.</li>
  
  <li><strong>Measurable ROI:</strong> One of the major advantages of PPC advertising is its measurability. By tracking key performance metrics such as click-through rates, conversion rates, and ROI, businesses in San Bernardino can gauge the effectiveness of their campaigns and make data-driven decisions to optimize their marketing efforts.</li>
</ul>

<h2>The Local Business Environment in San Bernardino</h2>

<p>San Bernardino's business landscape is characterized by innovation, entrepreneurship, and resilience. From tech startups to established enterprises, the city offers a dynamic ecosystem where businesses can thrive and grow. With a strong emphasis on community engagement and collaboration, San Bernardino fosters a supportive environment for business owners to flourish.</p>

<h3>Key Industries in San Bernardino</h3>

<p>San Bernardino's economy is driven by diverse industries that contribute to its growth and prosperity. Some key sectors that have a significant presence in the city include:</p>

<ul>
  <li><strong>Healthcare:</strong> San Bernardino is home to a thriving healthcare sector, with top-notch medical facilities and research institutions driving innovation in the field.</li>
  
  <li><strong>Logistics and Transportation:</strong> As a strategic hub for logistics and transportation, San Bernardino plays a vital role in facilitating the movement of goods across the region.</li>
  
  <li><strong>E-commerce:</strong> The rise of e-commerce has opened up new opportunities for businesses in San Bernardino, creating a vibrant marketplace for online retailers and digital storefronts.</li>
</ul>

<h3>Challenges and Opportunities for Businesses in San Bernardino</h3>

<p>While San Bernardino offers a wealth of opportunities for businesses, it also presents unique challenges that require strategic planning and innovation. Some key challenges and opportunities for businesses in San Bernardino include:</p>

<ul>
  <li><strong>Competition:</strong> With a competitive business landscape, businesses in San Bernardino must differentiate themselves and leverage digital marketing strategies like PPC advertising to stand out from the crowd.</li>
  
  <li><strong>Local Engagement:</strong> Building strong relationships with the local community is essential for businesses in San Bernardino. By connecting with customers on a personal level and demonstrating a commitment to the city, businesses can foster brand loyalty and drive repeat business.</li>
  
  <li><strong>Adaptability:</strong> San Bernardino's business environment is constantly evolving, requiring businesses to stay agile and flexible in response to changing market trends and consumer demands. By embracing innovation and embracing digital technologies, businesses can stay ahead of the curve and position themselves for success.</li>
</ul>

<h2>Conclusion: Unlocking the Power of PPC Advertising in San Bernardino</h2>

<p>In conclusion, San Bernardino offers a wealth of opportunities for businesses looking to harness the power of digital marketing, with PPC advertising serving as a strategic tool to drive growth and achieve business objectives. By understanding the local business environment, targeting the right audience, and leveraging the benefits of PPC advertising, businesses in San Bernardino can take their online marketing efforts to new heights and unlock the city's full potential for success.</p>

<p>Are you ready to elevate your business's online presence in San Bernardino? Contact us today to learn more about our PPC advertising services and how we can help you reach your digital marketing goals in this dynamic city!</p>
                    </div>
                </div>
            </section>
            {listings && listings.length > 0 ? (
                <section>
                    <div className="max-w-7xl mx-auto">
                        <div className="max-w-5xl mx-auto py-24">
                            <div className="block sm:flex sm:flex-col justify-center items-center px-4">
                                <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                    <span className="relative">We support small business</span>
                                    <span className="text-green-500 ml-2 relative">near {city.name}, {state.code}</span>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="absolute left-1/2 -mt-2 ml-16 hidden xl:block"
                                        width={238}
                                        height={17}
                                        fill="none"
                                    >
                                        <path stroke="#48BB78" strokeLinecap="round" strokeWidth="2" d="M1.833 12.55c101.19 8.798 198.897-3.667 235.102-11" />
                                    </svg>
                                </h2>
                                <p className="text-base text-center">
                                    Generate quality leads in {city.name} with our expert social media ads management services.
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-wrap justify-center items-center">
                            {listings
                                .filter(listing => listing.website && listing.phone) // Only show listings with a valid image_url
                                .map((listing, index) => (
                                        <div key={listing.id || index} className="w-full sm:w-1/2 lg:w-1/3 px-3 mb-6">
                                            <div className="p-4 max-w-md rounded-lg overflow-hidden shadow-lg bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out">
                                                <div className="p-4">
                                                    <h2 className="font-bold text-2xl mb-4 text-gray-800 whitespace-nowrap overflow-hidden text-ellipsis">{listing.name}</h2>
                                                    <div className="mb-4 text-gray-600">
                                                        <p>{listing.address}</p>
                                                        <p>{listing.city}, {listing.state} {listing.zip}</p>
                                                        <p>{listing.country || ''}</p>
                                                    </div>
                                                    <p className="text-gray-600 text-md">{listing.category}</p>
                                                    <p className="text-gray-600 text-md">Rating: {listing.rating || 'No rating available'} ⭐</p>
                                                    <p className="text-gray-600 text-md pb-4">Phone:  <a href={`tel:${listing.phone}`} className="text-green-600 hover:underline mb-4">
                                                        {listing.phone}
                                                    </a>
                                                    </p>
                                                    {listing.website && (
                                                        <a
                                                            href={`${listing.website.includes('http') ? listing.website : `https://${listing.website}`}?utm_source=gurulabs&utm_medium=referral&utm_campaign=${city.name}-${state.code}-ppc`}
                                                            className="relative w-full py-2 px-3 top-1 right-2 button rounded-sm text-gray-700 hover:bg-green-500"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            Visit Website
                                                        </a>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                ))
                            }
                        </div>
                    </div>
                </section>
            ) :

                (
                    <>
                    </>
                )}
            <section>
                <div className="bg-amber-50 py-12">
                    <div className="max-w-7xl mx-auto">
                        <div>
                            <div className='block sm:flex justify-center items-center px-4'>
                                <h1 className="block text-3xl sm:text-4xl py-5 relative">
                                    <span className="relative">Cities We Serve in {state.name}</span>
                                    <span className="text-green-500 ml-2 relative">
                                        for PPC Services
                                    </span>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4 ml-16 hidden xl:block" width={151} height={3} fill="none"><path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                                </h1>
                            </div>
                            <div className="px-4">
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                    {cities
                                        .sort(() => Math.random() - 0.5) // Shuffle the array
                                        .slice(0, 21) // Select the first 21 cities after shuffling
                                        .map((city) => (
                                            <Link key={city.id} href={`/ppc/${state.slug}/${city.slug}`} className="text-green-500">
                                                Pay Per Click Management near {city.name}
                                            </Link>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <PaidAdsPricingTable city={city.name} state={state.code} />
            <Newsletter />
            <section>
                <div className="bg-gray-50 py-12">
                    <div className="max-w-7xl mx-auto">
                        <div className="block sm:flex justify-center items-center px-4">
                            <h1 className="block text-3xl sm:text-4xl py-5 relative">
                                <span className="relative">Your Reliable Partner for </span>
                                <span className="text-green-500 ml-2 relative">
                                    PPC Management in {city.name}, {state.code}
                                </span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="absolute left-3/4 ml-16 hidden xl:block"
                                    width={151}
                                    height={3}
                                    fill="none"
                                >
                                    <path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" />
                                </svg>
                            </h1>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 px-4">
                            <div>
                                <p className="text-xl py-4 text-gray-700">
                                    <strong>Real Success Stories from {city.name}</strong>
                                </p>
                                <p className="text-xl py-4 text-gray-700">
                                    Our PPC strategies have driven significant growth for businesses in {city.name}. We've helped a local retailer increase sales by 50% in three months and a service provider double their client base with targeted ad campaigns.
                                </p>
                                <p className="text-xl py-4 text-gray-700">
                                    A tech startup saw a 70% reduction in their cost per acquisition while scaling their campaigns. Our data-driven approach and continuous optimization ensure sustainable growth and profitability.
                                </p>
                                <p className="text-xl py-4 text-gray-700">
                                    These success stories highlight our commitment to delivering measurable results. Whether you're a small business or a growing enterprise, our PPC strategies are designed to help you thrive in {city.name}.
                                </p>
                            </div>
                            <div>
                                <p className="text-xl py-4 text-gray-700">
                                    <strong>Commitment to Excellence</strong>
                                </p>
                                <p className="text-xl py-4 text-gray-700">
                                    Transparency, integrity, and exceptional results are the pillars of our PPC management. We provide detailed reports and regular updates, ensuring you understand the impact of every dollar spent.
                                </p>
                                <p className="text-xl py-4 text-gray-700">
                                    Our team is dedicated to understanding your business and creating PPC strategies that contribute to your overall growth. We focus on long-term success through continuous optimization and strategic adjustments.
                                </p>
                                <p className="text-xl py-4 text-gray-700">
                                    Experience the difference with a partner dedicated to driving your success in {city.name}. Our local expertise and industry knowledge ensure highly targeted campaigns that resonate with your audience.
                                </p>
                                <p className="text-xl py-4 text-gray-700">
                                    By choosing us, you are choosing a partner invested in your success. We are passionate about helping businesses in {city.name} thrive and are here to support you every step of the way.
                                </p>
                                <p className="text-xl py-4 text-gray-700">
                                    Discover more about our <a href="https://www.gurulabs.dev/paid-ads" className="font-bold underline">Paid Ads services</a> and how they can benefit your business in {city.name}, {state.code}.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <SocialProof city={city.name} state={state.code} />
            <PPCFAQs city={city.name} state={state.code} />
            {/* Rest of the JSX */}
        </PageLayout>
    );
};

export default SanBernardinoCAPPCPage;