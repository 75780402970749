import React, { useState } from 'react';

const GoogleAdsCostCalculator = () => {
    const [numberOfClicks, setNumberOfClicks] = useState(5000);
    const [costPerClick, setCostPerClick] = useState(1.50);
    const [totalCost, setTotalWeCost] = useState(7500.00);

    const calculateTotalCost = () => {
        const calculatedCost = numberOfClicks * costPerClick;
        setTotalWeCost(calculatedCost);
    };

    return (
        <section>
            <div className="flex items-center py-12">
                <div className="max-w-lg mx-auto mt-8 p-6 bg-gray-100 rounded-md shadow-md">
                    <h2 className="text-2xl font-semibold mb-4">Google Ads Cost Calculator</h2>
                    <div className="mb-4">
                        <label htmlFor="numberOfClicks" className="block mb-1">Number of Clicks:</label>
                        <input
                            type="text"
                            id="numberOfClicks"
                            value={numberOfClicks}
                            onChange={(e) => setNumberOfClicks(Number(e.target.value))}
                            className="border-gray-300 rounded-md p-2 w-full"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="costPerClick" className="block mb-1">Cost Per Click (CPC):</label>
                        <input
                            type="text"
                            id="costPerClick"
                            value={costPerClick}
                            onChange={(e) => setCostPerClick(Number(e.target.value))}
                            className="border-gray-300 rounded-md p-2 w-full"
                        />
                    </div>
                    <div className="mb-4">
                        <button
                            onClick={calculateTotalCost}
                            className="px-4 py-2 button text-white rounded hover:bg-blue-600"
                        >
                            Calculate Cost
                        </button>
                    </div>
                    <div className="mb-4">
                        <h3 className="text-xl">
                            Google Ad Cost: ${totalCost.toFixed(2)}
                        </h3>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default GoogleAdsCostCalculator;
