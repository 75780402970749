import React, { useState } from 'react';

const CACCostCalculator = () => {
    const [salesCosts, setSalesCosts] = useState('');
    const [marketingCosts, setMarketingCosts] = useState('');
    const [newCustomers, setNewCustomers] = useState('');
    const [cac, setCAC] = useState(null);

    const calculateCAC = () => {
        if (salesCosts === '' || marketingCosts === '' || newCustomers === '') {
            alert('Please fill in all fields');
            return;
        }

        const totalCosts = parseFloat(salesCosts) + parseFloat(marketingCosts);
        const cacValue = totalCosts / parseInt(newCustomers);
        setCAC(cacValue.toFixed(2));
    };

    return (
        <section>
            <div className="py-12">
                <div className="max-w-lg mx-auto mt-8 p-6 bg-gray-100 rounded-md shadow-md">
                    <h2 className="text-2xl font-semibold mb-4">Customer Acquisition Cost (CAC) Calculator</h2>
                    <div className="mb-4">
                        <label htmlFor="salesCosts" className="block mb-1">Sales Costs:</label>
                        <input
                            type="text"
                            id="salesCosts"
                            value={salesCosts}
                            onChange={(e) => setSalesCosts(parseFloat(e.target.value))}
                            className="border-gray-300 rounded-md p-2 w-full"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="marketingCosts" className="block mb-1">Marketing Costs:</label>
                        <input
                            type="text"
                            id="marketingCosts"
                            value={marketingCosts}
                            onChange={(e) => setMarketingCosts(parseFloat(e.target.value))}
                            className="border-gray-300 rounded-md p-2 w-full"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="newCustomers" className="block mb-1">New Customers Acquired:</label>
                        <input
                            type="text"
                            id="newCustomers"
                            value={newCustomers}
                            onChange={(e) => setNewCustomers(Number(e.target.value))}
                            className="border-gray-300 rounded-md p-2 w-full"
                        />
                    </div>
                    <div className="mb-4">
                        <button
                            onClick={calculateCAC}
                            className="px-4 py-2 button text-gray-700 rounded hover:bg-blue-600"
                        >
                            Calculate CAC
                        </button>
                    </div>
                    {cac !== null && (
                        <div className="mb-4">
                            <h3 className="text-xl">
                                Customer Acquisition Cost: ${cac}
                            </h3>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default CACCostCalculator;
