import React, { useState } from 'react'
import Swal from 'sweetalert2'
import { Switch } from '@headlessui/react'
import { useForm } from '@inertiajs/inertia-react';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


const ContactForm = ({ website }) => {

    const { data, setData, post, processing, errors, reset, wasSuccessful } = useForm({
        firstName: "",
        lastName: "",
        company: "",
        email: "",
        website: website,
        phoneNumber: "",
        message: "",
    });



    const onSubmitHandler = e => {
        e.preventDefault()
        post('/contact-us', {
            preserveScroll: true,
            onSuccess: () => {
                reset()
                Swal.fire(
                    'Awesome!',
                    'Your information has been received!',
                    'success'
                )
            },
        }, data)
    }

    return (
        <form onSubmit={onSubmitHandler} className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
            <div>
                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                    First name
                </label>
                <div className="my-1">
                    <input
                        type="text"
                        name="firstName"
                        id="firstName"
                        autoComplete="given-name"
                        value={data.firstName}
                        onChange={(e) => setData('firstName', e.target.value)}
                        className="py-3 px-4 block w-full shadow-sm border focus:ring-gray-500 focus:border-gray-500 border-gray-300 rounded-md"
                    />
                    {errors.firstName && <p className="px-4 text-red-500">{errors.firstName}</p>}
                </div>
            </div>
            <div>
                <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                    Last name
                </label>
                <div className="my-1">
                    <input
                        type="text"
                        name="lastName"
                        id="lastName"
                        value={data.lastName}
                        onChange={(e) => setData('lastName', e.target.value)}
                        autoComplete="family-name"
                        className="py-3 px-4 block w-full shadow-sm border focus:ring-gray-500 focus:border-gray-500 border-gray-300 rounded-md"
                    />
                    {errors.lastName && <p className="px-4 text-red-500">{errors.lastName}</p>}
                </div>
            </div>
            <div className="sm:col-span-2">
                <label htmlFor="company" className="block text-sm font-medium text-gray-700">
                    Company Name
                </label>
                <div className="my-1">
                    <input
                        type="text"
                        name="company"
                        id="company"
                        autoComplete="organization"
                        value={data.company}
                        onChange={(e) => setData('company', e.target.value)}
                        className="py-3 px-4 block w-full shadow-sm border focus:ring-gray-500 focus:border-gray-500 border-gray-300 rounded-md"
                    />
                    {errors.company && <p className="px-4 text-red-500">{errors.company}</p>}
                </div>
            </div>
            <div className="sm:col-span-2">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Email
                </label>
                <div className="my-1">
                    <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        value={data.email}
                        onChange={(e) => setData('email', e.target.value)}
                        className="py-3 px-4 block w-full shadow-sm border focus:ring-gray-500 focus:border-gray-500 border-gray-300 rounded-md"
                    />
                    {errors.email && <p className="px-4 text-red-500">{errors.email}</p>}
                </div>
            </div>
            <div className="sm:col-span-2">
                <label htmlFor="website" className="block text-sm font-medium text-gray-700">
                    Website
                </label>
                <div className="my-1">
                    <input
                        id="website"
                        name="website"
                        type="website"
                        autoComplete="website"
                        value={data.website}
                        onChange={(e) => setData('website', e.target.value)}
                        disabled
                        className="py-3 px-4 block w-full shadow-sm border focus:ring-gray-500 focus:border-gray-500 border-gray-300 rounded-md"
                    />
                    {errors.website && <p className="px-4 text-red-500">{errors.website}</p>}
                </div>
            </div>
            <div className="sm:col-span-2">
                <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">
                    Phone Number
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                        type="text"
                        name="phoneNumber"
                        id="phoneNumber"
                        autoComplete="tel"
                        value={data.phoneNumber}
                        onChange={(e) => setData('phoneNumber', e.target.value)}
                        className="py-3 px-4 block w-full shadow-sm border focus:ring-gray-500 focus:border-gray-500 border-gray-300 rounded-md"
                        placeholder="+1 (555) 987-6543"
                    />
                    {errors.phoneNumber && <p className="px-4 text-red-500">{errors.phoneNumber}</p>}
                </div>
            </div>
            <div className="sm:col-span-2">
                <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                    Message
                </label>
                <div className="my-1">
                    <textarea
                        id="message"
                        name="message"
                        rows={4}
                        value={data.message}
                        onChange={(e) => setData('message', e.target.value)}
                        className="py-3 px-4 block w-full shadow-sm focus:ring-gray-500 focus:border-gray-500 border border-gray-300 rounded-md"
                    />
                    {errors.message && <p className="px-4 text-red-500">{errors.message}</p>}
                </div>
            </div>

            <div className="sm:col-span-2">
                <button
                    disabled={processing}
                    type="submit"
                    className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white hover:text-gray-900 bg-gray-900 hover:bg-green-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                >
                    Let's talk
                </button>
            </div>
        </form>
    )
}
export default ContactForm
