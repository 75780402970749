import React, { useEffect } from 'react'
import NewsletterForm from '../../Components/NewsletterForm/Index'
import ProposalForm from '../../Components/ProposalForm/Index'
import { Link } from '@inertiajs/inertia-react'

const navigation = {
    solutions: [
        { name: 'Local SEO', href: '/seo/local' },
        { name: 'SEO Services', href: '/lead-generation' },
        { name: 'National SEO', href: '/seo/national' },
        { name: 'Enterprise SEO', href: '/seo/enterprise' },
        { name: 'Lead Generation', href: '/lead-generation' },
        { name: 'GMB Optimizations', href: '/seo/gmb-optimization' },
        { name: 'OnPage Optimization', href: '/seo/on-page-optimization' },
        // { name: 'Social Media Services', href: '#' },
        // { name: 'Digital Advertising Services', href: '#' },
        // { name: 'Content Marketing Services', href: '#' },
    ],
    support: [
        { name: 'Social Media', href: '/social/social-media-management' },
        { name: 'Zapier Consulting', href: '/automation/zapier-consulting' },
        { name: 'Make.com Consulting', href: '/automation/make-consulting' },
        { name: 'Pabbly Consulting', href: '/automation/pabbly-consulting' },
        // { name: 'SEO', href: '#' },
        // { name: 'PPC', href: '#' },
        // { name: 'Aamzon', href: '#' },
    ],
    company: [
        { name: 'Paid Ads', href: '/paid-ads' },
        { name: 'Tiktok Ads', href: '/ppc/tiktok-ads' },
        { name: 'Youtube Ads', href: '/ppc/youtube-ads' },
        { name: 'Facebook Ads', href: '/ppc/facebook-ads' },
        { name: 'Instagram Ads', href: '/ppc/instagram-ads' },
        { name: 'Google PPC Ads', href: '/ppc/google-ads' },
        // { name: 'Social Media Agency', href: '#' },
        // { name: 'Web Design Agency', href: '#' },
        // { name: 'Web Design Company', href: '#' },
        // { name: 'Industries We Serve', href: '#' },
    ],
    legal: [
        { name: 'Blog', href: '/blog' },
        { name: 'PPC Virginia', href: '/ppc/virginia' },
        { name: 'PPC Maryland', href: '/ppc/maryland' },
        { name: 'PPC Baltimore', href: '/ppc/virginia' },
        { name: 'PPC Arlington, VA', href: '/ppc/va/arlington' },
        { name: 'PPC Alexandria, VA', href: '/ppc/va/alexandria' },
        { name: 'PPC Washington DC', href: '/ppc/dc/washington-dc' },
        // { name: 'Blog', href: '/posts' },
        // { name: 'Careers', href: '#' },
        // { name: 'Locations', href: '#' },
        // { name: 'Partner with Us', href: '//www.seogurudc.com/partner-with-us/' },
        // { name: 'Community Impact', href: '#' },
        // { name: 'Tools', href: '#' },
    ],
    social: [
        {
            name: 'Facebook',
            href: '//facebook.com/myadsgurus',
            icon: (props) => (
                <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                    <path
                        fillRule="evenodd"
                        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                        clipRule="evenodd"
                    />
                </svg>
            ),
        },
        {
            name: 'Instagram',
            href: '//instagram.com/myadsgurus',
            icon: (props) => (
                <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                    <path
                        fillRule="evenodd"
                        d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                        clipRule="evenodd"
                    />
                </svg>
            ),
        },
        {
            name: 'Twitter',
            href: '//twitter.com/myadsgurus',
            icon: (props) => (
                <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                    <path d="M8 2H1L9.26086 13.0145L1.44995 21.9999H4.09998L10.4883 14.651L16 22H23L14.3917 10.5223L21.8001 2H19.1501L13.1643 8.88578L8 2ZM17 20L5 4H7L19 20H17Z">
                    </path>
                </svg>
            ),
        },
    ],
}

function Footer() {
    useEffect(() => {

    }, [])
    return (
        <footer className="bghero" aria-labelledby="footer-heading">
            <h2 id="footer-heading" className="sr-only">
                Footer
            </h2>
            <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                <div className='grid grid-cols-1 lg:grid-cols-2'>
                    <div className="w-full">
                        <h3 className="text-3xl text-gray-900 py-4 relative">Get started with Gurulabs today!</h3>
                        <svg xmlns="http://www.w3.org/2000/svg" width="175" height="4" className="-mt-4 ml-64 hidden lg:block" fill="none"><path stroke="#000" strokeLinecap="round" strokeWidth="2" d="M1.2 2.751c94.5-2.16 154.575-.9 172.8 0" /></svg>
                        <ProposalForm />
                    </div>
                    <div className="w-full">
                        <img src="/frontend/images/png/footer-01.png" className='mx-auto' alt="Guru Labs" />
                    </div>
                </div>
                <svg className="py-16" viewBox="0 0 1196 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line y1="1.10742" x2={1196} y2="1.10742" stroke="#48BB78" strokeWidth={2} />
                </svg>

                <div className="xl:grid xl:grid-cols-3 xl:gap-8">
                    <div className="grid grid-cols-2 gap-8 xl:col-span-2">
                        <div className="md:grid md:grid-cols-2 md:gap-8">
                            <div>
                                <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase">SEO Services</h3>
                                <ul role="list" className="mt-4 space-y-4">
                                    {navigation.solutions.map((item) => (
                                        <li key={item.name}>
                                            <Link href={item.href} className="text-base text-gray-700 font-medium hover:text-gray-900 outline-green-700">
                                                {item.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="mt-12 md:mt-0">
                                <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase">Consultation</h3>
                                <ul role="list" className="mt-4 space-y-4">
                                    {navigation.support.map((item) => (
                                        <li key={item.name}>
                                            <Link href={item.href} className="text-base text-gray-700 font-medium hover:text-gray-900 outline-green-700">
                                                {item.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="md:grid md:grid-cols-2 md:gap-8">
                            <div>
                                <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase">PPC Management</h3>
                                <ul role="list" className="mt-4 space-y-4">
                                    {navigation.company.map((item) => (
                                        <li key={item.name}>
                                            <Link href={item.href} className="text-base text-gray-700 font-medium hover:text-gray-900 outline-green-700">
                                                {item.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="mt-12 md:mt-0">
                                <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase">Company</h3>
                                <ul role="list" className="mt-4 space-y-4">
                                    {navigation.legal.map((item) => (
                                        <li key={item.name}>
                                            <Link href={item.href} className="text-base text-gray-700 font-medium hover:text-gray-900 outline-green-700">
                                                {item.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <NewsletterForm />
                </div>
                <div className="mt-8 border-t-2 border-green-500 pt-8 md:flex md:items-center md:justify-between">
                    <div className="flex space-x-6 md:order-2">
                        {navigation.social.map((item) => (
                            <a key={item.name} href={item.href} className="text-gray-900 hover:text-gray-300">
                                <span className="sr-only">{item.name}</span>
                                <item.icon className="h-6 w-6" aria-hidden="true" />
                            </a>
                        ))}
                    </div>
                    <p className="mt-8 text-base text-gray-900 md:mt-0 md:order-1">
                        &copy; 2022 TwelveFifth, Inc. DBA Gurulabs, Inc All rights reserved. <Link href="/privacy-policy">Privacy Policy</Link>
                    </p>
                </div>
            </div>
        </footer>
    )
}

export default Footer
