import React from 'react';
import Process from '../Process/Index';
import { BeakerIcon, CogIcon, EyeIcon, SunIcon } from '@heroicons/react/24/solid';
import { SpeakerWaveIcon, UsersIcon } from '@heroicons/react/24/solid';

const SearchEngineProcess = () => {
    return (
        <section>
            <div className="max-w-5xl mx-auto py-24">
                <div className='block sm:flex sm:flex-col justify-center items-center px-4'>
                    <h2 className="block text-3xl sm:text-4xl py-5 relative">
                        <span className="relative">Our Tech-Enabled</span>
                        <span className="text-green-500 ml-2 relative">
                            SEO Process
                        </span>
                        <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-1/2 -mt-2 ml-16 hidden xl:block" width={238} height={17} fill="none"><path stroke="#48BB78" strokeLinecap="round" strokeWidth="2" d="M1.833 12.55c101.19 8.798 198.897-3.667 235.102-11" /></svg>
                    </h2>
                    <p className="text-base text-center">Our Lead generation process helps you identify potential customers or clients and gather their contact information to nurture them toward a sale.</p>
                </div>
            </div>
            <div className="max-w-7xl mx-auto pb-24">
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
                    <Process
                        title="Keyword research"
                        description="Our Keyword Research service helps you to identify the most relevant and effective keywords and phrases that your target audience is using when they search for products or services related to your business or topic. Using the latest keyword research tools and techniques, our team will seek out the keywords that drive quality traffic to your website and give you the competitive edge you need to succeed online."
                        button="button-3"
                        Icon={BeakerIcon}
                    />
                    <Process
                        title="On-page optimization"
                        description="Our on-page optimization service will ensure that your website pages are optimized for search engine rankings. We will analyze the current content of your web pages and optimize it including the use of target keywords, meta tags, title tags, header tags, and internal linking. This service ensures that your web pages are formatted correctly, easy to navigate and contain relevant and keyword-rich content, so that your website will be more visible in relevant search engine results."
                        button="button-2"
                        Icon={SpeakerWaveIcon}
                    />
                    <Process
                        title="Technical SEO"
                        description="We specialize in Technical SEO services to ensure your website is properly configured for search engines. Our services include optimizing website speed, mobile responsiveness, and website security. We strive to help your website reach its maximum potential in terms of visibility and performance. Our team of experienced and knowledgeable SEO professionals will work closely with you to assess your current website and make the necessary changes to maximize search engine visibility."
                        button="button-3"
                        Icon={UsersIcon}
                    />
                    <Process
                        title="Off-page optimization"
                        description="Our Off-page Optimization service will build high-quality backlinks from other reputable websites to your website, increasing your website's authority and credibility. We utilize proven strategies to ensure that your website is linked to from other influential websites in your niche, helping you to gain visibility and improve your search engine rankings. Our team will research and analyze the best backlink opportunities for your website and ensure that the links are natural, trustworthy, and relevant to your website."
                        button="button-2"
                        Icon={EyeIcon}
                    />
                    <Process
                        title="Content creation"
                        description="We specialize in creating tailored content that accurately reflects the search intent and interests of your target audience. Our team of experienced content creators strives to provide high-quality content that resonates with readers and effectively communicates your key messages. We leverage our understanding of SEO and keyword optimization to ensure your content is optimized to reach the right people, at the right time. With our content creation services, you'll be able to establish yourself as an authoritative presence in your industry."
                        button="button-3"
                        Icon={CogIcon}
                    />
                    <Process
                        title="Performance tracking and analysis"
                        description="We provide comprehensive performance tracking and analysis services to help you monitor and evaluate your website's search engine ranking and traffic. Our services utilize advanced analytics to identify opportunities for improvement, allowing you to make strategic adjustments to your SEO strategy. Our performance tracking and analysis services provide you with the insights you need to optimize your SEO performance and stay ahead of the competition."
                        button="button-2"
                        Icon={SunIcon}
                    />
                </div>
            </div>
        </section>
    )
}
export default SearchEngineProcess
