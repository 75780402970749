import React from 'react'
import LeadsForm from '../../../../Components/LeadsForm/Index'
import Image from '../../../../Components/Image/Index'


const CommercePageHero = () => {
    return (
        <section className="">
            <div className="max-w-7xl mx-auto py-8 lg:py-48 px-4">
                <div className="flex flex-col lg:flex-row justify-between items-center">
                    <div className="w-full lg:w-8/12 py-4">
                        <h1 className="text-3xl lg:text-6xl my-1">We help you create the best
                            <span className="text-green-500 relative mx-2">
                                Shopping Experience
                                <svg width="175" height="3" viewBox="0 0 175 3" fill="none" className="absolute hidden lg:block left-6">
                                    <path d="M1.43945 1.54395H173.903" stroke="#48BB78" strokeWidth="2" strokeLinecap="round" />
                                </svg>
                            </span>
                            for your clients</h1>
                        <p className="my-3">Gurulabs is your key to unlocking the potential of your website’s online visibility. Our experienced team of gurus use proven methods to increase your website’s search engine rankings and maximize your online traffic.</p>
                        <LeadsForm />
                    </div>
                    <div className="w-full lg:w-4/12 py-4">
                        <Image
                            src="frontend/images/png/process-02.webp"
                            fallback="frontend/images/png/process-02.png"
                            className="h-[491px] w-[467px] mx-auto" alt=""
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}
export default CommercePageHero
