import React from 'react'
import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
// import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const faqs = [
    {
        question: "What is social media marketing?",
        answer: "Social media marketing is the process of using social media platforms to promote a product or service. At Gurulabs, we help businesses leverage social media marketing to increase brand awareness, engage with customers, and drive traffic to their websites.",
    },
    {
        question: "How to do social media marketing?",
        answer: "To do social media marketing effectively, you need to identify your target audience, create engaging content, and use the right platforms. Gurulabs offers comprehensive social media marketing services to help you plan and execute successful campaigns.",
    },
    {
        question: "Why use social media marketing?",
        answer: "Social media marketing is essential for reaching a large audience, building brand loyalty, and driving sales. Gurulabs can help you harness the power of social media to achieve your marketing goals.",
    },
    {
        question: "What is a social media marketing strategy?",
        answer: "A social media marketing strategy outlines how your business will use social media to achieve its marketing goals. It includes content planning, audience targeting, and performance metrics. Gurulabs specializes in developing effective social media marketing strategies tailored to your business needs.",
    },
    {
        question: "How does social media marketing work?",
        answer: "Social media marketing works by creating and sharing content on social media platforms to engage your audience and promote your brand. Gurulabs uses data-driven approaches to optimize your social media marketing efforts for maximum impact.",
    },
    {
        question: "What is a social media marketing plan?",
        answer: "A social media marketing plan is a detailed document that outlines your social media goals, tactics, and performance metrics. Gurulabs can help you create a comprehensive social media marketing plan to guide your efforts and measure success.",
    },
    {
        question: "What is the importance of social media marketing?",
        answer: "Social media marketing is important because it helps businesses connect with their audience, increase brand visibility, and drive conversions. Gurulabs offers expert social media marketing services to help you achieve these benefits.",
    },
    {
        question: "How to use social media for marketing?",
        answer: "Using social media for marketing involves creating engaging content, interacting with your audience, and analyzing performance metrics. Gurulabs provides the tools and expertise to help you effectively use social media for marketing.",
    },
    {
        question: "What are the benefits of social media marketing?",
        answer: "The benefits of social media marketing include increased brand awareness, improved customer engagement, and higher conversion rates. Gurulabs can help you maximize these benefits through strategic social media marketing efforts.",
    },
    {
        question: "How to start social media marketing?",
        answer: "To start social media marketing, identify your goals, choose the right platforms, and create a content plan. Gurulabs offers guidance and support to help you kickstart your social media marketing journey.",
    },
    {
        question: "How to create a social media marketing strategy?",
        answer: "Creating a social media marketing strategy involves setting goals, identifying your audience, and planning your content. Gurulabs can help you develop a tailored strategy to achieve your marketing objectives.",
    },
    {
        question: "Why is social media marketing important?",
        answer: "Social media marketing is important because it allows businesses to reach a broad audience, engage with customers, and drive sales. Gurulabs provides expert social media marketing services to help you leverage these opportunities.",
    },
    {
        question: "What is social media management?",
        answer: "Social media management involves creating, scheduling, analyzing, and engaging with content posted on social media platforms. At Gurulabs, we offer comprehensive social media management services to help businesses enhance their online presence and connect with their audience effectively.",
    },
    {
        question: "Why is social media management important?",
        answer: "Social media management is crucial for maintaining a consistent online presence, engaging with customers, and driving traffic to your website. Gurulabs helps you manage your social media accounts to build brand loyalty and increase your reach.",
    },
    {
        question: "What are the best social media management tools?",
        answer: "The best social media management tools include Hootsuite, Buffer, and Sprout Social. These tools help streamline your social media activities, allowing for efficient scheduling, monitoring, and engagement. Gurulabs can help you choose and implement the right tools for your business.",
    },
    {
        question: "How can a social media management company help my business?",
        answer: "A social media management company like Gurulabs offers expertise in creating and executing social media strategies that align with your business goals. We help you manage your accounts, create engaging content, and analyze performance to drive results.",
    },
    {
        question: "What is social media management software?",
        answer: "Social media management software is designed to help businesses manage their social media activities from one platform. It includes features for scheduling posts, monitoring engagement, and analyzing performance. Gurulabs can help you select and utilize the best software for your needs.",
    },
    {
        question: "Why use social media management tools?",
        answer: "Social media management tools save time and increase efficiency by automating tasks such as scheduling posts and tracking analytics. Gurulabs uses these tools to optimize your social media strategy and ensure consistent engagement with your audience.",
    },
    {
        question: "What are the benefits of social media management?",
        answer: "The benefits of social media management include increased brand awareness, improved customer engagement, and higher website traffic. Gurulabs provides expert social media management services to help you achieve these benefits and grow your online presence.",
    },
    {
        question: "How to choose the best social media management company?",
        answer: "Choose the best social media management company by evaluating their experience, client reviews, and the range of services they offer. Gurulabs stands out with its proven track record, expert team, and comprehensive social media management solutions.",
    },
];


function SocialFAQs() {
    return (
        <div className="bg-white">
            <div className="mx-auto max-w-7xl px-6 py-12 sm:py-24 lg:px-8 lg:py-24">
                <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
                    <h2 className="text-3xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
                    <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                        {faqs.map((faq) => (
                            <Disclosure as="div" key={faq.question} className="pt-6">
                                {({ open }) => (
                                    <>
                                        <dt>
                                            <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                                                <span className="text-base font-semibold leading-7">{faq.question}</span>
                                                <span className="ml-6 flex h-7 items-center">
                                                    {open ? (
                                                        <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                    ) : (
                                                        <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                    )}
                                                </span>
                                            </Disclosure.Button>
                                        </dt>
                                        <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                            <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
}


export default SocialFAQs
