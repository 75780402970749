import React from 'react'
import { Disclosure } from '@headlessui/react'
const MobileMenuLinks = () => {
    return (
        <>
            <Disclosure.Button
                as="a"
                href="/web-design"
                className="text-gray-900 hover:bg-green-400 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
            >
                Website Design & Development
            </Disclosure.Button>
            <Disclosure.Button
                as="a"
                href="/seo/services"
                className="text-gray-900 hover:bg-green-400 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
            >
                Search Engine Optimization
            </Disclosure.Button>
            <Disclosure.Button
                as="a"
                href="/lead-generation"
                className="text-gray-900 hover:bg-green-400 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
            >
                Lead Generation Services
            </Disclosure.Button>
            <Disclosure.Button
                as="a"
                href="/paid-ads"
                className="text-gray-900 hover:bg-green-400 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
            >
                Paid Ads Management
            </Disclosure.Button>
            <Disclosure.Button
                as="a"
                href="/automation/zapier-consulting"
                className="text-gray-900 hover:bg-green-400 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
            >
                AI & Automation
            </Disclosure.Button>
            <Disclosure.Button
                as="a"
                href="/social-media"
                className="text-gray-900 hover:bg-green-400 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
            >
                Social Media
            </Disclosure.Button>
            <Disclosure.Button
                as="a"
                href="/blog"
                className="text-gray-900 hover:bg-green-400 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
            >
                Blog
            </Disclosure.Button>
            <Disclosure.Button
                as="a"
                href="//calendly.com/gurulabs/15min"
                className="text-gray-900 hover:bg-green-400 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
            >
                Schedule a Meeting
            </Disclosure.Button>
        </>
    )
}
export default MobileMenuLinks
