import React, { useEffect, useState } from 'react'
import PageLayout from '../../../../Layout/PageLayout/Index'
import mixpanel from 'mixpanel-browser'
import Process from '../../../../Components/Process/Index'
import { SiGoogle, SiGoogleanalytics, SiGooglesearchconsole, SiHtml5 } from '@icons-pack/react-simple-icons'
import { LinkIcon } from '@heroicons/react/24/outline'
import SearchEngineSlider from '../../../../Components/SearchEngineSlider/Index'
import Slider from '../../../../Components/Slider/Index'
import CalendarModal from '../../../../Layout/CalendarModal/Index'
import LeadsForm from '../../../../Components/LeadsForm/Index'
import GMBFAQs from '../../../../Components/FAQs/GMBFAQs/Index'
import GMBPricingTable from '../../../../Components/PricingTables/GMBPricingTable/Index'
import SEOMetaHead from '../../../../Components/SEOMetaHead/Index'
import GMBPricing from '../../../../Components/SEOPricing/GMBPricing/Index'
import SocialProof from '../../../../Components/SocialProof/Index'




const GoogleMyBusiness = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false)

    useEffect(() => {
        mixpanel.track("On-Page Optimiztaion Page Visited")
    }, [])

    return (
        <PageLayout>
            <SEOMetaHead
                title="Google My Business Optimization - Boost Local Search Visibility"
                description="Enhance your local search ranking with Gurulabs' GMB optimization services. Drive more local traffic and improve your business’s online presence today."
                convertKitPage="https://relentless-inventor-6062.ck.page/5a9ed0f555/index.js"
            />
            <section className="">
                <div className="max-w-7xl mx-auto py-8 lg:py-48 px-4">
                    <div className="flex flex-col lg:flex-row justify-between items-center">
                        <div className="w-full py-4 xl:w-8/12">
                            <h1 className="text-3xl lg:text-6xl my-1">Let Us Fine Tune Your
                                <span className="text-green-500 relative mx-2">
                                    Google Business Profile
                                    <svg width="175" height="3" viewBox="0 0 175 3" fill="none" className="absolute hidden lg:block left-6">
                                        <path d="M1.43945 1.54395H173.903" stroke="#48BB78" strokeWidth="2" strokeLinecap="round" />
                                    </svg>
                                </span>
                                Reach and Visibility</h1>
                            <p className="my-3">Gurulabs enhances your business's local visibility through strategic Google My Business optimization. Our experienced team leverages proven techniques to refine your GMB profile, driving more local traffic and increasing customer engagement.</p>
                            <LeadsForm />
                        </div>
                        <div className="w-full py-4 xl:w-4/12">
                            <img src="/frontend/images/svg/icon-22.svg" className="mx-auto" alt="National SEO By Gurulabs" />
                        </div>
                    </div>
                </div>
            </section>
            <Slider />
            <section>
                <div className="bg-gray-50 py-12">
                    <div className="max-w-7xl mx-auto">
                        <div className='block sm:flex justify-center items-center px-4'>
                            <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                <span className="relative">Come take a look at what On-Page plans</span>
                                <span className="text-green-500 ml-2 relative">
                                    we offer!
                                </span>
                                <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4 ml-16 hidden xl:block" width={151} height={3} fill="none"><path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                            </h2>
                        </div>
                        <GMBPricing modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
                    </div>
                </div>
            </section>
            <SocialProof />
            <section>
                <div className="max-w-5xl mx-auto py-24">
                    <div className='block sm:flex sm:flex-col justify-center items-center px-4'>
                        <h2 className="block text-3xl sm:text-4xl py-5 relative">
                            <span className="relative">Our Tech-Enabled</span>
                            <span className="text-green-500 ml-2 relative">
                                GMB Optimization Process
                            </span>
                            <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-1/2 -mt-2 ml-16 hidden xl:block" width={238} height={17} fill="none"><path stroke="#48BB78" strokeLinecap="round" strokeWidth="2" d="M1.833 12.55c101.19 8.798 198.897-3.667 235.102-11" /></svg>
                        </h2>
                        <p className="text-base text-center">Our On-Page Optimization process ensures that your website is fully optimized to rank higher in search engines and provide a seamless user experience.</p>
                    </div>
                </div>
                <div className="max-w-7xl mx-auto pb-24">
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
                        <Process
                            title="GMB Profile Audit"
                            description="We start with a comprehensive audit of your Google My Business profile, checking for accuracy, completeness, and optimization opportunities across all elements."
                            button="button-3"
                            Icon={SiGooglesearchconsole}
                        />
                        <Process
                            title="Business Information Optimization"
                            description="We optimize your business name, address, phone number, and other essential details to ensure consistency across all platforms and improve your local search ranking."
                            button="button-2"
                            Icon={SiGoogleanalytics}
                        />
                        <Process
                            title="Category & Service Selection"
                            description="Our team carefully selects the most relevant categories and services for your GMB profile, making it easier for potential customers to find you in local searches."
                            button="button-3"
                            Icon={SiGoogle}
                        />
                        <Process
                            title="Photo & Video Optimization"
                            description="We enhance your profile with high-quality images and videos, properly optimized for local SEO, to attract more customers and improve engagement."
                            button="button-2"
                            Icon={SiHtml5}
                        />
                        <Process
                            title="Review Management"
                            description="We help you manage customer reviews by encouraging positive feedback, responding to reviews, and handling negative comments to maintain a strong online reputation."
                            button="button-3"
                            Icon={LinkIcon}
                        />
                        <Process
                            title="Performance Monitoring & Reporting"
                            description="After optimization, we continuously monitor your GMB profile’s performance, providing regular reports with insights into your local search visibility and engagement metrics."
                            button="button-2"
                            Icon={SiGoogleanalytics}
                        />
                    </div>
                </div>
            </section>
            <section>
                <div className="bg-gray-50 py-12">
                    <div className="max-w-7xl mx-auto">
                        <div>
                            <div className='block sm:flex justify-center items-center px-4'>
                                <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                    <span className="relative">Maximize Your Local Visibility with</span>
                                    <span className="text-green-500 ml-2 relative">
                                        GMB Optimization
                                    </span>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4 ml-16 hidden xl:block" width={151} height={3} fill="none"><path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                                </h2>
                            </div>
                            <p className="py-4 text-lg text-center">Gurulabs' GMB Optimization process enhances every aspect of your Google My Business profile to improve local search rankings and attract more customers.</p>
                        </div>
                        <div className="px-4">
                            <div>
                                <p className="text-xl py-4 text-gray-700">At Gurulabs, we understand that GMB Optimization is a vital component of any successful local SEO strategy. Our process ensures that every element of your Google My Business profile is finely tuned to enhance visibility in local searches and provide potential customers with accurate, engaging information. By focusing on key GMB elements, we help your business stand out, attract more local traffic, and convert visitors into loyal customers.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Gurulabs' GMB Optimization Process</strong></p>

                                <p className="text-xl py-4 text-gray-700">Our GMB Optimization process begins with a comprehensive audit of your profile. We meticulously review all aspects, including business information, categories, and service listings, to identify opportunities for improvement. This audit forms the foundation of a targeted optimization strategy tailored to your business needs.</p>

                                <p className="text-xl py-4 text-gray-700">Next, we focus on optimizing your business information. Ensuring consistency and accuracy across all platforms is crucial for local SEO success. We refine your business name, address, phone number, and other key details to improve your local search ranking and ensure customers can easily find you.</p>

                                <p className="text-xl py-4 text-gray-700">We also enhance your GMB profile with high-quality photos and videos. Properly optimized visual content not only improves your profile’s appeal but also helps engage potential customers more effectively, driving more visits and interactions.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>How Gurulabs Benefits Your Business</strong></p>

                                <p className="text-xl py-4 text-gray-700">Choosing Gurulabs for GMB Optimization means partnering with a team of experts dedicated to maximizing your local search performance. Our customized approach ensures that your GMB profile meets the latest SEO standards and delivers outstanding visibility in local searches. By improving your profile’s accuracy, appeal, and engagement, we help drive more local traffic, increase conversions, and boost your ROI.</p>

                                <p className="text-xl py-4 text-gray-700">Our expertise in GMB Optimization covers all essential aspects, including review management, category selection, and ongoing performance monitoring. We continuously track your profile’s performance, making adjustments as needed to maintain optimal results. With Gurulabs, you can be confident that your GMB presence is in expert hands, allowing you to focus on growing your business.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Tailored Solutions for Your Unique Needs</strong></p>

                                <p className="text-xl py-4 text-gray-700">Every business is unique, and so are its local SEO needs. At Gurulabs, we offer tailored GMB Optimization solutions that address your specific challenges and goals. Whether you’re looking to improve your profile’s visibility, enhance customer engagement, or ensure your business information is consistently accurate, our team provides the expertise and support you need to achieve lasting success in local search rankings.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Partner with Gurulabs for Exceptional GMB Optimization</strong></p>

                                <p className="text-xl py-4 text-gray-700">Investing in GMB Optimization with Gurulabs is a strategic move that can significantly elevate your local search performance. Our comprehensive approach ensures that every element of your GMB profile is optimized for both search engines and potential customers, delivering measurable improvements in visibility and conversions. Partner with Gurulabs today and take your Google My Business presence to the next level.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <GMBPricingTable />
            <GMBFAQs />
            <section>
                <div className="bg-amber-50 hero-reviews py-12">
                    <div className="max-w-7xl mx-auto overflow-hidden">
                        <div className='block sm:flex justify-center items-center py-16 px-4'>
                            <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                <span className="relative">Hear from our Lead Gen</span>
                                <span className="text-green-500 ml-2 relative">
                                    Clients!
                                </span>
                                <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4  hidden xl:block" width={151} height={3} fill="none"><path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                            </h2>
                        </div>
                        <SearchEngineSlider />
                    </div>
                </div>
            </section>
            <CalendarModal isOpen={modalIsOpen} setIsOpen={setModalIsOpen} />
            {/* <Popup /> */}
        </PageLayout>
    )
}
export default GoogleMyBusiness
