import React, { useState } from 'react';

const FacebookAdsCostCalculator = () => {
    const [impressions, setImpressions] = useState(100000);
    const [clicks, setClicks] = useState(500);
    const [costPerClick, setCostPerClick] = useState(0.50);
    const [costPerMille, setCostPerMille] = useState(5.00);
    const [totalCost, setTotalCost] = useState(750.00);

    const calculateTotalCost = () => {
        const impressionsValue = isNaN(impressions) || impressions === '' ? 0 : impressions;
        const clicksValue = isNaN(clicks) || clicks === '' ? 0 : clicks;
        const costPerClickValue = isNaN(costPerClick) || costPerClick === '' ? 0 : costPerClick;
        const costPerMilleValue = isNaN(costPerMille) || costPerMille === '' ? 0 : costPerMille;

        const costFromClicks = clicksValue * costPerClickValue;
        const costFromImpressions = (impressionsValue / 1000) * costPerMilleValue;
        const totalCost = costFromClicks + costFromImpressions;
        setTotalCost(totalCost);
    };

    return (
        <section>
            <div className="">
                <div className="max-w-lg mx-auto mt-8 p-6 bg-gray-100 rounded-md shadow-md">
                    <h2 className="text-2xl font-semibold mb-4">Facebook Ads Cost Calculator</h2>
                    <div className="mb-4">
                        <label htmlFor="impressions" className="block mb-1">Impressions:</label>
                        <input
                            type="text"
                            id="impressions"
                            value={impressions}
                            onChange={(e) => setImpressions(e.target.value === '' ? '' : Number(e.target.value))}
                            className="border-gray-300 rounded-md p-2 w-full"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="clicks" className="block mb-1">Clicks:</label>
                        <input
                            type="text"
                            id="clicks"
                            value={clicks}
                            onChange={(e) => setClicks(e.target.value === '' ? '' : Number(e.target.value))}
                            className="border-gray-300 rounded-md p-2 w-full"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="costPerClick" className="block mb-1">Cost Per Click (CPC):</label>
                        <input
                            type="text"
                            id="costPerClick"
                            value={costPerClick}
                            onChange={(e) => setCostPerClick(e.target.value === '' ? '' : parseFloat(e.target.value))}
                            className="border-gray-300 rounded-md p-2 w-full"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="costPerMille" className="block mb-1">Cost Per Mille (CPM):</label>
                        <input
                            type="text"
                            id="costPerMille"
                            value={costPerMille}
                            onChange={(e) => setCostPerMille(e.target.value === '' ? '' : parseFloat(e.target.value))}
                            className="border-gray-300 rounded-md p-2 w-full"
                        />
                    </div>
                    <div className="mb-4">
                        <button
                            onClick={calculateTotalCost}
                            className="px-4 py-2 button text-white bg-blue-500 rounded hover:bg-blue-600"
                        >
                            Calculate Cost
                        </button>
                    </div>
                    <div className="mb-4">
                        <h3 className="text-xl">
                            Facebook Ads Cost: ${totalCost.toFixed(2)}
                        </h3>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FacebookAdsCostCalculator;
