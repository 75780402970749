export const COLUMNS = [
    {
        Header: 'Features',
        accessor: 'features',
    },
    {
        Header: 'Starter',
        accessor: 'starter',
    },
    {
        Header: 'Standard',
        accessor: 'standard',
    },
    {
        Header: 'Premium',
        accessor: 'premium',
    },
    {
        Header: 'Enterprise',
        accessor: 'enterprise',
    },
]
