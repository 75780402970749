import React from 'react'
import PageLayout from '../../../Layout/PageLayout/Index'

const Technology = () => {
    return (
        <PageLayout>
            <section>
            </section>
        </PageLayout>
    )
}
export default Technology
