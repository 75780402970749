import React, { useState } from 'react';

const FacebookAdsAOVCalculator = () => {
    const [aov, setAov] = useState(20.00);
    const [productCost, setProductCost] = useState(5.00);
    const [creditCardFeePercentage, setCreditCardFeePercentage] = useState(2.50);
    const [merchantAccountFee, setMerchantAccountFee] = useState(0.30);
    const [shippingCost, setShippingCost] = useState(3.50);
    const [fulfillmentCost, setFulfillmentCost] = useState(2.45);

    const calculateTotalCost = () => {
        const aovValue = isNaN(aov) || aov === '' ? 0 : aov;
        const productCostValue = isNaN(productCost) || productCost === '' ? 0 : productCost;
        const creditCardFeePercentageValue = isNaN(creditCardFeePercentage) || creditCardFeePercentage === '' ? 0 : creditCardFeePercentage;
        const merchantAccountFeeValue = isNaN(merchantAccountFee) || merchantAccountFee === '' ? 0 : merchantAccountFee;
        const shippingCostValue = isNaN(shippingCost) || shippingCost === '' ? 0 : shippingCost;
        const fulfillmentCostValue = isNaN(fulfillmentCost) || fulfillmentCost === '' ? 0 : fulfillmentCost;

        const creditCardFeeFixed = (aovValue * (creditCardFeePercentageValue / 100));
        const totalCost = productCostValue + creditCardFeeFixed + merchantAccountFeeValue + shippingCostValue + fulfillmentCostValue;
        return totalCost;
    };

    const totalCost = calculateTotalCost();
    const percentageCost = (totalCost / aov) * 100;
    const grossProfitMargin = ((aov - totalCost) / aov) * 100;
    const expectedGrossProfit = aov - totalCost;
    const breakEvenROAS = totalCost / aov;

    return (
        <section className="py-12 bg-gray-100">
            <div className="max-w-lg mx-auto mt-8 p-6 bg-white rounded-md shadow-md">
                <h2 className="text-2xl font-semibold mb-4">Product/Service Cost Calculator</h2>
                <div className="mb-4">
                    <label htmlFor="aov" className="block mb-1">AOV:</label>
                    <input
                        type="text"
                        id="aov"
                        value={aov}
                        onChange={(e) => setAov(e.target.value === '' ? '' : parseFloat(e.target.value))}
                        className="border-gray-300 rounded-md p-2 w-full"
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="productCost" className="block mb-1">Product Cost:</label>
                    <input
                        type="text"
                        id="productCost"
                        value={productCost}
                        onChange={(e) => setProductCost(e.target.value === '' ? '' : parseFloat(e.target.value))}
                        className="border-gray-300 rounded-md p-2 w-full"
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="creditCardFeePercentage" className="block mb-1">Credit Card Fee Percentage:</label>
                    <input
                        type="text"
                        id="creditCardFeePercentage"
                        value={creditCardFeePercentage}
                        onChange={(e) => setCreditCardFeePercentage(e.target.value === '' ? '' : parseFloat(e.target.value))}
                        className="border-gray-300 rounded-md p-2 w-full"
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="merchantAccountFee" className="block mb-1">Merchant Account Fee:</label>
                    <input
                        type="text"
                        id="merchantAccountFee"
                        value={merchantAccountFee}
                        onChange={(e) => setMerchantAccountFee(e.target.value === '' ? '' : parseFloat(e.target.value))}
                        className="border-gray-300 rounded-md p-2 w-full"
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="shippingCost" className="block mb-1">Shipping Cost:</label>
                    <input
                        type="text"
                        id="shippingCost"
                        value={shippingCost}
                        onChange={(e) => setShippingCost(e.target.value === '' ? '' : parseFloat(e.target.value))}
                        className="border-gray-300 rounded-md p-2 w-full"
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="fulfillmentCost" className="block mb-1">Fulfillment Cost:</label>
                    <input
                        type="text"
                        id="fulfillmentCost"
                        value={fulfillmentCost}
                        onChange={(e) => setFulfillmentCost(e.target.value === '' ? '' : parseFloat(e.target.value))}
                        className="border-gray-300 rounded-md p-2 w-full"
                    />
                </div>
                <div className="mb-4">
                    <h3 className="text-xl text-gray-800">Total Cost: ${totalCost.toFixed(2)}</h3>
                    <h3 className="text-xl text-gray-800">Percentage Cost: {percentageCost.toFixed(2)}%</h3>
                    <h3 className="text-xl text-gray-800">Gross Profit Margin: {grossProfitMargin.toFixed(2)}%</h3>
                    <h3 className="text-xl text-gray-800">Expected Gross Profit: ${expectedGrossProfit.toFixed(2)}</h3>
                    <h3 className="text-xl text-gray-800">Break Even ROAS: {breakEvenROAS.toFixed(2)}</h3>
                </div>
            </div>
        </section>
    );
};

export default FacebookAdsAOVCalculator;
