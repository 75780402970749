import React from 'react'
import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
// import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const faqs = [
    {
        question: "What is lead generation?",
        answer: "Lead generation is the process of attracting and converting strangers and prospects into someone who has indicated interest in your company's product or service."
    },
    {
        question: "What services does Gurulabs offer?",
        answer: "Gurulabs specializes in providing comprehensive lead generation services to help businesses attract, nurture, and convert leads into customers."
    },
    {
        question: "What is a lead generation agency?",
        answer: "A lead generation agency is a company that helps businesses identify and attract potential customers through various marketing strategies and tactics."
    },
    {
        question: "How can a B2B lead generation agency help my business?",
        answer: "A B2B lead generation agency like Gurulabs can help your business by targeting and attracting high-quality leads, increasing your sales pipeline, and ultimately boosting your revenue."
    },
    {
        question: "Why choose Gurulabs for lead generation services?",
        answer: "Gurulabs offers tailored lead generation solutions, leveraging advanced strategies and technologies to ensure you receive high-quality leads that convert into customers."
    },
    {
        question: "What industries does Gurulabs serve?",
        answer: "Gurulabs serves a wide range of industries including real estate, IT, healthcare, legal, and more, providing specialized lead generation services to meet industry-specific needs."
    },
    {
        question: "How does Gurulabs generate leads?",
        answer: "Gurulabs uses a combination of digital marketing techniques including SEO, PPC, social media marketing, email marketing, and content marketing to generate high-quality leads."
    },
    {
        question: "What is the cost of Gurulabs' lead generation services?",
        answer: "The cost of our lead generation services varies based on your business needs and goals. Contact us for a customized quote."
    },
    {
        question: "How quickly can I see results from lead generation services?",
        answer: "Lead generation results can vary, but many clients see an increase in leads within the first few months of working with Gurulabs."
    },
    {
        question: "Can Gurulabs help with digital marketing and lead generation?",
        answer: "Yes, Gurulabs offers comprehensive digital marketing services that complement our lead generation strategies to maximize your online presence and attract more leads."
    },
    {
        question: "Does Gurulabs provide lead generation services for small businesses?",
        answer: "Yes, Gurulabs offers customized lead generation services for businesses of all sizes, including small businesses."
    },
    {
        question: "What is outbound lead generation?",
        answer: "Outbound lead generation involves proactively reaching out to potential customers through methods such as cold calling, email marketing, and social selling."
    },
    {
        question: "What makes Gurulabs different from other lead generation agencies?",
        answer: "Gurulabs stands out with our data-driven approach, personalized strategies, and commitment to delivering measurable results for our clients."
    },
    {
        question: "Can Gurulabs generate leads through social media?",
        answer: "Yes, Gurulabs utilizes various social media platforms to run targeted campaigns that attract and engage potential leads."
    },
    {
        question: "Does Gurulabs offer lead generation services in specific locations?",
        answer: "Gurulabs provides lead generation services globally, with specialized services for locations such as Miami, New York, London, and Dubai."
    },
    {
        question: "What is the difference between inbound and outbound lead generation?",
        answer: "Inbound lead generation focuses on attracting leads through valuable content and SEO, while outbound lead generation involves direct outreach to potential customers."
    },
    {
        question: "How does Gurulabs ensure the quality of leads?",
        answer: "Gurulabs uses advanced targeting and filtering techniques to ensure the leads we generate are high-quality and have a high potential for conversion."
    },
    {
        question: "What is the role of a lead generation marketing agency?",
        answer: "A lead generation marketing agency like Gurulabs creates and implements strategies to attract and convert leads through various marketing channels."
    },
    {
        question: "Can Gurulabs help with lead generation for specific industries?",
        answer: "Yes, Gurulabs offers specialized lead generation services for industries such as real estate, healthcare, legal, and more."
    },
    {
        question: "How can I get started with Gurulabs' lead generation services?",
        answer: "To get started with Gurulabs, simply contact us through our website or call us directly to discuss your lead generation needs and goals."
    }
];



function HomeFAQs() {
    return (
        <div className="bg-white">
            <div className="mx-auto max-w-7xl px-6 py-12 sm:py-24 lg:px-8 lg:py-24">
                <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
                    <h2 className="text-3xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
                    <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                        {faqs.map((faq) => (
                            <Disclosure as="div" key={faq.question} className="pt-6">
                                {({ open }) => (
                                    <>
                                        <dt>
                                            <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                                                <span className="text-base font-semibold leading-7">{faq.question}</span>
                                                <span className="ml-6 flex h-7 items-center">
                                                    {open ? (
                                                        <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                    ) : (
                                                        <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                    )}
                                                </span>
                                            </Disclosure.Button>
                                        </dt>
                                        <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                            <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
}


export default HomeFAQs
