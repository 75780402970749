import React from 'react'
import LeadsForm from '../../../../Components/LeadsForm/Index'

const HeroSection = () => {
    return (
        <section className="seo-hero">
            <div className="hero-image">
                <div className="max-w-4xl mx-auto px-4">
                    <div className="block text-center pt-12">
                        <h1 className="text-5xl xl:text-6xl">Maximize growth with
                            <br />{''}
                            <svg width="270" height="17" fill="none" className="absolute mt-12 ml-24 hidden md:block">
                                <path stroke="#48BB78" strokeWidth="2" d="M.5 3C183-3.5 145.5 10 123 14s118-4 146.5-7.5" />
                            </svg>
                            <span className="mr-2 text-green-500 font-semibold">
                                SEO Strategies
                            </span>{' '}
                            in your digital transformation journey.
                        </h1>
                        <p className="py-5 text-md md:text-lg max-w-xl mx-auto">
                            Experience a significant reduction in operational costs while boosting your digital presence. Our innovative SEO solutions drive your business forward in the digital transformation journey.
                        </p>
                    </div>
                    <div className="max-w-3xl mx-auto">
                        <LeadsForm />
                    </div>
                </div>
            </div>
        </section>
    )
}
export default HeroSection
