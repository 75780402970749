import React, { useEffect, useState } from 'react'
import PageLayout from '../../../../Layout/PageLayout/Index'
import mixpanel from 'mixpanel-browser'
import Process from '../../../../Components/Process/Index'
import { SiGoogle, SiGoogleanalytics, SiGooglesearchconsole, SiHtml5 } from '@icons-pack/react-simple-icons'
import { LinkIcon } from '@heroicons/react/24/outline'
import SearchEngineSlider from '../../../../Components/SearchEngineSlider/Index'
import Slider from '../../../../Components/Slider/Index'
import CalendarModal from '../../../../Layout/CalendarModal/Index'
import LeadsForm from '../../../../Components/LeadsForm/Index'
import OnPageFAQs from '../../../../Components/FAQs/OnPageFAQs/Index'
import OnPagePricingTable from '../../../../Components/PricingTables/OnPagePricingTable/Index'
import SEOMetaHead from '../../../../Components/SEOMetaHead/Index'
import OnPagePricing from '../../../../Components/SEOPricing/OnPagePricing/Index'
import SocialProof from '../../../../Components/SocialProof/Index'

const OnPageOptimization = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false)

    useEffect(() => {
        mixpanel.track("On-Page Optimiztaion Page Visited")
    }, [])

    return (
        <PageLayout>
            <SEOMetaHead
                title="Effective On-Page SEO Services - Boost Your Website's Performance"
                description="Improve your website's visibility and user experience with Gurulabs' on-page SEO optimization. Drive more organic traffic and enhance your site’s performance."
                convertKitPage="https://relentless-inventor-6062.ck.page/5a9ed0f555/index.js"
            />
            <section className="">
                <div className="max-w-7xl mx-auto py-8 lg:py-48 px-4">
                    <div className="flex flex-col lg:flex-row justify-between items-center">
                        <div className="w-full py-4 xl:w-8/12">
                            <h1 className="text-3xl lg:text-6xl my-1">Enhance your
                                <span className="text-green-500 relative mx-2">
                                    On-Page SEO
                                    <svg width="175" height="3" viewBox="0 0 175 3" fill="none" className="absolute hidden lg:block left-6">
                                        <path d="M1.43945 1.54395H173.903" stroke="#48BB78" strokeWidth="2" strokeLinecap="round" />
                                    </svg>
                                </span>
                                with Gurulabs' Proven Strategies</h1>
                            <p className="my-3">Gurulabs empowers your website to reach its full potential through expert On-Page Optimization. Our seasoned team utilizes advanced techniques to refine every element of your site, boosting search engine rankings and enhancing user experience.</p>
                            <LeadsForm />
                        </div>
                        <div className="w-full py-4 xl:w-4/12">
                            <img src="/frontend/images/svg/icon-22.svg" className="mx-auto" alt="National SEO By Gurulabs" />
                        </div>
                    </div>
                </div>
            </section>
            <Slider />
            <section>
                <div className="bg-gray-50 py-12">
                    <div className="max-w-7xl mx-auto">
                        <div className='block sm:flex justify-center items-center px-4'>
                            <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                <span className="relative">Come take a look at what plans</span>
                                <span className="text-green-500 ml-2 relative">
                                    we offer!
                                </span>
                                <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4 ml-16 hidden xl:block" width={151} height={3} fill="none"><path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                            </h2>
                        </div>
                        <OnPagePricing modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
                    </div>
                </div>
            </section>
            <SocialProof />
            <section>
                <div className="max-w-5xl mx-auto py-24">
                    <div className='block sm:flex sm:flex-col justify-center items-center px-4'>
                        <h2 className="block text-3xl sm:text-4xl py-5 relative">
                            <span className="relative">Our Tech-Enabled</span>
                            <span className="text-green-500 ml-2 relative">
                                On-Page SEO Optimization Process
                            </span>
                            <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-1/2 -mt-2 ml-16 hidden xl:block" width={238} height={17} fill="none"><path stroke="#48BB78" strokeLinecap="round" strokeWidth="2" d="M1.833 12.55c101.19 8.798 198.897-3.667 235.102-11" /></svg>
                        </h2>
                        <p className="text-base text-center">Our On-Page Optimization process ensures that your website is fully optimized to rank higher in search engines and provide a seamless user experience.</p>
                    </div>
                </div>
                <div className="max-w-7xl mx-auto pb-24">
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
                        <Process
                            title="Comprehensive SEO Audit"
                            description="We begin with a detailed audit of your website's current on-page elements to identify key areas for improvement. This includes analyzing content, meta tags, and overall site structure."
                            button="button-3"
                            Icon={SiGooglesearchconsole}
                        />
                        <Process
                            title="Keyword Research & Mapping"
                            description="We conduct in-depth keyword research to determine the best keywords for your pages. These keywords are then strategically mapped to specific pages to maximize relevance and searchability."
                            button="button-2"
                            Icon={SiGoogleanalytics}
                        />
                        <Process
                            title="Meta Tags Optimization"
                            description="Our experts optimize title tags, meta descriptions, and headers to ensure they are keyword-rich and aligned with your SEO goals, enhancing your visibility in search engine results."
                            button="button-3"
                            Icon={SiGoogle}
                        />
                        <Process
                            title="Content & Image Optimization"
                            description="We optimize your content for readability, keyword usage, and relevance while ensuring that all images are properly tagged, compressed, and optimized for faster loading times."
                            button="button-2"
                            Icon={SiHtml5}
                        />
                        <Process
                            title="Internal Linking Structure"
                            description="We refine your website’s internal linking structure, ensuring that all pages are easily accessible and that link equity is properly distributed, boosting overall site authority."
                            button="button-3"
                            Icon={LinkIcon}
                        />
                        <Process
                            title="Performance Monitoring & Reporting"
                            description="After optimization, we continually monitor the performance of your pages, tracking key metrics like bounce rate and user engagement. Regular reports provide insights into your on-page SEO progress."
                            button="button-2"
                            Icon={SiGoogleanalytics}
                        />
                    </div>
                </div>
            </section>
            <section>
                <div className="bg-gray-50 py-12">
                    <div className="max-w-7xl mx-auto">
                        <div>
                            <div className='block sm:flex justify-center items-center px-4'>
                                <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                    <span className="relative">Maximize Your Website's Potential with</span>
                                    <span className="text-green-500 ml-2 relative">
                                        On-Page Optimization
                                    </span>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4 ml-16 hidden xl:block" width={151} height={3} fill="none"><path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                                </h2>
                            </div>
                            <p className="py-4 text-lg text-center">Gurulabs' On-Page Optimization process enhances every element of your website to improve search engine rankings and boost user engagement.</p>
                        </div>
                        <div className="px-4">
                            <div>
                                <p className="text-xl py-4 text-gray-700">At Gurulabs, we understand that On-Page Optimization is a critical component of any successful SEO strategy. Our process is designed to ensure that every aspect of your website is finely tuned to meet search engine criteria while providing an exceptional user experience. By focusing on key on-page elements, we help your site rank higher, attract more traffic, and convert visitors into customers.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Gurulabs' On-Page Optimization Process</strong></p>

                                <p className="text-xl py-4 text-gray-700">Our On-Page Optimization process starts with a comprehensive audit of your website. We meticulously examine each page to identify areas of improvement, from content quality and keyword usage to technical factors like page load speed and mobile-friendliness. This audit forms the foundation of a targeted optimization strategy tailored to your business needs.</p>

                                <p className="text-xl py-4 text-gray-700">Next, we focus on keyword research and mapping. By identifying the most relevant and high-performing keywords for your industry, we ensure that your content is strategically aligned with what your target audience is searching for. Our team optimizes your title tags, meta descriptions, headers, and content to enhance relevance and boost rankings.</p>

                                <p className="text-xl py-4 text-gray-700">Content and image optimization is another critical aspect of our process. We refine your website’s content to make it both search-engine-friendly and engaging for users. This includes optimizing images with proper alt tags and compressing them for faster load times, ensuring that your site not only ranks well but also provides a seamless user experience.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>How Gurulabs Benefits Your Business</strong></p>

                                <p className="text-xl py-4 text-gray-700">Choosing Gurulabs for On-Page Optimization means partnering with a team of experts dedicated to maximizing your website’s performance. Our customized approach ensures that your site meets the latest SEO standards and delivers outstanding user satisfaction. By improving your site’s visibility and usability, we help drive organic traffic, increase conversions, and ultimately, boost your ROI.</p>

                                <p className="text-xl py-4 text-gray-700">Our expertise in On-Page Optimization covers all essential aspects, including internal linking, schema markup, and mobile optimization. We continuously monitor your site’s performance, making adjustments as needed to maintain optimal results. With Gurulabs, you can be confident that your On-Page SEO is in expert hands, allowing you to focus on growing your business.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Tailored Solutions for Your Unique Needs</strong></p>

                                <p className="text-xl py-4 text-gray-700">Every business is unique, and so are its SEO needs. At Gurulabs, we offer tailored On-Page Optimization solutions that address your specific challenges and goals. Whether you’re looking to improve your site’s content, enhance user engagement, or ensure technical excellence, our team provides the expertise and support you need to achieve lasting success in search engine rankings.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Partner with Gurulabs for Exceptional On-Page SEO</strong></p>

                                <p className="text-xl py-4 text-gray-700">Investing in On-Page Optimization with Gurulabs is a strategic move that can significantly elevate your website’s performance. Our comprehensive approach ensures that every element of your site is optimized for both search engines and users, delivering measurable improvements in traffic and conversions. Partner with Gurulabs today and take your on-page SEO to the next level.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <OnPagePricingTable />
            <OnPageFAQs />
            <section>
                <div className="bg-amber-50 hero-reviews py-12">
                    <div className="max-w-7xl mx-auto overflow-hidden">
                        <div className='block sm:flex justify-center items-center py-16 px-4'>
                            <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                <span className="relative">Hear from our Lead Gen</span>
                                <span className="text-green-500 ml-2 relative">
                                    Clients!
                                </span>
                                <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4  hidden xl:block" width={151} height={3} fill="none"><path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                            </h2>
                        </div>
                        <SearchEngineSlider />
                    </div>
                </div>
            </section>
            <CalendarModal isOpen={modalIsOpen} setIsOpen={setModalIsOpen} />
            {/* <Popup /> */}
        </PageLayout>
    )
}
export default OnPageOptimization
