import React, { useMemo } from 'react'
import { useTable } from 'react-table'
import MOCK_DATA from './MOCK_DATA.json'
import { COLUMNS } from './columns'

const EnterpriseSEOPricingTable = () => {
    const columns = useMemo(() => COLUMNS, [])
    const data = useMemo(() => MOCK_DATA, [])

    const tableInstance = useTable({ columns, data })
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance

    return (
        <section>
            <div className="max-w-7xl mx-auto py-24 overflow-hidden">
                <table id="pricing" className='min-w-full divide-y divide-gray-300' {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => {
                            const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps();
                            return (
                                <tr key={key} className="bg-green-25 text-xl" {...headerGroupProps}>
                                    {headerGroup.headers.map(column => {
                                        const { key, ...headerProps } = column.getHeaderProps();
                                        return (
                                            <th key={key} className="[&:not(:first-child)]:text-center px-3 py-3.5 text-left text-xl font-semibold text-gray-700" {...headerProps}>
                                                {column.render('Header')}
                                            </th>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map(row => {
                            prepareRow(row);
                            const { key, ...rowProps } = row.getRowProps();
                            return (
                                <tr key={key} className={row.index % 2 === 0 ? undefined : 'bg-green-25'} {...rowProps}>
                                    {row.cells.map(cell => {
                                        const { key, ...cellProps } = cell.getCellProps();
                                        return (
                                            <td key={key} className={`[&:not(:first-child)]:text-center whitespace-nowrap py-4 pl-4 pr-3 text-md border-b border-gray-50 font-medium text-gray-700 sm:pl-3`} {...cellProps}>
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>
    );
}

export default EnterpriseSEOPricingTable;
