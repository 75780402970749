import mixpanel from 'mixpanel-browser';
import React, { useEffect, useState } from 'react';
import { Link, usePage } from '@inertiajs/inertia-react';
import Slider from '../../../../Components/Slider/Index';
import PageLayout from '../../../../Layout/PageLayout/Index';
import Newsletter from '../../../../Components/Newsletter/Index';
import PPCMetaHead from '../../../../Components/PPCMetaHead/Index';
import PaidAdsLeadsForm from '../../../../Layout/PaidAdsLeadsForm/Index';
import PaidAdsPricing from '../../../../Components/PaidAdsPricing/Index';
import PaidAdsPricingTable from '../../../../Components/PaidAdsPricingTable/Index';
import Process from '../../../../Components/Process/Index';
import SocialProof from '../../../../Components/SocialProof/Index';
import PPCFAQs from '../../../../Components/FAQs/PPCFAQs/Index';
import { SiFacebook, SiInstagram, SiLinkedin, SiTiktok, SiYoutube, SiTwitter } from '@icons-pack/react-simple-icons';


// More imports as required...

const ClevelandTNPPCPage = ({ listings }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const { city, state, cities } = usePage().props;
    const [weather, setWeather] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        mixpanel.track(`${city.name} Page Visited`)

    }, []);

    return (
        <PageLayout>
            <PPCMetaHead
                    title={`${city.name} PPC Agency | Pay-Per-Click Services in ${state.code} | Gurulabs`}
                    description={`Get top-notch PPC services in ${city.name}, ${state.code} with Gurulabs. Contact us for expert PPC management to grow your business.`}
             />
           <section className="max-w-7xl mx-auto overflow-hidden">
                <div className="">
                    <div className='py-4'>
                        <h2 className="py-4 text-3xl lg:text-6xl text-center my-1">Affordable
                            <span className="text-green-500 relative mx-2">
                                Pay Per Click
                                <svg width="175" height="3" viewBox="0 0 175 3" fill="none" className="absolute hidden lg:block left-6">
                                    <path d="M1.43945 1.54395H173.903" stroke="#48BB78" strokeWidth="2" strokeLinecap="round" />
                                </svg>
                            </span>
                            Management Agency in {city.name}, {state.code}</h2>
                    </div>
                    <div className='py-4' >
                        {
                            isMobile ? (
                                <div className='grid grid-cols-1 lg:grid-cols-2'>
                                    <div className="px-4 w-[400px] h-[720px] mx-auto aspect-w-16 aspect-h-9">
                                        <video autoPlay loop muted controls playsInline className="mx-auto">
                                            <source src="/frontend/videos/intro.mp4" type="video/mp4" />
                                        </video>
                                    </div>
                                    <PaidAdsLeadsForm />
                                </div>
                            ) : (
                                <div className='grid grid-cols-1 lg:grid-cols-2'>
                                    <div className="px-4 w-[400px] h-[720px] mx-auto aspect-w-16 aspect-h-9">
                                        <video autoPlay loop muted controls playsInline className="mx-auto">
                                            <source src="/frontend/videos/intro.mp4" type="video/mp4" />
                                        </video>
                                    </div>
                                    <PaidAdsLeadsForm />
                                </div>
                            )
                        }

                    </div>
                </div>
            </section>
            <Slider />
            <section>
                <div className="bg-gray-50 py-12">
                    <div className="max-w-7xl mx-auto">
                        <div className="block sm:flex justify-center items-center px-4">
                            <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                <span className="relative">Explore Our Service Plans</span>
                                <span className="text-green-500 ml-2 relative">in {city.name}, {state.code}!</span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="absolute left-3/4 ml-16 hidden xl:block"
                                    width={151}
                                    height={3}
                                    fill="none"
                                >
                                    <path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" />
                                </svg>
                            </h2>
                        </div>
                        <PaidAdsPricing modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} city={city.name} state={state.code} />
                    </div>
                </div>
            </section>
            <section>
                <div className="max-w-5xl mx-auto py-24">
                    <div className="block sm:flex sm:flex-col justify-center items-center px-4">
                        <h2 className="block text-3xl sm:text-4xl py-5 relative">
                            <span className="relative">Tech-Enabled</span>
                            <span className="text-green-500 ml-2 relative">Social Media Ads Management</span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="absolute left-1/2 -mt-2 ml-16 hidden xl:block"
                                width={238}
                                height={17}
                                fill="none"
                            >
                                <path stroke="#48BB78" strokeLinecap="round" strokeWidth="2" d="M1.833 12.55c101.19 8.798 198.897-3.667 235.102-11" />
                            </svg>
                        </h2>
                        <p className="text-base text-center">
                            Generate quality leads in {city.name} with our expert social media ads management services.
                        </p>
                    </div>
                </div>
                <div className="max-w-7xl mx-auto pb-24 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    <Process
                        title="Facebook Ads Management"
                        description={`In the vibrant city of ${city.name}, ${state.name}, our Facebook Ads management services are designed to engage the dynamic local community. Utilizing advanced targeting, we tap into the city's diverse interests—from music and arts to tech innovations—ensuring your ads reach and resonate with the ideal audience.`}
                        button="button-3"
                        Icon={SiFacebook}
                    />
                    <Process
                        title="LinkedIn Ads Management"
                        description={`Leverage LinkedIn Ads with Gurulabs in ${city.name}, ${state.name}, to connect with the city's bustling professional scene. Our campaigns target key decision-makers in major local industries, from tech startups to established corporations, driving high-quality leads and fostering valuable B2B relationships.`}
                        button="button-2"
                        Icon={SiLinkedin}
                    />
                    <Process
                        title="YouTube Ads Management"
                        description={`Gurulabs offers expert YouTube Ads management in ${city.name}, ${state.name}, tailored to captivate the city's tech-savvy and creative populace. We create engaging video content and strategic placements that convert viewers into customers, enhancing your brand's visibility in this innovative city.`}
                        button="button-3"
                        Icon={SiYoutube}
                    />
                    <Process
                        title="Twitter (X) Ads Management"
                        description={`Our Twitter Ads management services in ${city.name}, ${state.name}, harness the platform’s real-time capabilities to create impactful campaigns. By tapping into local trends and conversations, we increase your brand’s visibility and drive traffic, engaging the city's active social media users with data-driven strategies.`}
                        button="button-2"
                        Icon={SiTwitter}
                    />
                    <Process
                        title="Instagram Ads Management"
                        description={`Choose Gurulabs for Instagram Ads management in ${city.name}, ${state.name}, and benefit from visually stunning ads that capture the city's vibrant culture. From scenic landscapes to bustling urban scenes, our precise targeting ensures significant engagement and business growth within this lively community.`}
                        button="button-3"
                        Icon={SiInstagram}
                    />
                    <Process
                        title="TikTok Ads Management"
                        description={`Tap into TikTok’s dynamic audience with our ads management in ${city.name}, ${state.name}. Our team creates shareable and entertaining content that captures the essence of the city’s youthful energy and creativity, maximizing visibility and engagement for your brand.`}
                        button="button-2"
                        Icon={SiTiktok}
                    />
                </div>
            </section>
            <section className="bg-gray-50 py-12 px-4">
                <div className="max-w-7xl mx-auto overflow-hidden">
                    <div className="text-xl py-4 text-gray-700 prose prose-lg">
                        <h1>Cleveland, Tennessee: Unlocking the Potential with PPC Advertising</h1>

<h2>Discovering Cleveland</h2>

<p>Cleveland, nestled in the heart of Tennessee, is a city brimming with history, culture, and economic vitality. Known for its charming small-town feel combined with urban amenities, Cleveland offers a unique blend of Southern hospitality and modern opportunities. As businesses in Cleveland seek to expand their reach and connect with their target audience in the digital realm, the power of Pay-Per-Click (PPC) advertising emerges as a key tool to drive growth and success.</p>

<h2>Unveiling Historic Roots</h2>

<p>Founded in 1837, Cleveland has a rich historical background that is reflected in its architecture, landmarks, and community spirit. The city played a pivotal role in the Civil War, hosting significant events that shaped its identity. Today, historic sites such as the Red Clay State Historic Park and the Museum Center at 5ive Points serve as reminders of Cleveland's past, attracting both residents and visitors keen on exploring the city's heritage.</p>

<h2>Cultural Vibrancy in Cleveland</h2>

<p>Embracing a diverse cultural tapestry, Cleveland offers a range of arts, entertainment, and gastronomic experiences. The city boasts a thriving arts scene, with galleries, theaters, and cultural events that celebrate local talent and creativity. From annual festivals like the Apple Festival to the iconic MainStreet Cruise-In, Cleveland provides a platform for cultural exchange and community engagement.</p>

<h2>PPC Advertising: A Gateway to Success</h2>

<p>For businesses in Cleveland looking to enhance their online presence and drive targeted traffic, PPC advertising presents a strategic opportunity to reach potential customers effectively. By leveraging platforms like Google Ads and Bing Ads, companies can showcase their products and services to a relevant audience, maximizing visibility and engagement. The precision targeting and measurable results of PPC campaigns make it a cost-effective and efficient marketing tool for businesses of all sizes in Cleveland.</p>

<h2>Benefits of PPC Advertising in Cleveland</h2>

<ul>
    <li><strong>Targeted Reach:</strong> With PPC advertising, businesses in Cleveland can reach specific demographics, locations, and interests, ensuring that their ads are seen by the right audience.</li>
    <li><strong>Immediate Impact:</strong> PPC campaigns generate instant visibility, driving traffic to websites and increasing leads and conversions in a short timeframe.</li>
    <li><strong>Measurable Results:</strong> By tracking key performance indicators such as clicks, impressions, and conversions, businesses in Cleveland can gauge the effectiveness of their PPC campaigns and optimize them for better outcomes.</li>
    <li><strong>Competitive Advantage:</strong> In a competitive market like Cleveland, PPC advertising allows businesses to stay ahead of the curve by outbidding competitors for ad placements and targeting niche keywords for maximum impact.</li>
    <li><strong>Cost-Effective Solution:</strong> Unlike traditional advertising methods, PPC advertising in Cleveland offers a pay-per-click model, where businesses only pay when users interact with their ads, ensuring a high return on investment.</li>
</ul>

<h2>The Local Business Landscape in Cleveland</h2>

<p>Cleveland's business environment is characterized by a mix of industries, including manufacturing, healthcare, education, and retail. With a growing population and a thriving economy, the city presents ample opportunities for businesses to thrive and expand. Local establishments, from family-owned shops to emerging startups, form the backbone of Cleveland's commercial landscape, contributing to its vibrancy and economic resilience.</p>

<h2>Understanding the Target Audience</h2>

<p>In a city like Cleveland, understanding the target audience is crucial for developing effective PPC campaigns that resonate with potential customers. Demographically diverse, Cleveland's residents span various age groups, occupations, and interests, making it essential for businesses to tailor their messaging and ad content to align with the preferences and needs of their target audience. By conducting thorough market research and audience analysis, businesses in Cleveland can create compelling PPC campaigns that capture the attention and engagement of their potential customers.</p>

<h2>Embrace the Power of PPC in Cleveland</h2>

<p>As Cleveland continues to evolve as a dynamic hub of opportunity and innovation, businesses can harness the power of PPC advertising to propel their growth and success in the digital realm. By capitalizing on the benefits of targeted reach, immediate impact, and measurable results offered by PPC campaigns, businesses in Cleveland can elevate their online visibility, attract new customers, and drive revenue growth effectively. With a strategic approach to PPC advertising and a deep understanding of the local market dynamics, businesses in Cleveland can unlock new possibilities and achieve sustainable growth in today's competitive landscape.</p>
                    </div>
                </div>
            </section>
            {listings && listings.length > 0 ? (
                <section>
                    <div className="max-w-7xl mx-auto">
                        <div className="max-w-5xl mx-auto py-24">
                            <div className="block sm:flex sm:flex-col justify-center items-center px-4">
                                <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                    <span className="relative">We support small business</span>
                                    <span className="text-green-500 ml-2 relative">near {city.name}, {state.code}</span>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="absolute left-1/2 -mt-2 ml-16 hidden xl:block"
                                        width={238}
                                        height={17}
                                        fill="none"
                                    >
                                        <path stroke="#48BB78" strokeLinecap="round" strokeWidth="2" d="M1.833 12.55c101.19 8.798 198.897-3.667 235.102-11" />
                                    </svg>
                                </h2>
                                <p className="text-base text-center">
                                    Generate quality leads in {city.name} with our expert social media ads management services.
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-wrap justify-center items-center">
                            {listings
                                .filter(listing => listing.website && listing.phone) // Only show listings with a valid image_url
                                .map((listing, index) => (
                                        <div key={listing.id || index} className="w-full sm:w-1/2 lg:w-1/3 px-3 mb-6">
                                            <div className="p-4 max-w-md rounded-lg overflow-hidden shadow-lg bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out">
                                                <div className="p-4">
                                                    <h2 className="font-bold text-2xl mb-4 text-gray-800 whitespace-nowrap overflow-hidden text-ellipsis">{listing.name}</h2>
                                                    <div className="mb-4 text-gray-600">
                                                        <p>{listing.address}</p>
                                                        <p>{listing.city}, {listing.state} {listing.zip}</p>
                                                        <p>{listing.country || ''}</p>
                                                    </div>
                                                    <p className="text-gray-600 text-md">{listing.category}</p>
                                                    <p className="text-gray-600 text-md">Rating: {listing.rating || 'No rating available'} ⭐</p>
                                                    <p className="text-gray-600 text-md pb-4">Phone:  <a href={`tel:${listing.phone}`} className="text-green-600 hover:underline mb-4">
                                                        {listing.phone}
                                                    </a>
                                                    </p>
                                                    {listing.website && (
                                                        <a
                                                            href={`${listing.website.includes('http') ? listing.website : `https://${listing.website}`}?utm_source=gurulabs&utm_medium=referral&utm_campaign=${city.name}-${state.code}-ppc`}
                                                            className="relative w-full py-2 px-3 top-1 right-2 button rounded-sm text-gray-700 hover:bg-green-500"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            Visit Website
                                                        </a>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                ))
                            }
                        </div>
                    </div>
                </section>
            ) :

                (
                    <>
                    </>
                )}
            <section>
                <div className="bg-amber-50 py-12">
                    <div className="max-w-7xl mx-auto">
                        <div>
                            <div className='block sm:flex justify-center items-center px-4'>
                                <h1 className="block text-3xl sm:text-4xl py-5 relative">
                                    <span className="relative">Cities We Serve in {state.name}</span>
                                    <span className="text-green-500 ml-2 relative">
                                        for PPC Services
                                    </span>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4 ml-16 hidden xl:block" width={151} height={3} fill="none"><path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                                </h1>
                            </div>
                            <div className="px-4">
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                    {cities
                                        .sort(() => Math.random() - 0.5) // Shuffle the array
                                        .slice(0, 21) // Select the first 21 cities after shuffling
                                        .map((city) => (
                                            <Link key={city.id} href={`/ppc/${state.slug}/${city.slug}`} className="text-green-500">
                                                Pay Per Click Management near {city.name}
                                            </Link>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <PaidAdsPricingTable city={city.name} state={state.code} />
            <Newsletter />
            <section>
                <div className="bg-gray-50 py-12">
                    <div className="max-w-7xl mx-auto">
                        <div className="block sm:flex justify-center items-center px-4">
                            <h1 className="block text-3xl sm:text-4xl py-5 relative">
                                <span className="relative">Your Reliable Partner for </span>
                                <span className="text-green-500 ml-2 relative">
                                    PPC Management in {city.name}, {state.code}
                                </span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="absolute left-3/4 ml-16 hidden xl:block"
                                    width={151}
                                    height={3}
                                    fill="none"
                                >
                                    <path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" />
                                </svg>
                            </h1>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 px-4">
                            <div>
                                <p className="text-xl py-4 text-gray-700">
                                    <strong>Real Success Stories from {city.name}</strong>
                                </p>
                                <p className="text-xl py-4 text-gray-700">
                                    Our PPC strategies have driven significant growth for businesses in {city.name}. We've helped a local retailer increase sales by 50% in three months and a service provider double their client base with targeted ad campaigns.
                                </p>
                                <p className="text-xl py-4 text-gray-700">
                                    A tech startup saw a 70% reduction in their cost per acquisition while scaling their campaigns. Our data-driven approach and continuous optimization ensure sustainable growth and profitability.
                                </p>
                                <p className="text-xl py-4 text-gray-700">
                                    These success stories highlight our commitment to delivering measurable results. Whether you're a small business or a growing enterprise, our PPC strategies are designed to help you thrive in {city.name}.
                                </p>
                            </div>
                            <div>
                                <p className="text-xl py-4 text-gray-700">
                                    <strong>Commitment to Excellence</strong>
                                </p>
                                <p className="text-xl py-4 text-gray-700">
                                    Transparency, integrity, and exceptional results are the pillars of our PPC management. We provide detailed reports and regular updates, ensuring you understand the impact of every dollar spent.
                                </p>
                                <p className="text-xl py-4 text-gray-700">
                                    Our team is dedicated to understanding your business and creating PPC strategies that contribute to your overall growth. We focus on long-term success through continuous optimization and strategic adjustments.
                                </p>
                                <p className="text-xl py-4 text-gray-700">
                                    Experience the difference with a partner dedicated to driving your success in {city.name}. Our local expertise and industry knowledge ensure highly targeted campaigns that resonate with your audience.
                                </p>
                                <p className="text-xl py-4 text-gray-700">
                                    By choosing us, you are choosing a partner invested in your success. We are passionate about helping businesses in {city.name} thrive and are here to support you every step of the way.
                                </p>
                                <p className="text-xl py-4 text-gray-700">
                                    Discover more about our <a href="https://www.gurulabs.dev/paid-ads" className="font-bold underline">Paid Ads services</a> and how they can benefit your business in {city.name}, {state.code}.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <SocialProof city={city.name} state={state.code} />
            <PPCFAQs city={city.name} state={state.code} />
            {/* Rest of the JSX */}
        </PageLayout>
    );
};

export default ClevelandTNPPCPage;