import React, { useState } from 'react';
import PageLayout from '../../../Layout/PageLayout/Index';
import MetaHead from '../../../Components/MetaHead';
import LeadsForm from '../../../Components/LeadsForm/Index';
import Slider from '../../../Components/Slider/Index';
import PaidAdsPricing from '../../../Components/PaidAdsPricing/Index';
import PaidAdsPricingTable from '../../../Components/PaidAdsPricingTable/Index';
import Process from '../../../Components/Process/Index';
import CalendarModal from '../../../Layout/CalendarModal/Index';
import LeadsModal from '../../../Layout/LeadsModal/Index';
import CommerceSlider from '../../../Components/CommerceSlider/Index';
import { SiFacebook, SiInstagram, SiLinkedin, SiTiktok, SiYoutube, SiTwitter } from '@icons-pack/react-simple-icons';
import SocialProof from '../../../Components/SocialProof/Index';
import PPCFAQs from '../../../Components/FAQs/PPCFAQs/Index';
import { usePage } from '@inertiajs/inertia-react';
import Newsletter from '../../../Components/Newsletter/Index';

const CitySEOPage = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const { city, state } = usePage().props;


    return (
        <PageLayout>
            <MetaHead
                title={`${city.name} PPC Agency | Pay-Per-Click Services in ${state.code} | Gurulabs`}
                description={`${city.name}'s top PPC agency, Gurulabs, offers expert PPC management services to help businesses grow. Contact us today for a free consultation!`}
            />
            <section className="max-w-7xl mx-auto overflow-hidden">
                <div className="">
                    <div>
                        <h2 className="py-4 text-3xl lg:text-6xl text-center my-1">Affordable
                            <span className="text-green-500 relative mx-2">
                                Pay Per Click
                                <svg width="175" height="3" viewBox="0 0 175 3" fill="none" className="absolute hidden lg:block left-6">
                                    <path d="M1.43945 1.54395H173.903" stroke="#48BB78" strokeWidth="2" strokeLinecap="round" />
                                </svg>
                            </span>
                            Management Agency in {city.name}, {state.code}</h2>
                    </div>
                    <div className="px-4 w-[400px] h-[720px] mx-auto aspect-w-16 aspect-h-9">
                        <video autoPlay loop muted controls playsInline className="mx-auto">
                            <source src="/frontend/videos/Ads.m4v" type="video/mp4" />
                        </video>
                    </div>
                    <div className="py-8 text-center">
                        {
                            isMobile ? (
                                <Link
                                    onClick={() => {
                                        mixpanel.track("Get Started Mobile button clicked");
                                    }}
                                    href='/get-started'
                                    className="relative py-4 px-4 top-1 right-2 button rounded-sm text-gray-700 hover:bg-green-700 transition duration-300 ease-in-out"
                                >
                                    <span className="text-xl">Get Started Now</span>
                                </Link>
                            ) : (
                                <button
                                    onClick={() => {
                                        setIsOpen(!isOpen);
                                        mixpanel.track("Get Started button clicked");
                                    }}
                                    className="relative py-4 px-4 top-1 right-2 button rounded-sm text-gray-700 hover:bg-green-700 transition duration-300 ease-in-out"
                                >
                                    <span className="text-xl">Get Started Now</span>
                                </button>
                            )
                        }
                    </div>
                </div>
            </section>
            <Slider city={city.name} state={state.code} />
            <section>
                <div className="bg-gray-50 py-12">
                    <div className="max-w-7xl mx-auto">
                        <div className="block sm:flex justify-center items-center px-4">
                            <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                <span className="relative">Come take a look at what service plans</span>
                                <span className="text-green-500 ml-2 relative">we offer in {city.name}!</span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="absolute left-3/4 ml-16 hidden xl:block"
                                    width={151}
                                    height={3}
                                    fill="none"
                                >
                                    <path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" />
                                </svg>
                            </h2>
                        </div>
                        <PaidAdsPricing modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} city={city.name} state={state.code} />
                    </div>
                </div>
            </section>
            <SocialProof city={city.name} state={state.code} />
            <section>
                <div className="max-w-5xl mx-auto py-24">
                    <div className="block sm:flex sm:flex-col justify-center items-center px-4">
                        <h2 className="block text-3xl sm:text-4xl py-5 relative">
                            <span className="relative">Our Tech-Enabled</span>
                            <span className="text-green-500 ml-2 relative">Social Media Ads Management</span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="absolute left-1/2 -mt-2 ml-16 hidden xl:block"
                                width={238}
                                height={17}
                                fill="none"
                            >
                                <path stroke="#48BB78" strokeLinecap="round" strokeWidth="2" d="M1.833 12.55c101.19 8.798 198.897-3.667 235.102-11" />
                            </svg>
                        </h2>
                        <p className="text-base text-center">
                            Our Lead generation process in {city.name} helps you identify potential customers or clients and gather their contact information to nurture them toward a sale.
                        </p>
                    </div>
                </div>
                <div className="max-w-7xl mx-auto pb-24">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                        <Process
                            title="Facebook Ads Management"
                            description={`Choosing Gurulabs for your Facebook Ads management in ${city.name}, ${state.name} ensures that your campaigns are optimized for the highest engagement and conversion rates. Our expert team leverages advanced targeting options, compelling ad creatives, and data-driven strategies to reach your ideal audience, drive meaningful interactions, and maximize your return on investment. With Gurulabs, your Facebook advertising will be managed by professionals dedicated to achieving measurable results and expanding your brand’s reach in ${state.code}.`}
                            button="button-3"
                            Icon={SiFacebook}
                        />
                        <Process
                            title="LinkedIn Ads Management"
                            description={`Gurulabs is the ideal choice for LinkedIn Ads management in ${city.name}, ${state.name}, as we specialize in creating professional and highly-targeted campaigns that connect your business with key decision-makers and industry professionals. Our team understands the unique nuances of LinkedIn advertising, from Sponsored Content to InMail, and uses this expertise to drive high-quality leads and build valuable B2B relationships. Trust Gurulabs to enhance your LinkedIn presence and achieve your marketing objectives effectively in ${state.code}.`}
                            button="button-2"
                            Icon={SiLinkedin}
                        />
                        <Process
                            title="YouTube Ads Management"
                            description={`For YouTube Ads management in ${city.name}, ${state.name}, Gurulabs offers unparalleled expertise in creating captivating video content and strategic placements that engage viewers and convert them into customers. We utilize comprehensive analytics and audience insights to optimize your campaigns, ensuring your videos reach the right people at the right time. By partnering with Gurulabs, you’ll benefit from a team committed to maximizing your brand’s visibility and driving substantial growth through YouTube advertising in ${state.code}.`}
                            button="button-3"
                            Icon={SiYoutube}
                        />
                        <Process
                            title="Twitter (X) Ads Management"
                            description={`Gurulabs stands out as the premier choice for Twitter (X) Ads management in ${city.name}, ${state.name} by harnessing the platform’s real-time nature to create impactful ad campaigns that resonate with your target audience. Our specialists craft engaging Promoted Tweets, Trends, and Accounts to increase your brand’s visibility, foster engagement, and drive traffic to your website. With Gurulabs, you’ll experience a data-driven approach that ensures your Twitter advertising delivers outstanding results and enhances your online presence in ${state.code}.`}
                            button="button-2"
                            Icon={SiTwitter}
                        />
                        <Process
                            title="Instagram Ads Management"
                            description={`Selecting Gurulabs for Instagram Ads management in ${city.name}, ${state.name} means entrusting your campaigns to a team that excels in leveraging the platform’s visual appeal to captivate and convert your audience. We develop visually stunning ads and stories that align with your brand’s aesthetic, combined with precise targeting to reach the most relevant users. Our expertise in Instagram’s unique advertising features ensures that your campaigns generate significant engagement and drive measurable growth for your business in ${state.code}.`}
                            button="button-3"
                            Icon={SiInstagram}
                        />
                        <Process
                            title="TikTok Ads Management"
                            description={`Gurulabs is the perfect partner for TikTok Ads management in ${city.name}, ${state.name}, offering innovative strategies to tap into the platform’s rapidly growing user base. Our team creates entertaining and shareable ad content that captures the essence of your brand and resonates with TikTok’s dynamic audience. By choosing Gurulabs, you’ll benefit from our deep understanding of TikTok’s algorithm and trends, ensuring your ads achieve maximum visibility and engagement, ultimately driving conversions and brand loyalty in ${state.code}.`}
                            button="button-2"
                            Icon={SiTiktok}
                        />
                    </div>

                </div>
            </section>
            <section>
                <div className="bg-gray-50 py-12">
                    <div className="max-w-7xl mx-auto">
                        <div>
                            <div className="block sm:flex justify-center items-center px-4">
                                <h1 className="block text-3xl sm:text-4xl py-5 relative">
                                    <span className="relative">We're your trusted partner </span>
                                    <span className="text-green-500 ml-2 relative">
                                        PPC Management in {city.name}, {state.code}
                                    </span>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="absolute left-3/4 ml-16 hidden xl:block"
                                        width={151}
                                        height={3}
                                        fill="none"
                                    >
                                        <path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" />
                                    </svg>
                                </h1>
                            </div>
                        </div>
                        <div className="px-4">
                            <div>
                                <p className="text-xl py-4 text-gray-700">
                                    <strong>Unlock the Full Potential of PPC Advertising</strong>
                                </p>
                                <p className="text-xl py-4 text-gray-700">
                                    {city.name}, known for its vibrant economy and rich history, offers a unique landscape for businesses to flourish. By leveraging cutting-edge Pay-Per-Click (PPC) strategies, your business can achieve unparalleled visibility and growth in this competitive market.
                                </p>

                                <p className="text-xl py-4 text-gray-700">
                                    <strong>Why Our PPC Services Stand Out</strong>
                                </p>
                                <p className="text-xl py-4 text-gray-700">
                                    Choosing our services means gaining a partner dedicated to your success. Our comprehensive PPC solutions are meticulously crafted to align with your business objectives. Through targeted campaigns and continuous optimization, we ensure your advertising budget is used efficiently, driving high-quality traffic and maximizing ROI.
                                </p>

                                <p className="text-xl py-4 text-gray-700">
                                    <strong>Our Proven Process</strong>
                                </p>
                                <p className="text-xl py-4 text-gray-700">
                                    From initial consultation to final execution, our process is designed to deliver results. We start with an in-depth understanding of your business needs, followed by thorough keyword research and campaign setup. Continuous monitoring and refinement guarantee your ads perform at their best, while regular updates keep you informed of your progress.
                                </p>

                                <p className="text-xl py-4 text-gray-700">
                                    <strong>Success Stories from {city.name}</strong>
                                </p>
                                <p className="text-xl py-4 text-gray-700">
                                    Businesses in {city.name} have witnessed extraordinary growth with our tailored PPC strategies. Imagine surges in organic traffic by over 600% and increases in online leads by more than 300%. Our focus on conversion rate optimization and high-quality leads has transformed the digital presence of many local businesses.
                                </p>

                                <p className="text-xl py-4 text-gray-700">
                                    <strong>Commitment to Excellence</strong>
                                </p>
                                <p className="text-xl py-4 text-gray-700">
                                    We pride ourselves on transparency, integrity, and delivering exceptional results. Our team works tirelessly to ensure your PPC campaigns are not only effective but also aligned with your long-term business goals. Experience the difference with a partner dedicated to driving your success in the dynamic {city.name} market.
                                </p>

                                <p className="text-xl py-4 text-gray-700">
                                    Discover more about our <a href="https://www.gurulabs.dev/paid-ads" className="font-bold underline">Paid Ads services</a> and how they can benefit your business in {city.name}, {state.code}.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <PaidAdsPricingTable city={city.name} state={state.code} />
            <Newsletter />
            <PPCFAQs city={city.name} state={state.code} />
            <section>
                <div className="bg-amber-50 hero-reviews py-12">
                    <div className="max-w-7xl mx-auto overflow-hidden">
                        <div className="block sm:flex justify-center items-center py-16 px-4">
                            <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                <span className="relative">Hear from our Lead Gen</span>
                                <span className="text-green-500 ml-2 relative">Clients in {city.name}!</span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="absolute left-3/4 hidden xl:block"
                                    width={151}
                                    height={3}
                                    fill="none"
                                >
                                    <path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" />
                                </svg>
                            </h2>
                        </div>
                        <CommerceSlider city={city.name} state={state.code} />
                    </div>
                </div>
            </section>
            <LeadsModal isOpen={isOpen} setIsOpen={setIsOpen} />
            <CalendarModal isOpen={modalIsOpen} setIsOpen={setModalIsOpen} />
        </PageLayout>
    );
};

export default CitySEOPage;
