import React from 'react'
import MetaHead from "../../Components/MetaHead"
import PageLayout from "../../Layout/PageLayout/Index"

const CoursePage = () => {
    return (
        <PageLayout>
            <MetaHead title="Courses - Digital Marketing Courses | Gurulabs" description="Data Analytics - Data Transformation Agency | Gurulabs" />
            <section className="bghero">
                <div className="max-w-7xl mx-auto">
                    <div className="py-16 pl-8 block sm:flex items-center justify-center">
                        <div className="w-full sm:w-1/2 mb-4 sm:mb-0">
                            <h1 className="text-2xl md:text-4xl lg:text-6xl text-white">Gurulabs Courses</h1>
                        </div>
                        <div className="w-full sm:w-1/2">
                            <img src="/frontend/images/webp/info.webp" alt="Courses" className="mx-auto" />
                        </div>
                    </div>
                    <div className="flex items-center justify-center">
                        <input type="text" name="website" required className="py-3 px-3 border border-gray-300 rounded-md w-full lg:w-8/12 py-4 mr-1" placeholder="Search for a course" id="" />
                        <button className="text-xl relative inline-flex justify-center items-center py-3 px-3 button rounted-sm text-white hover:bg-green-500 w-full lg:w-3/12">Search</button>
                    </div>
                </div>
            </section>
        </PageLayout>
    )
}
export default CoursePage
