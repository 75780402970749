import mixpanel from 'mixpanel-browser';
import React, { useEffect, useState } from 'react';
import { Link, usePage } from '@inertiajs/inertia-react';
import Slider from '../../../../Components/Slider/Index';
import PageLayout from '../../../../Layout/PageLayout/Index';
import Newsletter from '../../../../Components/Newsletter/Index';
import PPCMetaHead from '../../../../Components/PPCMetaHead/Index';
import PaidAdsLeadsForm from '../../../../Layout/PaidAdsLeadsForm/Index';
import PaidAdsPricing from '../../../../Components/PaidAdsPricing/Index';
import PaidAdsPricingTable from '../../../../Components/PaidAdsPricingTable/Index';
import Process from '../../../../Components/Process/Index';
import SocialProof from '../../../../Components/SocialProof/Index';
import PPCFAQs from '../../../../Components/FAQs/PPCFAQs/Index';
import { SiFacebook, SiInstagram, SiLinkedin, SiTiktok, SiYoutube, SiTwitter } from '@icons-pack/react-simple-icons';


// More imports as required...

const AnchorageAKPPCPage = ({ listings }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const { city, state, cities } = usePage().props;
    const [weather, setWeather] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        mixpanel.track(`${city.name} Page Visited`)

    }, []);

    return (
        <PageLayout>
            <PPCMetaHead
                    title={`${city.name} PPC Agency | Pay-Per-Click Services in ${state.code} | Gurulabs`}
                    description={`Get top-notch PPC services in ${city.name}, ${state.code} with Gurulabs. Contact us for expert PPC management to grow your business.`}
             />
           <section className="max-w-7xl mx-auto overflow-hidden">
                <div className="">
                    <div className='py-4'>
                        <h1 className="py-4 text-3xl lg:text-6xl text-center my-1">Affordable
                            <span className="text-green-500 relative mx-2">
                                Pay Per Click
                                <svg width="175" height="3" viewBox="0 0 175 3" fill="none" className="absolute hidden lg:block left-6">
                                    <path d="M1.43945 1.54395H173.903" stroke="#48BB78" strokeWidth="2" strokeLinecap="round" />
                                </svg>
                            </span>
                            Management Agency in {city.name}, {state.code}</h1>
                    </div>
                    <div className='py-4' >
                        {
                            isMobile ? (
                                <div className='grid grid-cols-1 lg:grid-cols-2'>
                                    <div className="px-4 w-[400px] h-[720px] mx-auto aspect-w-16 aspect-h-9">
                                        <video autoPlay loop muted controls playsInline className="mx-auto">
                                            <source src="/frontend/videos/intro.mp4" type="video/mp4" />
                                        </video>
                                    </div>
                                    <PaidAdsLeadsForm />
                                </div>
                            ) : (
                                <div className='grid grid-cols-1 lg:grid-cols-2'>
                                    <div className="px-4 w-[400px] h-[720px] mx-auto aspect-w-16 aspect-h-9">
                                        <video autoPlay loop muted controls playsInline className="mx-auto">
                                            <source src="/frontend/videos/intro.mp4" type="video/mp4" />
                                        </video>
                                    </div>
                                    <PaidAdsLeadsForm />
                                </div>
                            )
                        }

                    </div>
                </div>
            </section>
            <Slider />
            <section>
                <div className="bg-gray-50 py-12">
                    <div className="max-w-7xl mx-auto">
                        <div className="block sm:flex justify-center items-center px-4">
                            <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                <span className="relative">Explore Our Service Plans</span>
                                <span className="text-green-500 ml-2 relative">in {city.name}, {state.code}!</span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="absolute left-3/4 ml-16 hidden xl:block"
                                    width={151}
                                    height={3}
                                    fill="none"
                                >
                                    <path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" />
                                </svg>
                            </h2>
                        </div>
                        <PaidAdsPricing modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} city={city.name} state={state.code} />
                    </div>
                </div>
            </section>
            <section>
                <div className="max-w-5xl mx-auto py-24">
                    <div className="block sm:flex sm:flex-col justify-center items-center px-4">
                        <h2 className="block text-3xl sm:text-4xl py-5 relative">
                            <span className="relative">Tech-Enabled</span>
                            <span className="text-green-500 ml-2 relative">Social Media Ads Management</span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="absolute left-1/2 -mt-2 ml-16 hidden xl:block"
                                width={238}
                                height={17}
                                fill="none"
                            >
                                <path stroke="#48BB78" strokeLinecap="round" strokeWidth="2" d="M1.833 12.55c101.19 8.798 198.897-3.667 235.102-11" />
                            </svg>
                        </h2>
                        <p className="text-base text-center">
                            Generate quality leads in {city.name} with our expert social media ads management services.
                        </p>
                    </div>
                </div>
                <div className="max-w-7xl mx-auto pb-24 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    <Process
                        title="Facebook Ads Management"
                        description={`In the vibrant city of ${city.name}, ${state.name}, our Facebook Ads management services are designed to engage the dynamic local community. Utilizing advanced targeting, we tap into the city's diverse interests—from music and arts to tech innovations—ensuring your ads reach and resonate with the ideal audience.`}
                        button="button-3"
                        Icon={SiFacebook}
                    />
                    <Process
                        title="LinkedIn Ads Management"
                        description={`Leverage LinkedIn Ads with Gurulabs in ${city.name}, ${state.name}, to connect with the city's bustling professional scene. Our campaigns target key decision-makers in major local industries, from tech startups to established corporations, driving high-quality leads and fostering valuable B2B relationships.`}
                        button="button-2"
                        Icon={SiLinkedin}
                    />
                    <Process
                        title="YouTube Ads Management"
                        description={`Gurulabs offers expert YouTube Ads management in ${city.name}, ${state.name}, tailored to captivate the city's tech-savvy and creative populace. We create engaging video content and strategic placements that convert viewers into customers, enhancing your brand's visibility in this innovative city.`}
                        button="button-3"
                        Icon={SiYoutube}
                    />
                    <Process
                        title="Twitter (X) Ads Management"
                        description={`Our Twitter Ads management services in ${city.name}, ${state.name}, harness the platform’s real-time capabilities to create impactful campaigns. By tapping into local trends and conversations, we increase your brand’s visibility and drive traffic, engaging the city's active social media users with data-driven strategies.`}
                        button="button-2"
                        Icon={SiTwitter}
                    />
                    <Process
                        title="Instagram Ads Management"
                        description={`Choose Gurulabs for Instagram Ads management in ${city.name}, ${state.name}, and benefit from visually stunning ads that capture the city's vibrant culture. From scenic landscapes to bustling urban scenes, our precise targeting ensures significant engagement and business growth within this lively community.`}
                        button="button-3"
                        Icon={SiInstagram}
                    />
                    <Process
                        title="TikTok Ads Management"
                        description={`Tap into TikTok’s dynamic audience with our ads management in ${city.name}, ${state.name}. Our team creates shareable and entertaining content that captures the essence of the city’s youthful energy and creativity, maximizing visibility and engagement for your brand.`}
                        button="button-2"
                        Icon={SiTiktok}
                    />
                </div>
            </section>
            <section className="bg-gray-50 py-12 px-4">
                <div className="max-w-7xl mx-auto overflow-hidden">
                    <div className="text-xl py-4 text-gray-700 prose prose-lg">
                        <h1>Anchorage, Alaska: A Hub of Opportunity for PPC Advertising</h1>

<h2>Introduction</h2>
<p>Anchorage, the largest city in Alaska, is not only a picturesque location surrounded by stunning natural beauty but also a thriving urban center with immense potential for businesses looking to leverage digital marketing strategies, particularly Pay-Per-Click (PPC) advertising. In this comprehensive guide, we delve into the major features, historical background, cultural significance, and the suitability of Anchorage for PPC advertising, shedding light on why this unique city presents lucrative opportunities for businesses seeking to enhance their online presence and reach their target audience effectively.</p>

<h2>Major Features of Anchorage</h2>
<p>Anchorage, situated in the south-central part of Alaska, boasts a diverse range of features that make it a compelling destination for both tourists and businesses alike. From its breathtaking views of the Chugach Mountains to its proximity to the Cook Inlet and the vast wilderness beyond, Anchorage offers a blend of urban amenities and outdoor adventures.</p>

<ul>
    <li>Scenic Beauty: Anchorage is renowned for its stunning landscapes, including parks, glaciers, and wildlife viewing opportunities. The city's backdrop of snow-capped mountains and expansive forests provides a picturesque setting for residents and visitors.</li>
    <li>Economic Hub: As Alaska's economic powerhouse, Anchorage serves as a vital hub for industries such as oil and gas, tourism, transportation, and healthcare. The city's strategic location makes it a critical junction for trade and commerce, attracting businesses looking to establish a strong presence in the region.</li>
    <li>Cultural Diversity: Anchorage is a melting pot of cultures, with a rich tapestry of traditions and heritage from Alaska Native communities, as well as influences from various immigrant populations. This cultural diversity lends vibrancy to the city's social fabric and offers unique marketing opportunities for businesses seeking to connect with diverse audiences.</li>
    <li>Outdoor Recreation: With easy access to hiking trails, fishing spots, and opportunities for outdoor recreation, Anchorage appeals to outdoor enthusiasts and adventure seekers. This aspect of the city's lifestyle fosters a sense of health and wellness among its residents, presenting niche marketing opportunities in sectors related to fitness, outdoor gear, and eco-tourism.</li>
</ul>

<h2>Historical Background</h2>
<p>Founded in 1914 as a tent city during the construction of the Alaska Railroad, Anchorage has transformed into a bustling metropolis that serves as the gateway to the Last Frontier. The city's growth was further fueled by the discovery of oil in Alaska, leading to rapid development and a surge in population. Anchorage's history is intertwined with the pioneering spirit of its early settlers and the resilience of its residents in the face of harsh environmental conditions.</p>

<h2>Cultural Significance</h2>
<p>Anchorage's cultural significance lies in its role as a meeting point between tradition and modernity, wilderness and urbanity. The city celebrates its heritage through events like the Alaska Native Heritage Center and the Anchorage Museum, showcasing the rich history and art forms of Alaska's indigenous peoples. At the same time, Anchorage embraces innovation and progress, reflected in its vibrant arts scene, culinary offerings, and entrepreneurial spirit.</p>

<h2>Suitability for PPC Advertising</h2>
<p>When it comes to digital marketing strategies, Anchorage presents a unique landscape for PPC advertising due to several key factors:</p>

<ul>
    <li>Target Audience: Anchorage's population consists of a diverse demographic mix, including young professionals, outdoor enthusiasts, tourists, and local residents. This diverse audience provides businesses with ample targeting options to tailor their PPC campaigns based on specific consumer segments.</li>
    <li>Local Business Environment: Anchorage's business community is characterized by a mix of established enterprises and emerging startups across various industries. With a strong focus on innovation and adaptability, businesses in Anchorage are poised to benefit from the targeted approach and measurable results offered by PPC advertising.</li>
    <li>Competitive Landscape: While Anchorage's market size may be smaller compared to major urban centers, the city's competitive landscape offers opportunities for businesses to carve out a niche and differentiate themselves through strategic PPC campaigns. By targeting local keywords and leveraging geotargeting features, businesses can enhance their visibility and attract relevant leads in the Anchorage market.</li>
    <li>Consumer Behavior: Anchorage residents exhibit unique consumer behaviors influenced by the city's outdoor lifestyle, seasonal variations, and cultural preferences. Understanding these nuances is crucial for crafting effective PPC ad copy and landing pages that resonate with the target audience and drive conversions.</li>
    <li>Seasonal Trends: Anchorage experiences distinct seasonal trends due to its northern location, with tourism, outdoor activities, and holiday shopping playing a significant role in shaping consumer behavior throughout the year. By aligning PPC campaigns with seasonal trends and local events, businesses can capitalize on peak periods of consumer engagement and drive sales effectively.</li>
</ul>

<h2>Benefits of PPC Advertising in Anchorage</h2>
<p>For businesses looking to enhance their online visibility and reach a targeted audience in Anchorage, PPC advertising offers a multitude of benefits:</p>

<ul>
    <li>Precise Targeting: PPC platforms allow businesses to target specific demographics, interests, and locations, enabling precise audience segmentation and personalized messaging tailored to the Anchorage market.</li>
    <li>Cost-Effective Reach: With PPC campaigns, businesses have control over their advertising budget and can optimize their ad spend to maximize ROI. By monitoring key performance metrics and adjusting campaign settings in real-time, businesses can achieve cost-effective reach and drive conversions efficiently.</li>
    <li>Measurable Results: PPC advertising provides detailed performance insights and analytics that enable businesses to track the effectiveness of their campaigns in real-time. From click-through rates to conversion rates, businesses can measure the impact of their ads and refine their strategies to improve outcomes and drive growth.</li>
    <li>Enhanced Brand Visibility: By leveraging PPC advertising, businesses can boost their brand visibility in search engine results pages and reach potential customers at the moment they are actively searching for products or services. This enhanced visibility increases brand awareness and encourages customer engagement, leading to higher conversions and sales.</li>
    <li>Adaptability and Flexibility: PPC campaigns offer businesses the flexibility to test different ad creatives, keywords, and targeting parameters to optimize campaign performance. This adaptability allows businesses to respond to market dynamics, competitor movements, and consumer trends, ensuring their advertising strategies remain agile and effective in the dynamic Anchorage market.</li>
</ul>

<h2>Target Audience in Anchorage</h2>
<p>The target audience in Anchorage comprises a diverse mix of consumers with varying preferences, behaviors, and needs. Businesses seeking to engage with the Anchorage audience through PPC advertising should consider the following key segments:</p>

<ul>
    <li>Outdoor Enthusiasts: Anchorage's proximity to nature and abundant outdoor recreational opportunities attract a significant segment of outdoor enthusiasts who are interested in activities such as hiking, fishing, camping, and wildlife viewing. Businesses offering outdoor gear, adventure tours, and eco-friendly products can tailor their PPC campaigns to resonate with this audience.</li>
    <li>Local Residents: Anchorage residents form a core part of the target audience, representing consumers with diverse interests and purchasing power. Businesses providing local services, events, and products can utilize PPC advertising to connect with residents and drive traffic to their websites or physical locations. Tailoring ads to reflect seasonal trends, community events, and local promotions can enhance engagement with this segment.</li>
    <li>Tourists: Anchorage is a popular tourist destination, welcoming visitors from around the world who seek to explore Alaska's wilderness and cultural attractions. Businesses in the hospitality, entertainment, and tourism sectors can leverage PPC advertising to capture the attention of tourists searching for accommodations, tours, activities, and dining experiences in Anchorage.</li>
    <li>Youth and Professionals: Anchorage's growing population of young professionals and students presents a dynamic segment for businesses targeting tech-savvy, urban audiences. By crafting compelling ad copy, utilizing social media platforms, and optimizing mobile-friendly campaigns, businesses can engage with this demographic and drive conversions effectively.</li>
</ul>

<h2>Conclusion</h2>
<p>In conclusion, Anchorage stands out as a prime location for businesses looking to elevate their digital marketing strategies through PPC advertising. With its diverse features, rich history, cultural significance, and vibrant business environment, Anchorage offers a unique setting for businesses to connect with their target audience, drive conversions, and enhance brand visibility. By leveraging the benefits of PPC advertising, businesses in Anchorage can achieve measurable results, reach new customers, and stay ahead in the competitive market landscape. Embracing the digital marketing opportunities available in Anchorage can propel businesses towards success and growth in this thriving Alaskan city.</p>
                    </div>
                </div>
            </section>
            {listings && listings.length > 0 ? (
                <section>
                    <div className="max-w-7xl mx-auto">
                        <div className="max-w-5xl mx-auto py-24">
                            <div className="block sm:flex sm:flex-col justify-center items-center px-4">
                                <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                    <span className="relative">We support small business</span>
                                    <span className="text-green-500 ml-2 relative">near {city.name}, {state.code}</span>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="absolute left-1/2 -mt-2 ml-16 hidden xl:block"
                                        width={238}
                                        height={17}
                                        fill="none"
                                    >
                                        <path stroke="#48BB78" strokeLinecap="round" strokeWidth="2" d="M1.833 12.55c101.19 8.798 198.897-3.667 235.102-11" />
                                    </svg>
                                </h2>
                                <p className="text-base text-center">
                                    Generate quality leads in {city.name} with our expert social media ads management services.
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-wrap justify-center items-center">
                            {listings
                                .filter(listing => listing.website && listing.phone) // Only show listings with a valid image_url
                                .map((listing, index) => (
                                        <div key={listing.id || index} className="w-full sm:w-1/2 lg:w-1/3 px-3 mb-6">
                                            <div className="p-4 max-w-md rounded-lg overflow-hidden shadow-lg bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out">
                                                <div className="p-4">
                                                    <h2 className="font-bold text-2xl mb-4 text-gray-800 whitespace-nowrap overflow-hidden text-ellipsis">{listing.name}</h2>
                                                    <div className="mb-4 text-gray-600">
                                                        <p>{listing.address}</p>
                                                        <p>{listing.city}, {listing.state} {listing.zip}</p>
                                                        <p>{listing.country || ''}</p>
                                                    </div>
                                                    <p className="text-gray-600 text-md">{listing.category}</p>
                                                    <p className="text-gray-600 text-md">Rating: {listing.rating || 'No rating available'} ⭐</p>
                                                    <p className="text-gray-600 text-md pb-4">Phone:  <a href={`tel:${listing.phone}`} className="text-green-600 hover:underline mb-4">
                                                        {listing.phone}
                                                    </a>
                                                    </p>
                                                    {listing.website && (
                                                        <a
                                                            href={`${listing.website.includes('http') ? listing.website : `https://${listing.website}`}?utm_source=gurulabs&utm_medium=referral&utm_campaign=${city.name}-${state.code}-ppc`}
                                                            className="relative w-full py-2 px-3 top-1 right-2 button rounded-sm text-gray-700 hover:bg-green-500"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            Visit Website
                                                        </a>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                ))
                            }
                        </div>
                    </div>
                </section>
            ) :

                (
                    <>
                    </>
                )}
            <section>
                <div className="bg-amber-50 py-12">
                    <div className="max-w-7xl mx-auto">
                        <div>
                            <div className='block sm:flex justify-center items-center px-4'>
                                <h1 className="block text-3xl sm:text-4xl py-5 relative">
                                    <span className="relative">Cities We Serve in {state.name}</span>
                                    <span className="text-green-500 ml-2 relative">
                                        for PPC Services
                                    </span>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4 ml-16 hidden xl:block" width={151} height={3} fill="none"><path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                                </h1>
                            </div>
                            <div className="px-4">
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                    {cities
                                        .sort(() => Math.random() - 0.5) // Shuffle the array
                                        .slice(0, 21) // Select the first 21 cities after shuffling
                                        .map((city) => (
                                            <Link key={city.id} href={`/ppc/${state.slug}/${city.slug}`} className="text-green-500">
                                                Pay Per Click Management near {city.name}
                                            </Link>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <PaidAdsPricingTable city={city.name} state={state.code} />
            <Newsletter />
            <section>
                <div className="bg-gray-50 py-12">
                    <div className="max-w-7xl mx-auto">
                        <div className="block sm:flex justify-center items-center px-4">
                            <h1 className="block text-3xl sm:text-4xl py-5 relative">
                                <span className="relative">Your Reliable Partner for </span>
                                <span className="text-green-500 ml-2 relative">
                                    PPC Management in {city.name}, {state.code}
                                </span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="absolute left-3/4 ml-16 hidden xl:block"
                                    width={151}
                                    height={3}
                                    fill="none"
                                >
                                    <path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" />
                                </svg>
                            </h1>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 px-4">
                            <div>
                                <p className="text-xl py-4 text-gray-700">
                                    <strong>Real Success Stories from {city.name}</strong>
                                </p>
                                <p className="text-xl py-4 text-gray-700">
                                    Our PPC strategies have driven significant growth for businesses in {city.name}. We've helped a local retailer increase sales by 50% in three months and a service provider double their client base with targeted ad campaigns.
                                </p>
                                <p className="text-xl py-4 text-gray-700">
                                    A tech startup saw a 70% reduction in their cost per acquisition while scaling their campaigns. Our data-driven approach and continuous optimization ensure sustainable growth and profitability.
                                </p>
                                <p className="text-xl py-4 text-gray-700">
                                    These success stories highlight our commitment to delivering measurable results. Whether you're a small business or a growing enterprise, our PPC strategies are designed to help you thrive in {city.name}.
                                </p>
                            </div>
                            <div>
                                <p className="text-xl py-4 text-gray-700">
                                    <strong>Commitment to Excellence</strong>
                                </p>
                                <p className="text-xl py-4 text-gray-700">
                                    Transparency, integrity, and exceptional results are the pillars of our PPC management. We provide detailed reports and regular updates, ensuring you understand the impact of every dollar spent.
                                </p>
                                <p className="text-xl py-4 text-gray-700">
                                    Our team is dedicated to understanding your business and creating PPC strategies that contribute to your overall growth. We focus on long-term success through continuous optimization and strategic adjustments.
                                </p>
                                <p className="text-xl py-4 text-gray-700">
                                    Experience the difference with a partner dedicated to driving your success in {city.name}. Our local expertise and industry knowledge ensure highly targeted campaigns that resonate with your audience.
                                </p>
                                <p className="text-xl py-4 text-gray-700">
                                    By choosing us, you are choosing a partner invested in your success. We are passionate about helping businesses in {city.name} thrive and are here to support you every step of the way.
                                </p>
                                <p className="text-xl py-4 text-gray-700">
                                    Discover more about our <a href="https://www.gurulabs.dev/paid-ads" className="font-bold underline">Paid Ads services</a> and how they can benefit your business in {city.name}, {state.code}.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <SocialProof city={city.name} state={state.code} />
            <PPCFAQs city={city.name} state={state.code} />
            {/* Rest of the JSX */}
        </PageLayout>
    );
};

export default AnchorageAKPPCPage;