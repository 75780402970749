import React, { useState } from 'react';

const CPLCalculator = () => {
    const [totalAdSpend, setTotalAdSpend] = useState(1000);
    const [numberOfLeads, setNumberOfLeads] = useState(100);
    const [cpl, setCPL] = useState(0.0);

    const calculateCPL = () => {
        if (numberOfLeads > 0) {
            const costPerLead = totalAdSpend / numberOfLeads;
            setCPL(costPerLead);
        }
    };

    return (
        <div className="max-w-lg mx-auto mt-8 p-6 bg-gray-100 rounded-md shadow-md">
            <h2 className="text-2xl font-semibold mb-4">Cost per Lead (CPL) Calculator</h2>
            <div className="mb-4">
                <label htmlFor="totalAdSpend" className="block mb-1">Total Ad Spend:</label>
                <input
                    type="number"
                    id="totalAdSpend"
                    value={totalAdSpend}
                    onChange={(e) => setTotalAdSpend(Number(e.target.value))}
                    className="border-gray-300 rounded-md p-2 w-full"
                    min="0"
                />
            </div>
            <div className="mb-4">
                <label htmlFor="numberOfLeads" className="block mb-1">Number of Leads:</label>
                <input
                    type="number"
                    id="numberOfLeads"
                    value={numberOfLeads}
                    onChange={(e) => setNumberOfLeads(Number(e.target.value))}
                    className="border-gray-300 rounded-md p-2 w-full"
                    min="0"
                />
            </div>
            <div className="mb-4">
                <button
                    onClick={calculateCPL}
                    className="px-4 py-2 button text-white rounded hover:bg-blue-700"
                >
                    Calculate CPL
                </button>
            </div>
            <div className="mb-4">
                <h3 className="text-xl">
                    Cost per Lead: ${cpl.toFixed(2)}
                </h3>
            </div>
        </div>
    );
};

export default CPLCalculator;
