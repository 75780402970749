import React, { useState } from 'react';

const CPCalculator = () => {
    const [adBudget, setAdBudget] = useState(1000);
    const [expectedClicks, setExpectedClicks] = useState(500);
    const [currency, setCurrency] = useState('USD');
    const [cpc, setCPC] = useState(2.00);

    const calculateCPC = () => {
        const costPerClick = adBudget / expectedClicks;
        setCPC(costPerClick);
    };

    return (
        <div className="max-w-lg mx-auto mt-8 p-6 bg-gray-100 rounded-md shadow-md">
            <h2 className="text-2xl font-semibold mb-4">Cost per Click (CPC) Calculator</h2>
            <div className="mb-4">
                <label htmlFor="adBudget" className="block mb-1">Total Ad Budget:</label>
                <input
                    type="text"
                    id="adBudget"
                    value={adBudget}
                    onChange={(e) => setAdBudget(Number(e.target.value))}
                    className="border-gray-300 rounded-md p-2 w-full"
                    min="0"
                />
            </div>
            <div className="mb-4">
                <label htmlFor="expectedClicks" className="block mb-1">Number of Expected Clicks:</label>
                <input
                    type="text"
                    id="expectedClicks"
                    value={expectedClicks}
                    onChange={(e) => setExpectedClicks(Number(e.target.value))}
                    className="border-gray-300 rounded-md p-2 w-full"
                    min="0"
                />
            </div>
            <div className="mb-4">
                <label htmlFor="currency" className="block mb-1">Currency:</label>
                <select
                    id="currency"
                    value={currency}
                    onChange={(e) => setCurrency(e.target.value)}
                    className="border-gray-300 rounded-md p-2 w-full"
                >
                    <option value="USD">USD</option>
                    <option value="EUR">EUR</option>
                    <option value="GBP">GBP</option>
                </select>
            </div>
            <div className="mb-4">
                <button
                    onClick={calculateCPC}
                    className="px-4 py-2 button text-white rounded hover:bg-blue-700"
                >
                    Calculate CPC
                </button>
            </div>
            <div className="mb-4">
                <h3 className="text-xl">
                    Cost per Click: {currency} {cpc.toFixed(2)}
                </h3>
            </div>
        </div>
    );
};

export default CPCalculator;
