import React, { useState } from 'react';

const CPACalculator = () => {
    const [totalAdSpend, setTotalAdSpend] = useState(1000);
    const [numberOfAcquisitions, setNumberOfAcquisitions] = useState(50);
    const [cpa, setCPA] = useState(0.0);

    const calculateCPA = () => {
        if (numberOfAcquisitions > 0) {
            const costPerAcquisition = totalAdSpend / numberOfAcquisitions;
            setCPA(costPerAcquisition);
        }
    };

    return (
        <div className="max-w-lg mx-auto mt-8 p-6 bg-gray-100 rounded-md shadow-md">
            <h2 className="text-2xl font-semibold mb-4">Cost per Acquisition (CPA) Calculator</h2>
            <div className="mb-4">
                <label htmlFor="totalAdSpend" className="block mb-1">Total Ad Spend:</label>
                <input
                    type="number"
                    id="totalAdSpend"
                    value={totalAdSpend}
                    onChange={(e) => setTotalAdSpend(Number(e.target.value))}
                    className="border-gray-300 rounded-md p-2 w-full"
                    min="0"
                />
            </div>
            <div className="mb-4">
                <label htmlFor="numberOfAcquisitions" className="block mb-1">Number of Acquisitions:</label>
                <input
                    type="number"
                    id="numberOfAcquisitions"
                    value={numberOfAcquisitions}
                    onChange={(e) => setNumberOfAcquisitions(Number(e.target.value))}
                    className="border-gray-300 rounded-md p-2 w-full"
                    min="0"
                />
            </div>
            <div className="mb-4">
                <button
                    onClick={calculateCPA}
                    className="px-4 py-2 button text-white rounded hover:bg-blue-700"
                >
                    Calculate CPA
                </button>
            </div>
            <div className="mb-4">
                <h3 className="text-xl">
                    Cost per Acquisition: ${cpa.toFixed(2)}
                </h3>
            </div>
        </div>
    );
};

export default CPACalculator;
