import React, { useEffect, useState } from 'react'
import MetaHead from '../../Components/MetaHead'
import BlogLayout from '../../Layout/BlogLayout/Index';
import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/24/outline'
import { Link } from '@inertiajs/inertia-react';
import mixpanel from 'mixpanel-browser';
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';

const BlogIndexPage = ({ posts }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [fileLink, setFileLink] = useState('');

    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    useEffect(() => {
        mixpanel.track("Download Playbook Page Visited")
    }, [])


    const handleDownload = (fileUrl) => {
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', fileUrl.split('/').pop());
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!name || !email) {
            setError('Please enter both name and email.');
            return;
        }
        setError('');

        try {
            const response = await fetch('/api/generate-file-link', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': csrfToken,
                },
                body: JSON.stringify({ name, email }),
            });

            if (!response.ok) {
                throw new Error(`Failed to generate file link. Status: ${response.status}`);
            }

            const data = await response.json();
            setFileLink(data.fileLink);
            handleDownload(data.fileLink);
        } catch (error) {
            console.error('Error:', error);
            setError(error.message);
        }
    };

    return (
        <BlogLayout>
            <MetaHead title="Blog Posts - Digital Marketing Agency | Gurulabs" description="Blog Posts - Digital Marketing Agency | Gurulabs" />
            <section>
                <div className='py-8'>
                    <div className="max-w-md mx-auto mt-8 p-8 bg-white rounded-lg shadow-xl">
                        <div className="text-center">
                            <div className="mx-auto flex h-16 w-16 items-center justify-center rounded-full bg-green-100">
                                <ArrowDownTrayIcon aria-hidden="true" className="h-8 w-8 text-green-600" />
                            </div>
                            <h3 className="mt-4 text-2xl font-semibold leading-6 text-gray-900">
                                Download SEO & Lead Gen Playbook
                            </h3>
                            <p className="mt-2 text-lg text-gray-500"></p>
                        </div>
                        <form className="mt-6" onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <label htmlFor="name" className="block text-xl font-medium text-gray-700">
                                    Name
                                </label>
                                <input
                                    id="name"
                                    name="name"
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-xl"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="email" className="block text-xl font-medium text-gray-700">
                                    Email
                                </label>
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-xl"
                                    required
                                />
                            </div>
                            {error && <p className="mb-4 text-lg text-red-600">{error}</p>}
                            <div className="mt-6">
                                <button
                                    type="submit"
                                    className="inline-flex button w-full justify-center rounded-md bg-indigo-600 px-4 py-3 text-xl font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Download File
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>

        </BlogLayout>
    )

}
export default BlogIndexPage
