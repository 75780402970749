import React from 'react'
import LeadsForm from '../../../../Components/LeadsForm/Index'


const PageHero = () => {
    return (
        <section className="">
            <div className="max-w-7xl mx-auto py-8 lg:py-48 px-4">
                <div className="flex flex-col lg:flex-row justify-between items-center">
                    <div className="w-full py-4 xl:w-8/12">
                        <h1 className="text-3xl lg:text-6xl my-1">We turn your
                            <span className="text-green-500 relative mx-2">
                                visitors
                                <svg width="175" height="3" viewBox="0 0 175 3" fill="none" className="absolute hidden lg:block left-6">
                                    <path d="M1.43945 1.54395H173.903" stroke="#48BB78" strokeWidth="2" strokeLinecap="round" />
                                </svg>
                            </span>
                            into revenue</h1>
                        <p className="my-3">Gurulabs is your key to unlocking the potential of your website’s online visibility. Our experienced team of gurus use proven methods to increase your website’s search engine rankings and maximize your online traffic.</p>
                        <LeadsForm />
                    </div>
                    <div className="w-full py-4 xl:w-4/12">
                        <img src="/frontend/images/svg/icon-22.svg" className="mx-auto" alt="" />
                    </div>
                </div>
            </div>
        </section>
    )
}
export default PageHero
