import React from 'react'
import Image from '../Image/Index'

function Slider() {
    return (
        <section>
            <div className="relative pt-4">
                <div className="slider">
                    <div className="slide-track">
                        <div className="slide">
                            <Image src="/frontend/images/png/partnerlogo_01.webp" fallback="/frontend/images/png/partnerlogo_01.png" height={100} width={250} alt="Shopify Partners" />
                        </div>
                        <div className="slide">
                            <Image src="/frontend/images/png/partnerlogo_02.webp" fallback="/frontend/images/png/partnerlogo_02.png" height={100} width={250} alt="Google Cloud Partners" />
                        </div>
                        <div className="slide">
                            <Image src="/frontend/images/png/partnerlogo_03.webp" fallback="/frontend/images/png/partnerlogo_03.png" height={100} width={250} alt="Microsoft Partners" />
                        </div>
                        <div className="slide">
                            <Image src="/frontend/images/png/partnerlogo_04.webp" fallback="/frontend/images/png/partnerlogo_04.png" height={100} width={250} alt="Google Partners" />
                        </div>
                        <div className="slide">
                            <Image src="/frontend/images/png/partnerlogo_05.webp" fallback="/frontend/images/png/partnerlogo_05.png" height={100} width={250} alt="Meta Partners" />
                        </div>
                        <div className="slide">
                            <Image src="/frontend/images/png/partnerlogo_06.webp" fallback="/frontend/images/png/partnerlogo_06.png" height={100} width={250} alt="Amazon Partners" />
                        </div>
                        <div className="slide">
                            <Image src="/frontend/images/png/partnerlogo_01.webp" fallback="/frontend/images/png/partnerlogo_01.png" height={100} width={250} alt="Shopify Partners" />
                        </div>
                        <div className="slide">
                            <Image src="/frontend/images/png/partnerlogo_02.webp" fallback="/frontend/images/png/partnerlogo_02.png" height={100} width={250} alt="Google Cloud Partners" />
                        </div>
                        <div className="slide">
                            <Image src="/frontend/images/png/partnerlogo_03.webp" fallback="/frontend/images/png/partnerlogo_03.png" height={100} width={250} alt="Microsoft Partners" />
                        </div>
                        <div className="slide">
                            <Image src="/frontend/images/png/partnerlogo_04.webp" fallback="/frontend/images/png/partnerlogo_04.png" height={100} width={250} alt="Google Partners" />
                        </div>
                        <div className="slide">
                            <Image src="/frontend/images/png/partnerlogo_05.webp" fallback="/frontend/images/png/partnerlogo_05.png" height={100} width={250} alt="Meta Partners" />
                        </div>
                        <div className="slide">
                            <Image src="/frontend/images/png/partnerlogo_06.webp" fallback="/frontend/images/png/partnerlogo_06.png" height={100} width={250} alt="Amazon Partners" />
                        </div>
                        <div className="slide">
                            <Image src="/frontend/images/png/partnerlogo_01.webp" fallback="/frontend/images/png/partnerlogo_01.png" height={100} width={250} alt="Shopify Partners" />
                        </div>
                        <div className="slide">
                            <Image src="/frontend/images/png/partnerlogo_02.webp" fallback="/frontend/images/png/partnerlogo_02.png" height={100} width={250} alt="Google Cloud Partners" />
                        </div>
                        <div className="slide">
                            <Image src="/frontend/images/png/partnerlogo_03.webp" fallback="/frontend/images/png/partnerlogo_03.png" height={100} width={250} alt="Microsoft Partners" />
                        </div>
                        <div className="slide">
                            <Image src="/frontend/images/png/partnerlogo_04.webp" fallback="/frontend/images/png/partnerlogo_04.png" height={100} width={250} alt="Google Partners" />
                        </div>
                        <div className="slide">
                            <Image src="/frontend/images/png/partnerlogo_05.webp" fallback="/frontend/images/png/partnerlogo_05.png" height={100} width={250} alt="Meta Partners" />
                        </div>
                        <div className="slide">
                            <Image src="/frontend/images/png/partnerlogo_06.webp" fallback="/frontend/images/png/partnerlogo_06.png" height={100} width={250} alt="Amazon Partners" />
                        </div>



                    </div>
                </div>
            </div>
        </section>
    )
}

export default Slider
