import React from 'react'
import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
// import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const faqs = [
    {
        question: "What is PPC management?",
        getAnswer: (city, state) => `PPC management refers to the process of overseeing and managing a company's pay-per-click advertising budget. At Gurulabs in ${city}, ${state}, we ensure that your PPC campaigns are optimized to deliver maximum ROI through strategic keyword selection, bid management, and performance analysis.`,
    },
    {
        question: "What services are included in PPC management services?",
        getAnswer: (city, state) => `PPC management services typically include keyword research, ad creation, bid management, and performance analysis. Gurulabs offers comprehensive PPC management services in ${city}, ${state}, to help you achieve your advertising goals efficiently and effectively.`,
    },
    {
        question: "Why should I hire a PPC management company?",
        getAnswer: (city, state) => `Hiring a PPC management company like Gurulabs in ${city}, ${state}, provides you with professional expertise, advanced tools, and strategic insights to optimize your PPC campaigns. This leads to better ad performance, higher ROI, and more effective use of your advertising budget.`,
    },
    {
        question: "What is AdWords PPC management?",
        getAnswer: (city, state) => `AdWords PPC management focuses on managing Google AdWords campaigns. This includes setting up ad groups, selecting keywords, and optimizing bids. Gurulabs specializes in AdWords PPC management in ${city}, ${state}, to ensure your ads reach the right audience at the right time.`,
    },
    {
        question: "How can a PPC management agency help my business?",
        getAnswer: (city, state) => `A PPC management agency like Gurulabs offers end-to-end services to manage all aspects of your PPC campaigns. From strategy development to execution and monitoring, we help you achieve better results by leveraging our expertise and advanced tools in ${city}, ${state}.`,
    },
    {
        question: "What is ecommerce PPC management?",
        getAnswer: (city, state) => `Ecommerce PPC management is tailored to online stores looking to increase sales through paid advertising. This involves selecting the right keywords, creating compelling ads, and optimizing product listings. Gurulabs provides effective ecommerce PPC management in ${city}, ${state}, to boost your online sales.`,
    },
    {
        question: "What does PPC campaign management involve?",
        getAnswer: (city, state) => `PPC campaign management involves planning, executing, and monitoring your PPC campaigns to ensure they meet your business goals. Gurulabs excels in PPC campaign management in ${city}, ${state}, continuously optimizing to improve ad performance and reduce costs.`,
    },
    {
        question: "What is Amazon PPC management?",
        getAnswer: (city, state) => `Amazon PPC management is essential for sellers on Amazon who want to increase product visibility and sales. This includes managing sponsored product ads, keyword targeting, and bid adjustments. Gurulabs offers expert Amazon PPC management in ${city}, ${state}, to drive more traffic to your product listings.`,
    },
    {
        question: "How does Google PPC management differ from other PPC platforms?",
        getAnswer: (city, state) => `Google PPC management involves overseeing PPC campaigns on Google’s platform, focusing on creating ads, selecting keywords, and monitoring performance. Gurulabs ensures your ads are optimized for the world's most popular search engine in ${city}, ${state}, providing you with a competitive edge.`,
    },
    {
        question: "What are the benefits of using PPC management software?",
        getAnswer: (city, state) => `PPC management software helps automate and optimize various aspects of PPC campaigns, such as keyword research, bid management, and performance tracking. At Gurulabs in ${city}, ${state}, we utilize advanced PPC management software to streamline processes and improve your campaign results.`,
    },
    {
        question: "How does Gurulabs optimize PPC campaigns for different industries?",
        getAnswer: (city, state) => `Gurulabs customizes PPC strategies based on industry-specific insights and trends, ensuring that your campaigns are tailored to meet the unique needs and challenges of your industry in ${city}, ${state}.`,
    },
    {
        question: "Can Gurulabs help with local PPC management?",
        getAnswer: (city, state) => `Yes, Gurulabs specializes in local PPC management to help businesses in ${city}, ${state}, target customers in specific geographic areas, increasing local visibility and driving relevant traffic to your business.`,
    },
    {
        question: "How does Gurulabs ensure ad quality and relevance?",
        getAnswer: (city, state) => `Gurulabs ensures ad quality and relevance through continuous monitoring and optimization, using advanced tools to analyze performance and make data-driven adjustments to improve results in ${city}, ${state}.`,
    },
    {
        question: "What kind of reporting does Gurulabs provide for PPC campaigns?",
        getAnswer: (city, state) => `Gurulabs provides comprehensive reporting on PPC campaign performance, including metrics such as click-through rates, conversion rates, and return on investment, along with actionable insights and recommendations for our clients in ${city}, ${state}.`,
    },
    {
        question: "How does Gurulabs handle PPC budget management?",
        getAnswer: (city, state) => `Gurulabs handles PPC budget management by carefully monitoring spend, adjusting bids, and allocating budget towards the most effective keywords and ads to maximize ROI and minimize wasted spend for our clients in ${city}, ${state}.`,
    },
    {
        question: "What is retargeting in PPC, and does Gurulabs offer this service?",
        getAnswer: (city, state) => `Retargeting in PPC involves targeting users who have previously visited your website with specific ads. Gurulabs offers retargeting services in ${city}, ${state}, to help re-engage potential customers and increase conversion rates.`,
    },
    {
        question: "How does Gurulabs stay updated with the latest PPC trends and algorithms?",
        getAnswer: (city, state) => `Gurulabs stays updated with the latest PPC trends and algorithms by continuously researching, attending industry conferences, and participating in training sessions to ensure we use the most effective strategies for our clients in ${city}, ${state}.`,
    },
    {
        question: "Can Gurulabs manage PPC campaigns on multiple platforms simultaneously?",
        getAnswer: (city, state) => `Yes, Gurulabs can manage PPC campaigns across multiple platforms, including Google Ads, Bing Ads, Facebook Ads, and more, ensuring a cohesive strategy and consistent performance across all channels for our clients in ${city}, ${state}.`,
    },
    {
        question: "How does Gurulabs approach keyword research for PPC campaigns?",
        getAnswer: (city, state) => `Gurulabs uses advanced tools and methodologies to conduct thorough keyword research, identifying high-performing keywords and phrases that align with your business goals and target audience, ensuring effective and relevant PPC campaigns in ${city}, ${state}.`,
    },
    {
        question: "What is the difference between PPC and SEO, and does Gurulabs offer both services?",
        getAnswer: (city, state) => `PPC involves paying for ads to appear in search results, while SEO focuses on optimizing your website to rank organically. Gurulabs offers both PPC and SEO services in ${city}, ${state}, providing a comprehensive approach to improving your online visibility and driving traffic.`,
    },
    {
        question: "How does Gurulabs ensure compliance with PPC platform policies?",
        getAnswer: (city, state) => `Gurulabs ensures compliance with PPC platform policies by staying informed about policy updates and best practices, regularly auditing campaigns, and adhering to guidelines to prevent account issues and ensure smooth campaign operation for our clients in ${city}, ${state}.`,
    },
    {
        question: "Can Gurulabs help with landing page optimization for PPC campaigns?",
        getAnswer: (city, state) => `Yes, Gurulabs offers landing page optimization services in ${city}, ${state}, to ensure that your landing pages are designed to convert, with compelling content, user-friendly design, and strategic calls to action that enhance the effectiveness of your PPC campaigns.`,
    },
    {
        question: "What industries does Gurulabs specialize in for PPC management?",
        getAnswer: (city, state) => `Gurulabs specializes in PPC management for a variety of industries, including ecommerce, healthcare, real estate, finance, and technology. We provide tailored strategies to meet the unique needs of each sector in ${city}, ${state}.`,
    },
    {
        question: "How does Gurulabs approach A/B testing in PPC campaigns?",
        getAnswer: (city, state) => `Gurulabs conducts A/B testing by creating multiple ad variations and comparing their performance to determine the most effective elements. We continuously optimize campaigns based on data-driven insights to improve results for our clients in ${city}, ${state}.`,
    },
    {
        question: "What support does Gurulabs offer for PPC account setup?",
        getAnswer: (city, state) => `Gurulabs provides full support for PPC account setup, including account creation, campaign structure development, keyword selection, ad creation, and initial performance monitoring to ensure a strong start for your PPC efforts in ${city}, ${state}.`,
    },
    {
        question: "Can Gurulabs help with managing seasonal PPC campaigns?",
        getAnswer: (city, state) => `Yes, Gurulabs has experience managing seasonal PPC campaigns, creating and optimizing ads to align with seasonal trends, promotions, and consumer behavior to maximize impact during peak periods in ${city}, ${state}.`,
    },

];




function PPCFAQs({ city, state }) {
    return (
        <div className="bg-white">
            <div className="mx-auto max-w-7xl px-6 py-12 sm:py-24 lg:px-8 lg:py-24">
                <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
                    <h2 className="text-3xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
                    <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                        {faqs.map((faq) => (
                            <Disclosure as="div" key={faq.question} className="pt-6">
                                {({ open }) => (
                                    <>
                                        <dt>
                                            <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                                                <span className="text-base font-semibold leading-7">{faq.question}</span>
                                                <span className="ml-6 flex h-7 items-center">
                                                    {open ? (
                                                        <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                    ) : (
                                                        <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                    )}
                                                </span>
                                            </Disclosure.Button>
                                        </dt>
                                        <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                            <p className="text-base leading-7 text-gray-600">{faq.getAnswer(city, state)}</p>
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
}


export default PPCFAQs
