import React from 'react'
import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
// import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const faqs = [
    {
        question: "What is On-Page Optimization?",
        answer: "On-Page Optimization refers to the process of optimizing individual web pages to improve their search engine rankings and drive organic traffic. This includes optimizing content, HTML source code, and media elements."
    },
    {
        question: "Why is On-Page Optimization important?",
        answer: "On-Page Optimization is crucial because it ensures that your website's content is relevant and easily understandable by search engines. This helps improve rankings, user experience, and conversion rates."
    },
    {
        question: "What elements are involved in On-Page Optimization?",
        answer: "Key elements of On-Page Optimization include title tags, meta descriptions, headers, URL structure, internal linking, image alt texts, and content quality. Each of these contributes to a page's SEO performance."
    },
    {
        question: "How does On-Page Optimization affect SEO?",
        answer: "On-Page Optimization directly impacts SEO by making your website more search-engine friendly. Properly optimized pages are more likely to rank higher in search results, leading to increased visibility and traffic."
    },
    {
        question: "What role does content play in On-Page Optimization?",
        answer: "Content is a critical component of On-Page Optimization. High-quality, keyword-rich content that addresses user intent can significantly boost a page's relevance and ranking on search engines."
    },
    {
        question: "How often should On-Page Optimization be updated?",
        answer: "On-Page Optimization should be reviewed and updated regularly, especially after significant changes in search engine algorithms, to ensure that your pages remain optimized and competitive."
    },
    {
        question: "What is the importance of meta tags in On-Page Optimization?",
        answer: "Meta tags, including title tags and meta descriptions, play a crucial role in On-Page Optimization by providing search engines with information about the content of your page, influencing click-through rates and rankings."
    },
    {
        question: "How does internal linking contribute to On-Page Optimization?",
        answer: "Internal linking helps distribute link equity throughout your site and improves the crawlability of your pages. It also enhances user experience by guiding visitors to relevant content within your website."
    },
    {
        question: "What are the best practices for optimizing images on a website?",
        answer: "Best practices for image optimization include using descriptive alt texts, compressing images for faster load times, and ensuring images are relevant to the content. This helps improve both SEO and user experience."
    },
    {
        question: "Can On-Page Optimization improve website speed?",
        answer: "Yes, On-Page Optimization can significantly improve website speed by optimizing images, leveraging browser caching, and minimizing unnecessary code, all of which contribute to better user experience and SEO rankings."
    },
    {
        question: "How does Gurulabs handle On-Page Optimization?",
        answer: "Gurulabs offers comprehensive On-Page Optimization services, including content analysis, meta tag optimization, keyword integration, and technical improvements to ensure your website ranks higher and performs better."
    },
    {
        question: "What is keyword mapping in On-Page Optimization?",
        answer: "Keyword mapping involves assigning specific keywords to individual pages on your website. This helps ensure that each page is optimized for relevant search queries, improving its chances of ranking higher in search results."
    },
    {
        question: "How do headers (H1, H2, etc.) affect On-Page Optimization?",
        answer: "Headers help structure your content and make it easier for search engines to understand the hierarchy and relevance of the information on your page. Properly using headers (H1, H2, etc.) is essential for effective On-Page Optimization."
    },
    {
        question: "What is the role of URL structure in On-Page Optimization?",
        answer: "A clean and descriptive URL structure helps search engines understand the content of your pages. It also improves user experience by making URLs easier to read and share, which can positively impact SEO."
    },
    {
        question: "Why is mobile-friendliness important in On-Page Optimization?",
        answer: "Mobile-friendliness is crucial because a significant portion of web traffic comes from mobile devices. Optimizing your pages for mobile ensures a better user experience and can improve rankings, especially with Google’s mobile-first indexing."
    },
    {
        question: "How does schema markup relate to On-Page Optimization?",
        answer: "Schema markup helps search engines better understand the content on your page by providing additional context. This can enhance search results with rich snippets, potentially increasing click-through rates and visibility."
    },
    {
        question: "What tools does Gurulabs use for On-Page Optimization?",
        answer: "Gurulabs utilizes a variety of tools, including Google Analytics, Google Search Console, and specialized SEO software, to analyze and optimize on-page elements for improved search engine rankings and user experience."
    },
    {
        question: "Can On-Page Optimization help with voice search?",
        answer: "Yes, On-Page Optimization can improve your website's performance in voice search by optimizing for long-tail keywords, natural language queries, and ensuring that content answers common questions directly and concisely."
    },
    {
        question: "What is the impact of user experience (UX) on On-Page Optimization?",
        answer: "User experience (UX) is a critical factor in On-Page Optimization. A well-optimized page provides a better user experience, leading to lower bounce rates, higher engagement, and improved search engine rankings."
    },
    {
        question: "How can Gurulabs improve my website's On-Page Optimization?",
        answer: "Gurulabs enhances your website's On-Page Optimization by conducting thorough audits, implementing best practices for content, meta tags, and technical elements, and continuously monitoring performance to ensure sustained improvements."
    }
];







function OnPageFAQs() {
    return (
        <div className="bg-white">
            <div className="mx-auto max-w-7xl px-6 py-12 sm:py-24 lg:px-8 lg:py-24">
                <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
                    <h2 className="text-3xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
                    <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                        {faqs.map((faq) => (
                            <Disclosure as="div" key={faq.question} className="pt-6">
                                {({ open }) => (
                                    <>
                                        <dt>
                                            <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                                                <span className="text-base font-semibold leading-7">{faq.question}</span>
                                                <span className="ml-6 flex h-7 items-center">
                                                    {open ? (
                                                        <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                    ) : (
                                                        <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                    )}
                                                </span>
                                            </Disclosure.Button>
                                        </dt>
                                        <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                            <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
}


export default OnPageFAQs
