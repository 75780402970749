import React, { useState } from 'react'
import PageLayout from '../../../Layout/PageLayout/Index'
import MetaHead from '../../../Components/MetaHead'
import FacebookAdsCostCalculator from '../../../Components/Calculators/FacebookAdsCostCalculator/Index'
import LeadsModal from '../../../Layout/LeadsModal/Index';
import CalendarModal from '../../../Layout/CalendarModal/Index';
import mixpanel from 'mixpanel-browser';
import CPCalculator from '../../../Components/Calculators/CPCalculator/Index'
import CPMCalculator from '../../../Components/Calculators/CPMCalculator/Index'
import CTRCalculator from '../../../Components/Calculators/CTRCalculator/Index'
import ROASCalculator from '../../../Components/Calculators/ROASCalculator/Index'
import ConversionRateCalculator from '../../../Components/Calculators/ConversionRateCalculator/Index'
import ConversionValueCalculator from '../../../Components/Calculators/ConversionValueCalculator/Index'
import BudgetForClicksCalculator from '../../../Components/Calculators/BudgetForClicksCalculator/Index'
import BudgetForConversionsCalculator from '../../../Components/Calculators/BudgetForConversionsCalculator/Index'
import CPLCalculator from '../../../Components/Calculators/CPLCalculator/Index'
import CLTVCalculator from '../../../Components/Calculators/CLTVCalculator/Index'
import CPACalculator from '../../../Components/Calculators/CPACalculator/Index'

const FacebookAdsCostCalculatorPage = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    return (
        <PageLayout>
            <MetaHead title="Facebook Ads Cost Calculator | PPC Campaign Cost Estimator"
                description="Estimate Facebook ad costs quickly with our PPC campaign cost calculator." />
            <section>
                <div className='max-w-7xl mx-auto'>
                    <div className="grid grid-cols-3 px-2 py-16">
                        <div className="max-w-sm max-h-sm">
                            <ConversionRateCalculator />
                        </div>
                        <div className="max-w-sm max-h-sm">
                            <ConversionValueCalculator />
                        </div>
                        <div className="max-w-sm max-h-sm">
                            <CTRCalculator />
                        </div>

                        <div className="max-w-sm max-h-sm">
                            <CPCalculator />
                        </div>
                        <div className="max-w-sm max-h-sm">
                            <CPMCalculator />
                        </div>


                        <div className="max-w-sm max-h-sm">
                            <ROASCalculator />
                        </div>
                        <div className="max-w-sm max-h-sm">
                            <FacebookAdsCostCalculator />
                        </div>
                        <div className="max-w-sm max-h-sm">
                            <BudgetForClicksCalculator />
                        </div>

                        <div className="max-w-sm max-h-sm">
                            <BudgetForConversionsCalculator />
                        </div>

                        <div className="max-w-sm max-h-sm">
                            <CPLCalculator />
                        </div>
                        <div className="max-w-sm max-h-sm">
                            <CLTVCalculator />
                        </div>
                        <div className="max-w-sm max-h-sm">
                            <CPACalculator />
                        </div>

                    </div>
                </div>
            </section>
            <section className="max-w-7xl mx-auto overflow-hidden">
                <div className="">
                    <div className="py-8 text-center">
                        {
                            isMobile ? (
                                <Link
                                    onClick={() => {
                                        mixpanel.track("Get Started Mobile button clicked");
                                    }}
                                    href='/get-started'
                                    className="relative py-4 px-4 top-1 right-2 button rounded-sm text-gray-700 hover:bg-green-700 transition duration-300 ease-in-out"
                                >
                                    <span className="text-xl">Struggling with Facebook Ads?</span>
                                </Link>
                            ) : (
                                <button
                                    onClick={() => {
                                        setIsOpen(!isOpen);
                                        mixpanel.track("Get Started button clicked");
                                    }}
                                    className="relative py-4 px-4 top-1 right-2 button rounded-sm text-gray-700 hover:bg-green-700 transition duration-300 ease-in-out"
                                >
                                    <span className="text-xl">Struggling with Facebook Ads?</span>
                                </button>
                            )
                        }
                    </div>
                </div>
            </section>
            <section>
                <div className="bg-gray-50 py-12">
                    <div className="max-w-7xl mx-auto">
                        <div>
                            <div className='block sm:flex justify-center items-center px-4'>
                                <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                    <span className="relative">Entrepreneur's Best Friend </span>
                                    <span className="text-green-500 ml-2 relative">
                                        Facebook Ads Cost Calculator
                                    </span>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4 ml-16 hidden xl:block" width={151} height={3} fill="none"><path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                                </h2>
                            </div>
                        </div>
                        <div className="px-4">
                            <div>
                                <p className="text-xl py-4 text-gray-700">Introducing the Facebook Ads Cost Calculator</p>

                                <p className="text-xl py-4 text-gray-700">As digital marketing continues to evolve, precision in campaign budgeting becomes ever more crucial. To assist businesses in optimizing their social media advertising strategies, we are thrilled to unveil the Facebook Ads Cost Calculator—a specialized tool crafted to accurately estimate the costs of advertising campaigns on Facebook.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Accurately Forecasting Your Advertising Costs</strong></p>

                                <p className="text-xl py-4 text-gray-700">The Facebook Ads Cost Calculator simplifies the financial planning of Facebook advertising campaigns. By inputting the expected number of clicks and the cost per click (CPC) along with the number of impressions and cost per mille (CPM), businesses can instantly see the total cost of their campaign. This precision allows for better budget management and helps clarify the financial impact of the campaign before it runs.</p>

                                <p className="text-xl py-4 text-gray-700">This tool is designed not only to provide immediate cost estimates but also to facilitate strategic adjustments. Marketers can modify the inputs for clicks, CPC, impressions, and CPM to explore different advertising scenarios, enabling them to discover the most cost-effective strategies for their specific goals.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Enhancing Campaign Efficiency and Return on Investment</strong></p>

                                <p className="text-xl py-4 text-gray-700">One of the greatest benefits of using the <a href="https://www.gurulabs.dev/blog/mastering-facebook-ads-management-a-comprehensive-guide" className="font-bold underline"> Facebook Ads </a> Cost Calculator is its ability to enhance the efficiency of campaign management. With clear insights into the costs associated with various advertising components, marketers can optimize their spending and focus resources on the most impactful aspects. This targeted approach helps maximize return on investment and reduces the likelihood of budget waste.</p>

                                <p className="text-xl py-4 text-gray-700">Moreover, the calculator promotes proactive budgeting and cost management, empowering businesses to maintain control over their advertising expenditures and avoid unexpected overspending.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Empowering Your Business with Strategic Advertising Insights</strong></p>

                                <p className="text-xl py-4 text-gray-700">At its core, the <a href="https://www.gurulabs.dev/blog/mastering-facebook-ads-management-a-comprehensive-guide" className="font-bold underline"> Facebook Ads </a> Cost Calculator provides businesses with the strategic insights needed to craft more effective advertising campaigns. By delivering accurate and timely cost predictions, our tool enables marketers to plan with confidence and invest wisely in their advertising efforts.</p>

                                <p className="text-xl py-4 text-gray-700">In conclusion, if you're seeking a tool that offers reliability, efficiency, and strategic depth for managing your Facebook advertising spend, the Facebook Ads) Cost Calculator is your go-to resource. Take control of your digital marketing budget and optimize your campaigns for optimal performance. For more information or to start using this tool, please visit <a href="/" className="font-bold underline">our website</a>.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <LeadsModal isOpen={isOpen} setIsOpen={setIsOpen} />
            <CalendarModal isOpen={modalIsOpen} setIsOpen={setModalIsOpen} />
        </PageLayout>
    )
}
export default FacebookAdsCostCalculatorPage
