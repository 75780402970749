import React, { useState } from 'react'
import PageLayout from '../../../../Layout/PageLayout/Index'
import MetaHead from '../../../../Components/MetaHead'
import LeadsForm from '../../../../Components/LeadsForm/Index'
import Slider from '../../../../Components/Slider/Index'
import PaidAdsPricing from '../../../../Components/PaidAdsPricing/Index'
import PaidAdsPricingTable from '../../../../Components/PaidAdsPricingTable/Index'
import Process from '../../../../Components/Process/Index'
import CalendarModal from '../../../../Layout/CalendarModal/Index'
import LeadsModal from '../../../../Layout/LeadsModal/Index'
import CommerceSlider from '../../../../Components/CommerceSlider/Index'
import { SiFacebook, SiInstagram, SiLinkedin, SiTiktok, SiYoutube, SiTwitter } from '@icons-pack/react-simple-icons'
import SocialProof from '../../../../Components/SocialProof/Index'
import PPCFAQs from '../../../../Components/FAQs/PPCFAQs/Index'

// washington dc ppc agency
// ppc agency washington dc
// ppc agency in washington dc
// washington dc ppc marketing agency

const WashingtonDCPage = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [isMobile, setIsMobile] = useState(false)
    return (
        <PageLayout>
            <MetaHead title="Washington DC PPC Agency | Pay Per Click (PPC) Agency in Washington DC | Gurulabs"
                description="Washington DC's top PPC Management agency, Gurulabs, offers expert PPC management services to help businesses grow. Contact us today for a free consultation!"
            />
            <section className="">
                <div className="max-w-7xl mx-auto py-8 lg:py-48 px-4">
                    <div className="flex flex-col lg:flex-row justify-between items-center">
                        <div className="w-full lg:w-8/12 py-4">
                            <h1 className="text-3xl lg:text-6xl my-1">Your Pay Per Click
                                <span className="text-green-500 relative mx-2">
                                    Management Agency
                                    <svg width="175" height="3" viewBox="0 0 175 3" fill="none" className="absolute hidden lg:block left-6">
                                        <path d="M1.43945 1.54395H173.903" stroke="#48BB78" strokeWidth="2" strokeLinecap="round" />
                                    </svg>
                                </span>
                                in Washington DC</h1>
                            <p className="my-3">Gurulabs is your key to unlocking the potential of your website’s online visibility. Unlock your website's full potential with Gurulabs. Our team of experts enhances your online visibility and maximizes traffic using proven PPC Management strategies.</p>
                            <LeadsForm />
                        </div>
                        <div className="w-full lg:w-4/12 py-4">
                            <img src="/frontend/images/svg/icon-22.svg" className="mx-auto" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <Slider />
            <section>
                <div className="bg-gray-50 py-12">
                    <div className="max-w-7xl mx-auto">
                        <div className='block sm:flex justify-center items-center px-4'>
                            <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                <span className="relative">Come take a look at what services plans</span>
                                <span className="text-green-500 ml-2 relative">
                                    we offer!
                                </span>
                                <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4 ml-16 hidden xl:block" width={151} height={3} fill="none"><path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                            </h2>
                        </div>
                        <PaidAdsPricing modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
                    </div>
                </div>
            </section>
            <SocialProof />
            <section>
                <div className="max-w-5xl mx-auto py-24">
                    <div className='block sm:flex sm:flex-col justify-center items-center px-4'>
                        <h2 className="block text-3xl sm:text-4xl py-5 relative">
                            <span className="relative">Our Tech-Enabled</span>
                            <span className="text-green-500 ml-2 relative">
                                Socail Media Ads Management
                            </span>
                            <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-1/2 -mt-2 ml-16 hidden xl:block" width={238} height={17} fill="none"><path stroke="#48BB78" strokeLinecap="round" strokeWidth="2" d="M1.833 12.55c101.19 8.798 198.897-3.667 235.102-11" /></svg>
                        </h2>
                        <p className="text-base text-center">Our Lead generation process helps you identify potential customers or clients and gather their contact information to nurture them toward a sale.</p>
                    </div>
                </div>
                <div className="max-w-7xl mx-auto pb-24">
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
                        <Process
                            title="Facebook Ads Management"
                            description="Choosing Gurulabs for your Facebook Ads management ensures that your campaigns are optimized for the highest engagement and conversion rates. Our expert team leverages advanced targeting options, compelling ad creatives, and data-driven strategies to reach your ideal audience, drive meaningful interactions, and maximize your return on investment. With Gurulabs, your Facebook advertising will be managed by professionals dedicated to achieving measurable results and expanding your brand’s reach."
                            button="button-3"
                            Icon={SiFacebook}
                        />
                        <Process
                            title="LinkedIn Ads Management"
                            description="Gurulabs is the ideal choice for LinkedIn Ads management, as we specialize in creating professional and highly-targeted campaigns that connect your business with key decision-makers and industry professionals. Our team understands the unique nuances of LinkedIn advertising, from Sponsored Content to InMail, and uses this expertise to drive high-quality leads and build valuable B2B relationships. Trust Gurulabs to enhance your LinkedIn presence and achieve your marketing objectives effectively."
                            button="button-2"
                            Icon={SiLinkedin}
                        />
                        <Process
                            title="Youtube Ads Management"
                            description="For YouTube Ads management, Gurulabs offers unparalleled expertise in creating captivating video content and strategic placements that engage viewers and convert them into customers. We utilize comprehensive analytics and audience insights to optimize your campaigns, ensuring your videos reach the right people at the right time. By partnering with Gurulabs, you’ll benefit from a team committed to maximizing your brand’s visibility and driving substantial growth through YouTube advertising."
                            button="button-3"
                            Icon={SiYoutube}
                        />
                        <Process
                            title="Twitter (X) Ads Management"
                            description="Gurulabs stands out as the premier choice for Twitter (X) Ads management by harnessing the platform’s real-time nature to create impactful ad campaigns that resonate with your target audience. Our specialists craft engaging Promoted Tweets, Trends, and Accounts to increase your brand’s visibility, foster engagement, and drive traffic to your website. With Gurulabs, you’ll experience a data-driven approach that ensures your Twitter advertising delivers outstanding results and enhances your online presence."
                            button="button-2"
                            Icon={SiTwitter}
                        />
                        <Process
                            title="Instagram Ads Management"
                            description="Selecting Gurulabs for Instagram Ads management means entrusting your campaigns to a team that excels in leveraging the platform’s visual appeal to captivate and convert your audience. We develop visually stunning ads and stories that align with your brand’s aesthetic, combined with precise targeting to reach the most relevant users. Our expertise in Instagram’s unique advertising features ensures that your campaigns generate significant engagement and drive measurable growth for your business."
                            button="button-3"
                            Icon={SiInstagram}
                        />
                        <Process
                            title="TikTok Ads Management"
                            description="Gurulabs is the perfect partner for TikTok Ads management, offering innovative strategies to tap into the platform’s rapidly growing user base. Our team creates entertaining and shareable ad content that captures the essence of your brand and resonates with TikTok’s dynamic audience. By choosing Gurulabs, you’ll benefit from our deep understanding of TikTok’s algorithm and trends, ensuring your ads achieve maximum visibility and engagement, ultimately driving conversions and brand loyalty."
                            button="button-2"
                            Icon={SiTiktok}
                        />
                    </div>
                </div>
            </section>
            <section>
                <div className="bg-gray-50 py-12">
                    <div className="max-w-7xl mx-auto">
                        <div>
                            <div className='block sm:flex justify-center items-center px-4'>
                                <h1 className="block text-3xl sm:text-4xl py-5 relative">
                                    <span className="relative">We're your trusted partner </span>
                                    <span className="text-green-500 ml-2 relative">
                                        PPC Management in Washington DC
                                    </span>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4 ml-16 hidden xl:block" width={151} height={3} fill="none"><path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                                </h1>
                            </div>
                        </div>
                        <div className="px-4">
                            <div>
                                <p className="text-xl py-4 text-gray-700">Our Paid Advertising Solutions Propel Your Brand Ahead of the Competition</p>
                                <p className="text-xl py-4 text-gray-700">Washington D.C. is the epicenter of American politics and history, famous for landmarks such as the White House and the Lincoln Memorial. Known as a hub for entrepreneurship, this vibrant city boasts a large startup community and a robust environment for business growth, according to the 2016 Kauffman Index of Growth Entrepreneurship.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Gurulabs' PPC Services</strong></p>
                                <p className="text-xl py-4 text-gray-700">Role of PPC: Pay-per-click marketing is crucial for businesses aiming to expand. It is designed to integrate seamlessly into your growth strategy, providing scalable solutions.</p>
                                <p className="text-xl py-4 text-gray-700">Service Offerings: Gurulabs offers customized, cost-efficient PPC solutions aligned with your financial objectives. Our team is equipped with profound digital marketing expertise, having led successful SEO and PPC campaigns since our founding.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>How Our Process Works</strong></p>
                                <p className="text-xl py-4 text-gray-700">Submit a Request: Fill out a form to get started with your <a href="/get-started" className="font-bold underline">washington dc ppc marketing agency</a></p>
                                <p className="text-xl py-4 text-gray-700">Consultation: We'll schedule a free consultation to discuss your needs.</p>
                                <p className="text-xl py-4 text-gray-700">Receive Your Proposal: Get a detailed proposal tailored to your business.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Client Success Stories</strong></p>
                                <p className="text-xl py-4 text-gray-700">Our clients enjoy significant improvements in their digital metrics:</p>
                                <p className="text-xl py-4 text-gray-700">Organic Traffic increased by over 600%</p>
                                <p className="text-xl py-4 text-gray-700">Online Leads grew by more than 300%</p>
                                <p className="text-xl py-4 text-gray-700">Conversions and lead quality significantly enhanced</p>
                                <p className="text-xl py-4 text-gray-700">Our commitment to delivering substantial results is echoed in our clients' feedback, highlighting our reliability, efficiency, and no-nonsense approach to digital marketing.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Comprehensive PPC Services</strong></p>
                                <p className="text-xl py-4 text-gray-700">Keyword Research: We optimize your campaigns for the highest ROI by targeting keywords most likely to convert.</p>
                                <p className="text-xl py-4 text-gray-700">Landing Page Optimization: We ensure your landing pages are optimized to convert visitors into leads, enhancing your overall lead generation strategy.</p>
                                <p className="text-xl py-4 text-gray-700">PPC Management: Our streamlined PPC management strategies rapidly generate leads, letting you focus on running your business.</p>
                                <p className="text-xl py-4 text-gray-700">Paid Advertising: We demystify paid advertising, making it accessible and stress-free, with a focus on tangible ROI.</p>
                                <p className="text-xl py-4 text-gray-700">A/B Testing for Ads: We use A/B testing to identify the most effective ads, improving your campaign's effectiveness and efficiency.</p>
                                <p className="text-xl py-4 text-gray-700">Conversion Rate Optimization: Our holistic approach to digital marketing focuses on strategies that enhance user experience and expand your customer base.</p>
                                <p className="text-xl py-4 text-gray-700">Social Media Campaigns: We connect your brand with the ideal audience using targeted advertising on platforms like Facebook, Twitter, LinkedIn, and Instagram.</p>
                                <p className="text-xl py-4 text-gray-700">Search Engine Ads Management: Our services include full optimization of your ads for peak performance on major search engines like Google and Bing.</p>
                                <p className="text-xl py-4 text-gray-700">Localized Advertising: We utilize platforms like Nextdoor to specifically target customers in your local area, ensuring high relevance and conversion.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Why Gurulabs Should Be Your Washington D.C. PPC Partner</strong></p>
                                <p className="text-xl py-4 text-gray-700">We offer unmatched expertise in pay-per-click strategies, tailored to meet your unique business needs. Our PPC team is dedicated to crafting customized campaigns that drive results and ensuring your business remains visible to your target audience.</p>
                                <p className="text-xl py-4 text-gray-700">Our approach is built on a foundation of transparency, integrity, and dedication to client success. We keep you in the loop with regular updates and detailed reports, demonstrating the effectiveness of your campaigns and our commitment to your goals.</p>
                                <p className="text-xl py-4 text-gray-700">Gurulabs is not just about achieving short-term goals; we aim to build lasting relationships with our clients, contributing to their long-term success in the competitive Washington D.C. market.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <PaidAdsPricingTable />
            <PPCFAQs />
            <section>
                <div className="bg-amber-50 hero-reviews py-12">
                    <div className="max-w-7xl mx-auto overflow-hidden">
                        <div className='block sm:flex justify-center items-center py-16 px-4'>
                            <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                <span className="relative">Hear from our Lead Gen</span>
                                <span className="text-green-500 ml-2 relative">
                                    Clients!
                                </span>
                                <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4  hidden xl:block" width={151} height={3} fill="none"><path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                            </h2>
                        </div>
                        <CommerceSlider />
                    </div>
                </div>
            </section>
            <LeadsModal isOpen={isOpen} setIsOpen={setIsOpen} />
            <CalendarModal isOpen={modalIsOpen} setIsOpen={setModalIsOpen} />
        </PageLayout>
    )
}
export default WashingtonDCPage
