import React, { useState } from 'react'
import PageLayout from '../../../Layout/PageLayout/Index'
import MetaHead from '../../../Components/MetaHead'
import GoogleAdsCostCalculator from '../../../Components/Calculators/GoogleAdsCostCalculator/Index'
import LeadsModal from '../../../Layout/LeadsModal/Index';
import CalendarModal from '../../../Layout/CalendarModal/Index';
import mixpanel from 'mixpanel-browser';
import CPCalculator from '../../../Components/Calculators/CPCalculator/Index'
import CPMCalculator from '../../../Components/Calculators/CPMCalculator/Index'
import CTRCalculator from '../../../Components/Calculators/CTRCalculator/Index'
import ROASCalculator from '../../../Components/Calculators/ROASCalculator/Index'
import ConversionRateCalculator from '../../../Components/Calculators/ConversionRateCalculator/Index'
import ConversionValueCalculator from '../../../Components/Calculators/ConversionValueCalculator/Index'
import BudgetForClicksCalculator from '../../../Components/Calculators/BudgetForClicksCalculator/Index'
import BudgetForConversionsCalculator from '../../../Components/Calculators/BudgetForConversionsCalculator/Index'
import CPLCalculator from '../../../Components/Calculators/CPLCalculator/Index';
import CLTVCalculator from '../../../Components/Calculators/CLTVCalculator/Index';
import CPACalculator from '../../../Components/Calculators/CPACalculator/Index';


const GoogleAdsCostCalculatorPage = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    return (
        <PageLayout>
            <MetaHead title="Google Ads Cost Calculator | PPC Campaign Cost Estimator"
                description="Quickly calculate your Google Ads expenses with our PPC campaign cost estimator. Efficiently manage your advertising budget and optimize your strategies." />
            <section>
                <div className='max-w-7xl mx-auto'>
                    <div className="grid grid-cols-3 px-2 py-16">
                        <div className="max-w-sm max-h-sm">
                            <ConversionRateCalculator />
                        </div>
                        <div className="max-w-sm max-h-sm">
                            <ConversionValueCalculator />
                        </div>
                        <div className="max-w-sm max-h-sm">
                            <CTRCalculator />
                        </div>

                        <div className="max-w-sm max-h-sm">
                            <CPCalculator />
                        </div>
                        <div className="max-w-sm max-h-sm">
                            <CPMCalculator />
                        </div>
                        <div className="max-w-sm max-h-sm">
                            <GoogleAdsCostCalculator />
                        </div>

                        <div className="max-w-sm max-h-sm">
                            <ROASCalculator />
                        </div>

                        <div className="max-w-sm max-h-sm">
                            <BudgetForClicksCalculator />
                        </div>

                        <div className="max-w-sm max-h-sm">
                            <BudgetForConversionsCalculator />
                        </div>
                        <div className="max-w-sm max-h-sm">
                            <CPLCalculator />
                        </div>
                        <div className="max-w-sm max-h-sm">
                            <CLTVCalculator />
                        </div>
                        <div className="max-w-sm max-h-sm">
                            <CPACalculator />
                        </div>
                    </div>
                </div>
            </section>
            <section className="max-w-7xl mx-auto overflow-hidden">
                <div className="">
                    <div className="py-8 text-center">
                        {
                            isMobile ? (
                                <Link
                                    onClick={() => {
                                        mixpanel.track("Get Started Mobile button clicked");
                                    }}
                                    href='/get-started'
                                    className="relative py-4 px-4 top-1 right-2 button rounded-sm text-gray-700 hover:bg-green-700 transition duration-300 ease-in-out"
                                >
                                    <span className="text-xl">Struggling with Google Ads?</span>
                                </Link>
                            ) : (
                                <button
                                    onClick={() => {
                                        setIsOpen(!isOpen);
                                        mixpanel.track("Get Started button clicked");
                                    }}
                                    className="relative py-4 px-4 top-1 right-2 button rounded-sm text-gray-700 hover:bg-green-700 transition duration-300 ease-in-out"
                                >
                                    <span className="text-xl">Struggling with Google Ads?</span>
                                </button>
                            )
                        }
                    </div>
                </div>
            </section>
            <section>
                <div className="bg-gray-50 py-12">
                    <div className="max-w-7xl mx-auto">
                        <div>
                            <div className='block sm:flex justify-center items-center px-4'>
                                <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                    <span className="relative">Entrepreneur's Best Friend </span>
                                    <span className="text-green-500 ml-2 relative">
                                        Google Ads Cost Per Click Calculator
                                    </span>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4 ml-16 hidden xl:block" width={151} height={3} fill="none"><path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                                </h2>
                            </div>
                        </div>
                        <div className="px-4">
                            <div>
                                <p className="text-xl py-4 text-gray-700">Introducing the Google Ads Cost Calculator</p>

                                <p className="text-xl py-4 text-gray-700">Navigating the complex landscape of digital advertising can be daunting, especially when managing budgets and expectations for pay-per-click campaigns. To aid businesses in this endeavor, we are proud to introduce the Google Ads Cost Calculator, a precise tool designed to predict and analyze the costs associated with running ads on Google's platform.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Optimizing Your Advertising Spend</strong></p>

                                <p className="text-xl py-4 text-gray-700">The <a href="https://www.gurulabs.dev/calculators/google-ads-cost-calculator" className="font-bold underline"> Google Ads </a> Cost Calculator simplifies the process of estimating the financial expenditure of Google Ads campaigns. Users need only input the number of clicks expected and the cost per click (CPC) to immediately see the total projected cost. This clarity helps businesses plan their advertising budgets more effectively and understand the financial implications of their campaigns upfront.</p>

                                <p className="text-xl py-4 text-gray-700">Our tool not only provides instant cost estimations but also assists in fine-tuning advertising strategies by allowing adjustments to the number of clicks and CPC values. This flexibility enables advertisers to experiment with different scenarios and find the optimal balance between budget and campaign reach.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Driving Efficiency and Effectiveness in Campaign Management</strong></p>

                                <p className="text-xl py-4 text-gray-700">One of the main advantages of using the <a href="https://www.gurulabs.dev/calculators/google-ads-cost-calculator" className="font-bold underline"> Google Ads </a> Cost Calculator is the potential for enhanced budget management. By having a clear understanding of campaign costs, businesses can make informed decisions that maximize ROI and minimize wasted spend. The tool’s straightforward design and functionality make it accessible to both novice marketers and seasoned professionals alike, ensuring that every dollar spent is an investment towards successful outcomes.</p>

                                <p className="text-xl py-4 text-gray-700">Moreover, the calculator encourages proactive management of advertising expenses, helping to avoid overspending and underperforming campaigns. It acts as a critical checkpoint for marketers to review and adjust their strategies based on cost efficiency.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Empowering Your Business with Accurate Cost Predictions</strong></p>

                                <p className="text-xl py-4 text-gray-700">At its core, the Google Ads Cost Calculator is about empowering businesses with the knowledge to craft more effective advertising campaigns. By providing precise cost predictions, our tool helps businesses of all sizes strategize more confidently, ensuring that every ad dollar spent is an informed decision.</p>

                                <p className="text-xl py-4 text-gray-700">In conclusion, if you’re looking for a reliable, efficient, and user-friendly way to manage your Google Ads expenditures, the Google Ads Cost Calculator is an indispensable tool. Embrace the certainty it brings to your advertising efforts and optimize your campaigns for peak performance. For further information or to begin using this tool, visit <a href="/" className="font-bold underline">our website</a>.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <LeadsModal isOpen={isOpen} setIsOpen={setIsOpen} />
            <CalendarModal isOpen={modalIsOpen} setIsOpen={setModalIsOpen} />
        </PageLayout>
    )
}
export default GoogleAdsCostCalculatorPage
