export const COLUMNS = [
    {
        Header: 'Features',
        accessor: 'features',
    },
    {
        Header: 'Essential',
        accessor: 'essential',
    },
    {
        Header: 'Advance',
        accessor: 'advance',
    },
    {
        Header: 'Enterprise',
        accessor: 'enterprise',
    },
]
