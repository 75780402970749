import React, { useState } from 'react'
import PageLayout from '../../../../Layout/PageLayout/Index'
import MetaHead from '../../../../Components/MetaHead'
import LeadsForm from '../../../../Components/LeadsForm/Index'
import Slider from '../../../../Components/Slider/Index'
import PaidAdsPricing from '../../../../Components/PaidAdsPricing/Index'
import PaidAdsPricingTable from '../../../../Components/PaidAdsPricingTable/Index'
import Process from '../../../../Components/Process/Index'
import CalendarModal from '../../../../Layout/CalendarModal/Index'
import LeadsModal from '../../../../Layout/LeadsModal/Index'
import CommerceSlider from '../../../../Components/CommerceSlider/Index'
import { SiFacebook, SiInstagram, SiLinkedin, SiTiktok, SiYoutube, SiTwitter } from '@icons-pack/react-simple-icons'
import SocialProof from '../../../../Components/SocialProof/Index'
import PPCFAQs from '../../../../Components/FAQs/PPCFAQs/Index'


const VirginiaPage = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [isMobile, setIsMobile] = useState(false)
    return (
        <PageLayout>
            <MetaHead title="Alexandria PPC Agency | Pay-Per-Click Services in Virginia | Gurulabs"
                description="Alexandria's top PPC agency, Gurulabs, offers expert PPC management services to help businesses grow. Contact us today for a free consultation!"
            />
            <section className="">
                <div className="max-w-7xl mx-auto py-8 lg:py-48 px-4">
                    <div className="flex flex-col lg:flex-row justify-between items-center">
                        <div className="w-full lg:w-8/12 py-4">
                            <h1 className="text-3xl lg:text-6xl my-1">Your Pay Per Click
                                <span className="text-green-500 relative mx-2">
                                    Management Agency
                                    <svg width="175" height="3" viewBox="0 0 175 3" fill="none" className="absolute hidden lg:block left-6">
                                        <path d="M1.43945 1.54395H173.903" stroke="#48BB78" strokeWidth="2" strokeLinecap="round" />
                                    </svg>
                                </span>
                                in Alexandria, VA</h1>
                            <p className="my-3">Gurulabs is your key to unlocking the potential of your website’s online visibility. Unlock your website's full potential with Gurulabs. Our team of experts enhances your online visibility and maximizes traffic using proven PPC Management strategies.</p>
                            <LeadsForm />
                        </div>
                        <div className="w-full lg:w-4/12 py-4">
                            <img src="/frontend/images/svg/icon-22.svg" className="mx-auto" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <Slider />
            <section>
                <div className="bg-gray-50 py-12">
                    <div className="max-w-7xl mx-auto">
                        <div className='block sm:flex justify-center items-center px-4'>
                            <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                <span className="relative">Come take a look at what services plans</span>
                                <span className="text-green-500 ml-2 relative">
                                    we offer!
                                </span>
                                <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4 ml-16 hidden xl:block" width={151} height={3} fill="none"><path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                            </h2>
                        </div>
                        <PaidAdsPricing modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
                    </div>
                </div>
            </section>
            <SocialProof />
            <section>
                <div className="max-w-5xl mx-auto py-24">
                    <div className='block sm:flex sm:flex-col justify-center items-center px-4'>
                        <h2 className="block text-3xl sm:text-4xl py-5 relative">
                            <span className="relative">Our Tech-Enabled</span>
                            <span className="text-green-500 ml-2 relative">
                                Socail Media Ads Management
                            </span>
                            <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-1/2 -mt-2 ml-16 hidden xl:block" width={238} height={17} fill="none"><path stroke="#48BB78" strokeLinecap="round" strokeWidth="2" d="M1.833 12.55c101.19 8.798 198.897-3.667 235.102-11" /></svg>
                        </h2>
                        <p className="text-base text-center">Our Lead generation process helps you identify potential customers or clients and gather their contact information to nurture them toward a sale.</p>
                    </div>
                </div>
                <div className="max-w-7xl mx-auto pb-24">
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
                        <Process
                            title="Facebook Ads Management"
                            description="Choosing Gurulabs for your Facebook Ads management ensures that your campaigns are optimized for the highest engagement and conversion rates. Our expert team leverages advanced targeting options, compelling ad creatives, and data-driven strategies to reach your ideal audience, drive meaningful interactions, and maximize your return on investment. With Gurulabs, your Facebook advertising will be managed by professionals dedicated to achieving measurable results and expanding your brand’s reach."
                            button="button-3"
                            Icon={SiFacebook}
                        />
                        <Process
                            title="LinkedIn Ads Management"
                            description="Gurulabs is the ideal choice for LinkedIn Ads management, as we specialize in creating professional and highly-targeted campaigns that connect your business with key decision-makers and industry professionals. Our team understands the unique nuances of LinkedIn advertising, from Sponsored Content to InMail, and uses this expertise to drive high-quality leads and build valuable B2B relationships. Trust Gurulabs to enhance your LinkedIn presence and achieve your marketing objectives effectively."
                            button="button-2"
                            Icon={SiLinkedin}
                        />
                        <Process
                            title="Youtube Ads Management"
                            description="For YouTube Ads management, Gurulabs offers unparalleled expertise in creating captivating video content and strategic placements that engage viewers and convert them into customers. We utilize comprehensive analytics and audience insights to optimize your campaigns, ensuring your videos reach the right people at the right time. By partnering with Gurulabs, you’ll benefit from a team committed to maximizing your brand’s visibility and driving substantial growth through YouTube advertising."
                            button="button-3"
                            Icon={SiYoutube}
                        />
                        <Process
                            title="Twitter (X) Ads Management"
                            description="Gurulabs stands out as the premier choice for Twitter (X) Ads management by harnessing the platform’s real-time nature to create impactful ad campaigns that resonate with your target audience. Our specialists craft engaging Promoted Tweets, Trends, and Accounts to increase your brand’s visibility, foster engagement, and drive traffic to your website. With Gurulabs, you’ll experience a data-driven approach that ensures your Twitter advertising delivers outstanding results and enhances your online presence."
                            button="button-2"
                            Icon={SiTwitter}
                        />
                        <Process
                            title="Instagram Ads Management"
                            description="Selecting Gurulabs for Instagram Ads management means entrusting your campaigns to a team that excels in leveraging the platform’s visual appeal to captivate and convert your audience. We develop visually stunning ads and stories that align with your brand’s aesthetic, combined with precise targeting to reach the most relevant users. Our expertise in Instagram’s unique advertising features ensures that your campaigns generate significant engagement and drive measurable growth for your business."
                            button="button-3"
                            Icon={SiInstagram}
                        />
                        <Process
                            title="TikTok Ads Management"
                            description="Gurulabs is the perfect partner for TikTok Ads management, offering innovative strategies to tap into the platform’s rapidly growing user base. Our team creates entertaining and shareable ad content that captures the essence of your brand and resonates with TikTok’s dynamic audience. By choosing Gurulabs, you’ll benefit from our deep understanding of TikTok’s algorithm and trends, ensuring your ads achieve maximum visibility and engagement, ultimately driving conversions and brand loyalty."
                            button="button-2"
                            Icon={SiTiktok}
                        />
                    </div>
                </div>
            </section>
            <section>
                <div className="bg-gray-50 py-12">
                    <div className="max-w-7xl mx-auto">
                        <div>
                            <div className='block sm:flex justify-center items-center px-4'>
                                <h1 className="block text-3xl sm:text-4xl py-5 relative">
                                    <span className="relative">We're your trusted partner </span>
                                    <span className="text-green-500 ml-2 relative">
                                        PPC Management in Virginia
                                    </span>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4 ml-16 hidden xl:block" width={151} height={3} fill="none"><path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                                </h1>
                            </div>
                        </div>
                        <div className="px-4">
                            <div>
                                <p className="text-xl py-4 text-gray-700">Our Paid Advertising Solutions Propel Your Brand Ahead of the Competition</p>
                                <p className="text-xl py-4 text-gray-700">Virginia, with its rich history and strong economic base, presents unique opportunities for businesses. As a state that prides itself on a diverse and innovative economy, Virginia is an ideal location for companies looking to grow and thrive.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Gurulabs' PPC Services</strong></p>
                                <p className="text-xl py-4 text-gray-700">Role of PPC: Pay-per-click marketing is essential for firms aiming to increase their market presence in Virginia’s competitive landscape. It provides scalable and targeted solutions that can adapt to the dynamics of the local market.</p>
                                <p className="text-xl py-4 text-gray-700">Service Offerings: Gurulabs offers precisely tailored, cost-efficient PPC solutions that align with your strategic goals. Our team boasts deep expertise in digital marketing, spearheading successful SEO and PPC initiatives that drive significant business outcomes.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>How Our Process Works</strong></p>
                                <p className="text-xl py-4 text-gray-700">Submit a Request: Fill out a form to get started with your <a href="/get-started" className="font-bold underline">Alexandria ppc marketing agency</a></p>
                                <p className="text-xl py-4 text-gray-700">Consultation: Schedule a free consultation to dive deep into your business needs.</p>
                                <p className="text-xl py-4 text-gray-700">Receive Your Proposal: Obtain a customized proposal designed specifically for your Virginia business.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Client Success Stories</strong></p>
                                <p className="text-xl py-4 text-gray-700">Our clients in Virginia report outstanding improvements:</p>
                                <p className="text-xl py-4 text-gray-700">Organic Traffic has surged by over 600%</p>
                                <p className="text-xl py-4 text-gray-700">Online Leads have increased by more than 300%</p>
                                <p className="text-xl py-4 text-gray-700">Significant enhancements in conversions and lead quality</p>
                                <p className="text-xl py-4 text-gray-700">Our commitment to exceptional results is reflected in our client testimonials, highlighting our efficiency and proactive approach.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Comprehensive PPC Services</strong></p>
                                <p className="text-xl py-4 text-gray-700">Keyword Research: We optimize your campaigns by targeting the most effective keywords, maximizing ROI.</p>
                                <p className="text-xl py-4 text-gray-700">Landing Page Optimization: We ensure that your landing pages are crafted to convert visitors into leads, boosting your results.</p>
                                <p className="text-xl py-4 text-gray-700">PPC Management: Employ streamlined and effective PPC management strategies to rapidly generate leads.</p>
                                <p className="text-xl py-4 text-gray-700">Paid Advertising: Making paid advertising easy and rewarding, focusing on a clear ROI.</p>
                                <p className="text-xl py-4 text-gray-700">A/B Testing for Ads: Determine the best-performing ads with A/B testing to enhance your campaign’s efficiency.</p>
                                <p className="text-xl py-4 text-gray-700">Conversion Rate Optimization: Apply a comprehensive digital marketing strategy focused on improving user experience and expanding your customer base.</p>
                                <p className="text-xl py-4 text-gray-700">Social Media Campaigns: Engage with the ideal Virginia audience through targeted advertising on platforms like Facebook, Twitter, LinkedIn, and Instagram.</p>
                                <p className="text-xl py-4 text-gray-700">Search Engine Ads Management: Ensure your ads achieve top performance on major search engines tailored to the Virginia market.</p>
                                <p className="text-xl py-4 text-gray-700">Localized Advertising: Target specific local audiences within Virginia using platforms like Nextdoor, enhancing relevance and conversion rates.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Why Gurulabs Should Be Your Virginia PPC Partner</strong></p>
                                <p className="text-xl py-4 text-gray-700">We provide unmatched PPC strategy expertise, tailored to meet the unique business environments of Virginia. Our dedicated team ensures your campaigns are finely tuned to the specifics of the local market.</p>
                                <p className="text-xl py-4 text-gray-700">Our approach is built on transparency, integrity, and a commitment to client success. Regular updates and comprehensive reports demonstrate the effectiveness of your campaigns and our ongoing commitment to your goals.</p>
                                <p className="text-xl py-4 text-gray-700">At Gurulabs, we focus on building enduring relationships with our clients, aiming to support their sustained success in Virginia’s competitive landscape.</p>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
            <PaidAdsPricingTable />
            <PPCFAQs />
            <section>
                <div className="bg-amber-50 hero-reviews py-12">
                    <div className="max-w-7xl mx-auto overflow-hidden">
                        <div className='block sm:flex justify-center items-center py-16 px-4'>
                            <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                <span className="relative">Hear from our Lead Gen</span>
                                <span className="text-green-500 ml-2 relative">
                                    Clients!
                                </span>
                                <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4  hidden xl:block" width={151} height={3} fill="none"><path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                            </h2>
                        </div>
                        <CommerceSlider />
                    </div>
                </div>
            </section>
        </PageLayout>
    )
}
export default VirginiaPage
