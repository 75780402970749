import React, { useEffect, useState } from 'react'
import PageLayout from '../../../Layout/PageLayout/Index'
import mixpanel from 'mixpanel-browser'
import ConversionPageHero from './PageHero/Index'
import CommerceSlider from '../../../Components/CommerceSlider/Index'
import Slider from '../../../Components/Slider/Index'
import CROPricing from '../../../Components/CROPricing/Index'
import SocialProof from '../../../Components/SocialProof/Index'
import Newsletter from '../../../Components/Newsletter/Index'
import Process from '../../../Components/Process/Index'
import { DocumentReportIcon, UserCircleIcon, LightBulbIcon, ScaleIcon, AdjustmentsIcon, ChartBarIcon, AdjustmentsHorizontalIcon, DocumentMagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { AdjustmentsVerticalIcon } from '@heroicons/react/24/solid'
import CalendarModal from '../../../Layout/CalendarModal/Index'
import CROFAQs from '../../../Components/FAQs/CROFAQs/Index'
import Popup from '../../../Components/Popup/Index'
import SEOMetaHead from '../../../Components/SEOMetaHead/Index'

const ConversionRateOptimizationPage = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false)

    useEffect(() => {
        mixpanel.track("eCommerce Page Visited")
    }, [])

    return (
        <PageLayout>
            <SEOMetaHead
                title="Conversion Rate Optimization Agency - Gurulabs"
                description="Gurulabs is a leading Conversion Rate Optimization agency dedicated to boosting your website's performance and conversion rates."
                convertKitPage="https://relentless-inventor-6062.ck.page/5a9ed0f555/index.js"
            />
            <ConversionPageHero />
            <Slider />
            <section>
                <div className="bg-gray-50 py-12">
                    <div className="max-w-7xl mx-auto">
                        <div className='block sm:flex justify-center items-center px-4'>
                            <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                <span className="relative">Come take a look at what services plans</span>
                                <span className="text-green-500 ml-2 relative">
                                    we offer!
                                </span>
                                <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4 ml-16 hidden xl:block" width={151} height={3} fill="none"><path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                            </h2>
                        </div>
                        <CROPricing modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
                    </div>
                </div>
            </section>
            <SocialProof />
            <section>
                <div className="bg-gray-50 py-12">
                    <div className="max-w-7xl mx-auto">
                        <div>
                            <div className='block sm:flex justify-center items-center px-4'>
                                <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                    <span className="relative">We're an affordable</span>
                                    <span className="text-green-500 ml-2 relative">
                                        Conversion Rate Optimization agency
                                    </span>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4 ml-16 hidden xl:block" width={151} height={3} fill="none"><path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                                </h2>
                            </div>
                            <p className="py-4 text-lg text-center">Our <a href="//www.gurulabs.dev/blog/finding-search-engine-optimization-agency-near-washington-dc" className='font-bold underline'>Conversion Rate Optimization</a> process helps you identify your target audience, optimize your website and content strategies, and gather valuable insights to increase organic traffic and maximize your return on investment.</p>

                        </div>
                        <div className="px-4">
                            <div>
                                <p className="text-xl py-4 text-gray-700">At Gurulabs, conversion rate optimization (CRO) is fundamental to improving the effectiveness of your website by enhancing the percentage of visitors who complete a desired action. This optimization process involves an in-depth analysis of how users interact with your site, followed by implementing strategies that reduce barriers to conversion. By optimizing your website's user experience, we aim to increase the likelihood of converting visitors into customers, thereby maximizing your return on investment and driving sustainable growth.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Enhancing Website Performance with CRO Services</strong></p>

                                <p className="text-xl py-4 text-gray-700">Gurulabs' conversion rate optimization services are designed to refine the performance of your website. By employing A/B testing, heat mapping, and usability testing, we identify potential pain points in the user journey. Our tailored recommendations are meant to streamline navigation, improve content relevance, and enhance overall user engagement, leading to higher conversion rates and improved site performance.</p>

                                <p className="text-xl py-4 text-gray-700">Our approach to CRO involves leveraging the latest industry practices and innovative tools to ensure your e-commerce site not only attracts customers but also excels in converting them. This includes optimizing check-out processes, personalizing user experiences, and ensuring that your website's structure is optimized for maximum conversion potential.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Advanced CRO Tools for E-commerce Excellence</strong></p>

                                <p className="text-xl py-4 text-gray-700">Utilizing cutting-edge conversion rate optimization tools, Gurulabs can significantly enhance your e-commerce site's sales. These tools help in understanding user behavior, testing different website versions, and providing actionable insights that drive decision-making. With these tools, we can fine-tune every aspect of the user experience, from landing pages to product listings, ensuring that every visitor has the highest chance of becoming a customer.</p>

                                <p className="text-xl py-4 text-gray-700">Optimizing landing pages is crucial for any successful online presence. At Gurulabs, we focus on creating landing pages that are not only visually appealing but also highly functional. Our strategies include clear call-to-action buttons, simplified forms, engaging content, and optimized images. Each of these elements is designed to work harmoniously to minimize bounce rates and maximize conversions.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Shopify Optimization through Tailored CRO Techniques</strong></p>

                                <p className="text-xl py-4 text-gray-700">Shopify platforms benefit immensely from specialized conversion rate optimization techniques that address unique e-commerce challenges. Gurulabs tailors strategies specifically for Shopify to enhance product discovery, streamline the checkout process, and personalize shopping experiences based on user data. This targeted approach not only improves user satisfaction but also significantly boosts sales figures.</p>

                                <p className="text-xl py-4 text-gray-700">Looking ahead to 2024, the landscape of conversion rate optimization is evolving with a strong emphasis on automation and personalization. Emerging trends include the use of artificial intelligence to predict user behavior, the integration of advanced analytics for deeper insight into conversion metrics, and the increasing importance of mobile optimization as consumers shift towards mobile-first browsing.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Insights from CRO Audits</strong></p>

                                <p className="text-xl py-4 text-gray-700">A comprehensive conversion rate optimization audit by Gurulabs can reveal critical insights about your website's effectiveness. Our audits delve into aspects such as page speed, user navigation paths, and call-to-action placements to identify obstacles that might be hindering user engagement. Addressing these issues can lead to a more engaging site that encourages users to stay longer and convert more frequently.</p>

                                <p className="text-xl py-4 text-gray-700">Artificial intelligence plays a transformative role in enhancing CRO strategies. At Gurulabs, we leverage AI to automate data analysis, enabling us to rapidly identify successful patterns and predict future behaviors. This allows for real-time adjustments to your website, ensuring optimal performance at all times.</p>

                                <p className="text-xl py-4 text-gray-700">Creating a conversion rate optimization checklist is essential for any new website project. At Gurulabs, we ensure that your checklist includes key elements like mobile responsiveness, fast loading times, SEO-optimized content, and clear navigation. These elements are crucial for building a foundation that supports high conversion rates right from the launch.</p>

                                <p className="text-xl py-4 text-gray-700">In conclusion, choosing Gurulabs for your conversion rate optimization needs means partnering with a leader in digital strategy innovation. Our comprehensive approach to CRO not only enhances your website's efficiency but also drives significant improvements in your overall online presence. Let Gurulabs help you transform your site into a high-converting engine that significantly boosts your bottom line.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Newsletter />
            <section>
                <div className="max-w-5xl mx-auto py-24">
                    <div className='block sm:flex sm:flex-col justify-center items-center px-4'>
                        <h2 className="block text-3xl sm:text-4xl py-5 relative">
                            <span className="relative">Our Strategic</span>
                            <span className="text-green-500 ml-2 relative">
                                Conversion Rate Optimization Process
                            </span>
                            <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-1/2 -mt-2 ml-16 hidden xl:block" width={238} height={17} fill="none"><path stroke="#48BB78" strokeLinecap="round" strokeWidth="2" d="M1.833 12.55c101.19 8.798 198.897-3.667 235.102-11" /></svg>
                        </h2>
                        <p className="text-base text-center">Our methodical approach to conversion rate optimization helps you enhance user experience and increase your conversion rates through data-driven insights and innovative techniques.</p>
                    </div>
                </div>
                <div className="max-w-7xl mx-auto pb-24">
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
                        <Process
                            title="Data Collection"
                            description="The first step in our CRO process involves gathering and analyzing data from your website. We use various analytics tools to understand user behavior and identify areas that need improvement."
                            button="button-3"
                            Icon={DocumentMagnifyingGlassIcon}
                        />
                        <Process
                            title="User Behavior Analysis"
                            description="We employ heatmaps, session recordings, and user feedback to gain insights into how users interact with your site. This step helps us pinpoint usability issues and opportunities for optimization."
                            button="button-3"
                            Icon={UserCircleIcon}
                        />
                        <Process
                            title="Hypothesis Formation"
                            description="Based on our analytics and user behavior analysis, we formulate hypotheses for A/B testing. Each hypothesis aims to test changes that could potentially increase your site's conversion rates."
                            button="button-2"
                            Icon={LightBulbIcon}
                        />
                        <Process
                            title="A/B Testing"
                            description="We conduct A/B testing to experiment with different versions of your webpages. This methodical testing allows us to determine which variations perform better and should be implemented permanently."
                            button="button-2"
                            Icon={ScaleIcon}
                        />
                        <Process
                            title="Learning and Optimization"
                            description="After testing, we analyze the results and learn from the data. Insights gained from these tests guide further optimization efforts to enhance your website's performance continuously."
                            button="button-3"
                            Icon={AdjustmentsVerticalIcon}
                        />
                        <Process
                            title="Reporting and Review"
                            description="The final step in our CRO process involves compiling reports and reviewing the outcomes with you. We discuss the improvements made and plan future strategies to keep your conversion rates climbing."
                            button="button-2"
                            Icon={ChartBarIcon}
                        />
                    </div>
                </div>
            </section>
            <CROFAQs />
            <section>
                <div className="bg-amber-50 hero-reviews py-12">
                    <div className="max-w-7xl mx-auto overflow-hidden">
                        <div className='block sm:flex justify-center items-center py-16 px-4'>
                            <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                <span className="relative">Hear from our Lead Gen</span>
                                <span className="text-green-500 ml-2 relative">
                                    Clients!
                                </span>
                                <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4  hidden xl:block" width={151} height={3} fill="none"><path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                            </h2>
                        </div>
                        <CommerceSlider />
                    </div>
                </div>
            </section>
            <CalendarModal isOpen={modalIsOpen} setIsOpen={setModalIsOpen} />
            {/* <Popup /> */}
        </PageLayout>
    )
}
export default ConversionRateOptimizationPage
