import React from 'react'
import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
// import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const faqs = [
    {
        question: "How much should I expect to pay for a website design?",
        answer: "The cost of website design can vary greatly depending on several factors, including the complexity of the site, the expertise of the designer, and the specific needs of your business. At Gurulabs, a leading web design company, prices can range from a few thousand dollars for a basic website to tens of thousands for a fully customized, feature-rich website. This variability ensures that whether you're a small business looking for a simple online presence or a large corporation requiring a complex ecommerce platform, Gurulabs can provide a tailored solution that fits your budget and goals.",
    },
    {
        question: "How much does it cost to hire a website designer?",
        answer: "Hiring a website designer involves a range of costs, depending on the designer's experience and the scope of the project. At Gurulabs, a professional website design company, hiring a designer for a standard project typically starts at around $5,000, which includes basic design and functionality. For more advanced features, such as integration with back-end systems, responsive design for mobile devices, and custom graphic design, costs can increase significantly. We ensure that clients receive detailed proposals that break down all costs, ensuring transparency and allowing for informed decision-making.",
    },
    {
        question: "How much does it cost to design a website list?",
        answer: "Designing a comprehensive website list, which often includes multiple pages tailored to different aspects of your business, can start from around $10,000 and go up depending on the depth and breadth of content and functionality required. Gurulabs, a web design and development company, specializes in creating detailed website lists that cater to the diverse needs of various stakeholders, from customers to employees, ensuring a seamless user experience that enhances engagement and drives business growth.",
    },
    {
        question: "How much do web design agencies charge?",
        answer: "Web design agencies like Gurulabs typically offer a range of pricing models to accommodate different types of clients and projects. For a standard website design, prices may start at about $5,000 and can go up to $50,000 or more for large-scale, complex projects that require advanced functionality and custom development. Our agency provides detailed quotations that outline all services, from initial design concepts to final deployment, ensuring our clients understand where their investment is going.",
    },
    {
        question: "What is the typical budget for a custom website design project?",
        answer: "For a custom website design project, businesses should typically budget anywhere from $10,000 to $50,000 when working with a reputable web design firm like Gurulabs. This budget includes a variety of services such as user interface design, custom graphics, advanced interactivity, and, if necessary, integration with external databases and applications. Our approach is to work closely with clients to define their exact needs, ensuring that the budget is used efficiently to create a website that not only looks great but also performs well and meets their strategic objectives.",
    },
    {
        question: "What are the average fees for professional website design services?",
        answer: "The average fees for professional website design services at a top web design company like Gurulabs can range from $5,000 for a basic site to over $30,000 for a highly complex, custom-built website with numerous functionalities. These fees cover everything from the initial design phase to the final rollout, including testing and revisions. Our team ensures that each project is tailored to meet the unique needs of the client, delivering a final product that enhances their online presence.",
    },
    {
        question: "How much do freelance web designers typically charge for a website?",
        answer: "Freelance web designers may charge anywhere from $500 for a very small, simple website to over $5,000 for more complex designs. At Gurulabs, while we offer the expertise and reliability of a dedicated agency, we recognize the value that freelancers can provide, especially for startups and small businesses. Clients looking for more substantial and sustained web development projects often find that partnering with a full-service web design and development company offers additional value, such as ongoing support and enhanced customization.",
    },
    {
        question: "What are the pricing models used by web design firms?",
        answer: "Web design firms like Gurulabs commonly use several pricing models to accommodate the diverse needs of their clients. These include flat fee projects, where a set price is agreed upon for the entire project; hourly rates, which are often used for projects where the scope is not clearly defined; and retainer agreements for clients who require ongoing services. Our firm works closely with clients to determine the most appropriate pricing model based on their specific requirements and budget constraints.",
    },
    {
        question: "How much does it cost to redesign an existing website?",
        answer: "The cost to redesign an existing website typically ranges from $3,000 to over $20,000 at a comprehensive web design agency like Gurulabs, depending on the extent of the redesign and the specific functionalities required. Redesigns can vary from simple cosmetic updates to complete overhauls that involve restructuring the entire architecture of the site. At Gurulabs, we focus on ensuring that the redesign not only updates the aesthetic and functional aspects of the site but also aligns with the latest web standards and business goals, providing a significant return on investment for our clients.",
    },
    {
        question: "What sets Gurulabs apart from other web design companies in Washington DC?",
        answer: "Gurulabs stands out among Washington DC web design companies due to our comprehensive approach to digital solutions. We combine innovative design with advanced web technologies to deliver websites that are not only visually appealing but also functionally robust. Our commitment to understanding each client's unique needs and our deep industry knowledge enables us to deliver customized solutions that drive results, making us a leader in the competitive DC market.",
    },
    {
        question: "How can I find the best website designer near me in Arlington?",
        answer: "Finding the best website designer near you in Arlington involves researching local designers' portfolios and client testimonials. Gurulabs, located near Arlington, is known for its transparent client engagements and high-quality designs. We recommend scheduling a consultation with our team to discuss your specific needs and review our extensive portfolio, which showcases our capability to deliver diverse and impactful web solutions.",
    },
    {
        question: "What are the benefits of hiring a local web design firm in Northern Virginia?",
        answer: "Hiring a local web design firm in Northern Virginia, such as Gurulabs, offers several benefits, including personalized service, local market understanding, and the convenience of face-to-face meetings. Our local presence enables us to provide tailored solutions that align with the specific cultural and business landscapes of the Northern Virginia area, which can significantly benefit your project's outcome.",
    },
    {
        question: "What should I look for when choosing a web design and development company?",
        answer: "When choosing a web design and development company, it's crucial to consider their portfolio, client reviews, responsiveness to inquiries, and the range of services they offer. At Gurulabs, we pride ourselves on our comprehensive service offerings—from initial design to development and post-launch support—and our proactive communication. We ensure that every project aligns with our clients' strategic goals, delivering not just a website but a complete digital experience.",
    },
    {
        question: "How does the website design process work at Gurulabs?",
        answer: "At Gurulabs, our website design process begins with a detailed consultation to understand your business objectives and user needs. We then move into the design phase, creating wireframes and mockups that reflect the planned site structure and visual style. Once approved, our development team transforms these designs into a fully functional website. Throughout this process, we maintain open lines of communication with our clients, ensuring that the final product accurately meets their expectations and is delivered on time.",
    },
    {
        question: "What trends are currently influencing web design in Washington DC?",
        answer: "Web design in Washington DC is currently influenced by trends that prioritize user experience, mobile responsiveness, and visual storytelling. As a leading web design firm in the area, Gurulabs incorporates these trends by focusing on creating accessible, intuitive, and visually engaging designs that communicate our clients' messages effectively. Additionally, we ensure that all our designs adhere to the best SEO practices to enhance online visibility.",
    },
    {
        question: "Can Gurulabs handle both web design and WordPress development?",
        answer: "Absolutely, Gurulabs specializes in both web design and WordPress development. We provide comprehensive services that cover everything from custom WordPress theme design to plugin development and site maintenance. Our expertise ensures that clients receive a robust, scalable, and secure website that meets their specific needs, all powered by the world’s leading content management system, WordPress.",
    },
    {
        question: "Why is responsive design important for my website?",
        answer: "Responsive design ensures that your website provides an optimal viewing experience across a wide range of devices, from desktops to mobile phones. This adaptability improves user engagement and accessibility, which are crucial for retaining visitors and improving search engine rankings. Gurulabs prioritizes responsive design in all our projects, ensuring that your site performs well on any device, enhancing user experience and maximizing your site’s reach and impact.",
    },
    {
        question: "How does Gurulabs ensure my website is optimized for search engines?",
        answer: "At Gurulabs, SEO is integrated into every stage of the web design and development process. From the initial coding to content creation, we ensure that all elements of your website are optimized for search engines. This includes using SEO-friendly URL structures, optimizing meta tags, and ensuring that all content is relevant and keyword-rich. Our holistic approach helps to increase your website's visibility and ranking in search engine results, driving more traffic to your site.",
    },
    {
        question: "What kind of support can I expect after my website goes live?",
        answer: "After your website goes live, Gurulabs provides ongoing support to ensure your site remains up-to-date and operates smoothly. Our support includes regular maintenance, security updates, and troubleshooting any issues that arise. Additionally, we offer training for your team to manage the website’s content effectively and make minor updates independently, ensuring you have full control over your digital presence.",
    },
    {
        question: "Does Gurulabs offer web development services for e-commerce businesses?",
        answer: "Yes, Gurulabs offers specialized web development services for e-commerce businesses. We understand the unique challenges of online retail and provide tailored solutions that include custom shopping cart integration, secure payment processing, and inventory management systems. Our goal is to create a seamless online shopping experience that not only meets the needs of your business but also enhances your customers' satisfaction and loyalty.",
    },
];



function WebDesignFAQs() {
    return (
        <div className="bg-white">
            <div className="mx-auto max-w-7xl px-6 py-12 sm:py-24 lg:px-8 lg:py-24">
                <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
                    <h2 className="text-3xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
                    <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                        {faqs.map((faq) => (
                            <Disclosure as="div" key={faq.question} className="pt-6">
                                {({ open }) => (
                                    <>
                                        <dt>
                                            <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                                                <span className="text-base font-semibold leading-7">{faq.question}</span>
                                                <span className="ml-6 flex h-7 items-center">
                                                    {open ? (
                                                        <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                    ) : (
                                                        <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                    )}
                                                </span>
                                            </Disclosure.Button>
                                        </dt>
                                        <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                            <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
}


export default WebDesignFAQs
