import React from 'react'
import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
// import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const faqs = [
    {
        question: "What is Google My Business Optimization?",
        answer: "Google My Business Optimization refers to the process of enhancing your Google My Business profile to improve its visibility in local search results, attract more customers, and provide accurate information about your business."
    },
    {
        question: "How much does Google My Business Optimization cost?",
        answer: "The cost of Google My Business Optimization varies depending on the service provider and the level of optimization required. Some services may offer packages ranging from $100 to $500 or more, depending on the scope of work."
    },
    {
        question: "Can I do SEO on Google My Business?",
        answer: "Yes, you can optimize your Google My Business profile for SEO by ensuring accurate business information, using relevant keywords in your business description, adding high-quality images, and regularly updating posts."
    },
    {
        question: "How do I improve my Google My Business ranking?",
        answer: "To improve your Google My Business ranking, you can focus on optimizing your profile with accurate information, collecting positive reviews, posting regular updates, and ensuring consistency across all online business listings."
    },
    {
        question: "What information should I include in my Google My Business profile?",
        answer: "Include your business name, address, phone number, website, business hours, a detailed description, categories, photos, and any relevant attributes such as accessibility or payment options in your Google My Business profile."
    },
    {
        question: "Why is Google My Business important for local SEO?",
        answer: "Google My Business is crucial for local SEO because it helps your business appear in local search results, especially in the 'Local Pack,' which can drive more traffic and customers to your physical location or website."
    },
    {
        question: "How often should I update my Google My Business profile?",
        answer: "You should update your Google My Business profile regularly, especially when there are changes in your business hours, services, or location. Regular posts and updates can also help improve your visibility."
    },
    {
        question: "What are Google My Business posts?",
        answer: "Google My Business posts are short updates or announcements that appear in your business profile. They can be used to share offers, events, news, or new products and can help keep your audience engaged."
    },
    {
        question: "How do I get more reviews on Google My Business?",
        answer: "You can get more reviews on Google My Business by asking satisfied customers to leave feedback, providing excellent service, and making it easy for customers to find the review link."
    },
    {
        question: "Can negative reviews on Google My Business be removed?",
        answer: "Negative reviews cannot typically be removed unless they violate Google’s review policies. However, you can respond professionally to address the customer's concerns, which can help mitigate the impact of a negative review."
    },
    {
        question: "How does Google My Business affect my website traffic?",
        answer: "Google My Business can significantly affect your website traffic by making your business more visible in local search results, leading to increased clicks on your website link from potential customers."
    },
    {
        question: "What are Google My Business categories?",
        answer: "Google My Business categories help define what your business does. You can choose a primary category and additional categories that best describe your business to improve search relevance."
    },
    {
        question: "Can I use Google My Business for a service-area business?",
        answer: "Yes, Google My Business allows service-area businesses to list the areas they serve instead of a physical address. This is particularly useful for businesses that operate in multiple locations or provide services at customer locations."
    },
    {
        question: "How can I track the performance of my Google My Business profile?",
        answer: "You can track the performance of your Google My Business profile through Google’s built-in Insights tool, which provides data on how customers find your profile, what actions they take, and how your profile performs in search."
    },
    {
        question: "What is the Google My Business 'Local Pack'?",
        answer: "The Google My Business 'Local Pack' is a section of Google’s search results that displays the top three local business listings relevant to the search query. Appearing in the Local Pack can significantly increase your visibility."
    },
    {
        question: "How do I verify my Google My Business profile?",
        answer: "You can verify your Google My Business profile by requesting a postcard from Google, which will contain a verification code. Alternatively, some businesses may qualify for phone or email verification."
    },
    {
        question: "Can I add multiple locations to Google My Business?",
        answer: "Yes, you can add multiple locations to Google My Business if your business operates in different areas. Each location will have its own profile, which you can manage individually or through bulk location management."
    },
    {
        question: "What are the benefits of Google My Business photos?",
        answer: "Google My Business photos can help showcase your business, attract more customers, and improve your profile’s appeal. High-quality images of your products, services, and premises can enhance customer trust and engagement."
    },
    {
        question: "What is Google My Business messaging?",
        answer: "Google My Business messaging allows customers to contact your business directly through your profile. It’s a convenient way to answer questions, provide customer service, and engage with potential customers."
    },
    {
        question: "Can I schedule posts on Google My Business?",
        answer: "Currently, Google My Business does not offer native post-scheduling. However, you can use third-party tools to schedule posts and ensure consistent updates to your profile."
    }
];








function GMBFAQs() {
    return (
        <div className="bg-white">
            <div className="mx-auto max-w-7xl px-6 py-12 sm:py-24 lg:px-8 lg:py-24">
                <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
                    <h2 className="text-3xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
                    <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                        {faqs.map((faq) => (
                            <Disclosure as="div" key={faq.question} className="pt-6">
                                {({ open }) => (
                                    <>
                                        <dt>
                                            <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                                                <span className="text-base font-semibold leading-7">{faq.question}</span>
                                                <span className="ml-6 flex h-7 items-center">
                                                    {open ? (
                                                        <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                    ) : (
                                                        <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                    )}
                                                </span>
                                            </Disclosure.Button>
                                        </dt>
                                        <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                            <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
}


export default GMBFAQs
