import React, { useState } from 'react';

const CLTVCalculator = () => {
    const [averagePurchaseValue, setAveragePurchaseValue] = useState(100);
    const [purchaseFrequency, setPurchaseFrequency] = useState(5);
    const [customerLifespan, setCustomerLifespan] = useState(3);
    const [cltv, setCLTV] = useState(0.0);

    const calculateCLTV = () => {
        const lifetimeValue = averagePurchaseValue * purchaseFrequency * customerLifespan;
        setCLTV(lifetimeValue);
    };

    return (
        <div className="max-w-lg mx-auto mt-8 p-6 bg-gray-100 rounded-md shadow-md">
            <h2 className="text-2xl font-semibold mb-4">Customer Lifetime Value (CLTV) Calculator</h2>
            <div className="mb-4">
                <label htmlFor="averagePurchaseValue" className="block mb-1">Average Purchase Value:</label>
                <input
                    type="number"
                    id="averagePurchaseValue"
                    value={averagePurchaseValue}
                    onChange={(e) => setAveragePurchaseValue(Number(e.target.value))}
                    className="border-gray-300 rounded-md p-2 w-full"
                    min="0"
                />
            </div>
            <div className="mb-4">
                <label htmlFor="purchaseFrequency" className="block mb-1">Purchase Frequency (per year):</label>
                <input
                    type="number"
                    id="purchaseFrequency"
                    value={purchaseFrequency}
                    onChange={(e) => setPurchaseFrequency(Number(e.target.value))}
                    className="border-gray-300 rounded-md p-2 w-full"
                    min="0"
                />
            </div>
            <div className="mb-4">
                <label htmlFor="customerLifespan" className="block mb-1">Customer Lifespan (years):</label>
                <input
                    type="number"
                    id="customerLifespan"
                    value={customerLifespan}
                    onChange={(e) => setCustomerLifespan(Number(e.target.value))}
                    className="border-gray-300 rounded-md p-2 w-full"
                    min="0"
                />
            </div>
            <div className="mb-4">
                <button
                    onClick={calculateCLTV}
                    className="px-4 py-2 button text-white rounded hover:bg-blue-700"
                >
                    Calculate CLTV
                </button>
            </div>
            <div className="mb-4">
                <h3 className="text-xl">
                    Customer Lifetime Value: ${cltv.toFixed(2)}
                </h3>
            </div>
        </div>
    );
};

export default CLTVCalculator;
