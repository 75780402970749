import React, { Fragment, useRef, useState } from 'react';
import { Tab } from '@headlessui/react'
import { Link } from '@inertiajs/inertia-react';
import Image from '../Image/Index';
import { motion, useInView } from 'framer-motion';



const ServiceTabs = () => {
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [modalIsOpen, setModalIsOpen] = useState(false)

    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    return (
        <div className='py-16 px-4'>
            <div className="bg-white shadow-2xl rounded-lg py-24">
                <Tab.Group as='div' className="" vertical>
                    <Tab.List as='div' className="block xs:flex flex-wrap justify-center items-center gap-6">
                        <Tab as='div' className="flex flex-col items-center outline-none cursor-pointer py-4">
                            {({ selected }) => (
                                <div className={`${selected ? "border-b-4 border-green-50 flex flex-col items-center" : "border-b-2 border-gray-500 flex flex-col items-center"}`}>
                                    <svg className="h-12 w-12 mx-auto flex" fill="none">
                                        <path fill={selected ? "#FFAC36" : "#D9D9D9"} d="M26.917 35.2a.943.943 0 0 1-.844-.538l-1.725-3.493a.94.94 0 1 1 1.687-.833l1.723 3.493a.94.94 0 0 1-.425 1.26.964.964 0 0 1-.416.11ZM18.913 29.333a11.267 11.267 0 1 1 10.731-14.705.942.942 0 0 1-1.792.575 9.385 9.385 0 1 0-.73 7.418.94.94 0 1 1 1.64.914 11.284 11.284 0 0 1-9.85 5.798Z" />
                                        <path fill={selected ? "#49BC79" : "#D9D9D9"} d="M17.564 22a.935.935 0 0 1-.584-.204l-3.224-2.544a.94.94 0 1 1 1.166-1.475l2.373 1.88 4.242-7.155a.94.94 0 0 1 1.57-.075l2.998 4.124 7.349-8.598a.94.94 0 1 1 1.43 1.223l-8.123 9.5a.938.938 0 0 1-1.475-.056l-2.86-3.933-4.06 6.851a.95.95 0 0 1-.802.462Z" />
                                        <path fill={selected ? "#49BC79" : "#D9D9D9"} d="M34.484 13.573a.938.938 0 0 1-.938-.871l-.231-3.125-3.125.231a.943.943 0 1 1-.137-1.88l4.062-.302a.943.943 0 0 1 1.008.871l.298 4.063a.94.94 0 0 1-.867 1.005l-.07.008Z" />
                                    </svg>
                                    <h3 className="text-xl py-4">Search Engine</h3>
                                </div>
                            )}
                        </Tab>
                        <Tab as='div' className="flex flex-col items-center outline-none cursor-pointer py-4">
                            {({ selected }) => (
                                <div className={`${selected ? "border-b-4 border-green-50 flex flex-col items-center" : "border-b-2 border-gray-500 flex flex-col items-center"}`}>
                                    <svg className="h-12 w-12 mx-auto flex" fill="none">
                                        <path fill={selected ? "#FFAC36" : "#D9D9D9"} d="M25.448 34.5a1.062 1.062 0 0 1-.513-.137 1.015 1.015 0 0 1-.375-.366l-2.129-3.568h-4.483a3.884 3.884 0 0 1-2.693-1.078 3.62 3.62 0 0 1-1.118-2.599V17.55a3.62 3.62 0 0 1 1.118-2.6 3.884 3.884 0 0 1 2.693-1.077H32.97c1.01.002 1.978.39 2.691 1.079a3.62 3.62 0 0 1 1.117 2.598v9.203a3.62 3.62 0 0 1-1.117 2.598 3.883 3.883 0 0 1-2.69 1.079h-4.486l-2.127 3.568a1.025 1.025 0 0 1-.384.37c-.16.088-.34.134-.525.133ZM17.94 15.873c-.46 0-.902.177-1.228.49a1.65 1.65 0 0 0-.51 1.186v9.203c0 .445.184.87.51 1.185.326.314.768.49 1.228.49h5.084c.18.002.357.05.513.137.156.088.285.213.376.364L25.44 31.5l1.53-2.573c.09-.151.22-.277.376-.364.156-.088.332-.135.513-.137h5.084c.46 0 .9-.177 1.226-.491a1.65 1.65 0 0 0 .51-1.184V17.55c-.002-.444-.185-.87-.51-1.184a1.77 1.77 0 0 0-1.226-.492H17.939Z" />
                                        <path fill={selected ? "#49BC79" : "#D9D9D9"} d="M10.67 24.47c-1.01 0-1.98-.388-2.694-1.078a3.62 3.62 0 0 1-1.117-2.601v-9.2a3.62 3.62 0 0 1 1.118-2.6 3.884 3.884 0 0 1 2.693-1.077h15.036c1.01.001 1.977.389 2.69 1.078a3.62 3.62 0 0 1 1.118 2.598c0 .266-.11.52-.304.708a1.055 1.055 0 0 1-.733.293c-.274 0-.538-.105-.732-.293a.984.984 0 0 1-.304-.708c0-.444-.184-.87-.509-1.184a1.77 1.77 0 0 0-1.226-.491H10.685c-.46 0-.903.176-1.228.49a1.65 1.65 0 0 0-.51 1.185v9.2c0 .446.183.873.509 1.188a1.77 1.77 0 0 0 1.229.491c.274 0 .538.105.732.293a.984.984 0 0 1 .304.708c0 .265-.11.52-.304.707a1.055 1.055 0 0 1-.732.293h-.015Z" />
                                    </svg>
                                    <h3 className="text-xl py-4">Social Media</h3>
                                </div>
                            )}
                        </Tab>
                        <Tab as='div' className="flex flex-col items-center outline-none cursor-pointer py-4">
                            {({ selected }) => (
                                <div className={`${selected ? "border-b-4 border-green-50 flex flex-col items-center" : "border-b-2 border-gray-500 flex flex-col items-center"}`}>
                                    <svg className="h-12 w-12 mx-auto flex" fill="none">
                                        <path fill={selected ? "#FFAC36" : "#D9D9D9"} d="M19.017 23.924c-3.101 0-5.626-2.832-5.626-6.31 0-3.477 2.524-6.31 5.626-6.31 3.102 0 5.63 2.83 5.63 6.31 0 3.48-2.525 6.31-5.63 6.31Zm0-10.555c-1.97 0-3.573 1.908-3.573 4.257 0 2.348 1.603 4.257 3.573 4.257s3.577-1.912 3.577-4.257c0-2.346-1.592-4.269-3.577-4.269v.012Z" />
                                        <path fill={selected ? "#49BC79" : "#D9D9D9"} d="M31.71 23.548c-2.328 0-4.222-2.108-4.222-4.69 0-2.584 1.894-4.692 4.222-4.692 2.328 0 4.225 2.105 4.225 4.691s-1.894 4.691-4.225 4.691Zm0-7.33c-1.197 0-2.17 1.173-2.17 2.64 0 1.465.974 2.638 2.17 2.638s2.172-1.188 2.172-2.639c0-1.451-.973-2.65-2.172-2.65v.011Z" />
                                        <path fill={selected ? "#FFAC36" : "#D9D9D9"} d="M26.488 36.695H11.55a3.685 3.685 0 0 1-3.68-3.682 7.092 7.092 0 0 1 7.087-7.087h8.124a7.092 7.092 0 0 1 7.087 7.087 3.686 3.686 0 0 1-3.68 3.682ZM14.957 27.98a5.04 5.04 0 0 0-5.035 5.034 1.634 1.634 0 0 0 1.628 1.63h14.938a1.633 1.633 0 0 0 1.627-1.63 5.04 5.04 0 0 0-5.034-5.034h-8.124Z" />
                                        <path fill={selected ? "#49BC79" : "#D9D9D9"} d="M36.63 36.695h-3.314a1.026 1.026 0 1 1 0-2.052h3.316a1.633 1.633 0 0 0 1.628-1.63 5.04 5.04 0 0 0-5.034-5.034h-1.513a1.026 1.026 0 1 1 0-2.053h1.513a7.092 7.092 0 0 1 7.086 7.087 3.686 3.686 0 0 1-3.682 3.682Z" />
                                    </svg>
                                    <h3 className="text-xl py-4">eCommerce</h3>
                                </div>
                            )}
                        </Tab>
                        <Tab as='div' className="flex flex-col items-center outline-none cursor-pointer py-4">
                            {({ selected }) => (
                                <div className={`${selected ? "border-b-4 border-green-50 flex flex-col items-center" : "border-b-2 border-gray-500 flex flex-col items-center"}`}>
                                    <svg className="h-12 w-12 mx-auto flex" fill="none">
                                        <path fill={selected ? "#49BC79" : "#D9D9D9"} d="M15.717 25.182H9.4c-.267 0-.522-.107-.71-.296a1.016 1.016 0 0 1-.295-.716v-4.493c.004-.761.306-1.49.84-2.027a2.862 2.862 0 0 1 2.014-.847h4.462c.266 0 .522.106.71.296.19.19.295.447.295.716v6.36a1.015 1.015 0 0 1-.294.71c-.188.189-.44.295-.705.297Zm-5.306-2.024h4.306v-4.337h-3.445a.841.841 0 0 0-.597.249.853.853 0 0 0-.247.601l-.018 3.487Z" />
                                        <path fill={selected ? "#49BC79" : "#D9D9D9"} d="M29.648 22.004c-.266 0-.522-.106-.71-.296a1.016 1.016 0 0 1-.295-.716v-9.744L16.23 18.685a.999.999 0 0 1-1.324-.38 1.018 1.018 0 0 1 .297-1.355l13.931-8.345a.997.997 0 0 1 1.011 0 1.017 1.017 0 0 1 .508.882V21.01a1.016 1.016 0 0 1-.3.704c-.188.186-.441.29-.705.29Z" />
                                        <path fill={selected ? "#49BC79" : "#D9D9D9"} d="M29.648 33.526c-.18 0-.358-.05-.514-.142L15.203 25.04a1.013 1.013 0 0 1-.297-1.354 1.006 1.006 0 0 1 1.324-.38l12.413 7.433v-9.741c0-.269.106-.526.295-.716a1.002 1.002 0 0 1 1.421 0c.188.19.294.447.294.716V32.52c0 .268-.106.525-.295.715-.188.19-.443.296-.71.297v-.006ZM11.255 33.527a2.861 2.861 0 0 1-2.014-.847 2.902 2.902 0 0 1-.84-2.027V24.17c0-.268.105-.526.294-.715.188-.19.444-.297.71-.297h3.699c.266 0 .522.107.71.297.189.19.295.447.295.715v6.483a2.901 2.901 0 0 1-.841 2.027 2.861 2.861 0 0 1-2.014.847Zm-.845-8.345v5.47c0 .226.09.442.248.602a.841.841 0 0 0 1.193 0 .853.853 0 0 0 .248-.601v-5.47H10.41Z" />
                                        <path fill={selected ? "#FFAC36" : "#D9D9D9"} fillRule="evenodd" d="M35.882 14c.488 0 .883.598.883 1.336v11.328c0 .738-.395 1.336-.883 1.336-.487 0-.882-.598-.882-1.336V15.336c0-.738.395-1.336.882-1.336Z" clipRule="evenodd" />
                                    </svg>
                                    <h3 className="text-xl py-4">Local Lead Ads</h3>
                                </div>
                            )}
                        </Tab>
                        <Tab as='div' className="flex flex-col items-center outline-none cursor-pointer py-4">
                            {({ selected }) => (
                                <div className={`${selected ? "border-b-4 border-green-50 flex flex-col items-center" : "border-b-2 border-gray-500 flex flex-col items-center"}`}>
                                    <svg className="h-12 w-12 mx-auto flex" fill="none">
                                        <path fill={selected ? "#FFAC36" : "#D9D9D9"} d="M13.967 35.798h-3.73a2.91 2.91 0 0 1-2.907-2.907v-4.76a2.91 2.91 0 0 1 2.907-2.911h3.733a2.91 2.91 0 0 1 2.91 2.91v4.76a2.91 2.91 0 0 1-2.913 2.908Zm-3.73-8.274a.602.602 0 0 0-.603.606v4.76a.603.603 0 0 0 .603.603h3.733a.6.6 0 0 0 .606-.602v-4.76a.606.606 0 0 0-.606-.607h-3.733ZM25.97 35.798h-3.733a2.913 2.913 0 0 1-2.91-2.907V20.074a2.913 2.913 0 0 1 2.91-2.907h3.734a2.913 2.913 0 0 1 2.91 2.907v12.817a2.914 2.914 0 0 1-2.91 2.907Zm-3.733-16.326a.606.606 0 0 0-.606.602v12.817a.606.606 0 0 0 .606.603h3.734a.606.606 0 0 0 .605-.603V20.074a.606.606 0 0 0-.605-.602h-3.734Z" />
                                        <path fill={selected ? "#49BC79" : "#D9D9D9"} d="M37.974 35.798h-3.733a2.914 2.914 0 0 1-2.91-2.907V15.113a2.917 2.917 0 0 1 2.91-2.91h3.733a2.918 2.918 0 0 1 2.91 2.91V32.89a2.914 2.914 0 0 1-2.91 2.907ZM34.242 14.49a.61.61 0 0 0-.606.606v17.779a.606.606 0 0 0 .606.602h3.733a.606.606 0 0 0 .606-.602V15.096a.61.61 0 0 0-.605-.606H34.24Z" />
                                    </svg>
                                    <h3 className="text-xl py-4">Data Analytics</h3>
                                </div>
                            )}
                        </Tab>
                        <Tab as='div' className="flex flex-col items-center outline-none cursor-pointer py-4">
                            {({ selected }) => (
                                <div className={`${selected ? "border-b-4 border-green-50 flex flex-col items-center" : "border-b-2 border-gray-500 flex flex-col items-center"}`}>
                                    <svg className="h-12 w-12 mx-auto flex" width="49" height="48" fill="none">
                                        <path fill={selected ? "#49BC79" : "#d9d9d9"} d="M15.373 32.584a1.246 1.246 0 0 1-.69-.212l-10.1-6.782a1.24 1.24 0 0 1 0-2.057l10.032-6.728a1.239 1.239 0 0 1 1.381 2.057l-8.499 5.705 8.57 5.751a1.24 1.24 0 0 1-.708 2.27l.014-.004ZM32.778 32.584a1.24 1.24 0 0 1-.709-2.27l8.57-5.75-8.499-5.706a1.24 1.24 0 0 1 1.381-2.057l10.032 6.728a1.24 1.24 0 0 1 0 2.058l-10.1 6.781c-.199.136-.434.211-.675.216Z" />
                                        <path fill={selected ? "#ffac36" : "#d9d9d9"} d="M19.02 38.296a1.224 1.224 0 0 1-1.132-.737 1.239 1.239 0 0 1-.022-.948l10.107-26.12a1.24 1.24 0 1 1 2.312.896l-10.107 26.12a1.243 1.243 0 0 1-1.158.79Z" />
                                    </svg>

                                    <h3 className="text-xl py-4">Development</h3>
                                </div>
                            )}
                        </Tab>
                    </Tab.List>
                    <Tab.Panels className="py-4 max-w-5xl mx-auto">
                        <Tab.Panel>
                            <div className="block lg:flex flex-row-reverse items-center px-4">
                                <div className="w-full lg:w-1/2 px-4">
                                    <Image
                                        src="/frontend/images/png/image-01.webp"
                                        fallback="frontend/images/png/image-01.png"
                                        className="h-[349px] w-[565px] mx-auto"
                                        alt="Search Engine Optimization"
                                    />
                                </div>
                                <div className="w-full lg:w-1/2 px-4">
                                    <h2 className="text-2xl sm:text-3xl py-2 relative">Boost
                                        <span className="text-green-500 relative"> Organic Traffic </span>
                                        <motion.svg
                                            className="left-24 absolute hidden xl:block"
                                            ref={ref}
                                            width={201}
                                            height={8}
                                            viewBox="0 0 201 8"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            initial={{ pathLength: 0 }}  // Start with no line drawn
                                            animate={{ pathLength: useInView ? 1 : 0 }}  // Animate to full line drawn
                                            transition={{
                                                duration: 2,  // Adjust the speed of the underline animation
                                                ease: "easeInOut",
                                                repeatType: 'reverse',
                                            }}
                                        >
                                            <motion.path
                                                d="M1.15381 6.85266C103.415 -2.66642 176.123 2.88637 199.694 6.85266"
                                                stroke="#48BB78"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                initial={{ pathLength: 0 }}
                                                animate={{ pathLength: 1 }}  // Animate drawing the underline
                                                transition={{
                                                    duration: 2,  // Duration of the animation
                                                    ease: "easeInOut",
                                                    repeatType: 'reverse',
                                                }}
                                            />
                                        </motion.svg>
                                        <span>
                                            to your business
                                        </span>
                                    </h2>
                                    <ul className="py-4">
                                        <li className="flex items-center py-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none"><g fill="#48BB78" clipPath="url(#a)"><path d="M9.112 11.646a.72.72 0 0 1-.476-.179l-3.55-3.132a.72.72 0 0 1 .953-1.08L9.085 9.93l6.357-6.311a.723.723 0 0 1 1.015 1.03L9.62 11.444a.723.723 0 0 1-.508.201Z" /><path d="M8.67 16.84A7.998 7.998 0 0 1 2.06 4.346a8 8 0 0 1 9.176-3.086.72.72 0 1 1-.463 1.365 6.559 6.559 0 1 0 4.189 8.076.72.72 0 0 1 1.381.412 8.045 8.045 0 0 1-7.672 5.727Z" /></g><defs><clipPath id="a"><path fill="#fff" d="M.67.84h16v16h-16z" /></clipPath></defs></svg>
                                            <span className="text-base">We find billions of national and local keywords</span>
                                        </li>
                                        <li className="flex items-center py-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none"><g fill="#48BB78" clipPath="url(#a)"><path d="M9.112 11.646a.72.72 0 0 1-.476-.179l-3.55-3.132a.72.72 0 0 1 .953-1.08L9.085 9.93l6.357-6.311a.723.723 0 0 1 1.015 1.03L9.62 11.444a.723.723 0 0 1-.508.201Z" /><path d="M8.67 16.84A7.998 7.998 0 0 1 2.06 4.346a8 8 0 0 1 9.176-3.086.72.72 0 1 1-.463 1.365 6.559 6.559 0 1 0 4.189 8.076.72.72 0 0 1 1.381.412 8.045 8.045 0 0 1-7.672 5.727Z" /></g><defs><clipPath id="a"><path fill="#fff" d="M.67.84h16v16h-16z" /></clipPath></defs></svg>
                                            <span className="text-base">We help optimize On-Page SEO for your site</span>
                                        </li>
                                        <li className="flex items-center py-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none"><g fill="#48BB78" clipPath="url(#a)"><path d="M9.112 11.646a.72.72 0 0 1-.476-.179l-3.55-3.132a.72.72 0 0 1 .953-1.08L9.085 9.93l6.357-6.311a.723.723 0 0 1 1.015 1.03L9.62 11.444a.723.723 0 0 1-.508.201Z" /><path d="M8.67 16.84A7.998 7.998 0 0 1 2.06 4.346a8 8 0 0 1 9.176-3.086.72.72 0 1 1-.463 1.365 6.559 6.559 0 1 0 4.189 8.076.72.72 0 0 1 1.381.412 8.045 8.045 0 0 1-7.672 5.727Z" /></g><defs><clipPath id="a"><path fill="#fff" d="M.67.84h16v16h-16z" /></clipPath></defs></svg>
                                            <span className="text-base">We help Off-Page SEO using latest techniques</span>
                                        </li>
                                    </ul>
                                    <Link href="/lead-generation" className="relative py-3 px-3 top-1 right-2 button-full rounted-sm text-white hover:bg-green-500 w-full">Lead Generation</Link>
                                </div>
                            </div>
                        </Tab.Panel>
                        <Tab.Panel>
                            <div className="block lg:flex flex-row-reverse items-center px-4">
                                <div className="w-full lg:w-1/2 px-4">
                                    <Image
                                        src="/frontend/images/png/image-02.webp"
                                        fallback="frontend/images/png/image-02.png"
                                        className="h-[340px] w-[464px] mx-auto"
                                        alt="Social Media Management"
                                    />
                                </div>
                                <div className="w-full lg:w-1/2 px-4">
                                    <h2 className="text-2xl sm:text-3xl py-2 relative">More People See Your Product,<br />
                                        <span className="text-green-500 relative"> More Leads Coming </span>{' '}
                                        <svg xmlns="http://www.w3.org/2000/svg" width={273} height={8} fill="none" className="hidden xl:block">
                                            <path stroke="#48BB78" strokeLinecap="round" strokeWidth="1.5" d="M1.078 6.623c139.263-9.519 238.279-3.966 270.378 0" />
                                        </svg>
                                    </h2>
                                    <ul>
                                        <li className="flex items-center py-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none"><g fill="#48BB78" clipPath="url(#a)"><path d="M9.112 11.646a.72.72 0 0 1-.476-.179l-3.55-3.132a.72.72 0 0 1 .953-1.08L9.085 9.93l6.357-6.311a.723.723 0 0 1 1.015 1.03L9.62 11.444a.723.723 0 0 1-.508.201Z" /><path d="M8.67 16.84A7.998 7.998 0 0 1 2.06 4.346a8 8 0 0 1 9.176-3.086.72.72 0 1 1-.463 1.365 6.559 6.559 0 1 0 4.189 8.076.72.72 0 0 1 1.381.412 8.045 8.045 0 0 1-7.672 5.727Z" /></g><defs><clipPath id="a"><path fill="#fff" d="M.67.84h16v16h-16z" /></clipPath></defs></svg>
                                            <span className="text-base">Optimize your Social Media Accounts Management</span>
                                        </li>
                                        <li className="flex items-center py-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none"><g fill="#48BB78" clipPath="url(#a)"><path d="M9.112 11.646a.72.72 0 0 1-.476-.179l-3.55-3.132a.72.72 0 0 1 .953-1.08L9.085 9.93l6.357-6.311a.723.723 0 0 1 1.015 1.03L9.62 11.444a.723.723 0 0 1-.508.201Z" /><path d="M8.67 16.84A7.998 7.998 0 0 1 2.06 4.346a8 8 0 0 1 9.176-3.086.72.72 0 1 1-.463 1.365 6.559 6.559 0 1 0 4.189 8.076.72.72 0 0 1 1.381.412 8.045 8.045 0 0 1-7.672 5.727Z" /></g><defs><clipPath id="a"><path fill="#fff" d="M.67.84h16v16h-16z" /></clipPath></defs></svg>
                                            <span className="text-base">Save hours from Social Media Automation</span>
                                        </li>
                                        <li className="flex items-center py-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none"><g fill="#48BB78" clipPath="url(#a)"><path d="M9.112 11.646a.72.72 0 0 1-.476-.179l-3.55-3.132a.72.72 0 0 1 .953-1.08L9.085 9.93l6.357-6.311a.723.723 0 0 1 1.015 1.03L9.62 11.444a.723.723 0 0 1-.508.201Z" /><path d="M8.67 16.84A7.998 7.998 0 0 1 2.06 4.346a8 8 0 0 1 9.176-3.086.72.72 0 1 1-.463 1.365 6.559 6.559 0 1 0 4.189 8.076.72.72 0 0 1 1.381.412 8.045 8.045 0 0 1-7.672 5.727Z" /></g><defs><clipPath id="a"><path fill="#fff" d="M.67.84h16v16h-16z" /></clipPath></defs></svg>
                                            <span className="text-base">Manage & Enhance Google My Business</span>
                                        </li>
                                        <li className="flex items-center py-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none"><g fill="#48BB78" clipPath="url(#a)"><path d="M9.112 11.646a.72.72 0 0 1-.476-.179l-3.55-3.132a.72.72 0 0 1 .953-1.08L9.085 9.93l6.357-6.311a.723.723 0 0 1 1.015 1.03L9.62 11.444a.723.723 0 0 1-.508.201Z" /><path d="M8.67 16.84A7.998 7.998 0 0 1 2.06 4.346a8 8 0 0 1 9.176-3.086.72.72 0 1 1-.463 1.365 6.559 6.559 0 1 0 4.189 8.076.72.72 0 0 1 1.381.412 8.045 8.045 0 0 1-7.672 5.727Z" /></g><defs><clipPath id="a"><path fill="#fff" d="M.67.84h16v16h-16z" /></clipPath></defs></svg>
                                            <span className="text-base">Accelerate Youtube Management & subscribers</span>
                                        </li>
                                    </ul>
                                    <Link href="/social-media" className="relative py-3 px-3 top-1 right-2  button-full rounted-sm text-white hover:bg-green-500 w-full">Social Media</Link>
                                </div>
                            </div>
                        </Tab.Panel>
                        <Tab.Panel>
                            <div className="block lg:flex flex-row-reverse items-center px-4">
                                <div className="w-full lg:w-1/2 px-4">
                                    <Image
                                        src="/frontend/images/png/image-04.webp"
                                        fallback="frontend/images/png/image-04.png"
                                        className="h-[340px] w-[464px] mx-auto"
                                        alt="eCommerce Management"
                                    />
                                </div>
                                <div className="w-full lg:w-1/2 px-4">
                                    <h2 className="text-2xl sm:text-4xl py-2 relative">Build & Manage your
                                        <span className="text-green-500 relative"> Online Store </span>
                                        <svg className="absolute" width={201} height={8} viewBox="0 0 201 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.15381 6.85266C103.415 -2.66642 176.123 2.88637 199.694 6.85266" stroke="#48BB78" strokeWidth="1.5" strokeLinecap="round" />
                                        </svg>
                                    </h2>
                                    <ul>
                                        <li className="flex items-center py-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none"><g fill="#48BB78" clipPath="url(#a)"><path d="M9.112 11.646a.72.72 0 0 1-.476-.179l-3.55-3.132a.72.72 0 0 1 .953-1.08L9.085 9.93l6.357-6.311a.723.723 0 0 1 1.015 1.03L9.62 11.444a.723.723 0 0 1-.508.201Z" /><path d="M8.67 16.84A7.998 7.998 0 0 1 2.06 4.346a8 8 0 0 1 9.176-3.086.72.72 0 1 1-.463 1.365 6.559 6.559 0 1 0 4.189 8.076.72.72 0 0 1 1.381.412 8.045 8.045 0 0 1-7.672 5.727Z" /></g><defs><clipPath id="a"><path fill="#fff" d="M.67.84h16v16h-16z" /></clipPath></defs></svg>
                                            <span className="text-base">Design & Develop Shopping Stores</span>
                                        </li>
                                        <li className="flex items-center py-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none"><g fill="#48BB78" clipPath="url(#a)"><path d="M9.112 11.646a.72.72 0 0 1-.476-.179l-3.55-3.132a.72.72 0 0 1 .953-1.08L9.085 9.93l6.357-6.311a.723.723 0 0 1 1.015 1.03L9.62 11.444a.723.723 0 0 1-.508.201Z" /><path d="M8.67 16.84A7.998 7.998 0 0 1 2.06 4.346a8 8 0 0 1 9.176-3.086.72.72 0 1 1-.463 1.365 6.559 6.559 0 1 0 4.189 8.076.72.72 0 0 1 1.381.412 8.045 8.045 0 0 1-7.672 5.727Z" /></g><defs><clipPath id="a"><path fill="#fff" d="M.67.84h16v16h-16z" /></clipPath></defs></svg>
                                            <span className="text-base">Optimize and bug fix your online stores</span>
                                        </li>
                                        <li className="flex items-center py-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none"><g fill="#48BB78" clipPath="url(#a)"><path d="M9.112 11.646a.72.72 0 0 1-.476-.179l-3.55-3.132a.72.72 0 0 1 .953-1.08L9.085 9.93l6.357-6.311a.723.723 0 0 1 1.015 1.03L9.62 11.444a.723.723 0 0 1-.508.201Z" /><path d="M8.67 16.84A7.998 7.998 0 0 1 2.06 4.346a8 8 0 0 1 9.176-3.086.72.72 0 1 1-.463 1.365 6.559 6.559 0 1 0 4.189 8.076.72.72 0 0 1 1.381.412 8.045 8.045 0 0 1-7.672 5.727Z" /></g><defs><clipPath id="a"><path fill="#fff" d="M.67.84h16v16h-16z" /></clipPath></defs></svg>
                                            <span className="text-base">Use technologies like Shopify & WooCommerce etc</span>
                                        </li>

                                    </ul>
                                    <Link href="/e-commerce" className="relative py-3 px-3 top-1 right-2  button-full rounted-sm text-white hover:bg-green-500 w-full">eCommerce</Link>
                                </div>
                            </div>
                        </Tab.Panel>
                        <Tab.Panel>
                            <div className="block lg:flex flex-row-reverse items-center px-4">
                                <div className="w-full lg:w-1/2 px-4">
                                    <Image
                                        src="/frontend/images/png/image-05.webp"
                                        fallback="frontend/images/png/image-05.png"
                                        className="h-[340px] w-[464px] mx-auto"
                                        alt="Leads Generation Agency"
                                    />
                                </div>
                                <div className="w-full lg:w-1/2 px-4">
                                    <h2 className="text-2xl sm:text-4xl py-2 relative">Create Lead Magnets
                                        <span className="text-green-500 relative"> Lead Funnels </span>
                                        <svg className="absolute" width="201" height="8" viewBox="0 0 201 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.15381 6.85266C103.415 -2.66642 176.123 2.88637 199.694 6.85266" stroke="#48BB78" strokeWidth="1.5" strokeLinecap="round" />
                                        </svg>
                                        for your business</h2>
                                    <ul>
                                        <li className="flex items-center py-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none"><g fill="#48BB78" clipPath="url(#a)"><path d="M9.112 11.646a.72.72 0 0 1-.476-.179l-3.55-3.132a.72.72 0 0 1 .953-1.08L9.085 9.93l6.357-6.311a.723.723 0 0 1 1.015 1.03L9.62 11.444a.723.723 0 0 1-.508.201Z" /><path d="M8.67 16.84A7.998 7.998 0 0 1 2.06 4.346a8 8 0 0 1 9.176-3.086.72.72 0 1 1-.463 1.365 6.559 6.559 0 1 0 4.189 8.076.72.72 0 0 1 1.381.412 8.045 8.045 0 0 1-7.672 5.727Z" /></g><defs><clipPath id="a"><path fill="#fff" d="M.67.84h16v16h-16z" /></clipPath></defs></svg>
                                            <span className="text-base">Desing and Build Funnels</span>
                                        </li>
                                        <li className="flex items-center py-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none"><g fill="#48BB78" clipPath="url(#a)"><path d="M9.112 11.646a.72.72 0 0 1-.476-.179l-3.55-3.132a.72.72 0 0 1 .953-1.08L9.085 9.93l6.357-6.311a.723.723 0 0 1 1.015 1.03L9.62 11.444a.723.723 0 0 1-.508.201Z" /><path d="M8.67 16.84A7.998 7.998 0 0 1 2.06 4.346a8 8 0 0 1 9.176-3.086.72.72 0 1 1-.463 1.365 6.559 6.559 0 1 0 4.189 8.076.72.72 0 0 1 1.381.412 8.045 8.045 0 0 1-7.672 5.727Z" /></g><defs><clipPath id="a"><path fill="#fff" d="M.67.84h16v16h-16z" /></clipPath></defs></svg>
                                            <span className="text-base">Automate your funnels to save time</span>
                                        </li>
                                        <li className="flex items-center py-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none"><g fill="#48BB78" clipPath="url(#a)"><path d="M9.112 11.646a.72.72 0 0 1-.476-.179l-3.55-3.132a.72.72 0 0 1 .953-1.08L9.085 9.93l6.357-6.311a.723.723 0 0 1 1.015 1.03L9.62 11.444a.723.723 0 0 1-.508.201Z" /><path d="M8.67 16.84A7.998 7.998 0 0 1 2.06 4.346a8 8 0 0 1 9.176-3.086.72.72 0 1 1-.463 1.365 6.559 6.559 0 1 0 4.189 8.076.72.72 0 0 1 1.381.412 8.045 8.045 0 0 1-7.672 5.727Z" /></g><defs><clipPath id="a"><path fill="#fff" d="M.67.84h16v16h-16z" /></clipPath></defs></svg>
                                            <span className="text-base">Help setup your business processes</span>
                                        </li>
                                        <li className="flex items-center py-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none"><g fill="#48BB78" clipPath="url(#a)"><path d="M9.112 11.646a.72.72 0 0 1-.476-.179l-3.55-3.132a.72.72 0 0 1 .953-1.08L9.085 9.93l6.357-6.311a.723.723 0 0 1 1.015 1.03L9.62 11.444a.723.723 0 0 1-.508.201Z" /><path d="M8.67 16.84A7.998 7.998 0 0 1 2.06 4.346a8 8 0 0 1 9.176-3.086.72.72 0 1 1-.463 1.365 6.559 6.559 0 1 0 4.189 8.076.72.72 0 0 1 1.381.412 8.045 8.045 0 0 1-7.672 5.727Z" /></g><defs><clipPath id="a"><path fill="#fff" d="M.67.84h16v16h-16z" /></clipPath></defs></svg>
                                            <span className="text-base">Work with click funnels, lead pages, landigi and more</span>
                                        </li>
                                    </ul>
                                    <Link href="/paid-ads" className="relative py-3 px-3 top-1 right-2  button-full rounted-sm text-white hover:bg-green-500 w-full">Lead Ads</Link>
                                </div>
                            </div>
                        </Tab.Panel>
                        <Tab.Panel>
                            <div className="block lg:flex flex-row-reverse items-center px-4">
                                <div className="w-full lg:w-1/2 px-4">
                                    <Image
                                        src="/frontend/images/png/process-04.webp"
                                        fallback="frontend/images/png/process-04.png"
                                        className="h-[340px] w-[464px] mx-auto"
                                        alt="Data Analytics Automation"
                                    />
                                </div>
                                <div className="w-full lg:w-1/2 px-4">
                                    <h2 className="text-2xl sm:text-4xl py-2 relative">Measure and Optimize
                                        <span className="text-green-500 relative"> Data Analytics </span>
                                        <svg className="absolute" width="201" height="8" viewBox="0 0 201 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.15381 6.85266C103.415 -2.66642 176.123 2.88637 199.694 6.85266" stroke="#48BB78" strokeWidth="1.5" strokeLinecap="round" />
                                        </svg>
                                        of your business</h2>
                                    <ul>
                                        <li className="flex items-center py-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none"><g fill="#48BB78" clipPath="url(#a)"><path d="M9.112 11.646a.72.72 0 0 1-.476-.179l-3.55-3.132a.72.72 0 0 1 .953-1.08L9.085 9.93l6.357-6.311a.723.723 0 0 1 1.015 1.03L9.62 11.444a.723.723 0 0 1-.508.201Z" /><path d="M8.67 16.84A7.998 7.998 0 0 1 2.06 4.346a8 8 0 0 1 9.176-3.086.72.72 0 1 1-.463 1.365 6.559 6.559 0 1 0 4.189 8.076.72.72 0 0 1 1.381.412 8.045 8.045 0 0 1-7.672 5.727Z" /></g><defs><clipPath id="a"><path fill="#fff" d="M.67.84h16v16h-16z" /></clipPath></defs></svg>
                                            <span className="text-base">Web analytics on traffic and visitor behavior</span>
                                        </li>
                                        <li className="flex items-center py-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none"><g fill="#48BB78" clipPath="url(#a)"><path d="M9.112 11.646a.72.72 0 0 1-.476-.179l-3.55-3.132a.72.72 0 0 1 .953-1.08L9.085 9.93l6.357-6.311a.723.723 0 0 1 1.015 1.03L9.62 11.444a.723.723 0 0 1-.508.201Z" /><path d="M8.67 16.84A7.998 7.998 0 0 1 2.06 4.346a8 8 0 0 1 9.176-3.086.72.72 0 1 1-.463 1.365 6.559 6.559 0 1 0 4.189 8.076.72.72 0 0 1 1.381.412 8.045 8.045 0 0 1-7.672 5.727Z" /></g><defs><clipPath id="a"><path fill="#fff" d="M.67.84h16v16h-16z" /></clipPath></defs></svg>
                                            <span className="text-base">Track social engagement & monitor brand sentiment</span>
                                        </li>
                                        <li className="flex items-center py-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none"><g fill="#48BB78" clipPath="url(#a)"><path d="M9.112 11.646a.72.72 0 0 1-.476-.179l-3.55-3.132a.72.72 0 0 1 .953-1.08L9.085 9.93l6.357-6.311a.723.723 0 0 1 1.015 1.03L9.62 11.444a.723.723 0 0 1-.508.201Z" /><path d="M8.67 16.84A7.998 7.998 0 0 1 2.06 4.346a8 8 0 0 1 9.176-3.086.72.72 0 1 1-.463 1.365 6.559 6.559 0 1 0 4.189 8.076.72.72 0 0 1 1.381.412 8.045 8.045 0 0 1-7.672 5.727Z" /></g><defs><clipPath id="a"><path fill="#fff" d="M.67.84h16v16h-16z" /></clipPath></defs></svg>
                                            <span className="text-base">Track campaign performance and optimize ROI </span>
                                        </li>
                                        <li className="flex items-center py-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none"><g fill="#48BB78" clipPath="url(#a)"><path d="M9.112 11.646a.72.72 0 0 1-.476-.179l-3.55-3.132a.72.72 0 0 1 .953-1.08L9.085 9.93l6.357-6.311a.723.723 0 0 1 1.015 1.03L9.62 11.444a.723.723 0 0 1-.508.201Z" /><path d="M8.67 16.84A7.998 7.998 0 0 1 2.06 4.346a8 8 0 0 1 9.176-3.086.72.72 0 1 1-.463 1.365 6.559 6.559 0 1 0 4.189 8.076.72.72 0 0 1 1.381.412 8.045 8.045 0 0 1-7.672 5.727Z" /></g><defs><clipPath id="a"><path fill="#fff" d="M.67.84h16v16h-16z" /></clipPath></defs></svg>
                                            <span className="text-base">Conduct A/B testing to compare performance </span>
                                        </li>
                                    </ul>
                                    <Link href="/analytics/data-analytics" className="relative py-3 px-3 top-1 right-2  button-full rounted-sm text-white hover:bg-green-500 w-full">Data Analytics</Link>
                                </div>
                            </div>
                        </Tab.Panel>
                        <Tab.Panel>
                            <div className="block lg:flex flex-row-reverse items-center px-4">
                                <div className="w-full lg:w-1/2 px-4">
                                    <Image
                                        src="/frontend/images/png/image-01.webp"
                                        fallback="frontend/images/png/image-01.png"
                                        className="h-[349px] w-[565px] mx-auto"
                                        alt="Web Design and Development"
                                    />
                                </div>
                                <div className="w-full lg:w-1/2 px-4">
                                    <h2 className="text-2xl sm:text-4xl py-2 relative">Design and Develop
                                        <span className="text-green-500 relative"> Custom Websites </span>
                                        <svg className="absolute" width="201" height="8" viewBox="0 0 201 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.15381 6.85266C103.415 -2.66642 176.123 2.88637 199.694 6.85266" stroke="#48BB78" strokeWidth="1.5" strokeLinecap="round" />
                                        </svg>
                                        for your business</h2>
                                    <ul>
                                        <li className="flex items-center py-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none"><g fill="#48BB78" clipPath="url(#a)"><path d="M9.112 11.646a.72.72 0 0 1-.476-.179l-3.55-3.132a.72.72 0 0 1 .953-1.08L9.085 9.93l6.357-6.311a.723.723 0 0 1 1.015 1.03L9.62 11.444a.723.723 0 0 1-.508.201Z" /><path d="M8.67 16.84A7.998 7.998 0 0 1 2.06 4.346a8 8 0 0 1 9.176-3.086.72.72 0 1 1-.463 1.365 6.559 6.559 0 1 0 4.189 8.076.72.72 0 0 1 1.381.412 8.045 8.045 0 0 1-7.672 5.727Z" /></g><defs><clipPath id="a"><path fill="#fff" d="M.67.84h16v16h-16z" /></clipPath></defs></svg>
                                            <span className="text-base">Create unique, professional, user-friendly websites </span>
                                        </li>
                                        <li className="flex items-center py-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none"><g fill="#48BB78" clipPath="url(#a)"><path d="M9.112 11.646a.72.72 0 0 1-.476-.179l-3.55-3.132a.72.72 0 0 1 .953-1.08L9.085 9.93l6.357-6.311a.723.723 0 0 1 1.015 1.03L9.62 11.444a.723.723 0 0 1-.508.201Z" /><path d="M8.67 16.84A7.998 7.998 0 0 1 2.06 4.346a8 8 0 0 1 9.176-3.086.72.72 0 1 1-.463 1.365 6.559 6.559 0 1 0 4.189 8.076.72.72 0 0 1 1.381.412 8.045 8.045 0 0 1-7.672 5.727Z" /></g><defs><clipPath id="a"><path fill="#fff" d="M.67.84h16v16h-16z" /></clipPath></defs></svg>
                                            <span className="text-base">Enhance website functionality and user experience</span>
                                        </li>
                                        <li className="flex items-center py-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none"><g fill="#48BB78" clipPath="url(#a)"><path d="M9.112 11.646a.72.72 0 0 1-.476-.179l-3.55-3.132a.72.72 0 0 1 .953-1.08L9.085 9.93l6.357-6.311a.723.723 0 0 1 1.015 1.03L9.62 11.444a.723.723 0 0 1-.508.201Z" /><path d="M8.67 16.84A7.998 7.998 0 0 1 2.06 4.346a8 8 0 0 1 9.176-3.086.72.72 0 1 1-.463 1.365 6.559 6.559 0 1 0 4.189 8.076.72.72 0 0 1 1.381.412 8.045 8.045 0 0 1-7.672 5.727Z" /></g><defs><clipPath id="a"><path fill="#fff" d="M.67.84h16v16h-16z" /></clipPath></defs></svg>
                                            <span className="text-base">Optimize website speed and performance </span>
                                        </li>
                                    </ul>
                                    <Link href="/web-design" className="relative py-3 px-3 top-1 right-2  button-full rounted-sm text-white hover:bg-green-500 w-full">Web Development</Link>
                                </div>
                            </div>
                        </Tab.Panel>
                    </Tab.Panels>
                </Tab.Group>
            </div>
        </div>
    )
}
export default ServiceTabs
