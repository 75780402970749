import React, { Fragment, useEffect, useState } from 'react'
import Navbar from '../Navbar/Index'
import Banner from '../Banner/Index'
import TopBar from '../TopBar/Index'
import Footer from '../Footer/Index'
import Header from '../Header/Index'
import CalendarModal from '../CalendarModal/Index'
import CookiePolicy from "../../Components/CookiePolicy/Index";
import { useCookies } from 'react-cookie'


const PageLayout = ({ children }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [cookie] = useCookies(['cookieConsent'])

    useEffect(() => { }, [])

    return (
        <Fragment>
            {/* <Banner /> */}
            <Navbar modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
            {/* <Header /> */}
            <TopBar />
            {children}
            <Footer />
            {modalIsOpen && <CalendarModal isOpen={modalIsOpen} setIsOpen={setModalIsOpen} />}
            {!cookie.cookieConsent && <CookiePolicy />}
        </Fragment>
    )
}
export default PageLayout
