import React from 'react'
import LeadsForm from '../../../../Components/LeadsForm/Index'

const SMMPageHero = () => {
    return (
        <section className="smm-hero">
            <div className="max-w-5xl mx-auto px-4">
                <div className="block text-center pt-12">
                    <h1 className="text-5xl xl:text-6xl">Elevate Your
                        <br />
                        <svg width="270" height="17" fill="none" className="absolute mt-12 ml-24 hidden md:block">
                            <path stroke="#48BB78" strokeWidth="2" d="M.5 3C183-3.5 145.5 10 123 14s118-4 146.5-7.5" />
                        </svg>
                        <span className="mr-2 text-green-500 font-semibold">
                            Social Media
                        </span>
                        Presence with Gurulabs
                    </h1>
                    <p className="py-5 text-md md:text-lg max-w-xl mx-auto">
                        Gurulabs is your key to unlocking the potential of your website’s online visibility. Our experienced team of gurus use proven methods to increase your website’s search engine rankings and maximize your online traffic.
                    </p>
                </div>
                <div className="max-w-3xl mx-auto">
                    <LeadsForm />
                </div>
            </div>
        </section>
    )
}
export default SMMPageHero
