import React, { useState } from 'react';

const BudgetForConversionsCalculator = () => {
    const [targetConversions, setTargetConversions] = useState(50);
    const [cpa, setCPA] = useState(20.00);
    const [estimatedBudget, setEstimatedBudget] = useState(0.0);

    const calculateBudget = () => {
        const budget = targetConversions * cpa;
        setEstimatedBudget(budget);
    };

    return (
        <div className="max-w-lg mx-auto mt-8 p-6 bg-gray-100 rounded-md shadow-md">
            <h2 className="text-2xl font-semibold mb-4">Budget Estimation for Target Conversions</h2>
            <div className="mb-4">
                <label htmlFor="targetConversions" className="block mb-1">Target Conversions:</label>
                <input
                    type="text"
                    id="targetConversions"
                    value={targetConversions}
                    onChange={(e) => setTargetConversions(Number(e.target.value))}
                    className="border-gray-300 rounded-md p-2 w-full"
                    min="0"
                />
            </div>
            <div className="mb-4">
                <label htmlFor="cpa" className="block mb-1">Cost Per Acquisition (CPA):</label>
                <input
                    type="text"
                    id="cpa"
                    value={cpa}
                    onChange={(e) => setCPA(Number(e.target.value))}
                    className="border-gray-300 rounded-md p-2 w-full"
                    min="0"
                />
            </div>
            <div className="mb-4">
                <button
                    onClick={calculateBudget}
                    className="px-4 py-2 button text-white rounded hover:bg-blue-700"
                >
                    Calculate Budget
                </button>
            </div>
            <div className="mb-4">
                <h3 className="text-xl">
                    Estimated Budget: ${estimatedBudget.toFixed(2)}
                </h3>
            </div>
        </div>
    );
};

export default BudgetForConversionsCalculator;
