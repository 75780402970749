import React from 'react'
import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
// import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const faqs = [
    {
        question: "What is e-commerce SEO?",
        answer: "E-commerce SEO refers to the practice of optimizing an online store to rank higher in search engine results pages. At Gurulabs, we specialize in e-commerce SEO to help your store gain more visibility and drive organic traffic.",
    },
    {
        question: "Why is e-commerce SEO important?",
        answer: "E-commerce SEO is crucial because it improves your online store's visibility, attracts potential customers, and increases sales. Gurulabs helps you implement effective e-commerce SEO strategies to stay ahead of the competition.",
    },
    {
        question: "What are the best e-commerce SEO strategies?",
        answer: "The best e-commerce SEO strategies include keyword research, on-page optimization, high-quality content creation, and building backlinks. Gurulabs provides comprehensive e-commerce SEO services to help you implement these strategies effectively.",
    },
    {
        question: "How can I optimize my e-commerce site for SEO?",
        answer: "Optimize your e-commerce site for SEO by conducting keyword research, optimizing product pages, improving site speed, and creating high-quality content. Gurulabs offers expert e-commerce SEO services to ensure your site is fully optimized.",
    },
    {
        question: "What are e-commerce SEO services?",
        answer: "E-commerce SEO services involve optimizing your online store to improve its ranking on search engines. This includes keyword research, on-page optimization, technical SEO, and content marketing. Gurulabs offers tailored e-commerce SEO services to boost your store's visibility.",
    },
    {
        question: "How to improve e-commerce SEO?",
        answer: "To improve e-commerce SEO, focus on keyword optimization, create engaging content, enhance user experience, and build quality backlinks. Gurulabs can help you improve your e-commerce SEO with proven strategies and techniques.",
    },
    {
        question: "What is an e-commerce SEO audit?",
        answer: "An e-commerce SEO audit involves analyzing your online store to identify areas for improvement in terms of SEO. Gurulabs provides detailed e-commerce SEO audits to help you optimize your site for better search engine rankings.",
    },
    {
        question: "Why choose Gurulabs for e-commerce SEO?",
        answer: "Gurulabs is a leading provider of e-commerce SEO services, offering customized strategies, expert insights, and proven results. We help your online store achieve higher visibility and drive more traffic.",
    },
    {
        question: "What are the benefits of e-commerce SEO?",
        answer: "The benefits of e-commerce SEO include increased visibility, higher search engine rankings, more organic traffic, and improved sales. Gurulabs helps you achieve these benefits through effective e-commerce SEO strategies.",
    },
    {
        question: "How to perform keyword research for e-commerce SEO?",
        answer: "Perform keyword research for e-commerce SEO by identifying relevant terms your target audience is searching for. Use tools like Google Keyword Planner and SEMrush. Gurulabs offers comprehensive keyword research services to help you target the right keywords.",
    },
    {
        question: "What is the role of content in e-commerce SEO?",
        answer: "Content plays a crucial role in e-commerce SEO by providing valuable information to users and improving search engine rankings. Gurulabs helps you create high-quality, SEO-optimized content to enhance your online store's visibility.",
    },
    {
        question: "How does technical SEO impact e-commerce sites?",
        answer: "Technical SEO impacts e-commerce sites by improving site speed, ensuring proper indexing, and enhancing user experience. Gurulabs offers technical SEO services to ensure your e-commerce site performs optimally.",
    },
    {
        question: "What are e-commerce SEO best practices?",
        answer: "E-commerce SEO best practices include optimizing product pages, improving site speed, creating quality content, and building backlinks. Gurulabs provides expert guidance on e-commerce SEO best practices to boost your store's rankings.",
    },
    {
        question: "How to optimize product pages for e-commerce SEO?",
        answer: "Optimize product pages for e-commerce SEO by using relevant keywords, writing detailed product descriptions, adding high-quality images, and including customer reviews. Gurulabs offers services to optimize your product pages effectively.",
    },
    {
        question: "What is the importance of backlinks in e-commerce SEO?",
        answer: "Backlinks are important in e-commerce SEO as they improve your site's authority and search engine rankings. Gurulabs helps you build high-quality backlinks to enhance your e-commerce site's SEO.",
    },
    {
        question: "How to increase organic traffic to an e-commerce site?",
        answer: "Increase organic traffic to an e-commerce site by optimizing for relevant keywords, creating quality content, and improving user experience. Gurulabs offers comprehensive e-commerce SEO services to drive more organic traffic to your site.",
    },
    {
        question: "What are common e-commerce SEO mistakes to avoid?",
        answer: "Common e-commerce SEO mistakes include neglecting keyword research, having duplicate content, slow site speed, and poor mobile optimization. Gurulabs helps you avoid these mistakes with expert e-commerce SEO services.",
    },
    {
        question: "How to measure the success of e-commerce SEO?",
        answer: "Measure the success of e-commerce SEO by tracking metrics such as organic traffic, conversion rates, bounce rates, and keyword rankings. Gurulabs provides detailed analytics to help you measure and improve your e-commerce SEO efforts.",
    },
    {
        question: "What is the difference between e-commerce SEO and regular SEO?",
        answer: "E-commerce SEO focuses on optimizing online stores, while regular SEO can apply to any type of website. Gurulabs specializes in e-commerce SEO to address the unique challenges and opportunities of online retail.",
    },
    {
        question: "How to choose the best e-commerce SEO company?",
        answer: "Choose the best e-commerce SEO company by looking for experience, expertise, client reviews, and proven results. Gurulabs stands out as a top e-commerce SEO company with a track record of success and satisfied clients."
    }
];



function CommerceFAQs() {
    return (
        <div className="bg-white">
            <div className="mx-auto max-w-7xl px-6 py-12 sm:py-24 lg:px-8 lg:py-24">
                <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
                    <h2 className="text-3xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
                    <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                        {faqs.map((faq) => (
                            <Disclosure as="div" key={faq.question} className="pt-6">
                                {({ open }) => (
                                    <>
                                        <dt>
                                            <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                                                <span className="text-base font-semibold leading-7">{faq.question}</span>
                                                <span className="ml-6 flex h-7 items-center">
                                                    {open ? (
                                                        <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                    ) : (
                                                        <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                    )}
                                                </span>
                                            </Disclosure.Button>
                                        </dt>
                                        <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                            <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
}


export default CommerceFAQs
