import React, { useState } from 'react';

const CTRCalculator = () => {
    const [numberOfClicks, setNumberOfClicks] = useState(500);
    const [totalImpressions, setTotalImpressions] = useState(10000);
    const [ctr, setCTR] = useState(0.0);

    const calculateCTR = () => {
        if (totalImpressions > 0) {
            const clickThroughRate = (numberOfClicks / totalImpressions) * 100;
            setCTR(clickThroughRate);
        }
    };

    return (
        <div className="max-w-lg mx-auto mt-8 p-6 bg-gray-100 rounded-md shadow-md">
            <h2 className="text-2xl font-semibold mb-4">Click-Through Rate (CTR) Calculator</h2>
            <div className="mb-4">
                <label htmlFor="numberOfClicks" className="block mb-1">Number of Clicks:</label>
                <input
                    type="text"
                    id="numberOfClicks"
                    value={numberOfClicks}
                    onChange={(e) => setNumberOfClicks(Number(e.target.value))}
                    className="border-gray-300 rounded-md p-2 w-full"
                    min="0"
                />
            </div>
            <div className="mb-4">
                <label htmlFor="totalImpressions" className="block mb-1">Total Impressions:</label>
                <input
                    type="text"
                    id="totalImpressions"
                    value={totalImpressions}
                    onChange={(e) => setTotalImpressions(Number(e.target.value))}
                    className="border-gray-300 rounded-md p-2 w-full"
                    min="0"
                />
            </div>
            <div className="mb-4">
                <button
                    onClick={calculateCTR}
                    className="px-4 py-2 button text-white rounded hover:bg-blue-700"
                >
                    Calculate CTR
                </button>
            </div>
            <div className="mb-4">
                <h3 className="text-xl">
                    Click-Through Rate: {ctr.toFixed(2)}%
                </h3>
            </div>
        </div>
    );
};

export default CTRCalculator;
