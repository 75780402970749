import React from 'react'
import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
// import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const faqs = [
    {
        question: "What is conversion rate optimization?",
        answer: "Conversion rate optimization (CRO) is the practice of increasing the percentage of users who perform a desired action on a website. CRO involves understanding user behavior, and using analytical data to make adjustments to web elements to improve the effectiveness of the site. Gurulabs specializes in using these techniques to help businesses achieve higher conversions from their existing traffic.",
    },
    {
        question: "How can conversion rate optimization benefit my business?",
        answer: "CRO can significantly benefit your business by enhancing the efficiency of your website, leading to more conversions without the need to increase traffic. This means better ROI, lower customer acquisition costs, and higher customer satisfaction. Gurulabs focuses on these metrics to ensure your digital strategy aligns with your business goals for maximum impact.",
    },
    {
        question: "How do Gurulabs' CRO services enhance website performance?",
        answer: "Our CRO services at Gurulabs enhance website performance by using data-driven strategies to refine user experience and improve conversion paths. We conduct thorough analyses, A/B testing, and leverage user feedback to optimize web design, content, and functionality, which results in increased customer engagement and conversion rates.",
    },
    {
        question: "What strategies do top CRO agencies like Gurulabs use to increase conversions?",
        answer: "As a leading CRO agency, Gurulabs employs a variety of strategies including user behavior analysis, continuous A/B testing, personalization, and psychological triggers to enhance user experience and encourage conversions. Our tailored approach ensures that every strategy aligns with the specific needs and goals of our clients.",
    },
    {
        question: "What are the best practices for CRO on landing pages?",
        answer: "Best practices for CRO on landing pages include clear and compelling call-to-actions, streamlined forms, engaging visuals, and targeted content. Gurulabs ensures that your landing pages are optimized to not only attract attention but also to encourage conversions by minimizing distractions and focusing on user intent.",
    },
    {
        question: "How does Shopify benefit from specialized CRO techniques?",
        answer: "Shopify stores benefit from specialized CRO techniques by tailoring user experiences to enhance customer engagement and sales. At Gurulabs, we optimize Shopify sites by improving product displays, simplifying checkout processes, and customizing user interactions based on extensive testing and analysis.",
    },
    {
        question: "What are the emerging trends in CRO for 2024?",
        answer: "Emerging trends in CRO for 2024 include increased use of AI and machine learning for predictive analytics, greater focus on mobile optimization due to rising mobile traffic, and more sophisticated personalization techniques to cater to individual user preferences. Gurulabs stays ahead of these trends to provide cutting-edge solutions to our clients.",
    },
    {
        question: "How can a CRO audit reveal opportunities for increased engagement on my site?",
        answer: "A CRO audit can reveal opportunities by identifying areas where users experience friction or disengagement. Gurulabs conducts comprehensive audits that analyze everything from site speed to content relevance, helping uncover hidden opportunities to enhance user engagement and increase conversion rates.",
    },
    {
        question: "What role does AI play in enhancing CRO strategies?",
        answer: "AI plays a significant role in enhancing CRO strategies by automating data analysis, predicting user behavior, and personalizing user experiences at scale. Gurulabs integrates AI technologies to streamline these processes, making our CRO efforts more efficient and effective.",
    },
    {
        question: "What are the essential elements to include in a CRO checklist for a new website?",
        answer: "A CRO checklist for a new website should include mobile responsiveness, fast loading times, SEO optimization, clear navigation, and effective call-to-actions. Gurulabs ensures these elements are not only included but also optimized based on best practices and industry standards.",
    },
    {
        question: "How do we approach CRO differently at Gurulabs?",
        answer: "At Gurulabs, we approach CRO with a holistic view that integrates with overall business objectives, rather than as standalone tasks. We consider the entire customer journey, employing advanced analytics and user-centric design principles to create seamless and effective conversion paths.",
    },
    {
        question: "What impact has Gurulabs seen from implementing CRO strategies?",
        answer: "Implementing CRO strategies at Gurulabs has led to significant improvements in user engagement, higher conversion rates, and increased customer retention. Our clients have seen tangible benefits in the form of increased sales and enhanced customer satisfaction.",
    },
    {
        question: "Why is user experience so crucial in CRO?",
        answer: "User experience is crucial in CRO because it directly affects how easily users can navigate and complete actions on a website. A positive user experience reduces barriers to conversion, enhances satisfaction, and ultimately leads to higher conversion rates. Gurulabs prioritizes exceptional user experience in all our CRO efforts.",
    },
    {
        question: "How does Gurulabs measure the success of its CRO initiatives?",
        answer: "Gurulabs measures the success of its CRO initiatives through a variety of metrics such as conversion rates, user engagement statistics, and overall revenue growth. We use advanced analytics tools to track these metrics pre and post-implementation to ensure that our strategies are delivering measurable results.",
    },
    {
        question: "What common mistakes do businesses make with CRO?",
        answer: "Common mistakes businesses make with CRO include not basing decisions on data, ignoring mobile optimization, and not continuously testing and refining strategies. Gurulabs helps businesses avoid these pitfalls by implementing a rigorous, data-driven approach to CRO.",
    },
    {
        question: "How often should a website undergo a CRO review?",
        answer: "A website should undergo a CRO review at least bi-annually to ensure that it remains aligned with user expectations and business goals. However, continuous monitoring and smaller-scale testing should be conducted regularly to keep up with changing user behaviors and technological advancements.",
    },
    {
        question: "What is the role of A/B testing in CRO?",
        answer: "The role of A/B testing in CRO is crucial as it allows businesses to compare two versions of a web page to determine which one performs better in terms of driving conversions. Gurulabs utilizes A/B testing extensively to scientifically validate the impact of changes made during the optimization process.",
    },
    {
        question: "How can small businesses benefit from CRO?",
        answer: "Small businesses can benefit from CRO by maximizing the efficiency of their limited marketing budgets, converting more of their existing traffic into customers. Gurulabs specializes in scalable CRO strategies that can be tailored to the budget and resources of small businesses, ensuring they achieve optimal results.",
    },
    {
        question: "What advanced CRO techniques does Gurulabs use?",
        answer: "Gurulabs uses advanced CRO techniques such as predictive analytics, multivariate testing, and behavioral segmentation to deeply understand user preferences and optimize their journey. These techniques allow us to deliver personalized experiences that drive higher conversion rates.",
    },
];







function CROFAQs() {
    return (
        <div className="bg-white">
            <div className="mx-auto max-w-7xl px-6 py-12 sm:py-24 lg:px-8 lg:py-24">
                <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
                    <h2 className="text-3xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
                    <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                        {faqs.map((faq) => (
                            <Disclosure as="div" key={faq.question} className="pt-6">
                                {({ open }) => (
                                    <>
                                        <dt>
                                            <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                                                <span className="text-base font-semibold leading-7">{faq.question}</span>
                                                <span className="ml-6 flex h-7 items-center">
                                                    {open ? (
                                                        <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                    ) : (
                                                        <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                    )}
                                                </span>
                                            </Disclosure.Button>
                                        </dt>
                                        <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                            <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
}


export default CROFAQs
