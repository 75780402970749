import React, { useState } from 'react';

const ROASCalculator = () => {
    const [revenueGenerated, setRevenueGenerated] = useState(5000);
    const [adSpend, setAdSpend] = useState(1000);
    const [roas, setROAS] = useState(0.0);

    const calculateROAS = () => {
        if (adSpend > 0) {
            const returnOnAdSpend = revenueGenerated / adSpend;
            setROAS(returnOnAdSpend);
        }
    };

    return (
        <div className="max-w-lg mx-auto mt-8 p-6 bg-gray-100 rounded-md shadow-md">
            <h2 className="text-2xl font-semibold mb-4">Return on Ad Spend (ROAS) Calculator</h2>
            <div className="mb-4">
                <label htmlFor="revenueGenerated" className="block mb-1">Revenue Generated:</label>
                <input
                    type="text"
                    id="revenueGenerated"
                    value={revenueGenerated}
                    onChange={(e) => setRevenueGenerated(Number(e.target.value))}
                    className="border-gray-300 rounded-md p-2 w-full"
                    min="0"
                />
            </div>
            <div className="mb-4">
                <label htmlFor="adSpend" className="block mb-1">Total Ad Spend:</label>
                <input
                    type="text"
                    id="adSpend"
                    value={adSpend}
                    onChange={(e) => setAdSpend(Number(e.target.value))}
                    className="border-gray-300 rounded-md p-2 w-full"
                    min="0"
                />
            </div>
            <div className="mb-4">
                <button
                    onClick={calculateROAS}
                    className="px-4 py-2 button text-white rounded hover:bg-blue-700"
                >
                    Calculate ROAS
                </button>
            </div>
            <div className="mb-4">
                <h3 className="text-xl">
                    Return on Ad Spend: {roas.toFixed(2)}
                </h3>
            </div>
        </div>
    );
};

export default ROASCalculator;
