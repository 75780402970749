import React, { useMemo } from 'react';
import { useTable } from 'react-table';
import MOCK_DATA from './MOCK_DATA.json';
import { COLUMNS } from './columns';

const PricingTable = () => {
    const columns = useMemo(() => COLUMNS, []);
    const data = useMemo(() => MOCK_DATA, []);

    const tableInstance = useTable({
        columns,
        data,
    });

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

    return (
        <section>
            <div className="max-w-7xl mx-auto py-24 overflow-hidden">
                <table id="pricing" className="min-w-full divide-y divide-gray-300" {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => {
                            const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps(); // Separate key
                            return (
                                <tr
                                    key={key} // Pass key directly
                                    className="bg-green-25 text-xl"
                                    {...restHeaderGroupProps} // Spread other props
                                >
                                    {headerGroup.headers.map(column => {
                                        const { key, ...restColumnProps } = column.getHeaderProps(); // Separate key
                                        return (
                                            <th
                                                key={key} // Pass key directly
                                                className="[&:not(:first-child)]:text-center px-3 py-3.5 text-left text-xl font-semibold text-gray-700"
                                                {...restColumnProps} // Spread other props
                                            >
                                                {column.render('Header')}
                                            </th>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, rowIndex) => {
                            prepareRow(row);
                            const { key, ...restRowProps } = row.getRowProps(); // Separate key
                            return (
                                <tr
                                    key={key} // Pass key directly
                                    className={rowIndex % 2 === 0 ? undefined : 'bg-green-25'}
                                    {...restRowProps} // Spread other props
                                >
                                    {row.cells.map(cell => {
                                        const { key, ...restCellProps } = cell.getCellProps(); // Separate key
                                        return (
                                            <td
                                                key={key} // Pass key directly
                                                className="[&:not(:first-child)]:text-center whitespace-nowrap py-4 pl-4 pr-3 text-md border-b border-gray-50 font-medium text-gray-700 sm:pl-3"
                                                {...restCellProps} // Spread other props
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>
    );
};

export default PricingTable;
