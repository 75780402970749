import React from 'react'
import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
// import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const faqs = [
    {
        question: "What is citation building in local SEO?",
        answer: "Citation building is the process of creating and managing mentions of your business’s name, address, and phone number (NAP) across various online directories, websites, and platforms. It's essential for improving local SEO as accurate citations help search engines verify the legitimacy and location of your business, leading to better local search rankings."
    },
    {
        question: "Why are citations important for local SEO?",
        answer: "Citations are crucial for local SEO because they help search engines like Google confirm the existence and location of your business. Consistent and accurate citations across the web can boost your local search visibility, making it easier for potential customers to find your business when searching for relevant services or products in your area."
    },
    {
        question: "How does GuruLabs' citation building process work?",
        answer: "GuruLabs' citation building process starts with a thorough audit of your current citations, identifying any inaccuracies or inconsistencies. We then create or update citations across key local directories, ensuring your business information is accurate and optimized for local search engines. Our process includes ongoing management to maintain consistency and improve your local SEO rankings."
    },
    {
        question: "What platforms are important for citation building?",
        answer: "Important platforms for citation building include major directories like Google My Business, Yelp, Bing Places, and industry-specific directories. Social media profiles and niche directories also play a significant role in enhancing your local SEO by providing authoritative backlinks and consistent NAP information."
    },
    {
        question: "How does citation building impact my business’s online presence?",
        answer: "Citation building directly impacts your business's online presence by ensuring that your business information is widely available and consistent across the web. This improves your visibility in local search results, increases trust with search engines, and helps attract more local customers."
    },
    {
        question: "What is the difference between a citation and a backlink?",
        answer: "A citation is an online mention of your business’s name, address, and phone number (NAP), typically without a link, while a backlink is a hyperlink from another website that points to your site. Both are important for SEO, but citations are particularly vital for local SEO as they help search engines verify your business location and legitimacy."
    },
    {
        question: "Can GuruLabs help with cleaning up incorrect citations?",
        answer: "Yes, GuruLabs offers citation cleanup services as part of our citation building process. We identify and correct incorrect or inconsistent citations, ensuring your business information is accurate across all platforms, which is critical for improving your local SEO."
    },
    {
        question: "How often should citations be updated or managed?",
        answer: "Citations should be regularly monitored and updated, especially if there are changes to your business information such as a new address, phone number, or rebranding. GuruLabs provides ongoing citation management to keep your information consistent and optimized for local search engines."
    },
    {
        question: "What are the benefits of using GuruLabs for citation building?",
        answer: "Using GuruLabs for citation building ensures that your business’s online information is accurate, consistent, and optimized across all key platforms. Our comprehensive approach enhances your local SEO, improves search engine rankings, and drives more local traffic to your business."
    },
    {
        question: "How does citation building differ from local SEO?",
        answer: "Citation building is a critical component of local SEO, focusing specifically on ensuring your business information (NAP) is accurately listed across various online platforms. Local SEO, however, is a broader strategy that includes citation building, keyword optimization, on-page SEO, and backlink development to improve your visibility in local search results."
    },
    {
        question: "What makes GuruLabs' citation building service unique?",
        answer: "GuruLabs’ citation building service is unique due to our thorough audit process, strategic citation creation, and ongoing management. We focus on ensuring your business is listed in the most relevant directories, with consistent and accurate information, boosting your local SEO and online visibility."
    },
];



function CitationFAQs() {
    return (
        <div className="bg-white">
            <div className="mx-auto max-w-7xl px-6 py-12 sm:py-24 lg:px-8 lg:py-24">
                <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
                    <h2 className="text-3xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
                    <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                        {faqs.map((faq) => (
                            <Disclosure as="div" key={faq.question} className="pt-6">
                                {({ open }) => (
                                    <>
                                        <dt>
                                            <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                                                <span className="text-base font-semibold leading-7">{faq.question}</span>
                                                <span className="ml-6 flex h-7 items-center">
                                                    {open ? (
                                                        <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                    ) : (
                                                        <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                    )}
                                                </span>
                                            </Disclosure.Button>
                                        </dt>
                                        <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                            <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
}


export default CitationFAQs
