import React, { useEffect, useState } from 'react'
import PageLayout from '../../../../Layout/PageLayout/Index'
import SEOPageHero from '../SEOPageHero/Index'
import mixpanel from 'mixpanel-browser'
import Process from '../../../../Components/Process/Index'
import { SiGoogle, SiGoogleanalytics, SiGooglesearchconsole, SiHtml5 } from '@icons-pack/react-simple-icons'
import { LinkIcon } from '@heroicons/react/24/outline'
import SearchEngineSlider from '../../../../Components/SearchEngineSlider/Index'
import Slider from '../../../../Components/Slider/Index'
import CalendarModal from '../../../../Layout/CalendarModal/Index'
import LeadsForm from '../../../../Components/LeadsForm/Index'
import CitationFAQs from '../../../../Components/FAQs/CitationFAQs/Index'
import Newsletter from '../../../../Components/Newsletter/Index'
import EnterpriseSEOPricing from '../../../../Components/SEOPricing/Enterprise/Index'
import EnterpriseSEOPricingTable from '../../../../Components/PricingTables/SEOPricingTable/Enterprise/Index'
import Popup from '../../../../Components/Popup/Index'
import SEOMetaHead from '../../../../Components/SEOMetaHead/Index'
import CitationPricing from '../../../../Components/CitationPricing/Index'
import SocialProof from '../../../../Components/SocialProof/Index'

const CitationBuilderPage = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false)

    useEffect(() => {
        mixpanel.track("Citation Builder Page Visited")
    }, [])

    return (
        <PageLayout>
            <SEOMetaHead
                title="Local SEO Citation Builder | White Label Service | GuruLabs 2024"
                description="Enhance your local SEO in 2024 with GuruLabs' Citation Builder. Manage and optimize citations to elevate your business's online presence and search rankings."
                convertKitPage="https://relentless-inventor-6062.ck.page/5a9ed0f555/index.js"
            />
            <section className="">
                <div className="max-w-7xl mx-auto py-8 lg:py-48 px-4">
                    <div className="flex flex-col lg:flex-row justify-between items-center">
                        <div className="w-full py-4 xl:w-8/12">
                            <h1 className="text-3xl lg:text-6xl my-1">Let Gurulabs handle your
                                <span className="text-green-500 relative mx-2">
                                    Citation Management
                                    <svg width="175" height="3" viewBox="0 0 175 3" fill="none" className="absolute hidden lg:block left-6">
                                        <path d="M1.43945 1.54395H173.903" stroke="#48BB78" strokeWidth="2" strokeLinecap="round" />
                                    </svg>
                                </span>
                                to Maximize Search Your Visibility</h1>
                            <p className="my-3">Leverage GuruLabs’ Citation Builder to enhance your local search engine visibility. Our tool helps you create, manage, and optimize citations, ensuring your business ranks higher in local searches. Perfect for businesses looking to dominate local SEO.</p>
                            <LeadsForm />
                        </div>
                        <div className="w-full py-4 xl:w-4/12">
                            <img src="/frontend/images/svg/icon-22.svg" className="mx-auto" alt="Enterprise SEO By Gurulabs" />
                        </div>
                    </div>
                </div>
            </section>
            <Slider />
            <section>
                <div className="bg-gray-50 py-12">
                    <div className="max-w-7xl mx-auto">
                        <div className='block sm:flex justify-center items-center px-4'>
                            <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                <span className="relative">Come take a look at what seo services plans</span>
                                <span className="text-green-500 ml-2 relative">
                                    we offer!
                                </span>
                                <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4 ml-16 hidden xl:block" width={151} height={3} fill="none"><path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                            </h2>
                        </div>
                        <CitationPricing modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
                    </div>
                </div>
            </section>
            <SocialProof />
            <section>
                <div className="max-w-5xl mx-auto py-24">
                    <div className='block sm:flex sm:flex-col justify-center items-center px-4'>
                        <h2 className="block text-3xl sm:text-4xl py-5 relative">
                            <span className="relative">Our Tech-Enabled</span>
                            <span className="text-green-500 ml-2 relative">
                                Citation Building Process
                            </span>
                            <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-1/2 -mt-2 ml-16 hidden xl:block" width={238} height={17} fill="none"><path stroke="#48BB78" strokeLinecap="round" strokeWidth="2" d="M1.833 12.55c101.19 8.798 198.897-3.667 235.102-11" /></svg>
                        </h2>
                        <p className="text-base text-center">Our citation building process leverages advanced technology to ensure your business information is consistently and accurately listed across all relevant platforms, boosting your local SEO performance and driving more traffic to your business.</p>
                    </div>
                </div>
                <div className="max-w-7xl mx-auto pb-24">
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
                        <Process
                            title="Citation Audit"
                            description="Our process begins with a comprehensive citation audit, where GuruLabs assesses your current online citations across all major platforms. We identify inconsistencies, duplicates, and gaps, ensuring your business information is accurate and fully optimized to enhance your local search visibility."
                            button="button-3"
                            Icon={SiGooglesearchconsole}
                        />
                        <Process
                            title="Citation Research and Strategy"
                            description="GuruLabs conducts thorough research to identify the most relevant and authoritative directories for your business. We develop a customized citation strategy that targets these platforms, ensuring your business is listed where it matters most, driving local SEO success."
                            button="button-2"
                            Icon={SiGoogleanalytics}
                        />
                        <Process
                            title="Citation Creation and Optimization"
                            description="Our team expertly creates new citations and optimizes existing ones, ensuring consistency across all platforms. We focus on key details such as your business name, address, phone number (NAP), and other critical information to improve your local search rankings and user trust."
                            button="button-3"
                            Icon={SiGoogle}
                        />
                        <Process
                            title="Content for Citations"
                            description="GuruLabs recognizes the importance of content in citations. We craft compelling descriptions and ensure that your business details are consistently and accurately represented, enhancing your credibility across all citation sites and improving your local SEO performance."
                            button="button-2"
                            Icon={SiHtml5}
                        />
                        <Process
                            title="Link Building via Citations"
                            description="In addition to building citations, GuruLabs focuses on securing high-quality backlinks through citation sites. Our strategies include leveraging local directories and industry-specific platforms to enhance your website's authority and drive organic traffic."
                            button="button-3"
                            Icon={LinkIcon}
                        />
                        <Process
                            title="Performance Monitoring and Reporting"
                            description="At GuruLabs, we prioritize transparency and continuous improvement. We monitor the performance of your citations and track their impact on your local search rankings. Regular reports keep you informed of progress, allowing for ongoing optimization and strategy refinement."
                            button="button-2"
                            Icon={SiGoogleanalytics}
                        />
                    </div>
                </div>

            </section>
            <section>
                <div className="bg-gray-50 py-12">
                    <div className="max-w-7xl mx-auto">
                        <div>
                            <div className='block sm:flex justify-center items-center px-4'>
                                <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                    <span className="relative">Affordable and Effective</span>
                                    <span className="text-green-500 ml-2 relative">
                                        Local Citation Building Service
                                    </span>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4 ml-16 hidden xl:block" width={151} height={3} fill="none"><path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                                </h2>
                            </div>
                            <p className="py-4 text-lg text-center">Our citation building process ensures that your business is accurately listed across key local directories, boosting your visibility and enhancing your local SEO rankings. We specialize in crafting and optimizing citations that help your business stand out in local search results.</p>
                        </div>
                        <div className="px-4">
                            <div>
                                <p className="text-xl py-4 text-gray-700">At GuruLabs, we are dedicated to improving your local search presence through an effective citation building strategy. Our team utilizes the latest tools and techniques to ensure that your business is consistently and accurately represented across all major citation platforms. With GuruLabs, you get a comprehensive citation management service designed to maximize your local SEO performance and ROI.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Streamlined Citation Building Process</strong></p>

                                <p className="text-xl py-4 text-gray-700">Our <a href="//www.gurulabs.dev/blog/high-quality-citation-services-for-search-engine-optimization-gurulabs" className="font-bold underline">Local Citation Building</a> services start with an in-depth audit of your current citations. GuruLabs identifies any inaccuracies or gaps and provides a strategy to correct and enhance your listings. We use a data-driven approach, leveraging state-of-the-art tools to ensure that your business information is consistent and optimized across all platforms.</p>

                                <p className="text-xl py-4 text-gray-700">At GuruLabs, we understand the critical role that accurate citations play in local SEO. Our team works to ensure your business appears in all the right places, from high-authority directories to niche industry sites. Expect thorough research, strategic planning, and meticulous execution to make sure your citations contribute effectively to your local search rankings.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Comprehensive Citation Management</strong></p>

                                <p className="text-xl py-4 text-gray-700">GuruLabs goes beyond just building citations. We manage your citations over time, keeping them updated and consistent. Our holistic approach includes regular audits, performance tracking, and ongoing optimization to ensure your citations continue to support your local SEO goals.</p>

                                <p className="text-xl py-4 text-gray-700">Our citation management service is tailored to meet the unique needs of your business. Whether you're a local store aiming to dominate your area or a multi-location enterprise seeking consistency across the board, GuruLabs has the expertise to elevate your local SEO strategy.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Tailored Solutions for Local Businesses</strong></p>

                                <p className="text-xl py-4 text-gray-700">Every business has unique needs, which is why GuruLabs offers customized <a href="//www.gurulabs.dev/blog/high-quality-citation-services-for-search-engine-optimization-gurulabs" className="font-bold underline">Local Citation Building</a> solutions. Whether you’re targeting specific local markets or need a robust strategy for nationwide reach, our team works closely with you to understand your goals and craft a plan that delivers results. From creating citations to managing them, we ensure your business is optimized for local search success.</p>

                                <p className="text-xl py-4 text-gray-700">Our commitment to staying ahead of the latest local SEO trends and updates means your citations are always in line with current best practices. With GuruLabs, you’re partnering with a team that’s focused on your long-term growth and success in the competitive local search landscape.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Maximizing Local Search Visibility and ROI</strong></p>

                                <p className="text-xl py-4 text-gray-700">At GuruLabs, we measure success by the tangible results we deliver. Our primary goal is to maximize your local search visibility, ensuring that your business stands out in relevant local searches. Through strategic citation building and management, we aim to drive significant traffic to your website and improve your overall ROI. With GuruLabs, you can trust that your local SEO strategy is in expert hands, designed to yield long-term benefits and growth.</p>

                                <p className="text-xl py-4 text-gray-700">In conclusion, choosing GuruLabs for your citation building needs means opting for a service that not only enhances your local SEO but also supports your overall business growth. From comprehensive audits to ongoing management, we provide a full suite of citation services that deliver lasting results. Partner with GuruLabs today and take your local search visibility to the next level.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <EnterpriseSEOPricingTable /> */}
            <Newsletter />
            <CitationFAQs />
            <section>
                <div className="bg-amber-50 hero-reviews py-12">
                    <div className="max-w-7xl mx-auto overflow-hidden">
                        <div className='block sm:flex justify-center items-center py-16 px-4'>
                            <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                <span className="relative">Hear from our Lead Gen</span>
                                <span className="text-green-500 ml-2 relative">
                                    Clients!
                                </span>
                                <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4  hidden xl:block" width={151} height={3} fill="none"><path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                            </h2>
                        </div>
                        <SearchEngineSlider />
                    </div>
                </div>
            </section>
            <CalendarModal isOpen={modalIsOpen} setIsOpen={setModalIsOpen} />
            {/* <Popup /> */}
        </PageLayout>
    )
}
export default CitationBuilderPage
