import { Link } from '@inertiajs/inertia-react'
import mixpanel from 'mixpanel-browser'
import React from 'react'

const seoPricing = [
    {
        id: 1,
        title: 'Amazon Ads',
        subtitle: 'Done For You Amazon Ads',
        name: 'Amazon Ads $350 / weekly',
        price: '$350',
        duration: 'weekly',
        image: '/frontend/images/svg/icon-16.svg',
        description: 'Enhance your marketing strategy with Amazon Ads. Connect with your audience on the world’s largest social media platform and drive engagement for your business.',
        paymentUrl: 'https://buy.stripe.com/4gw8zMd86gbYbsc5km',
        features: [
            "Competitor Analysis",
            "Campaign Setup",
            "Account Setup",
            "Ad Copywriting",
            "Bid Management",
            "Bi-Weekly Report",
            "Conversion Tracking",
            "Dedicated PPC Specialist",
            "Keyword Research",
            "Monthly Reporting",
            "Scalable Strategy",
            "Zapier Automation",
            "Active Ad Management",
            "60 Minutes Consultation",
            "And Much More!"
        ]
    },
    {
        "id": 2,
        "title": "Google Ads",
        "subtitle": "Done For You Google Ads",
        "name": "Google Ads $350 / weekly",
        "price": "$350",
        "duration": "weekly",
        "image": "/frontend/images/svg/icon-15.svg",
        "description": "Boost your online presence with targeted advertising through Google Ads. Reach potential customers efficiently and effectively with our comprehensive package.",
        "paymentUrl": "https://buy.stripe.com/4gw8zMd86gbYbsc5km",
        "features": [
            "Competitor Analysis",
            "Campaign Setup",
            "Account Setup",
            "Ad Copywriting",
            "Bid Management",
            "Bi-Weekly Report",
            "Conversion Tracking",
            "Dedicated PPC Specialist",
            "Keyword Research",
            "Monthly Reporting",
            "Scalable Strategy",
            "Zapier Automation",
            "Active Ad Management",
            "60 Minutes Consultation",
            "And Much More!"
        ]
    },
    {
        id: 3,
        title: 'Facebook Ads',
        subtitle: 'Done For You Facebook Ads',
        name: 'Facebook Ads $350 / weekly',
        price: '$350',
        duration: 'weekly',
        image: '/frontend/images/svg/icon-16.svg',
        description: 'Enhance your marketing strategy with Facebook Ads. Connect with your audience on the world’s largest social media platform and drive engagement for your business.',
        paymentUrl: 'https://buy.stripe.com/eVa17kc42gbY9k48wz',
        features: [
            "Competitor Analysis",
            "Campaign Setup",
            "Account Setup",
            "Ad Copywriting",
            "Bid Management",
            "Bi-Weekly Report",
            "Conversion Tracking",
            "Dedicated PPC Specialist",
            "Keyword Research",
            "Monthly Reporting",
            "Scalable Strategy",
            "Zapier Automation",
            "Active Ad Management",
            "60 Minutes Consultation",
            "And Much More!"
        ]
    },
    {
        id: 4,
        title: 'Tiktok Ads',
        subtitle: 'Done For You Tiktok Ads',
        name: 'Tiktok Ads $350 / weekly',
        price: '$350',
        duration: 'weekly',
        image: '/frontend/images/svg/icon-18.svg',
        description: 'Capture the attention of a younger demographic with TikTok Ads. Utilize this rapidly growing platform to showcase your brand and products creatively.',
        paymentUrl: 'https://buy.stripe.com/4gwcQ21po1h43ZK7sw',
        features: [
            "Competitor Analysis",
            "Campaign Setup",
            "Account Setup",
            "Ad Copywriting",
            "Bid Management",
            "Bi-Weekly Report",
            "Conversion Tracking",
            "Dedicated PPC Specialist",
            "Keyword Research",
            "Monthly Reporting",
            "Scalable Strategy",
            "Zapier Automation",
            "Active Ad Management",
            "60 Minutes Consultation",
            "And Much More!"
        ]
    },
]

const PaidAdsPricing = ({ modalIsOpen, setModalIsOpen }) => {
    return (
        <div className="max-w-7xl mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 py-20 px-2">
                {
                    seoPricing.map((item, index) => (
                        <div className="border-2 border-gray-100 py-6" key={index}>
                            {item.id === 2 && (
                                <div className="bg-green-300 flex justify-center items-center -mt-10">
                                    <h2 className="py-2 text-gray-700">Most Popular</h2>
                                </div>
                            )}
                            <div className="bg-white py-4 px-2">
                                <img src={item.image} className="py-2 px-2" alt={`${item.title}`} />
                                <h2 className="text-xl py-2">{item.title}</h2>
                                <h3 className="text-lg text-gray-700 py-2">{item.subtitle}</h3>
                                <p className="text-gray-700 py-2">{item.description}</p>
                                <h4 className="text-xl text-gray-900 py-2">{item.price} / {item.duration} + Ads Cost</h4>
                                {item.id === 2 ? (
                                    <p>3% - 10% / Generated Leads</p>
                                ) : (
                                    <p>3% - 10% / Generated Leads</p>
                                )}

                                <ul className="py-4 px-2">
                                    {item.features.map((feature, index) => (

                                        <li className="flex items-center text-gray-700 py-4 px-2 border-b-2" key={index}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none"><g fill="#48BB78" clipPath="url(#a)"><path d="M9.13 12.024a.765.765 0 0 1-.506-.19L4.852 8.505a.766.766 0 0 1 1.013-1.15l3.236 2.845 6.754-6.706a.768.768 0 1 1 1.079 1.094L9.67 11.81a.768.768 0 0 1-.54.215Z" /><path d="M8.661 17.541a8.502 8.502 0 0 1-8.38-9.88A8.5 8.5 0 0 1 11.388.987a.766.766 0 1 1-.492 1.45 6.969 6.969 0 1 0 4.45 8.581.765.765 0 1 1 1.468.438 8.548 8.548 0 0 1-8.152 6.084Z" /></g><defs><clipPath id="a"><path fill="#fff" d="M.161.542h17v17h-17z" /></clipPath></defs></svg>
                                            {feature}
                                        </li>

                                    ))}
                                </ul>
                                <div className="flex items-center text-gray-700 py-2 ml-4 px-2">
                                    {/* <a href={item.paymentUrl} className="button w-full py-4 px-4 text-white text-center">Get Started {item.title}</a> */}
                                    <a href={item.paymentUrl}
                                        onClick={() => {
                                            mixpanel.track(`${item.name} Link Clicked`, { 'Plan': item.name, 'Link': item.paymentUrl, 'Page': 'PaidAds Page Pricing', 'User': 'Guest', 'Time': new Date(), 'Location': 'Paid Ads Page Pricing' })
                                        }}
                                        className="relative w-full py-3 px-3 top-1 right-2 text-center button rounted-sm text-gray-700 hover:bg-green-500"
                                    >
                                        <span className="text-xl">{item.id === 2 ? 'Get Started' : 'Subscribe'} {item.price}</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
            <div className="flex flex-col items-center">
                <p className="py-2 ">*Prices are based on monthly plans, and can be subscribed and unsubscribed at any time.</p>
                <a href="#pricing" className="button text-gray-700 py-4 px-4">See all plans details</a>
            </div>
        </div>
    )
}
export default PaidAdsPricing
