import React from 'react'
import toast from 'react-hot-toast'
import { motion } from 'framer-motion'

const domainNameValidation = (URL) => {
    // Regular expression to match domain names
    const regex = new RegExp('^([a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\\.)+[a-zA-Z]{2,}$');
    return regex.test(URL);
};

const onSubmitHandler = (e) => {
    e.preventDefault();
    const inputUrl = e.target[0].value;
    domainNameValidation(inputUrl) ?
        window.location.replace(`/get-started?website=${inputUrl}`) :
        toast.error('Please enter a valid domain name', {
            duration: 4000,
            position: 'top-center',
            style: {
                borderRadius: '10px',
                background: 'linear-gradient(94.33deg, #48BB78 5.89%, #94F3C3 111.03%)',
            },
        });
};

function LeadsForm({ placeholder }) {
    return (
        <>
            <form className="" onSubmit={onSubmitHandler}>
                <input type="text" name="website" required className="py-3 px-3 border border-gray-300 rounded-md w-full lg:w-8/12 mr-1" placeholder="Please enter your domain" id="" />
                <button className="mt-2 lg:-mt-2 text-xl relative py-3 px-3 button rounted-sm text-gray-700 hover:bg-green-500 w-full lg:w-3/12">Get a proposal</button>
            </form>
        </>
    )
}

export default LeadsForm
