import React from 'react'
import PageLayout from '../../Layout/PageLayout/Index'
import MetaHead from '../../Components/MetaHead'


const Calculators = () => {
    return (
        <PageLayout>
            <MetaHead title="Random Color Generator | Effortlessly Create Unique Colors"
                description="Discover the perfect palette for your next design project! Gurulabs' Random Color Generator helps you instantly create and copy unique, harmonious color schemes with ease. Ideal for designers, developers, and creatives." />
            <section>
                <div className='max-w-7xl mx-auto'>
                    {/* <ColorGenerator /> */}
                </div>
            </section>
        </PageLayout>
    )
}
export default Calculators
