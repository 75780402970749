import { Link } from '@inertiajs/inertia-react';
import mixpanel from 'mixpanel-browser';
import React, { useEffect, useState } from 'react'

const GetStartedForm = ({ isOpen, setIsOpen, website }) => {
    const [isMobile, setIsMobile] = useState(false);
    console.log(website);

    useEffect(() => {
        mixpanel.track("Paid Ads Page viewed");
        if (window.matchMedia("(max-width: 768px)").matches) {
            setIsMobile(true)
        }
    }, []);

    return (
        <div>
            {
                isMobile ? (
                    <Link
                        onClick={() => {
                            analytics.track("Button Clicked", {
                                button: "Get Started Mobile"
                            });
                            mixpanel.track("Get Started Mobile button clicked");
                        }}
                        href='/get-started'
                        className="w-full relative py-4 px-4 top-1 right-2 button rounded-sm text-gray-700 hover:bg-green-700 transition duration-300 ease-in-out"
                    >
                        <span className="text-xl">Get Started Now</span>
                    </Link>
                ) : (
                    <button
                        onClick={() => {
                            setIsOpen(!isOpen);
                            analytics.track("Button Clicked", {
                                button: "Get Started"
                            });
                            mixpanel.track("Get Started button clicked");
                        }}
                        className="w-full relative py-4 px-4 top-1 right-2 button rounded-sm text-gray-700 hover:bg-green-700 transition duration-300 ease-in-out"
                    >
                        <span className="text-xl">Get Started Now</span>
                    </button>
                )
            }
        </div>
    )
}
export default GetStartedForm
