export const COLUMNS = [
    {
        Header: 'Features',
        accessor: 'features',
    },
    {
        Header: 'Google Ads',
        accessor: 'startup',
    },
    {
        Header: 'Facebook Ads',
        accessor: 'professional',
    },
    {
        Header: 'Tiktok Ads',
        accessor: 'enterprise',
    },
]
