import React, { useEffect } from 'react';
import MainLayout from '../../Layout/MainLayout/Index'
import MetaHead from '../../Components/MetaHead'
import { usePage } from '@inertiajs/inertia-react';
import { format, parseISO } from 'date-fns'
import mixpanel from 'mixpanel-browser'

const Confirm = () => {
    const { customParams } = usePage().props

    useEffect(() => {
        mixpanel.track("Confirmation Page Visited")
    }, [])

    return (
        <MainLayout>
            <MetaHead title="Thank-you from Gurulabs" description="Gurulabs Official Website" />
            <section>
                <div className="max-w-7xl mx-auto">
                    <div className="flex flex-col justify-center items-center py-16">
                        <img src="/frontend/images/gif/thankyou.webp" className="h-[700px] w-[700px] py-8 px-4" alt="" />
                        <h1 className="text-7xl py-2">{customParams.name}</h1>
                        <p className="text-3xl py-2">
                            Thank-you for booking some time with {customParams?.eventAssignerName} <br />
                        </p>
                        <p className="text-2xl py-2">
                            Your event starts: {format(parseISO(customParams?.eventStart), "MMM dd, yyyy, hh:mm aa")} <br />
                            Your event ends: {format(parseISO(customParams?.eventEnd), "MMM dd, yyyy, hh:mm aa")}
                        </p>
                    </div>
                </div>
            </section>
        </MainLayout>
    )
}
export default Confirm



