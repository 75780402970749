import React from 'react';
import PageLayout from '../../Layout/PageLayout/Index'
import { Link } from '@inertiajs/inertia-react';

export default function ErrorPage({ status }) {
    const title = {
        503: '503',
        500: '500',
        404: '404',
        403: '403',
    }[status];

    const description = {
        503: 'Sorry, we are doing some maintenance. Please check back soon.',
        500: 'Whoops, something went wrong on our servers.',
        404: 'Sorry, the page you are looking for could not be found.',
        403: 'Sorry, you are forbidden from accessing this page.',
    }[status];

    return (
        <PageLayout>
            <div className="max-w-7xl mx-auto">
                <div className="py-24 text-center">
                    <img src="/frontend/images/gif/rudra.webp" className="h-[1080px] w-[700px] py-8 px-4 mx-auto" alt="" />
                    <p className='text-2xl py-8'>Meet Rudra (The German Shephard Dog)</p>
                    <h1 className="text-7xl font-bold">{title}</h1>
                    <div className="text-2xl py-8">{description}</div>
                    <Link href="/" className="text-gray-700 button py-4 px-4 hover:underline">Go back home</Link>
                </div>
            </div>
        </PageLayout>
    );
}
