import React, { useEffect, useState } from 'react'
import PageLayout from '../../../../Layout/PageLayout/Index'
import MetaHead from '../../../../Components/MetaHead'
import LeadsForm from '../../../../Components/LeadsForm/Index'
import SEOPricing from '../../../../Components/SEOPricing/Index'
import Slider from '../../../../Components/Slider/Index'
import CalendarModal from '../../../../Layout/Modal/Index'
import mixpanel from 'mixpanel-browser'
import Newsletter from '../../../../Components/Newsletter/Index'
import SocialProof from '../../../../Components/SocialProof/Index'
import PaidAdsPricingTable from '../../../../Components/PaidAdsPricingTable/Index'
import CommerceSlider from '../../../../Components/CommerceSlider/Index'
import WebDesignFAQs from '../../../../Components/FAQs/WebDesignFAQs/Index'
import Process from '../../../../Components/Process/Index'
import WebsiteDesignPricing from '../WebsiteDesignPricing/Index'
import { SiAdobe, SiGoogleanalytics, SiGoogledomains, SiJavascript, SiTarget, SiTestinglibrary, SiGoogle } from '@icons-pack/react-simple-icons'


const WebsiteDesign = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false)

    useEffect(() => {
        mixpanel.track("Website Design Page Visited")
    }, [])

    return (
        <PageLayout>
            <MetaHead
                title="Web Design & Development Agency Washington DC - Gurulabs"
                description="Top web design and development agency in Washington DC. Gurulabs offers expert web design services for businesses in Washington DC."
            />
            <section className="">
                <div className="max-w-7xl mx-auto py-8 lg:py-48 px-4">
                    <div className="flex flex-col lg:flex-row justify-between items-center">
                        <div className="w-full lg:w-8/12 py-4">
                            <h1 className="text-3xl lg:text-6xl my-1">
                                We specialize in corss-platform
                                <span className="text-green-500 relative mx-2">
                                    Website
                                    <svg width="175" height="3" viewBox="0 0 175 3" fill="none" className="absolute hidden lg:block left-6">
                                        <path d="M1.43945 1.54395H173.903" stroke="#48BB78" strokeWidth="2" strokeLinecap="round" />
                                    </svg>
                                </span>
                                Development
                            </h1>
                            <p className="my-3">Gurulabs is your key to unlocking the potential of your website’s online visibility. Our experienced team of gurus use proven methods to increase your website’s search engine rankings and maximize your online traffic.</p>
                            <LeadsForm />
                        </div>
                        <div className="w-full lg:w-4/12 py-4">
                            <img src="/frontend/images/svg/icon-22.svg" className="mx-auto" alt="Website Design By Gurulabs" />
                        </div>
                    </div>
                </div>
            </section>
            <Slider />
            <section>
                <div className="bg-gray-50 py-12">
                    <div className="max-w-7xl mx-auto">
                        <div className='block sm:flex justify-center items-center px-4'>
                            <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                <span className="relative">Come take a look at what services plans</span>
                                <span className="text-green-500 ml-2 relative">
                                    we offer!
                                </span>
                                <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4 ml-16 hidden xl:block" width={151} height={3} fill="none"><path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                            </h2>
                        </div>
                        <WebsiteDesignPricing modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
                    </div>
                </div>
            </section>
            <SocialProof />
            <section>
                <div className="max-w-5xl mx-auto py-24">
                    <div className='block sm:flex sm:flex-col justify-center items-center px-4'>
                        <h2 className="block text-3xl sm:text-4xl py-5 relative">
                            <span className="relative">Simplifying your</span>
                            <span className="text-green-500 ml-2 relative">
                                Design & Development Process
                            </span>
                            <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-1/2 -mt-2 ml-16 hidden xl:block" width={238} height={17} fill="none"><path stroke="#48BB78" strokeLinecap="round" strokeWidth="2" d="M1.833 12.55c101.19 8.798 198.897-3.667 235.102-11" /></svg>
                        </h2>
                        <p className="text-base text-center">Our Lead generation process helps you identify potential customers or clients and gather their contact information to nurture them toward a sale.</p>
                    </div>
                </div>
                <div className="max-w-7xl mx-auto pb-24">
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
                        <Process
                            title="Define Objectives and Scope"
                            description="We begin by clearly defining the goals and objectives of the website. We understand your target audience, purpose, and desired features. Outline the scope of the project, including the number of pages, functionalities, and any specific design requirements."
                            button="button-3"
                            Icon={SiTarget}
                        />
                        <Process
                            title="Planning and Research"
                            description="We conduct thorough research on industry trends, competitor websites, and target audience preferences. A comprehensive plan that includes site architecture, navigation structure, and content strategy. It also involves selecting the technology stack."
                            button="button-2"
                            Icon={SiGoogleanalytics}
                        />
                        <Process
                            title="Design and Wireframing"
                            description="Develop wireframes and prototypes to visualize the layout and structure of the website. Create a user-friendly and visually appealing design that aligns with the brand identity. This step involves designing the user interface (UI) and user experience (UX), ensuring a seamless and intuitive navigation experience."
                            button="button-3"
                            Icon={SiAdobe}
                        />
                        <Process
                            title="Development"
                            description="Implement the design into a fully functional website during the development phase. This step involves coding, database development, and integrating necessary functionalities. Developers use programming languages such as HTML, CSS, JavaScript, and backend languages (e.g., PHP, NodeJS) to bring the design to life."
                            button="button-2"
                            Icon={SiJavascript}
                        />
                        <Process
                            title="Testing and Quality Assurance"
                            description="Thoroughly test the website to identify and fix any bugs or issues. Perform compatibility testing across various browsers and devices to ensure a consistent user experience. Check the functionality of interactive elements, forms, and other features. Quality assurance is crucial to delivering a reliable and error-free website."
                            button="button-3"
                            Icon={SiTestinglibrary}
                        />
                        <Process
                            title="Launch and Maintenance"
                            description="Once the website has passed testing and quality checks, it's ready for launch. We help Deploy the website to the hosting server, making it accessible to users. After launch, monitor the website's performance, gather user feedback, and address any issues that may arise."
                            button="button-2"
                            Icon={SiGoogledomains}
                        />
                    </div>
                </div>
            </section>
            <section>
                <div className="bg-gray-50 py-12">
                    <div className="max-w-7xl mx-auto">
                        <div>
                            <div className='block sm:flex justify-center items-center px-4'>
                                <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                    <span className="relative">We're an affordable</span>
                                    <span className="text-green-500 ml-2 relative">
                                        Website Development Agency
                                    </span>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4 ml-16 hidden xl:block" width={151} height={3} fill="none"><path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                                </h2>
                            </div>
                            <p className="py-4 text-lg text-center">Our web design and development process at Gurulabs helps you create a compelling online presence, optimize user experiences, and gather valuable insights to drive engagement and maximize your business's growth potential.</p>
                        </div>
                        <div className="px-4">
                            <div>
                                <p className="text-xl py-4 text-gray-700">At Gurulabs, our mission is to simplify the process of managing your web design and development projects. Our expert team leverages advanced strategies to ensure your website is both visually appealing and highly functional. With Gurulabs, you get a seamless web development experience that drives results and maximizes your online presence in Washington DC.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Simplified Web Design Process</strong></p>

                                <p className="text-xl py-4 text-gray-700">Our web design services in Washington DC are designed to make your life easier. We start with an in-depth analysis of your current website and identify areas for improvement. Our Washington DC web design company prides itself on its data-driven approach. We use advanced tools to monitor and optimize your website continuously. Whether you're starting a new project or looking to enhance your existing site, our web design services are tailored to meet your specific needs.</p>

                                <p className="text-xl py-4 text-gray-700">At Gurulabs, we understand the importance of targeted design. Our team specializes in creating customized web strategies that align with your business goals. With our Washington DC web design agency, you can expect precise design elements, intuitive navigation, and meticulous attention to detail. We handle everything from initial concept to final deployment, ensuring your website design in Washington DC is always on point.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Expertise in Web Development and Beyond</strong></p>

                                <p className="text-xl py-4 text-gray-700">Gurulabs is not just another web design company in Washington DC. We have extensive experience in managing various platforms and technologies, ensuring your site is built on a robust foundation. Our web development services are designed to boost your online presence and drive traffic to your site. We also excel in responsive design, ensuring your website looks great on any device.</p>

                                <p className="text-xl py-4 text-gray-700">Our holistic approach to web development services ensures that every aspect of your website is optimized for success. From user experience design to backend development, we cover all bases to enhance your web development experience. Our goal is to deliver top-notch web design services in Washington DC that not only meet but exceed your expectations.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Tailored Solutions for Your Business</strong></p>

                                <p className="text-xl py-4 text-gray-700">Every business is unique, and so are its website needs. At Gurulabs, we offer customized web design solutions that cater to your specific industry and audience. Whether you're looking for ecommerce web design or need help with local SEO, our Washington DC web design agency has you covered. Our team works closely with you to understand your objectives and craft a strategy that drives results.</p>

                                <p className="text-xl py-4 text-gray-700">We believe that effective web design requires constant learning and adaptation. Our experts stay updated with the latest trends and best practices in the industry. This ensures that your web development projects are always ahead of the curve. With Gurulabs, you get a partner who is committed to your success and growth.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Maximizing Your Online Presence</strong></p>

                                <p className="text-xl py-4 text-gray-700">At Gurulabs, we measure our success by the results we deliver to our clients. Our web design services are focused on maximizing your online presence. We employ a combination of proven techniques and innovative strategies to ensure your website is effective and high-performing. With our Washington DC web design company, you can be confident that your online presence is in good hands.</p>

                                <p className="text-xl py-4 text-gray-700">In conclusion, Gurulabs Web Design Agency in Washington DC offers a comprehensive suite of services designed to simplify and enhance your web design and development process. From in-depth analysis to ongoing optimization, we provide end-to-end web development solutions that drive results. Partner with Gurulabs today and take your website to the next level.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Newsletter />
            <WebDesignFAQs />
            <section>
                <div className="bg-amber-50 hero-reviews py-12">
                    <div className="max-w-7xl mx-auto overflow-hidden">
                        <div className='block sm:flex justify-center items-center py-16 px-4'>
                            <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                <span className="relative">Hear from our Lead Gen</span>
                                <span className="text-green-500 ml-2 relative">
                                    Clients!
                                </span>
                                <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4  hidden xl:block" width={151} height={3} fill="none"><path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                            </h2>
                        </div>
                        <CommerceSlider />
                    </div>
                </div>
            </section>
            <CalendarModal isOpen={modalIsOpen} setIsOpen={setModalIsOpen} />
        </PageLayout>
    )
}
export default WebsiteDesign
