import React, { useEffect } from 'react'
import MetaHead from '../../Components/MetaHead'
import BlogLayout from '../../Layout/BlogLayout/Index';
import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/24/outline'
import { Link } from '@inertiajs/inertia-react';
import mixpanel from 'mixpanel-browser';

const BlogIndexPage = ({ posts }) => {
    // console.log('Posts', posts);

    useEffect(() => {
        mixpanel.track("Blog Page Visited")
    }, [])

    return (
        <BlogLayout>
            <MetaHead title="Blog Posts - Digital Marketing Agency | Gurulabs" description="Blog Posts - Digital Marketing Agency | Gurulabs" />
            <section>
            </section>
            <section>
                <div className="max-w-7xl mx-auto">
                    <div className="px-4 py-8 mx-auto grid grid-cols-1 lg:grid-cols-3">
                        {
                            posts.data.map((post) => (
                                <div className="max-w-2xl mx-auto" key={post.id}>
                                    <Link className="px-4" href={`/blog/${post.slug}`}>
                                        <img className="px-2 w-full" height={500} width={500} src={post.featured_image} alt={post.title} />
                                        <h1 className="px-2 pt-4 text-2xl text-gray-700">{post.title}</h1>
                                        <p className="px-2 pt-4 text-gray-500">{post.excerpt.substring(0, 150)}...</p>
                                    </Link>
                                </div>
                            ))
                        }
                    </div>
                    <div className='py-8 max-w-xl md:max-w-7xl mx-auto'>
                        <nav className="flex items-center justify-between px-4 sm:px-0">
                            <div className="-mt-px flex w-0 flex-1">
                                {
                                    posts.prev_page_url ? (
                                        <a
                                            href={posts.prev_page_url}
                                            className="inline-flex items-center border-t-2 border-transparent pt-4 pr-1 text-sm font-medium text-gray-700 hover:border-gray-300 hover:text-gray-700"
                                        >
                                            <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                            Previous
                                        </a>
                                    ) : ''

                                }

                            </div>
                            <div className="-mt-px flex w-0 flex-1 justify-end">
                                {posts.next_page_url ? (
                                    <a
                                        href={posts.next_page_url}
                                        className="inline-flex items-center border-t-2 border-transparent pt-4 pl-1 text-sm font-medium text-gray-700 hover:border-gray-300 hover:text-gray-700"
                                    >
                                        Next
                                        <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </a>) : ''
                                }
                            </div>
                        </nav>
                    </div>
                </div>
            </section>
        </BlogLayout>
    )

}
export default BlogIndexPage
