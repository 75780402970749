import { Link } from "@inertiajs/inertia-react";
import React, { Component, Fragment, useEffect, useState } from "react";

export default function Banner() {
    const [show, setShow] = useState(false);

    useEffect(() => {
        const show = localStorage.getItem("show");
        if (show === "false") {
            setShow(false);
        } else {
            setShow(true);
        }
    }, [show]);

    const handleClose = () => {
        localStorage.setItem("show", "false");
        setShow(false);
    };

    if (show) {
        return (
            <div id="banner" className="bg-green-50 topbar">
                <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6">
                    <div className="flex items-center justify-between flex-wrap">
                        <div className="w-0 flex-1 flex items-center">
                            <span className="flex p-2 bg-green-100">
                                {/* Heroicon name: speakerphone */}
                                <svg
                                    className="h-6 w-6 text-green-500"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
                                    />
                                </svg>
                            </span>
                            <p className="ml-3 font-medium text-gray-700 truncate">
                                <span className="md:hidden">
                                    Website Updated {new Date().getDate()} {new Date().toLocaleString('default', { month: 'long' })} {new Date().getFullYear()}!  <a href="//seo.gurulabs.dev" className="text-white underline">Download SEO & Lead Generation Playbook</a>
                                </span>
                                <span className="hidden md:inline text-xl">
                                    Ready for your next digital endavour? <a href="//gurulabs.dev/get-started" className="text-gray-700 underline">Get started now! </a>
                                    {/* We've refreshed the site Dive in and discover what's new! */}
                                </span>
                            </p>
                        </div>
                        <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
                            <button type="button" onClick={handleClose} className="-mr-1 flex rounded-md p-2 hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2">
                                <span className="sr-only">Dismiss</span>
                                <svg className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return null;
    }
}
