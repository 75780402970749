import React from 'react'
import { Head, usePage } from '@inertiajs/inertia-react'

function SEOMetaHead({ title, description, convertKitPage }) {
    const { props } = usePage()
    console.log(props.canonicalUrl);
    return (
        <Head>
            <title>{title}</title>
            <meta name="description" content={description} />
            <link rel="canonical" href={props.canonicalUrl} />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Gurulabs" />
            <meta property="og:description" content="Internet Marketing Agency" />
            <meta property="og:url" content={props.canonicalUrl} />
            <meta property="og:site_name" content="Gurulabs" />
            <meta property="og:image" content="https://www.gurulabs.dev/frontend/images/png/logo-01.png" />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@myadsgurus" />
            <meta name="twitter:title" content="Gurulabs" />
            <meta name="twitter:description" content={props.canonicalUrl} />
            <meta name="twitter:image" content="https://www.gurulabs.dev/frontend/images/png/logo-01.png" />
            <meta name="twitter:label1" content="Internet Marketing Agency" />
            <meta name="twitter:data1" content="Washington DC" />
            <meta name="twitter:label2" content="Lead Generation Agency" />
            <meta name="twitter:data2" content="Washington DC" />
            <script async data-uid="5a9ed0f555" src={convertKitPage}></script>
        </Head>
    )
}

export default SEOMetaHead
