import React from 'react'
import { createRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/inertia-react'
import { InertiaProgress } from '@inertiajs/progress'
import mixpanel from 'mixpanel-browser';
import { MixpanelProvider, MixpanelConsumer } from 'react-mixpanel';
import { Toaster } from 'react-hot-toast';

InertiaProgress.init({
  // The delay after which the progress bar will
  // appear during navigation, in milliseconds.
  delay: 250,

  // The color of the progress bar.
  color: '#48bb78',

  // Whether to include the default NProgress styles.
  includeCSS: true,

  // Whether the NProgress spinner will be shown.
  showSpinner: true,
})

console.log(process.env.APP_ENV)

mixpanel.init('1b3939a20ce7dd2ab3e91b0565e2193d', {
    debug: false,
    track_pageview: true,
    persistence: 'localStorage',
    cookie_name: 'gurulabs_mixpanel',
    cookie_expiration: 365,
    secure_cookie: true,
    ip: true,
    property_blacklist: ['password'],
    localStorage: {
        enabled: true,
        name: 'inertia-mixpanel'
    },
    error: function(err) {
        console.log('Mixpanel error', err);
    }
});

function printAsciiArt() {
const asciiArt = `
_____                __        _
|   __|_ _ ___ _ _   |  |   ___| |_ ___
|  |  | | |  _| | |  |  |__| .'| . |_ -|
|_____|___|_| |___|  |_____|__,|___|___|
Don't trust the code, trust the process.
We're always partnering with code enthusiasts
to build amazing products. Drop us a line at
hello@gurulabs.dev
            `;
console.log(asciiArt);
}

printAsciiArt()

createInertiaApp({
  resolve: (name) => require(`./Pages/${name}`),
  setup({ el, App, props }) {
    const root = createRoot(el);
    root.render(
        <MixpanelProvider mixpanel={mixpanel}>
          <App {...props} />
          <Toaster />
        </MixpanelProvider>
    );
  },
})
