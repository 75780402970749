import mixpanel from 'mixpanel-browser';
import React, { useEffect, useState } from 'react';
import { Link, usePage } from '@inertiajs/inertia-react';
import Slider from '../../../../Components/Slider/Index';
import PageLayout from '../../../../Layout/PageLayout/Index';
import Newsletter from '../../../../Components/Newsletter/Index';
import PPCMetaHead from '../../../../Components/PPCMetaHead/Index';
import PaidAdsLeadsForm from '../../../../Layout/PaidAdsLeadsForm/Index';
import PaidAdsPricing from '../../../../Components/PaidAdsPricing/Index';
import PaidAdsPricingTable from '../../../../Components/PaidAdsPricingTable/Index';
import Process from '../../../../Components/Process/Index';
import SocialProof from '../../../../Components/SocialProof/Index';
import PPCFAQs from '../../../../Components/FAQs/PPCFAQs/Index';
import { SiFacebook, SiInstagram, SiLinkedin, SiTiktok, SiYoutube, SiTwitter } from '@icons-pack/react-simple-icons';


// More imports as required...

const OxnardCAPPCPage = ({ listings }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const { city, state, cities } = usePage().props;
    const [weather, setWeather] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        mixpanel.track(`${city.name} Page Visited`)

    }, []);

    return (
        <PageLayout>
            <PPCMetaHead
                    title={`${city.name} PPC Agency | Pay-Per-Click Services in ${state.code} | Gurulabs`}
                    description={`Get top-notch PPC services in ${city.name}, ${state.code} with Gurulabs. Contact us for expert PPC management to grow your business.`}
             />
           <section className="max-w-7xl mx-auto overflow-hidden">
                <div className="">
                    <div className='py-4'>
                        <h1 className="py-4 text-3xl lg:text-6xl text-center my-1">Affordable
                            <span className="text-green-500 relative mx-2">
                                Pay Per Click
                                <svg width="175" height="3" viewBox="0 0 175 3" fill="none" className="absolute hidden lg:block left-6">
                                    <path d="M1.43945 1.54395H173.903" stroke="#48BB78" strokeWidth="2" strokeLinecap="round" />
                                </svg>
                            </span>
                            Management Agency in {city.name}, {state.code}</h1>
                    </div>
                    <div className='py-4' >
                        {
                            isMobile ? (
                                <div className='grid grid-cols-1 lg:grid-cols-2'>
                                    <div className="px-4 w-[400px] h-[720px] mx-auto aspect-w-16 aspect-h-9">
                                        <video autoPlay loop muted controls playsInline className="mx-auto">
                                            <source src="/frontend/videos/intro.mp4" type="video/mp4" />
                                        </video>
                                    </div>
                                    <PaidAdsLeadsForm />
                                </div>
                            ) : (
                                <div className='grid grid-cols-1 lg:grid-cols-2'>
                                    <div className="px-4 w-[400px] h-[720px] mx-auto aspect-w-16 aspect-h-9">
                                        <video autoPlay loop muted controls playsInline className="mx-auto">
                                            <source src="/frontend/videos/intro.mp4" type="video/mp4" />
                                        </video>
                                    </div>
                                    <PaidAdsLeadsForm />
                                </div>
                            )
                        }

                    </div>
                </div>
            </section>
            <Slider />
            <section>
                <div className="bg-gray-50 py-12">
                    <div className="max-w-7xl mx-auto">
                        <div className="block sm:flex justify-center items-center px-4">
                            <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                <span className="relative">Explore Our Service Plans</span>
                                <span className="text-green-500 ml-2 relative">in {city.name}, {state.code}!</span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="absolute left-3/4 ml-16 hidden xl:block"
                                    width={151}
                                    height={3}
                                    fill="none"
                                >
                                    <path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" />
                                </svg>
                            </h2>
                        </div>
                        <PaidAdsPricing modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} city={city.name} state={state.code} />
                    </div>
                </div>
            </section>
            <section>
                <div className="max-w-5xl mx-auto py-24">
                    <div className="block sm:flex sm:flex-col justify-center items-center px-4">
                        <h2 className="block text-3xl sm:text-4xl py-5 relative">
                            <span className="relative">Tech-Enabled</span>
                            <span className="text-green-500 ml-2 relative">Social Media Ads Management</span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="absolute left-1/2 -mt-2 ml-16 hidden xl:block"
                                width={238}
                                height={17}
                                fill="none"
                            >
                                <path stroke="#48BB78" strokeLinecap="round" strokeWidth="2" d="M1.833 12.55c101.19 8.798 198.897-3.667 235.102-11" />
                            </svg>
                        </h2>
                        <p className="text-base text-center">
                            Generate quality leads in {city.name} with our expert social media ads management services.
                        </p>
                    </div>
                </div>
                <div className="max-w-7xl mx-auto pb-24 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    <Process
                        title="Facebook Ads Management"
                        description={`In the vibrant city of ${city.name}, ${state.name}, our Facebook Ads management services are designed to engage the dynamic local community. Utilizing advanced targeting, we tap into the city's diverse interests—from music and arts to tech innovations—ensuring your ads reach and resonate with the ideal audience.`}
                        button="button-3"
                        Icon={SiFacebook}
                    />
                    <Process
                        title="LinkedIn Ads Management"
                        description={`Leverage LinkedIn Ads with Gurulabs in ${city.name}, ${state.name}, to connect with the city's bustling professional scene. Our campaigns target key decision-makers in major local industries, from tech startups to established corporations, driving high-quality leads and fostering valuable B2B relationships.`}
                        button="button-2"
                        Icon={SiLinkedin}
                    />
                    <Process
                        title="YouTube Ads Management"
                        description={`Gurulabs offers expert YouTube Ads management in ${city.name}, ${state.name}, tailored to captivate the city's tech-savvy and creative populace. We create engaging video content and strategic placements that convert viewers into customers, enhancing your brand's visibility in this innovative city.`}
                        button="button-3"
                        Icon={SiYoutube}
                    />
                    <Process
                        title="Twitter (X) Ads Management"
                        description={`Our Twitter Ads management services in ${city.name}, ${state.name}, harness the platform’s real-time capabilities to create impactful campaigns. By tapping into local trends and conversations, we increase your brand’s visibility and drive traffic, engaging the city's active social media users with data-driven strategies.`}
                        button="button-2"
                        Icon={SiTwitter}
                    />
                    <Process
                        title="Instagram Ads Management"
                        description={`Choose Gurulabs for Instagram Ads management in ${city.name}, ${state.name}, and benefit from visually stunning ads that capture the city's vibrant culture. From scenic landscapes to bustling urban scenes, our precise targeting ensures significant engagement and business growth within this lively community.`}
                        button="button-3"
                        Icon={SiInstagram}
                    />
                    <Process
                        title="TikTok Ads Management"
                        description={`Tap into TikTok’s dynamic audience with our ads management in ${city.name}, ${state.name}. Our team creates shareable and entertaining content that captures the essence of the city’s youthful energy and creativity, maximizing visibility and engagement for your brand.`}
                        button="button-2"
                        Icon={SiTiktok}
                    />
                </div>
            </section>
            <section className="bg-gray-50 py-12 px-4">
                <div className="max-w-7xl mx-auto overflow-hidden">
                    <div className="text-xl py-4 text-gray-700 prose prose-lg">
                        <h1>Welcome to Oxnard: A Gem of California's Central Coast</h1>

<p>Oxnard, a coastal city nestled in Ventura County, California, is a vibrant hub of culture, history, and opportunity. From its rich agricultural heritage to its stunning beaches and diverse community, Oxnard offers a unique blend of experiences for residents and visitors alike. In this detailed guide, we'll delve into the major features, historical background, cultural significance, and the potential for digital marketing, specifically PPC advertising, in Oxnard.</p>

<h2>The History and Heritage of Oxnard</h2>

<p>Established in 1903, Oxnard has deep roots in agriculture, particularly in the cultivation of lima beans and sugar beets. The city was named after Henry T. Oxnard, a prominent entrepreneur who played a key role in developing the local sugar industry. Over the years, Oxnard has evolved into a thriving urban center while still maintaining its agricultural heritage through the annual California Strawberry Festival, celebrating the region's prolific strawberry harvest.</p>

<h2>Exploring Oxnard's Cultural Scene</h2>

<p>Oxnard boasts a diverse cultural scene, with museums, galleries, and festivals that showcase the city's artistic and historic treasures. The Carnegie Art Museum, housed in a beautifully restored building, features exhibitions highlighting local and international artists. The Heritage Square, a collection of Victorian-era homes, offers a glimpse into Oxnard's past and hosts various events throughout the year.</p>

<h2>Revel in Oxnard's Natural Beauty</h2>

<p>One of Oxnard's biggest draws is its picturesque coastline, with miles of pristine beaches that beckon residents and tourists alike. From the surf-friendly shores of Silver Strand Beach to the serene expanses of Mandalay Beach, Oxnard offers a paradise for outdoor enthusiasts. The Channel Islands Harbor is a bustling marina where visitors can embark on whale-watching excursions or enjoy waterfront dining with stunning views.</p>

<h2>The Potential of Digital Marketing in Oxnard</h2>

<p>As businesses in Oxnard strive to reach a wider audience and drive growth, digital marketing, particularly PPC advertising, has emerged as a powerful tool to enhance brand visibility and attract potential customers. PPC advertising allows businesses to target specific keywords and demographics, ensuring that their ads reach the right audience at the right time.</p>

<h3>Benefits of PPC Advertising for Oxnard Businesses</h3>

<ul>
    <li><strong>Targeted Reach:</strong> PPC advertising enables businesses in Oxnard to reach local customers actively searching for their products or services, increasing the likelihood of conversions.</li>
    <li><strong>Cost-Effective:</strong> With PPC advertising, businesses only pay when users click on their ads, making it a cost-effective marketing strategy with measurable results.</li>
    <li><strong>Instant Visibility:</strong> PPC campaigns can quickly boost a business's online visibility, allowing them to compete effectively in the digital landscape.</li>
    <li><strong>Customization Options:</strong> Businesses can tailor their PPC ads based on location, device, and keywords, ensuring maximum relevance to their target audience in Oxnard.</li>
</ul>

<h3>The Local Business Environment in Oxnard</h3>

<p>Oxnard's business landscape is characterized by a mix of industries, including agriculture, manufacturing, tourism, and technology. The city's proximity to major urban centers like Los Angeles and its access to transportation networks make it an attractive location for businesses seeking growth opportunities. From family-owned farms to tech startups, Oxnard offers a diverse ecosystem for entrepreneurs and established companies alike.</p>

<h3>Target Audience in Oxnard</h3>

<p>The diverse population of Oxnard presents a vast array of target audiences for businesses looking to engage with local customers. Whether targeting beachgoers seeking recreational activities, food enthusiasts exploring the city's culinary scene, or young professionals in search of entertainment options, businesses in Oxnard can tailor their marketing strategies to resonate with specific audience segments.</p>

<h2>Conclusion</h2>

<p>In conclusion, Oxnard stands out as a dynamic city with a rich history, cultural vibrancy, and abundant natural beauty. For businesses in Oxnard looking to elevate their online presence and connect with local customers, PPC advertising offers a strategic approach to digital marketing. By leveraging the benefits of PPC advertising and understanding the local business environment and target audience, businesses in Oxnard can position themselves for success in the competitive market landscape.</p>

<p>Explore the possibilities in Oxnard, where history meets modernity, and where businesses thrive amidst a backdrop of coastal charm and entrepreneurial spirit.</p>
                    </div>
                </div>
            </section>
            {listings && listings.length > 0 ? (
                <section>
                    <div className="max-w-7xl mx-auto">
                        <div className="max-w-5xl mx-auto py-24">
                            <div className="block sm:flex sm:flex-col justify-center items-center px-4">
                                <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                    <span className="relative">We support small business</span>
                                    <span className="text-green-500 ml-2 relative">near {city.name}, {state.code}</span>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="absolute left-1/2 -mt-2 ml-16 hidden xl:block"
                                        width={238}
                                        height={17}
                                        fill="none"
                                    >
                                        <path stroke="#48BB78" strokeLinecap="round" strokeWidth="2" d="M1.833 12.55c101.19 8.798 198.897-3.667 235.102-11" />
                                    </svg>
                                </h2>
                                <p className="text-base text-center">
                                    Generate quality leads in {city.name} with our expert social media ads management services.
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-wrap justify-center items-center">
                            {listings
                                .filter(listing => listing.website && listing.phone) // Only show listings with a valid image_url
                                .map((listing, index) => (
                                        <div key={listing.id || index} className="w-full sm:w-1/2 lg:w-1/3 px-3 mb-6">
                                            <div className="p-4 max-w-md rounded-lg overflow-hidden shadow-lg bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out">
                                                <div className="p-4">
                                                    <h2 className="font-bold text-2xl mb-4 text-gray-800 whitespace-nowrap overflow-hidden text-ellipsis">{listing.name}</h2>
                                                    <div className="mb-4 text-gray-600">
                                                        <p>{listing.address}</p>
                                                        <p>{listing.city}, {listing.state} {listing.zip}</p>
                                                        <p>{listing.country || ''}</p>
                                                    </div>
                                                    <p className="text-gray-600 text-md">{listing.category}</p>
                                                    <p className="text-gray-600 text-md">Rating: {listing.rating || 'No rating available'} ⭐</p>
                                                    <p className="text-gray-600 text-md pb-4">Phone:  <a href={`tel:${listing.phone}`} className="text-green-600 hover:underline mb-4">
                                                        {listing.phone}
                                                    </a>
                                                    </p>
                                                    {listing.website && (
                                                        <a
                                                            href={`${listing.website.includes('http') ? listing.website : `https://${listing.website}`}?utm_source=gurulabs&utm_medium=referral&utm_campaign=${city.name}-${state.code}-ppc`}
                                                            className="relative w-full py-2 px-3 top-1 right-2 button rounded-sm text-gray-700 hover:bg-green-500"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            Visit Website
                                                        </a>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                ))
                            }
                        </div>
                    </div>
                </section>
            ) :

                (
                    <>
                    </>
                )}
            <section>
                <div className="bg-amber-50 py-12">
                    <div className="max-w-7xl mx-auto">
                        <div>
                            <div className='block sm:flex justify-center items-center px-4'>
                                <h1 className="block text-3xl sm:text-4xl py-5 relative">
                                    <span className="relative">Cities We Serve in {state.name}</span>
                                    <span className="text-green-500 ml-2 relative">
                                        for PPC Services
                                    </span>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4 ml-16 hidden xl:block" width={151} height={3} fill="none"><path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                                </h1>
                            </div>
                            <div className="px-4">
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                    {cities
                                        .sort(() => Math.random() - 0.5) // Shuffle the array
                                        .slice(0, 21) // Select the first 21 cities after shuffling
                                        .map((city) => (
                                            <Link key={city.id} href={`/ppc/${state.slug}/${city.slug}`} className="text-green-500">
                                                Pay Per Click Management near {city.name}
                                            </Link>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <PaidAdsPricingTable city={city.name} state={state.code} />
            <Newsletter />
            <section>
                <div className="bg-gray-50 py-12">
                    <div className="max-w-7xl mx-auto">
                        <div className="block sm:flex justify-center items-center px-4">
                            <h1 className="block text-3xl sm:text-4xl py-5 relative">
                                <span className="relative">Your Reliable Partner for </span>
                                <span className="text-green-500 ml-2 relative">
                                    PPC Management in {city.name}, {state.code}
                                </span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="absolute left-3/4 ml-16 hidden xl:block"
                                    width={151}
                                    height={3}
                                    fill="none"
                                >
                                    <path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" />
                                </svg>
                            </h1>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 px-4">
                            <div>
                                <p className="text-xl py-4 text-gray-700">
                                    <strong>Real Success Stories from {city.name}</strong>
                                </p>
                                <p className="text-xl py-4 text-gray-700">
                                    Our PPC strategies have driven significant growth for businesses in {city.name}. We've helped a local retailer increase sales by 50% in three months and a service provider double their client base with targeted ad campaigns.
                                </p>
                                <p className="text-xl py-4 text-gray-700">
                                    A tech startup saw a 70% reduction in their cost per acquisition while scaling their campaigns. Our data-driven approach and continuous optimization ensure sustainable growth and profitability.
                                </p>
                                <p className="text-xl py-4 text-gray-700">
                                    These success stories highlight our commitment to delivering measurable results. Whether you're a small business or a growing enterprise, our PPC strategies are designed to help you thrive in {city.name}.
                                </p>
                            </div>
                            <div>
                                <p className="text-xl py-4 text-gray-700">
                                    <strong>Commitment to Excellence</strong>
                                </p>
                                <p className="text-xl py-4 text-gray-700">
                                    Transparency, integrity, and exceptional results are the pillars of our PPC management. We provide detailed reports and regular updates, ensuring you understand the impact of every dollar spent.
                                </p>
                                <p className="text-xl py-4 text-gray-700">
                                    Our team is dedicated to understanding your business and creating PPC strategies that contribute to your overall growth. We focus on long-term success through continuous optimization and strategic adjustments.
                                </p>
                                <p className="text-xl py-4 text-gray-700">
                                    Experience the difference with a partner dedicated to driving your success in {city.name}. Our local expertise and industry knowledge ensure highly targeted campaigns that resonate with your audience.
                                </p>
                                <p className="text-xl py-4 text-gray-700">
                                    By choosing us, you are choosing a partner invested in your success. We are passionate about helping businesses in {city.name} thrive and are here to support you every step of the way.
                                </p>
                                <p className="text-xl py-4 text-gray-700">
                                    Discover more about our <a href="https://www.gurulabs.dev/paid-ads" className="font-bold underline">Paid Ads services</a> and how they can benefit your business in {city.name}, {state.code}.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <SocialProof city={city.name} state={state.code} />
            <PPCFAQs city={city.name} state={state.code} />
            {/* Rest of the JSX */}
        </PageLayout>
    );
};

export default OxnardCAPPCPage;